import { selector } from 'recoil';
import { BuyerFeature, UserGroup } from '../Commons/types.d';

const userPermissionState = selector({
  key: 'userPermssionState',
  get: () => {
    const {
      id: userId,
      group: {
        id: group = 0,
        original_id: originalGroup = 0,
      } = {},
      features: {
        results: features = [],
      } = {},
    } = window?.context || {};

    const userGroup = group || originalGroup;

    const isStaff = userGroup === UserGroup.STAFF || userGroup === UserGroup.ACCOUNTANT;
    const isProvider = userGroup === UserGroup.PROVIDER;
    const isManager = userGroup === UserGroup.MANAGER;
    const isDispatcher = userGroup === UserGroup.DISPATCHER;
    const isServiceCompany = features?.some(
        ({ id }) => id === BuyerFeature.PERFORM_MARKETPLACE_WORK
    );
    const isBuyer = features?.some(({ id }) => id === BuyerFeature.BUY_FROM_MARKETPLACE);

    return {
      userId,
      userGroup,
      isStaff,
      isProvider,
      isManager,
      isDispatcher,
      isServiceCompany,
      isBuyer,
    };
  },
});

export default userPermissionState;
