export function error(message, title = 'Error') {
  if (window._notificationSystem) {
      if (message && message.obj && message.obj.message) {
          message = message.obj.message;
      }

      if (message && message.status == 500) {
          try {
              const json = JSON.parse(message.statusText);
              message = json.error.error.message;
          } catch (e) {
              // ignore
          }
      }

      window._notificationSystem.addNotification({
          title,
          autoDismiss: 0,
          message,
          position: 'br',
          level: 'error',
      });
  }
}

export function success(message, title = 'Success', children = null) {
  if (window._notificationSystem) {
      window._notificationSystem.addNotification({
          title,
          autoDismiss: 5,
          message,
          position: 'br',
          level: 'success',
          children,
      });
  }
}

export function info(message, title = 'Info', children = null) {
  if (window._notificationSystem) {
      window._notificationSystem.addNotification({
          title,
          autoDismiss: 5,
          message,
          position: 'br',
          level: 'info',
          children,
      });
  }
}
