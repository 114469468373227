/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Grid,
  GridColumn,
  Median,
  MedianAlpha,
  MedianOmega,
  v2,
} from '@fieldnation/platform-components';

import css from '../styles.scss';
import { useEditPermission } from '../../../hooks';
import { ProfileInfoItems, RequestTypes, VALIDATION_DEBOUNCE_TIME } from '../../../constants';
import {
  OptionalText,
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
  InvalidInputHelpMessage,
} from '../../../components';
import {
  getInputFieldAtom,
  getInputValidationFieldAtom,
  getInputValidatorCallback,
  getRequestConfigCallback,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  passwordProtectedRequestAtom,
  resetFormCallback,
  resetValidationErrorsCallback,
  userContextSelector,
} from '../state';

const { TextInput } = v2;

const PhoneNumberEditFrom = () => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.PhoneNumber),
  );

  const atoms = ['phone', 'phone_ext'];

  const [
    [currentPhone, setCurrentPhone],
    [currentPhoneExt, setCurrentPhoneExt],
  ] = atoms.map((atomName) => useRecoilState(getInputFieldAtom(atomName)));

  const [phoneError, phoneExtError] = atoms.map((atomName) =>
    useRecoilValue(getInputValidationFieldAtom(atomName)),
  );

  const phoneExtText = currentPhoneExt ? ` ext. ${currentPhoneExt}` : '';

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);

  const onSave = async () => {
    const requestConfig = await getRequestConfig(RequestTypes.UPDATE_PHONENUMBER);

    const updateCallback = async () => {
      const success = await makeRequest(
        requestConfig,
        'Successfully updated phone number',
      );
      if (success) await resetForm(atoms);
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)(...atoms);

  const inputFieldValidator = useRecoilCallback(getInputValidatorCallback);
  const resetInputErrors = useRecoilCallback(resetValidationErrorsCallback);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'phone';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentPhone, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'phone_ext';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentPhoneExt, isEditing]);

  return (
    <div>
      {!isEditing && (
        <BodyText styleLevel="md" tag="span">
          <span
            className={classNames({
              [css['list-item-content']]: true,
              [css['list-item-content-empty']]: !currentPhone,
            })}
          >
            {`${currentPhone || 'No phone number'}${phoneExtText}`}
          </span>
        </BodyText>
      )}
      {isEditing && (
        <Grid vSpace={false}>
          <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
            <Grid>
              <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                <TextInput
                  label="Phone Number"
                  optional
                  type="phone"
                  value={currentPhone}
                  onChange={(e) => {
                    setCurrentPhone(e.target.value);
                  }}
                />
                <InvalidInputHelpMessage validationError={phoneError} />
              </GridColumn>
              <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                <div className={css['hide-number-type-arrow']}>
                  <TextInput
                    label="Ext."
                    optional
                    type="text"
                    value={currentPhoneExt}
                    onChange={(e) => {
                      setCurrentPhoneExt(e.target.value);
                    }}
                  />
                </div>
                <InvalidInputHelpMessage validationError={phoneExtError} />
              </GridColumn>
            </Grid>
            <ProfileInfoFormActionButton
              onClose={onClose}
              onSave={onSave}
              isValid={phoneError.isValid && phoneExtError.isValid}
            />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export const PhoneNumber = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.PhoneNumber);
  const setOpenForEdit = useSetRecoilState(openForEditAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.PhoneNumber),
  );

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="div">
        <span className={css['list-item-name']}>
          Other Phone
          <OptionalText />
        </span>
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top">
        <MedianAlpha>
          <PhoneNumberEditFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="phone number" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.PhoneNumber);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
