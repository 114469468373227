/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useState } from 'react';

export enum EVENTS {
  UPDATED_ADDRESS = 'Updated Address',
  CONFIRMED_SCREENING_ORDER_DETAILS = 'Confirmed Order Details',
  SUBMITTED_SCREENING_PAYMENT = 'Submitted Payment',
  ADDED_LICENSE_OR_CERTIFICATION = 'Added License or Certification',
  EDITED_LICENSE_OR_CERTIFICATION = 'Edited License or Certification',
  PACKAGE_USAGE_NAVIGATED = 'Navigated',
  STARTED_ADDING_LICENSE_OR_CERTIFICATION = 'Started Adding License or Certification',
}

interface IJsObject<T = unknown> {
  [key: string]: T;
}

export type ITrackEvent = (
  eventType: EVENTS,
  eventProperties: IJsObject,
  callback?: () => void,
) => number | undefined;

export const useAmplitudeTracker = (): ITrackEvent => {
  const [client, setClient] = useState<IJsObject<ITrackEvent>>({});

  useEffect(() => {
    // @ts-ignore
    if (!client?.logEvent && window?._amplitudeClient?.logEvent) {
      // @ts-ignore
      setClient(window?._amplitudeClient);
    }
  }, []);

  return useCallback(
    (eventType: EVENTS, eventProperties: IJsObject, callback?: () => void) => {
      if (client?.logEvent) {
        return client.logEvent(eventType, eventProperties, callback);
      }
      console.warn('No client found.', eventType, eventProperties, callback);
      return 0;
    },
    [client],
  );
};
