import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn,
  Link,
} from '@fieldnation/platform-components';

interface IProps {
  id: number;
  selectedCount: number;
  optionCount: number;
  onChange: (id: number, arr: unknown) => void;
}

const CustomFooter = ({
  id, selectedCount, optionCount, onChange,
}: IProps): JSX.Element => (
  <div className="u-padding--md">
    <Grid>
      <GridColumn xs="6">
        { selectedCount > 0 ? (
          <Link
            onClick={() => onChange(id, [])}
          >
            Deselect all
          </Link>
        ) : ' '}
      </GridColumn>
      <GridColumn xs="6">
        <div className="text-muted u-textRight">{`${selectedCount} / ${optionCount} selected`}</div>
      </GridColumn>
    </Grid>
  </div>
);

CustomFooter.propTypes = {
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCount: PropTypes.number.isRequired,
  optionCount: PropTypes.number.isRequired,
};

export default CustomFooter;
