enum VerificationStatus {
  IN_REVIEW = 0,
  VERIFIED = 1,
  UNVERIFIED = 2,
  EXPIRED = 3,
  DENIED = 4,
}

export enum PAGE {
  SELECT_CREDENTIAL= 1,
  ADD_EDIT_CREDENTIAL= 2,
}

const getUserCredentialStatus = (status: number): string => {
  switch (status) {
    case VerificationStatus.IN_REVIEW:
      return 'IN_REVIEW';
    case VerificationStatus.VERIFIED:
      return 'VERIFIED';
    case VerificationStatus.EXPIRED:
      return 'EXPIRED';
    case VerificationStatus.UNVERIFIED:
      return 'UNVERIFIED';
    case VerificationStatus.DENIED:
      return 'DENIED';
    default:
      return '';
  }
};

export default getUserCredentialStatus;
