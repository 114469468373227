/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { selector, DefaultValue } from 'recoil';
import {
  ColumnConfig,
  Setting,
  defaultSettings,
  snapshotState,
} from './snapshot.atom';
import trackAmplitudeEvent, {
  CUSTOMIZED_PROVIDER_DRAWER,
} from '../amplitude';
import skillsQuery from './SkillsQuery';

const getSidebarConfigQuery = selector({
  key: 'getSidebarConfigQuery',
  get: ({ get }) => {
    const canViewSkillDiscovery = get(skillsQuery);
    const oldSidebarState: ColumnConfig = JSON.parse(get(snapshotState));
    let sidebarState: ColumnConfig = oldSidebarState;

    // disable provider skills section
    if (!canViewSkillDiscovery) {
      sidebarState = {
        ...oldSidebarState,
        columns: oldSidebarState.columns.map((x: any) => {
          // @ts-ignore
          if (x.setting === 'Provider Skills') {
            return { ...x, enabled: false };
          }
          return x;
        }),
      };
    }

    const defaultColumns: ColumnConfig = defaultSettings;
    const newSideBarState: ColumnConfig = {
      ...sidebarState,
    };
    let newColumnsFound = false;

    // Check if new columns were added to the default settings
    // eslint-disable-next-line no-unused-expressions
    defaultColumns?.columns?.forEach((d: Setting) => {
      if (d.whatVersionWasColumnIntroduced > sidebarState.configVersion) {
        newSideBarState.configVersion = d.whatVersionWasColumnIntroduced;
        newSideBarState.columns.push(d);
        newColumnsFound = true;
      }
    });

    // Check if we remvoed columns from the default config
    if (defaultColumns?.columns.length < sidebarState.columns.length) {
      sidebarState.columns.forEach((d: Setting) => {
        // eslint-disable-next-line lodash/prefer-some
        if (defaultColumns.columns.findIndex((c: any) => c.setting === d.setting) === -1) {
          newSideBarState.columns = newSideBarState.columns
            .filter((c: any) => c.setting !== d.setting);
        }
      });
    }
    return {
      ...newSideBarState,
      newColumnsFound,
    };
  },
});

const getSidebarConfigEnabledCount = selector({
  key: 'getSidebarConfigEnabledCount',
  get: ({ get }) => {
    const canViewSkillDiscovery = get(skillsQuery);
    const sidebarState: ColumnConfig = JSON.parse(get(snapshotState));
    return sidebarState.columns.filter((c: Setting) => {
      if (!canViewSkillDiscovery && c.setting === 'Provider Skills') {
        return false;
      }
      return c.enabled;
    }).length;
  },
});

const resetSidebarConfigToDefaults = selector({
  key: 'resetSidebarConfigToDefaults',
  get: () => { /* empty */ },
  set: ({ get, set }) => {
    const sidebarState: ColumnConfig = JSON.parse(get(snapshotState));
    const newSideBarState: ColumnConfig = {
      ...sidebarState,
      columns: defaultSettings.columns,
    };
    set(snapshotState, JSON.stringify(newSideBarState));

    trackAmplitudeEvent({
      category: CUSTOMIZED_PROVIDER_DRAWER,
      ampProps: {
        Source: 'Provider Drawer',
        'Reset To Config Defaults': true,
      },
    });
  },
});

const uptickSidebarConfigVersion = selector({
  key: 'uptickSidebarConfigVersion',
  get: () => { /* empty */ },
  set: ({ set }, columnConfig = {} as DefaultValue) => {
    const newSideBarState: ColumnConfig = {
      // @ts-ignore
      columns: columnConfig.columns,
      // @ts-ignore
      configVersion: columnConfig.configVersion,
    };
    set(snapshotState, JSON.stringify(newSideBarState));
  },
});

const updateSidebarConfigOrdering = selector({
  key: 'updateSidebarConfigOrdering',
  get: () => { /* empty */ },
  // @ts-ignore
  set: ({ set, get }, newOrder = []) => {
    const sidebarState: ColumnConfig = JSON.parse(get(snapshotState));

    const newConfigOrdering: Setting[] = [];
    // @ts-ignore
    newOrder.forEach((section: any) => {
      newConfigOrdering.push(
        sidebarState.columns.find((x: any) => x?.setting === section) || {} as Setting,
      );
    });
    const newSideBarState: ColumnConfig = {
      ...sidebarState,
      columns: newConfigOrdering,
    };
    set(snapshotState, JSON.stringify(newSideBarState));

    trackAmplitudeEvent({
      category: CUSTOMIZED_PROVIDER_DRAWER,
      ampProps: {
        Source: 'Provider Drawer',
        Shown: newSideBarState.columns.filter((e) => e.enabled).map((f) => f.setting).join(', '),
        Hidden: newSideBarState.columns.filter((e) => !e.enabled).map((f) => f.setting).join(', '),
        'Reset To Defaults': true,
        'Reordered Sections': 'True',
        'Previous Section Order': sidebarState.columns.map((e: any) => e.setting).join(', '),
        // @ts-ignore
        'New Section Order': newOrder.join(', '),
      },
    });
  },
});

const toggleSidebarConfigCollapse = selector({
  key: 'toggleSidebarConfigCollapse',
  get: () => { /* empty */ },
  set: ({ set, get }, sectionNameToToggle) => {
    const sidebarState: ColumnConfig = JSON.parse(get(snapshotState));
    const newSideBarState: ColumnConfig = {
      ...sidebarState,
      columns: sidebarState.columns.map((x: any) => {
        // @ts-ignore
        if (sectionNameToToggle === 'COLLAPSE ALL') {
          return { ...x, collapse: true };
        }
        // @ts-ignore
        if (sectionNameToToggle === 'EXPAND ALL') {
          return { ...x, collapse: false };
        }
        // @ts-ignore
        if (x.setting === sectionNameToToggle) {
          return { ...x, collapse: !x.collapse };
        }
        return x;
      }),
    };
    set(snapshotState, JSON.stringify(newSideBarState));
  },
});

const toggleSidebarConfigToggle = selector({
  key: 'toggleSidebarConfigToggle',
  get: () => { /* empty */ },
  set: ({ set, get }, sectionNameToToggle) => {
    const sidebarState: ColumnConfig = JSON.parse(get(snapshotState));
    const newSideBarState: ColumnConfig = {
      ...sidebarState,
      columns: sidebarState.columns.map((x: any) => {
        // @ts-ignore
        if (x.setting === sectionNameToToggle) {
          return { ...x, enabled: !x.enabled };
        }
        return x;
      }),
    };
    set(snapshotState, JSON.stringify(newSideBarState));

    trackAmplitudeEvent({
      category: CUSTOMIZED_PROVIDER_DRAWER,
      ampProps: {
        Source: 'Provider Drawer',
        Shown: newSideBarState.columns.filter((e) => e.enabled).map((f) => f.setting).join(', '),
        Hidden: newSideBarState.columns.filter((e) => !e.enabled).map((f) => f.setting).join(', '),
        'Reset To Defaults': true,
        'Reordered Sections': 'False',
        'Previous Section Order': sidebarState.columns.map((e: any) => e.setting).join(', '),
      },
    });
  },
});

export {
  getSidebarConfigQuery,
  getSidebarConfigEnabledCount,
  resetSidebarConfigToDefaults,
  uptickSidebarConfigVersion,
  updateSidebarConfigOrdering,
  toggleSidebarConfigCollapse,
  toggleSidebarConfigToggle,
};
