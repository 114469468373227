import * as PropTypes from 'prop-types';
import * as React from 'react';

interface Props {
  href?: string;
  email?: string;
  block?: boolean;
}

const Email: React.FC<Props> = ({ email, href, block }: Props) => {
  if (!email) {
    return null;
  }

  if (!href) {
    const ret = (
      <a className="u-textLink" href={`mailto:${email}`}>
        {email}
      </a>
    );

    if (block) {
      return <div>{ret}</div>;
    }

    return ret;
  }

  const ret = (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a className="u-textLink" href={href} target="_blank">
      {email}
    </a>
  );

  if (block) {
    return <div>{ret}</div>;
  }

  return ret;
};

Email.propTypes = {
  // href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  email: PropTypes.string,
  block: PropTypes.bool,
};

Email.defaultProps = {
  email: '',
  href: undefined,
  block: false,
};

export default Email;
