import { selector } from 'recoil';
import { getUserById } from '../api';
import { ScreeningInputValues } from '../types.d';
import screeningAtom from './screening.atom';

export const screeningDefaultInputs = {
  id: 0,
  first_name: '',
  last_name: '',
  ssn: '',
  date_of_birth: '',
  driver_license_state: '',
  driver_license_number: '',
  email: '',
  phone: '',
  cell: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  card_number: '',
  card_exp: '',
  card_cvv: '',
  cardholder_name: '',
  card_type: '',
  billing_address1: '',
  billing_address2: '',
  billing_city: '',
  billing_state: '',
  billing_zip: '',
  billing_country: '',
};

export const userDataQuery = selector({
  key: 'userDataQuery',
  get: async ({ get }) => {
    const { userId } = get(screeningAtom);
    if (!userId) {
      return screeningDefaultInputs as ScreeningInputValues;
    }
    const user = await getUserById(userId);
    return {
      id: user?.id || 0,
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      cell: user?.cell || '',
      city: user?.city || '',
      state: user?.state || '',
      postal_code: user?.zip || '',
      country: user?.country || 'US',
      billing_address1: user?.address1 || '',
      billing_address2: user?.address2 || '',
      billing_city: user?.city || '',
      billing_state: user?.state || '',
      billing_zip: user?.zip || '',
      billing_country: user?.country || 'US',
      ssn: '',
      date_of_birth: user?.date_of_birth || '',
      driver_license_state: '',
      driver_license_number: '',
      card_number: '',
      card_exp: '',
      card_cvv: '',
      cardholder_name: '',
      card_type: '',
    } as ScreeningInputValues;
  },
});
