import moment from 'moment';

const defaultTZ = () => moment().tz() || moment.tz.guess();

const dateToLocalMoment = (date) => moment.utc(date).local().tz(defaultTZ());

const dateStringToLocalTime = (date, dateFormat) => dateToLocalMoment(date).format(dateFormat);

const getDayDifference = (date) => {
  const mom = moment.utc(date).local().tz(defaultTZ());
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  if (mom.isSame(today, 'day')) {
    return 'TODAY';
  }
  if (mom.isSame(yesterday, 'day')) {
    return 'YESTERDAY';
  }
  return dateStringToLocalTime(date, 'dddd MMM D, YYYY');
};

const messageData = (messageThread, messageType) => {
  const directReplies = messageThread[0]?.replies ?? [];
  const newData = messageType === 'direct' ? [messageThread[0], ...directReplies].reverse() : messageThread;
  const list = newData?.reduce((formatData, curr) => {
    const date = getDayDifference(curr?.created?.utc);
    const existingData = formatData?.find((item) => item?.title === date);
    if (existingData) {
      existingData.data.push(curr);
    } else {
      formatData.push({ title: date, data: [curr] });
    }
    return formatData;
  }, []);

  return list?.reverse()?.map((item) => ({
    ...item,
    data: item?.data?.reverse(),
  }));
};

export default messageData;
