import React from 'react';
import { Grid, GridColumn } from '@fieldnation/platform-components';

import { IGridSizeTokens, IProfileInfoItemWrapper } from './types.d';

import css from './styles.scss';

export const gridSizeTokens: IGridSizeTokens = {
  full: '12',
  listItemName: '3',
  listItemContent: '9',
};

export const ProfileInfoItemWrapper = (
  props: IProfileInfoItemWrapper,
): JSX.Element => {
  const { children } = props;
  const childList = [children].flat().map((Child, index) => (
    <GridColumn
      xs={gridSizeTokens.full}
      sm={gridSizeTokens.full}
      md={gridSizeTokens.full}
      lg={gridSizeTokens.full}
      xl={gridSizeTokens.full}
      key={`profile-info-item${index + 1}`}
    >
      <div className={css['profile-info-item']}>{Child}</div>
    </GridColumn>
  ));

  return (
    <div className={css['profile-info-items-container']}>
      <Grid vSpace={false} divided>
        {childList}
      </Grid>
    </div>
  );
};
