import React from 'react';
import { Grid, GridColumn } from '@fieldnation/platform-components';
import { IProps } from './types.d';
import { GridSize } from '../../Commons/types.d';
import css from './styles.scss';

const Wrapper: React.FC<IProps> = ({ children, useDescription }: IProps): JSX.Element => {
  const wrappedChildren = children
    .filter((e) => !!e)
    .flat()
    .map((child, index) => (
      <GridColumn
        xs={GridSize.LARGE}
        sm={GridSize.LARGE}
        md={GridSize.LARGE}
        lg={GridSize.LARGE}
        xl={GridSize.LARGE}
        key={`wrapper-item${index + 1}`}
      >
        <div className={css['wrapper--item']}>{child}</div>
      </GridColumn>
    ));

  const descriptionChild = (wrappedChildren.length === 1)
    ? wrappedChildren[0]
    : wrappedChildren.slice(0, 2);
  const contentChildren = (useDescription)
    ? wrappedChildren.slice(wrappedChildren.length === 1 ? 1 : 2)
    : wrappedChildren;

  return (
    <div className={css.wrapper}>
      {useDescription && (
      <Grid vSpace={false}>
        {descriptionChild}
      </Grid>
      )}
      <Grid vSpace={false} divided>
        {contentChildren}
      </Grid>
    </div>
  );
};

export default Wrapper;
