import { format, fromUnixTime } from 'date-fns';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const TimelinessChckInDate = ({ checkinDateTime }: any): JSX.Element => (
  <span>
    {format(fromUnixTime(checkinDateTime), 'MM/dd/yyyy')}
  </span>
);

export default TimelinessChckInDate;
