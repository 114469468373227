import { selector } from 'recoil';
import contextQuery from './context.query';
import userQuery from './user.query';
import { getProviderCommitment } from '../api';
import { hasShowPerformanceTile, performanceFilter } from './performance.query';
import { ProviderCommitmentResponse } from '../types.d';

const providerCommitmentQuery = selector<ProviderCommitmentResponse | null>({
  key: 'provider-commitment',
  get: async ({ get }) => {
    const context = get(contextQuery);
    const user = get(userQuery);
    const showPerformanceTile = hasShowPerformanceTile();

    if (!context || !user) {
      return null;
    }

    const filters = get(performanceFilter);
    const companyId = context?.company?.id || 0;
    const providerCommitment = await getProviderCommitment({
      userId: Number(user.id),
      companyId,
      filters: showPerformanceTile
        ? filters
        : {
          ...filters,
          dayHistory: Number(filters?.dayHistory),
        },
    });

    if (!providerCommitment) {
      return null;
    }

    return providerCommitment;
  },
});

export default providerCommitmentQuery;
