/* eslint-disable import/prefer-default-export */
import React from 'react';
import { DialogueModal } from '@fieldnation/platform-components';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  addressConfirmationDescriptionAtom,
  isAddressConfirmationOpenAtom,
} from './address-confirmation.atom';
import {
  getRequestConfigCallback,
  makeRequestCallback,
  resetFormCallback,
  userContextSelector,
} from '../PersonalInfo/state';
import { RequestTypes } from '../../constants';
import { useAmplitudeTracker } from '../../../Amplitude/useAmplitudeTracker';

export const AddressConfirmation = (): JSX.Element => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const [isOpen, setIsOpen] = useRecoilState(isAddressConfirmationOpenAtom);
  const description = useRecoilValue(addressConfirmationDescriptionAtom);

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);
  const trackOnAmplitude = useAmplitudeTracker();

  const onCancel = () => setIsOpen(false);
  const onSave = async () => {
    setIsOpen(false);
    const success = await makeRequest(
      await getRequestConfig(RequestTypes.FORCE_UPDATE_ADDRESS),
      'Successfully updated address',
      false,
      trackOnAmplitude,
    );

    if (success) {
      await resetForm([
        'address1',
        'address2',
        'city',
        'zip',
        'state',
        'country',
      ]);
    }
  };
  const onClose = (save) => (save ? onSave() : onCancel());

  return (
    <DialogueModal
      positiveText="Save Address"
      negativeText="Cancel"
      header="Invalid Address"
      isOpen={isStaff && isOpen}
      onClose={onClose}
    >
      {description}
    </DialogueModal>
  );
};
