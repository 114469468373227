import { useQuery } from 'react-query';
import { getDistance } from '../api';

import { DistanceResponse } from '../types.d';

export default function useDistance(
  workOrderId?: number,
  userId?: number,
): DistanceResponse | null {
  if (!userId || !workOrderId) {
    return null;
  }

  const distance = useQuery<DistanceResponse | null>(
    ['workorders', workOrderId, 'distance', userId],
    async () => {
      const resp = await getDistance(workOrderId, userId);

      if (resp && resp?.length > 0) {
        return resp[0];
      }

      return null;
    },
  );

  return distance.status === 'success' ? distance.data : null;
}
