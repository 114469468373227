import React from 'react';
import {
  BodyText, Link, Median, MedianAlpha, MedianOmega,
} from '@fieldnation/platform-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import css from './styles.scss';
import EditButton from '../EditButton';
import { IProps } from './types.d';
import { sectionCardCreatePermissionGroup, sectionCardModeState } from '../../States/AtomFamilies';
import { SectionCardKey, SectionCardMode } from '../Section/types.d';
import userPermissionState from '../../States/Selectors';
import { UserGroup } from '../../Commons/types.d';

const EmptySection: React.FC<IProps> = ({
  label, cardKey,
}: IProps): JSX.Element => {
  const { userGroup } = useRecoilValue(userPermissionState);
  const createPermissionGroup = useRecoilValue(
    sectionCardCreatePermissionGroup(cardKey as SectionCardKey),
  );
  const isCreateEnabled = createPermissionGroup?.includes(userGroup as UserGroup);

  const setMode = useSetRecoilState(sectionCardModeState(cardKey as SectionCardKey));
  return (
    <div>
      <Median verticalAlign="top">
        <MedianAlpha>
          <BodyText styleLevel="md" tag="span">
            <span
              className={css['section--empty']}
            >
              {`No ${label.toLowerCase()} listed.`}
            </span>
          </BodyText>
          &nbsp;
          {
            isCreateEnabled && (
              <Link onClick={() => setMode(SectionCardMode.CREATE)}>
                <span className={css['section-link']}>{`Add ${label}`}</span>
              </Link>
            )
          }
          {
            !isCreateEnabled && (
              <span className={css['section--access']}>
                {`Contact your admin to create ${label.toLowerCase()}.`}
              </span>
            )
          }
        </MedianAlpha>
        <MedianOmega>
          { isCreateEnabled &&
            <EditButton isDisabled={false} onClick={() => setMode(SectionCardMode.CREATE)} />}
        </MedianOmega>
      </Median>
    </div>
  );
};

export default EmptySection;
