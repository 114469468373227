import * as React from 'react';
import Header from './Header';
import WrapSection from './WrapSection';

import { MonolithProfile } from '../types.d';

interface Props {
    user: MonolithProfile;
    collapse?: boolean;
    overlayPosition?: 'top' | 'right' | 'left' | 'bottom';
}

const UserSummary = ({
  user, collapse, overlayPosition,
}: Props): JSX.Element => (
  <WrapSection
    label="Summary"
    value=""
    collapse={collapse}
    fullWidth
  >
    <Header user={user} backgroundColor="#FFF" isSummary overlayPosition={overlayPosition} />
  </WrapSection>
);

UserSummary.defaultProps = {
  collapse: false,
  overlayPosition: null,
};

export default UserSummary;
