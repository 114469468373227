import {
  WaWorkerCompEventAnswerOnBehalf,
  WaWorkerCompEventAnswerValue,
} from './types.d';

export const transformBooleanAnswerToString =
    (value: string | number): WaWorkerCompEventAnswerValue => (value === '0' || value === 0 ? WaWorkerCompEventAnswerValue.NO : WaWorkerCompEventAnswerValue.YES);

export const getAnsweredByUser = (): string => {
  const user = window?.user?.first_name ? window.user : window?.context;
  return user && user?.first_name ? `${user?.first_name} ${user.last_name}` : '';
};

export const getOnBehalfUser = ():WaWorkerCompEventAnswerOnBehalf => {
  const groupId = window?.user?.group?.id || window?.context?.group?.id;
  return groupId === 1 ? WaWorkerCompEventAnswerOnBehalf.PROVIDER : WaWorkerCompEventAnswerOnBehalf.SERVICE_COMPANY;
};
