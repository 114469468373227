import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  GridColumn,
  Loader,
  Section,
  v2,
} from '@fieldnation/platform-components';
import css from './styles.scss';
import { supportMessage } from './utils';

const {
  TextInput,
  // @ts-ignore
  NotificationBand,
  Tile,
  TileDivision,
} = v2;

const OtpComponent = ({
  loading,
  email,
  submitHandler,
  contactSupportHandler,
}): JSX.Element => (
  <Loader isLoading={loading}>
    <div className={css['inline-container']}>
      <Tile>
        <TileDivision>
          <Section>
            <div style={{ padding: '20px' }}>
              <div style={{ textAlign: 'center' }}>
                <Grid>
                  <GridColumn>
                    <p className={css['Body--header']}>Continue to this section</p>
                    <p className={css['Body--title']}>Receive a code by email</p>
                  </GridColumn>
                  <GridColumn>
                    You will receive a code at the following email:
                  </GridColumn>
                </Grid>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Grid>
                  <GridColumn>
                    <TextInput
                      label="Account Email"
                      value={email}
                      disabled
                      onChange={() => {}}
                    />
                  </GridColumn>
                  <GridColumn>
                    <NotificationBand
                      secondaryText={supportMessage(contactSupportHandler)}
                    />
                  </GridColumn>
                  <GridColumn>
                    <Button
                      label="Continue"
                      type="primary"
                      block
                      onClick={() => submitHandler()}
                    />
                  </GridColumn>
                </Grid>
              </div>
            </div>
          </Section>
        </TileDivision>
      </Tile>
    </div>
  </Loader>
);

OtpComponent.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string.isRequired,
  submitHandler: PropTypes.func.isRequired,
  contactSupportHandler: PropTypes.func.isRequired,
};

OtpComponent.defaultProps = {
  loading: false,
};

export default OtpComponent;
