/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { createContext } from 'react';
import PropTypes from 'prop-types';

declare global {
  interface Window {
    _fnQualificationRegistry: () => Map<string, any>;
  }
}

let reg = new Map<string, any>();

if (!window._fnQualificationRegistry) {
  window._fnQualificationRegistry = () => reg;
} else {
  reg = window._fnQualificationRegistry();
}

export const ctx = createContext(reg);

const { Provider } = ctx;

const Registry = ({ children }) => {
  if (!window._fnQualificationRegistry) {
    window._fnQualificationRegistry = () => reg;
  }

  return (
    <Provider value={reg}>
      <div id="fn-qualifications-registry">{children}</div>
    </Provider>
  );
};

Registry.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Registry;
