export enum WaWorkerCompEvents {
  ANSWERED_FIRST_QUESTION = 'Answered WA L&I 1st question',
  ANSWERED_SECOND_QUESTION = 'Answered WA L&I 2nd question',
  ANSWERED_THIRD_QUESTION = 'Answered WA L&I 3rd question',
  ENTERED_UBI_NUMBER = 'Entered WA UBI number',
  SAVED_WA_L_AND_I_INFO = 'Saved WA L&I info',
  OPENED_WA_L_AND_I_FORM = 'Opened WA L&I form',
  CLOSED_WA_L_AND_I_FORM = 'Closed WA L&I form',
}

export enum WaWorkerCompEventProps {
  ANSWERED_BY = 'Answered by',
  VALUE = 'Value',
  ON_BEHALF_OF = 'On behalf of',
  SOURCE = 'Source',
  SAVED_BY = 'Saved by',
  VALUES = 'Values',
  STATUS = 'Status',
  OPENED_BY = 'Opened by',
  CLOSED_BY = 'Closed by',
}

export enum WaWorkerCompEventAnswerValue {
  YES = 'Yes',
  NO = 'No',
}

export enum WaWorkerCompEventSaveStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum WaWorkerCompEventAnswerOnBehalf {
  PROVIDER = 'provider',
  SERVICE_COMPANY = 'service company',
}

export enum WaWorkerCompEventSource {
  PROFILE = 'profile',
  ONBOARDING = 'onboarding',
  ACCEPT_ROUTED_WO = 'accept routed WO',
  REQUEST_WO = 'request WO',
  COUNTER_OFFER_WO = 'counter-offer WO',
}
