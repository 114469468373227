import { selector } from 'recoil';
import { getContract } from '../api';
import { MonolithContract } from '../types.d';

const contractQuery = selector<MonolithContract | null>({
  key: 'contract',
  get: async () => {
    const contract = await getContract();

    return contract || null;
  },
});

export default contractQuery;
