import { useQuery } from 'react-query';
import { getProviderWorkSummary } from '../api';
import useWorkOrderId from './use-work-order-id';
import { ProviderSkillSegmentaionResponse } from '../types.d';
import { BUYER_GROUP_IDS } from '../utils';

export default function useTechnicianV4WorkHistory(
  userId?: number,
  defaultWorkOrderId?: number,
): ProviderSkillSegmentaionResponse | null{
  const workOrderId = useWorkOrderId(defaultWorkOrderId);
  const companyId = Number(window?.context?.company?.id || 0);
  const groupId = window?.context?.group?.id || 0;
  const isBuyer = Boolean(BUYER_GROUP_IDS.includes(Number(groupId)));

  if (!userId) {
    return null;
  }
  const v4skills = useQuery<ProviderSkillSegmentaionResponse | null>([
    'v4users',
    userId,
    'v4skills',
    workOrderId,
  ], async () => {
    const skillResp = await getProviderWorkSummary(
      userId,
      workOrderId,
      isBuyer ? companyId : undefined,
      {
        throwExceptions: true,
        timeout: 4000,
      },
    ) as unknown as ProviderSkillSegmentaionResponse;
    return skillResp;
  });
  return v4skills.status === 'success' ? v4skills.data : null;
}
