/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classNames from 'classnames';
import { Icon, Link } from '@fieldnation/platform-components';
import ReactHtmlParser from 'react-html-parser';
import { UpgradeDowngradeModalContentProps } from './types.d';
import { JSObject } from '../../types.d';

import css from './UpgradeDowngradeModal.scss';

const contentBase = {
  basic_downgrade: {
    left: {
      logo: 'problemSolving',
      heading_2: 'Basic Work Order charge - 0% for 20 work orders / month.',
      body_text:
        'Field Nation Basic costs 0% of the total work order value for your first 20 work orders per month. After that, you will be charged 5% per work order.',
    },
    right: {
      heading_1: 'By downgrading to Basic, you will lose access to:',
      ul: [
        'The ability to copy a work order',
        'Custom fields',
        'Templates',
        'Selection rules',
        'Work order activity reports',
      ],
      link: {
        label: 'And Much More',
        href:
          'https://assets.fieldnation.com/documents/feature-comparison-matrix.pdf',
      },
    },
  },
  plus_upgrade: {
    left: {
      logo: 'problemSolving',
      heading_2: '<span>Field Nation Plus</span> is an optimal solution for small businesses looking to develop their on-demand labor strategy.',
      body_text:
        'All non-approved Work Orders will incur a 5% charge.',
    },
    right: {
      heading_1: 'When you upgrade to Plus, you get',
      ul: [
        'Unlimited work orders',
        'Custom fields',
        'Templates',
        'Selection rules',
        'Work order activity reports',
      ],
    },
  },
  premier_upgrade: {
    left: {
      logo: 'problemSolving',
      heading_2: '<span>Field Nation Premier</span> is an ideal solution for businesses running high volumes of work that are looking to expand their service delivery capabilities.',
    },
    right: {
      heading_1: 'Thank you for your interest in Field Nation Premier!',
      body_text:
        'Please select "Contact Sales" and a member of our team will reach out to you within two business days to help you get started.',
    },
  },
  success: {
    full: {
      logo: 'completeSolid',
      heading_2:
        'Your Field Nation #target_tier# #operation# request has been submitted.',
      body_text:
        'Please wait up to two minutes while we finish processing your upgrade.',
    },
  },
  fail: {
    full: {
      logo: 'trigger',
      heading_2:
        'Your Field Nation #target_tier# #operation# request has failed.',
      body_text:
        'Sorry but the request has failed. Please try again in a few moment or contact support.',
    },
  },
};

const replacer = (target: string, source: JSObject, seperator = '#') => {
  let resp = target;
  const regexp = new RegExp(`s*${seperator}.*?${seperator}s*`, 'g');
  const matches = target.match(regexp) || [];

  matches.forEach((item: string) => {
    resp = resp.replaceAll(
      item?.trim(),
      source[item?.trim()?.replaceAll(seperator, '') || ''],
    );
  });

  return resp;
};

const UpgradeDowngradeModalContent = ({
  transition_package,
  package_update_resp,
}: UpgradeDowngradeModalContentProps): JSX.Element => {
  const iconSize: any = 96;
  const tier = transition_package?.target_tier;
  const operation = transition_package?.operation;
  const contentData = contentBase[`${tier}_${operation}`];
  const statusCode = package_update_resp?.result?.statusCode;

  const displayContent = (data: JSObject) =>
    Object.keys(data).map((type, index) => {
      const key = `rck-${index + 1}`;
      if (type === 'logo') {
        return (
          <div
            key={key}
            className={classNames(
              css.Message__icon,
              css[
                !statusCode
                  ? `Message__${tier}`
                  : `Success_${statusCode === 200}`
              ],
            )}
          >
            <Icon size={iconSize} name={data[type] || 'success'} />
          </div>
        );
      }
      if (type === 'ul') {
        return (
          <div key={key} className={classNames(css.list)}>
            <ul>
              {data[type]?.map((l: string, i: number) => (
                <li key={`li-${i + 1}`}>
                  &#8226;
                  {` ${replacer(String(l), transition_package)}`}
                </li>
              ))}
            </ul>
          </div>
        );
      }
      if (type === 'link') {
        return (
          <div key={key} className={classNames(css.list)}>
            <Link
              href={replacer(data[type]?.href, transition_package)}
              target="_blank"
            >
              {replacer(data[type]?.label, transition_package)}
            </Link>
          </div>
        );
      }
      return (
        <div key={key} className={classNames(css[type])}>
          {ReactHtmlParser(replacer(String(data[type]), transition_package))}
        </div>
      );
    });

  const displayMainContent = (data: JSObject) => (
    <div className={classNames(css['modal-content-container'])}>
      {data?.left && (
        <div className={classNames(css['content-left'])}>
          {displayContent(data?.left)}
        </div>
      )}
      {data?.right && (
        <div className={classNames(css['content-right'])}>
          {displayContent(data?.right)}
        </div>
      )}
      {data?.full && (
        <div className={classNames(css['content-full'])}>
          {displayContent(data?.full)}
        </div>
      )}
    </div>
  );

  if (package_update_resp) {
    if (statusCode === 200) {
      return displayMainContent(contentBase?.success || {});
    }
    if (statusCode !== 200) {
      return displayMainContent(contentBase?.fail || {});
    }
  }

  if (!package_update_resp && contentData) {
    return displayMainContent(contentData);
  }
  return <></>;
};

export default UpgradeDowngradeModalContent;
