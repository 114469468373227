import { useQuery } from 'react-query';
import { JobHistoryResponse } from '../types.d';

export interface JobHistoryRequestDto {
  userId: number;
  search: string;
  typeOfWorkIds?: number[];
  starRating?: number;
  onlyMyCompany?: '1' | '0';
  networkIds?: number[];
  skillIds?: number[];
  categoryIds?: number[];
  subCategoryIds?: number[];
  cbsa?: number[];
  dates?: [string, string];
}

export function computeHash(dto: JobHistoryRequestDto) {
  return [
    dto.userId,
    dto.search,
    dto.typeOfWorkIds ? dto.typeOfWorkIds.join(',') : '',
    dto.networkIds ? dto.networkIds.join(',') : '',
    dto.skillIds ? dto.skillIds.join(',') : '',
    dto.cbsa ? dto.cbsa.join(',') : '',
    dto.categoryIds ? dto.categoryIds.join(',') : '',
    dto.subCategoryIds ? dto.subCategoryIds.join(',') : '',
    dto.onlyMyCompany === '1' ? '1' : '',
    dto.starRating ? `${dto.starRating}` : '',
    dto.dates ? `${dto.dates[0]}:${dto.dates[1]}` : '',
  ].join(':');
}

export default function useJobHistory(
  dto: JobHistoryRequestDto,
): JobHistoryResponse | null {
  const hash = computeHash(dto);

  const query = useQuery<JobHistoryResponse | null>(
    ['users', dto.userId, 'jobs', hash],
    async () => new Promise<JobHistoryResponse>((resolve, reject) => {
      fetch(`/skill-discovery/v1/technicians/${dto.userId}/jobs`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(dto),
        credentials: 'same-origin',
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(resp as JobHistoryResponse);
        })
        .catch((e) => {
          reject(e);
        });
    }),
  );

  return query.status === 'success' ? (query.data as JobHistoryResponse) : null;
}
