/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useContext } from 'react';
import { Loader } from '@fieldnation/platform-components';
import PropTypes from 'prop-types';

const Resolution = ({
  store,
  requirement: {
    id,
    loading,
    error,
    qualification: {
      resolution: {
        type, link, actionLabel, api,
      },
    },
  },
  userId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { dispatch } = useContext<any>(store);

  if (loading) {
    return <Loader>&nbsp;</Loader>;
  }

  if (error) {
    return <span className="text-danger">{error}</span>;
  }

  if (type === 'unresolvable') {
    return null;
  }

  if (type === 'link') {
    const newLink = link.includes('/p/me') && userId ? link.replace('/p/me', `/p/${userId}`) : link;
    return (
      <a href={newLink} target="_blank" rel="noreferrer">
        {actionLabel}
      </a>
    );
  }

  let newApi = api;

  if (type === 'api' && userId) {
    const splitApi = api?.path?.split('/');
    splitApi[3] = userId;
    const joinApi = splitApi.join('/');

    newApi = { ...api, path: `${joinApi}` };
  }

  if (type !== 'api') {
    // eslint-disable-next-line no-console
    console.error('Unknown resolution type:', type);
    return null;
  }

  return (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={() => {
        dispatch({
          type: 'resolve',
          id,
          api: newApi,
        });
      }}
    >
      {actionLabel}
    </a>
  );
};

Resolution.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.any.isRequired,
  requirement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.string,
    qualification: PropTypes.shape({
      resolution: PropTypes.shape({
        type: PropTypes.oneOf(['api', 'link', 'unresolvable']).isRequired,
        actionLabel: PropTypes.string,
        uiCode: PropTypes.string,
        api: PropTypes.shape({
          serviceUri: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          method: PropTypes.string.isRequired,
          body: PropTypes.string,
        }),
        requiresApproval: PropTypes.bool,
        link: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  userId: PropTypes.number.isRequired,
};

export default Resolution;
