export const DEFAULT_CVC_LENGTH = 3;
export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g;
export const CARD_TYPES = [
  {
    type: 'amex',
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    startPattern: /^3[47]/,
    maxCardNumberLength: 15,
    cvcLength: 4,
  },
  {
    type: 'discover',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(6011|65|64[4-9]|622)/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: 'mastercard',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: 'visa',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^4/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
];

export const getCardTypeByValue = (value: string) => CARD_TYPES.filter((cardType) => cardType.startPattern.test(value))[0];

export const getCardTypeByType = (type: string) => CARD_TYPES.filter((cardType) => cardType.type === type)[0];

export const hasCardNumberReachedMaxLength = (
  currentValue: string,
  currentValueLength: number,
) => {
  const cardType = getCardTypeByValue(currentValue);
  if (!cardType) return currentValueLength >= 16;
  return cardType && currentValueLength >= cardType.maxCardNumberLength;
};

export const hasCVCReachedMaxLength = (type: string, currentValueLength: number) => {
  const cardType = getCardTypeByType(type);
  if (!cardType) {
    return currentValueLength >= DEFAULT_CVC_LENGTH;
  }
  return currentValueLength >= cardType.cvcLength;
};

export const formatCardNumber = (cardNumber): string => {
  const cardType = getCardTypeByValue(cardNumber);
  if (!cardType) return (cardNumber.match(DEFAULT_CARD_FORMAT) || []).join(' ');
  const { format } = cardType;
  if (format.global) {
    return cardNumber.match(format).join(' ');
  }
  const execResult = format.exec(cardNumber.split(' ').join(''));
  if (execResult) {
    return execResult
      .splice(1, 3)
      .filter((x) => x)
      .join(' ');
  }
  return cardNumber;
};

export const formatCvc = (cvc: string) => (cvc.match(/\d+/g) || []).join('');

export const formatExp = (expDate = '') => {
  let exp = expDate;
  exp = exp.endsWith('/') ? exp[0] : exp;
  exp = exp.replace(/[^\d]/g, '');
  exp = exp.length >= 2 ? `${exp.slice(0, 2)} / ${exp.slice(2, 4)}` : exp;
  return exp;
};

export const getCardTypeForState = (number) => {
  switch (number[0]) {
    case '3': return 'American Express';
    case '4': return 'Visa';
    case '2':
    case '5': return 'Mastercard';
    case '6': return 'Discover';
    default: return '';
  }
};

export const checkValidCardNumber = (number: string) => {
  const cardType = getCardTypeByValue(number);
  if (!cardType) return false;
  const { format } = cardType;
  const regex = new RegExp(format);
  return regex.test(number);
};
