/* eslint-disable indent */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import startCase from 'lodash/startCase';
import classNames from 'classnames';
import {
  v2,
  Heading,
  BodyText,
  Loader,
  // @ts-ignore
  currencyFormatter,
} from '@fieldnation/platform-components';

import css from './CurrentPackage.scss';
import { ContractColorTheme } from '../common/enums';
import { CardTooltip } from '../../components';
import { CurrentMonthWOCountSelector } from '../common/GetWorkOrderCount.selector';
import { CurrentContractSelector } from '../common/GetContract.selector';
import { ContractAnnualSpendSelector } from '../common/GetCompanySpend.selector';
import { IMarketplaceBuyer, IWindowContract } from '../common/types.d';
import { getContractDateLabel } from '../common/utils';

const { Tile, TileDivision } = v2;

export const getWorkOrderFeeLabel = (
  marketplaceBuyer: IMarketplaceBuyer,
  isOverage: boolean,
): string => {
  const workOrderFee = marketplaceBuyer?.work_order_fee;
  const overageFee = marketplaceBuyer?.overage_fee;
  const structure = workOrderFee?.structure;
  switch (structure) {
    case 'flat':
      return !isOverage
        ? `${workOrderFee?.amount}${
            workOrderFee?.type === 'percent' ? '%' : ' flat'
          }`
        : `${overageFee?.amount}${
            overageFee?.type === 'percent' ? '%' : ' flat'
          }`;
    case 'annual-spend-amount':
      return !isOverage
        ? `${workOrderFee?.amounts?.[0]?.amount}%`
        : `${workOrderFee?.amounts?.[1].amount}%`;
    case 'date':
      return `${workOrderFee?.dates?.[0]?.amount}%`;
    default:
      return 'error';
  }
};

export const getWorkOrderLimitLabel = (
  marketplaceBuyer: IMarketplaceBuyer,
  isOverage: boolean,
): string => {
  const workOrderFee = marketplaceBuyer?.work_order_fee;
  const overageFee = marketplaceBuyer?.overage_fee;
  return !isOverage
    ? `${workOrderFee?.amount}${
        workOrderFee?.type === 'percent' ? '%' : ' flat'
      } for ${marketplaceBuyer?.work_order_limit?.limit} Work Orders per month.`
    : `${overageFee?.amount}${overageFee?.type === 'percent' ? '%' : ' flat'}`;
};

export const getCurrentPackageFooterLabel = (contract: IWindowContract): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  if (contract?.marketplace_buyer?.tier === 'basic') {
    return 'Work Order count automatically resets on the first day of each month.';
  }

  if (contract?.contract_type?.type === 'Created from Closed/Won Opp') {
    return `Contract automatically expires on ${getContractDateLabel(contract?.metadata?.endsAt, timezone)}.`;
  }

  return `Contract has been effective since ${getContractDateLabel(contract?.metadata?.startsAt, timezone)}.`;
};

const CurrentPackage = (): JSX.Element => {
  const contract = useRecoilValue(CurrentContractSelector);
  const { count = null } = useRecoilValue(CurrentMonthWOCountSelector);
  const { amount: annualSpend = 0 } = useRecoilValue(
    ContractAnnualSpendSelector,
  );

  const currentMonthWOCount = count;

  const title = 'Current Package';
  const { marketplace_buyer } = contract;
  const packageName = startCase(marketplace_buyer?.tier || '');

  const isWorkOrderLimitOverage: boolean =
    marketplace_buyer?.tier === 'basic'
      ? (currentMonthWOCount || 0) >
        Number(marketplace_buyer?.work_order_limit?.limit)
      : false; // we will have to write custom logic here for plus and premier
  const isSubscription: boolean =
    (marketplace_buyer?.tier !== 'basic' && contract?.subscription?.enabled) ||
    false;
  const isContractAnnualSpend: boolean =
    marketplace_buyer?.work_order_fee?.structure === 'annual-spend-amount' ||
    false;
  const maxSpend: number = isSubscription
    ? marketplace_buyer?.work_order_fee?.amounts?.[0]?.max || 0
    : 0;
  const subscriptionOverageRate: number = isSubscription
    ? marketplace_buyer?.work_order_fee?.amounts?.[1]?.amount || 0
    : 0;
  const workOrderSpendOverage: boolean = annualSpend >= maxSpend;
  const isSubscriptionOverage =
    isSubscription && isContractAnnualSpend && workOrderSpendOverage;

  return (
    <div className={classNames(css['currentpackage-main'])}>
      <Suspense fallback={<Loader fixed isLoading />}>
        <Tile>
          <TileDivision heading={title}>
            <div className={classNames(css['cp-tile-content'])}>
              <div
                className={classNames(
                  css['cp-details'],
                  css[
                    `bg-${
                      isWorkOrderLimitOverage || isSubscriptionOverage
                        ? 'red'
                        : ContractColorTheme[String(marketplace_buyer?.tier)]
                    }`
                  ],
                )}
              >
                <div className={classNames(css['cp-name'])}>
                  <Heading styleLevel="h2" tag="h2">
                    {`Field Nation ${packageName}`}
                  </Heading>
                </div>
                {marketplace_buyer?.work_order_limit?.enabled && (
                  <>
                    <BodyText styleLevel="md">
                      {`${packageName} `}
                      Work Order charge -&nbsp;&nbsp;
                      {getWorkOrderLimitLabel(
                        marketplace_buyer,
                        isWorkOrderLimitOverage,
                      )}
                    </BodyText>
                    <br />
                    <BodyText styleLevel="md">
                      {`${packageName} Work Order count: ${currentMonthWOCount ||
                        0} / ${marketplace_buyer?.work_order_limit?.limit ||
                        0}`}
                    </BodyText>
                    <span> &nbsp;</span>
                  </>
                )}
                {marketplace_buyer?.work_order_fee?.enabled && (
                  <>
                    <BodyText styleLevel="md">
                      {`${packageName} `}
                      Work Order charge -&nbsp;&nbsp;
                      {getWorkOrderFeeLabel(
                        marketplace_buyer,
                        isWorkOrderLimitOverage || isSubscriptionOverage,
                      )}
                    </BodyText>
                    <span> &nbsp;</span>
                  </>
                )}
                {isWorkOrderLimitOverage && (
                  <CardTooltip
                    heading={`${packageName} Overage`}
                    // footerActions goes here
                  >
                    {`${packageName} `}
                    includes
                    {` ${marketplace_buyer?.work_order_limit?.limit || 0} `}
                    Work Orders / month for free. Any additional Work Orders
                    will be charged an overage fee of
                    {` ${marketplace_buyer?.overage_fee?.amount} ${
                      marketplace_buyer?.overage_fee?.type === 'percent'
                        ? '%.'
                        : ' flat.'
                    }`}
                  </CardTooltip>
                )}
                {isSubscriptionOverage && (
                  <CardTooltip heading="Subscription Overage">
                    Your contract includes&nbsp;
                    {currencyFormatter(maxSpend, { forceDecimal: true })}
                    &nbsp;of annual work order spend on Field Nation. Any
                    additional work orders will be charged an overage fee
                    of&nbsp;
                    {subscriptionOverageRate}
                    %. Please contact your account representative if you need to
                    increase your annual spend.
                  </CardTooltip>
                )}
              </div>
              <div className={classNames(css['cp-footer'])}>
                {getCurrentPackageFooterLabel(contract)}
              </div>
            </div>
          </TileDivision>
        </Tile>
      </Suspense>
    </div>
  );
};

export default CurrentPackage;
