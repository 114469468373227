import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from '../Store';
import { ctx } from '../../Registry';
import Form from './Form';
import ListRequirements from './ListRequirements';
import { saveRequirements } from '../../api';
import { SaveQualificationsResponse, QualificationInterface } from '../../types.d';
import { mapV2Requirements } from '../utils';

interface IProps {
  isWoDetails: boolean;
}

const RequirementsWidget = ({ isWoDetails }: IProps): JSX.Element => {
  const registry = useContext(ctx);
  const context = useContext(store);
  const {
    dispatch,
    state: { requirements = [], templateId, newRequirements },
  } = context;

  const reqs = newRequirements || requirements;

  const experiments = window.context?.experiments?.results || [];
  const qualificationsV2 = experiments.some(
    ({ id, value }) => id === 108 && Number(value) === 1,
  );

  registry.set('getQualifications', () => reqs || []);

  registry.set('onSave', (id: number, cb: (resp: SaveQualificationsResponse) => void, tempId = 0) => {
    saveRequirements({
      templateId: id ? 0 : (tempId || templateId),
      workOrderId: id || 0,
      requirements: reqs,
      qualificationsV2: qualificationsV2 ? mapV2Requirements(reqs) : false,
    })
      .then((resp) => {
        if (cb) {
          cb(resp);
        }
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(
        `Failed to save qualifications: ${JSON.stringify(
          reqs,
          null,
          2,
        )}: ${err}`,
      ));
  });

  return isWoDetails ? <ListRequirements /> : (
    <div>
      <div className="u-margin-bottom--sm">
        Work Order qualifications can include items like licenses,
        certifications, insurance, screenings, and necessary equipment.
        If any qualifications are missing from a
        requesting provider&#39;s profile, you will be prompted to confirm assignment.
      </div>
      <div className="u-margin-bottom--md">
        <Form
          useV2Data={qualificationsV2}
          onChangeData={
            (qualifications: QualificationInterface[]) => dispatch({ type: 'add', value: qualifications })
          }
        />
      </div>
    </div>
  );
};

RequirementsWidget.propTypes = {
  isWoDetails: PropTypes.bool.isRequired,
};

export default RequirementsWidget;
