import { atom, selector } from 'recoil';

export interface Company {
  id: number;
  name?: string,
  country?: string,
  state?: string,
  city?: string,
  zip?: string,
  address1?: string,
  address2?: string,
}

export const CertCaptureTokenSelector = selector({
  key: 'CertCaptureTokenSelector',
  get: async ({ get }) => {
    const customer = get<Company | null>(CertCaptureCustomerAtom);
    if (!customer?.id) {
      return null;
    }
    try {
      let response = await fetch(`/micro/calculate-pay/certcapture/company/${customer?.id}`, { method: 'GET' });
      if (!response?.ok) {
        const json = await response.json();
        if (json?.message === 'Unable to find any results for Customer') {
          response = await fetch(`/micro/calculate-pay/certcapture/company`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                customer_number: String(customer?.id),
                ...(customer?.name ? { name: customer?.name } : {}),
                ...(customer?.city ? { city: customer?.city } : {}),
                ...(customer?.zip ? { zip: customer?.zip } : {}),
                ...(customer?.address1 ? { address_line1: customer?.address1 } : {}),
                ...(customer?.address2 ? { address_line2: customer?.address2 } : {}),
                ...(customer?.state ? { state: { initials: customer?.state } } : {}),
                ...(customer?.country ? { country: { initials: customer?.country } } : {}),
              }),
            },
          );
          if (!response?.ok) {
            console.log(`Unable to create customer ${customer?.id} in certcapture.`);
            return null;
          }
        }
      }
      response = await fetch(`/micro/calculate-pay/certcapture/token/${customer?.id}`, { method: 'POST' });
      const json = await response.json();
      return json?.response?.token;
    } catch (err) {
      console.log('CertCapture Token Generation Error', err);
    }
  }
});

export const CertCaptureCustomerAtom = atom({
  key: 'CertCaptureCustomerAtom',
  default: null,
});

export const CertCaptureShipZoneAtom = atom({
  key: 'CertCaptureShipZoneAtom',
  default: '',
});
