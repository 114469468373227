/* eslint-disable lodash/import-scope */
/* eslint-disable import/prefer-default-export */
import { selector } from 'recoil';
import { get as lodashGet } from 'lodash';
import { IWindowContract } from './types.d';
import { CurrentCompanyAtom } from './CurrentCompany.atom';

export const CurrentContractSelector = selector({
  key: 'CurrentContractSelector',
  get: async ({ get }): Promise<IWindowContract> => {
    try {
      const companyId = get(CurrentCompanyAtom);
      if (!companyId) return {};
      const windowContract: IWindowContract = lodashGet(window, 'contract', {});

      // we may add different contract collection strategy here
      // if windowContract fails, collect from props, this will be selectorfamily
      // if collect from props fails, collect from api, make fetch call here
      return windowContract;
    } catch (error) {
      return {};
    }
  },
});
