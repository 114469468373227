import { selector } from 'recoil';
import canEditPpn from './can-edit-ppn';
import userQuery, { versionState } from './user.query';
import contractQuery from './contract.query';
import contextQuery from './context.query';
import { getTalentType } from '../api';

import { MonolithTalentType, MonolithPreferredGroups } from '../types.d';

interface TalentType {
  id: number;
  canEdit: boolean;
  hasContract: boolean;
  monolithTalentType: MonolithPreferredGroups;
  results: MonolithTalentType[];
}

const allTalentTypesQuery = selector<null | MonolithPreferredGroups>({
  key: 'allTalentTypes',
  get: async ({ get }) => {
    const context = get(contextQuery);
    get(versionState);

    if (!context || !context.company?.id) {
      return null;
    }

    const groups = await getTalentType(context.company?.id);

    return groups;
  },
});

const talentTypeQuery = selector<null | TalentType>({
  key: 'talentType',
  get: async ({ get }) => {
    const data = get(userQuery);
    const contract = get(contractQuery);
    const context = get(contextQuery);
    const groups = get(allTalentTypesQuery);

    if (!data || !contract || !context || !groups || data?.role_type !== 'provider') {
      return null;
    }

    return {
      id: data.id || 0,
      canEdit: canEditPpn(data, context),
      hasContract: Boolean(contract?.provider_tags?.enabled),
      monolithTalentType: groups,
      results: !data.preferred_groups?.results
        ? []
        : data.preferred_groups?.results,
    };
  },
});

export default talentTypeQuery;
