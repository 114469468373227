import { atom, selector } from 'recoil';
import currentVanity from './current-vanity.atom';
import { getUserData } from '../api';

import { MonolithProfile } from '../types.d';

// allow for bumping versions to refresh the profile and components
// (since this is the source component for almost everything)
export const versionState = atom<number>({
  key: 'userVersion',
  default: 1,
});

export const userIdQuery = selector<number | null>({
  key: 'userId',
  get: async ({ get }) => {
    const userIdOrVanity = get(currentVanity);
    if (!userIdOrVanity) {
      return null;
    }

    // the previous model is a truncated version from their session, we won't get all the
    // data. So, we have to re-query by id instead for things like 'me' or when the
    // url is a vanity URL
    if (typeof userIdOrVanity !== 'number'
      || Number.isNaN(userIdOrVanity)
      || `${userIdOrVanity}`.match(/^\d+$/)) {
      const user = await getUserData(userIdOrVanity, { throwExceptions: true });
      return Number(user.id || 0);
    }

    return Number(userIdOrVanity);
  },
});

const userQuery = selector<MonolithProfile | null>({
  key: 'user',
  get: async ({ get }) => {
    get(versionState); // forces refreshes when this increments, re-queries all data

    const userId = get(userIdQuery);
    if (!userId) {
      return null;
    }

    const user = await getUserData(userId, { throwExceptions: true });

    return user || null;
  },
});

export default userQuery;
