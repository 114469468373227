import * as React from 'react';
import { GroupCardListItem } from '@fieldnation/platform-components';
import classNames from 'classnames';
import CancellationDetails from './CancellationDetails';
import { IReliabilityScore, ScoreType } from './types.d';
import trackAmplitudeEvent, { VIEWED_RELIABILITY_DETAILS } from '../ProviderSnapshot/amplitude';
import css from './Reliability.scss';

interface Props {
  providerId: number;
  reliabilityScore: IReliabilityScore;
}

export default function CancellationRate(props: Props): JSX.Element {
  const { providerId, reliabilityScore } = props;
  const [openDetails, setOpenDetails] = React.useState(false);
  const cancellationScore = reliabilityScore?.[ScoreType.Cancellation] ?? {};
  const scoreValue = cancellationScore?.score >= 0 && cancellationScore?.count > 4 ? (
    `${cancellationScore?.score}%`
  ) : (
    <span className={classNames('text-muted', css['reliability-sub-text'])}>not enough data</span>
  );

  return (
    <>
      <div className="u-padding-bottom--sm">
        <div className={css['reliability-container']}>
          <GroupCardListItem
            hideExpander
            dottedLine
            middleContent=""
            label={(
              <>
                <span>Cancellation Rate</span>
                {cancellationScore?.target && (
                  <span className="text-muted">
                    {' '}
                    &nbsp;
                    {`(≤ ${cancellationScore.target}% target)`}
                    {' '}
                    &nbsp;
                  </span>
                )}
              </>
            )}
            data={<span className={css['text-semi-bold']}>{scoreValue}</span>}
            type="default"
            border="none"
          />
        </div>
        {cancellationScore?.details?.totalAssignment > 0 && cancellationScore?.count > 4 && (
          <>
            <div className={classNames('text-muted', css['reliability-sub-text'])}>
              <span>
                {cancellationScore?.details?.totalCancellation || '0'}
                {' '}
                cancellations of last
                {' '}
                {cancellationScore?.details?.totalAssignment || ''}
                {' '}
                assigned work orders
              </span>
            </div>
            <div>
              <button
                className={css['show-reliability-details']}
                type="button"
                onClick={() => {
                  setOpenDetails(true);
                  const match = window.location.pathname.match(/workorders\/(\d)+\/providers/g);
                  trackAmplitudeEvent({
                    category: VIEWED_RELIABILITY_DETAILS,
                    ampProps: {
                      Source: match?.length ? 'Provider Drawer' : 'Profile',
                      Type: 'Cancellation Rate',
                      Score: cancellationScore?.score ?? 0,
                    },
                  });
                }}
              >
                <span className={css['reliability-sub-text']}>
                  See cancellations / removals
                </span>
              </button>
            </div>
          </>
        )}
      </div>
      <CancellationDetails
        isOpen={openDetails}
        providerId={providerId}
        onClose={() => setOpenDetails(false)}
        reliabilityScore={reliabilityScore}
      />
    </>
  );
}
