/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Grid,
  GridColumn,
  Median,
  MedianAlpha,
  MedianOmega,
  v2,
} from '@fieldnation/platform-components';

import css from '../styles.scss';
import { useEditPermission } from '../../../hooks';
import { ProfileInfoItems, RequestTypes, VALIDATION_DEBOUNCE_TIME } from '../../../constants';
import {
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
  InvalidInputHelpMessage,
} from '../../../components';
import {
  getInputFieldAtom,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  userContextSelector,
  getRequestConfigCallback,
  resetFormCallback,
  passwordProtectedRequestAtom,
  getInputValidationFieldAtom,
  getInputValidatorCallback,
  resetValidationErrorsCallback,
} from '../state';

const { TextInput } = v2;

const UsernameFrom = () => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.Username),
  );

  const [currentUsername, setCurrentUsername] = useRecoilState(
    getInputFieldAtom('username'),
  );

  const usernameError = useRecoilValue(getInputValidationFieldAtom('username'));

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);

  const onSave = async () => {
    const requestConfig = await getRequestConfig(RequestTypes.UPDATE_USERNAME);

    const updateCallback = async () => {
      const success = await makeRequest(
        requestConfig,
        'Successfully updated username',
      );
      if (success) await resetForm(['username']);
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)('username');

  const inputFieldValidator = useRecoilCallback(getInputValidatorCallback);
  const resetInputErrors = useRecoilCallback(resetValidationErrorsCallback);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'username';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentUsername, isEditing]);

  return (
    <div>
      {!isEditing && (
        <BodyText styleLevel="md" tag="span">
          <span
            className={classNames({
              [css['list-item-content']]: true,
              [css['list-item-content-empty']]: !currentUsername,
            })}
          >
            {currentUsername || 'No username'}
          </span>
        </BodyText>
      )}
      {isEditing && (
        <Grid>
          <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
            <TextInput
              label="Username"
              value={currentUsername}
              onChange={(e) => setCurrentUsername(e.target.value)}
            />
            <InvalidInputHelpMessage validationError={usernameError} />
            <ProfileInfoFormActionButton
              onClose={onClose}
              onSave={onSave}
              isValid={usernameError.isValid}
            />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export const Username = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.Username);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.Username),
  );
  const setOpenForEdit = useSetRecoilState(openForEditAtom);

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="span">
        <span className={css['list-item-name']}>Username</span>
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top">
        <MedianAlpha>
          <UsernameFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="username" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.Username);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
