import { atom, selector } from 'recoil';
import { getTimelinessDetails } from '../api';

import { BackoutsDetailsFilters, TimelinessDetails } from '../types.d';

type TimelinessFilters = BackoutsDetailsFilters

export const modalfilters = atom<TimelinessFilters>({
  key: 'timelinessFilters',
  default: {},
});

export const timelinessDetails = selector<TimelinessDetails>({
  key: 'timelinessDetails',
  get: async ({ get }) => {
    const filters = get(modalfilters);
    if (!filters?.userId) {
      return { canViewList: false, results: [], graphData: [] } as TimelinessDetails;
    }

    return getTimelinessDetails(filters);
  },
});
