import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import {
  v2,
  Link,
  Icon,
  HorizontalList,
  HorizontalListItem,
} from '@fieldnation/platform-components';
import { FooterAction } from '@fieldnation/platform-components/src/v2/Modal/types.d';
import UpgradeDwongradeModal from '../UpgradeDowngradeModal';
import { EVENTS, EventTrackerSelector } from '../common/amplitude';
import { PossibleUpdateOptions } from '../common/UpdateOptions.selector';

import css from './style.scss';

const { Modal } = v2;

const MarketSmartInsightsModal = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const trackEvent = useRecoilValue(EventTrackerSelector);
  const { premier: updateOption } = useRecoilValue(PossibleUpdateOptions);

  const getFooterActions = (): FooterAction[] => {
    const actions: FooterAction[] = [
      {
        label: 'Close',
        type: 'secondary',
        onClick: () => setModalOpen(false),
      },
    ];
    if (updateOption?.current_tier !== 'premier') {
      actions.push({
        label: 'Get Premier',
        type: 'primary',
        onClick: () => {
          trackEvent(EVENTS.CLICKED_UPGRADE_PACKAGE, {
            'Current Package': updateOption?.current_tier,
            'New Package': 'premier',
          });
          setModalOpen(false);
          setOpenUpgradeModal(true);
        },
      } as FooterAction);
    }

    return actions;
  };

  return (
    <div className="MarketSmartInsightsModal">
      <Link onClick={() => setModalOpen(true)}>
        <div className={classNames(css.PricingTableComponent__linkText)}>
          Watch Video
        </div>
      </Link>
      {modalOpen && (
        <Modal
          size="large"
          header="Introducing MarketSmart™ Insights"
          footerActions={getFooterActions()}
          onClose={() => setModalOpen(false)}
          isOpen
        >
          <div>
            <div
              className={classNames(
                css.MarketSmartInsightsModal__iframeContainer,
              )}
            >
              <div className={classNames(css['iframe-wrapper'])}>
                <iframe
                  width="100%"
                  height="400"
                  src="https://www.youtube.com/embed/Nx0skPrDyU8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <div
              className={classNames(
                css['MarketSmartInsightsModal__section--margin'],
              )}
            >
              MarketSmart Insights, an exclusive tool for Premier customers,
              aggregates data from 3 million field service jobs on our platform
              so you can get pricing and coverage data on-demand. With this
              information at your fingertips, you can win and fulfill more work,
              increase margins, and monitor the effectiveness of your pricing
              strategies.
            </div>
            <div
              className={classNames(
                css['MarketSmartInsightsModal__section--margin'],
              )}
            >
              <Link href="https://fieldnation.com/marketsmart" target="_blank">
                <HorizontalList spacing={2}>
                  <HorizontalListItem>Learn More</HorizontalListItem>
                  <HorizontalListItem>
                    <Icon name="openInNew" size={24} block />
                  </HorizontalListItem>
                </HorizontalList>
              </Link>
            </div>
          </div>
        </Modal>
      )}

      <UpgradeDwongradeModal
        target="premier"
        openModal={openUpgradeModal}
        hideButtons
        callBack={() => setOpenUpgradeModal(false)}
      />
    </div>
  );
};

export default MarketSmartInsightsModal;
