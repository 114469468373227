import React, { memo } from 'react';
import { v2 } from '@fieldnation/platform-components';
import { Options } from '@fieldnation/platform-components/src/v2/Select/types.d';
import { VerificationStatusEnum } from './types.d';

const { Select } = v2;

const selectOptions: Options[] = [
  {
    label: 'New',
    value: VerificationStatusEnum.NEW,
  },
  {
    label: 'Verified',
    value: VerificationStatusEnum.VERIFIED,
  },
  {
    label: 'Invalid',
    value: VerificationStatusEnum.INVALID,
  },
];

interface SelectStatusProps {
  verificationStatus: string;
  onChangeVerificationStatus(status: VerificationStatusEnum): void;
}

const SelectStatus = ({
  verificationStatus,
  onChangeVerificationStatus,
}: SelectStatusProps): JSX.Element => (
  <Select
    label="Select Status"
    options={selectOptions}
    onChange={(option) => onChangeVerificationStatus(option.value as VerificationStatusEnum)}
    value={verificationStatus}
  />
);

export default memo(SelectStatus);
