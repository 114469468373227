import ExampleComponent from './ExampleComponent/index';
import ProviderNotes from './ProviderNotes/index';
import ProviderSnapshot from './ProviderSnapshot/index';
import V2Accordion from './ProviderSnapshot/Components/Accordion';
import V2AccordionContent from './ProviderSnapshot/Components/AccordionContent';
import SlateEditor from './SlateEditor/index';
import TalentPoolComponent from './TalentPoolComponent/index';
import OtpComponent from './OtpComponent/index';
import OtpComponentModal from './OtpComponent/OtpComponentModal';
import OtpVerificationComponent from './OtpComponent/OtpVerificationComponent';
import OtpVerificationComponentModal from './OtpComponent/OtpVerificationComponentModal';
import TaxExemptionsList from './TaxExemptionsList/index';
import RequirementsWidget from './Qualifications/RequirementsWidget/index';
import ScreeningModalWidget from './Screening/index';
import {
  LicenseCertifcaionWidget,
  ConfigurableCredentialWidget,
  EnhancedCredentialsAddWidget,
  EnhancedCredentialsEditWidget,
} from './LicenseCertificaton/index';
import { CustomSelectOptions } from './LicenseCertificaton/CustomSelectOptions';
import TaxForm from './TaxForm/index';
import {
  AccountOverview,
  CurrentPackage,
  PageHeader,
  TileManager,
  CardTooltip,
  Intercept,
  ContextGuard,
} from './AccountOverview';
import CompanyOverview from './CompanyOverview';
import CompanyContacts from './CompanyContacts';
import CuratedChip from './CuratedChip/index';
import WorkOrderCuratedChip from './WorkOrderCuratedChip/index';
import UbiForm from './UbiForm';
import UbiModal from './UbiModal';
import MessageContainer from './MessageContainer';
import ProviderCard from './ProviderCard/index';
import FeedbackList from './FeedbackList';
import GroupList from './GroupList';
import ProviderReliability from './ProviderReliability';
import ShipmentForm from './ShipmentForm';

const QualificationCreateWidgetWrapper = RequirementsWidget;

export {
  ExampleComponent,
  TalentPoolComponent,
  OtpComponent,
  OtpComponentModal,
  OtpVerificationComponent,
  OtpVerificationComponentModal,
  TaxExemptionsList,
  AccountOverview,
  CurrentPackage,
  PageHeader,
  TileManager,
  CardTooltip,
  Intercept,
  ContextGuard,
  CompanyOverview,
  CompanyContacts,
  ScreeningModalWidget,
  ProviderNotes,
  LicenseCertifcaionWidget,
  ConfigurableCredentialWidget,
  CustomSelectOptions,
  TaxForm,
  SlateEditor,
  ProviderSnapshot,
  CuratedChip,
  WorkOrderCuratedChip,
  V2Accordion,
  V2AccordionContent,
  RequirementsWidget,
  QualificationCreateWidgetWrapper,
  UbiForm,
  UbiModal,
  MessageContainer,
  ProviderCard,
  FeedbackList,
  GroupList,
  EnhancedCredentialsAddWidget,
  EnhancedCredentialsEditWidget,
  ProviderReliability,
  ShipmentForm,
};

export * from './ProfileSettings/index';
export * from './SmartAddress/index';
