import * as React from 'react';
import { Loader } from '@fieldnation/platform-components';
import { Element } from 'react-scroll';

interface Props {
  label: string;
  children: JSX.Element | React.ReactNode[];
  fallback?: JSX.Element;
}

const SuspenseSectionWrapper: React.FC<Props> = ({
  label,
  children,
  fallback,
}: Props) => (
  <Element name={label}>
    <React.Suspense
      fallback={
        fallback || (
          <Loader isLoading>
            <div style={{ width: '10rem', height: '30rem' }}>&nbsp;</div>
          </Loader>
        )
      }
    >
      {children}
    </React.Suspense>
  </Element>
);

SuspenseSectionWrapper.defaultProps = {
  fallback: undefined,
};

export default SuspenseSectionWrapper;
