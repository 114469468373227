/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useRecoilValue } from 'recoil';
import get from 'lodash/get';
import classNames from 'classnames';
import {
  // @ts-ignore
  PricingTable,
  Link,
  Icon,
  HorizontalList,
  HorizontalListItem,
  Median,
  MedianAlpha,
  MedianOmega,
} from '@fieldnation/platform-components';
import css from './style.scss';
import MarketSmartInsightsModal from './MarketSmartInsightsModal';
import { CurrentContractSelector } from '../common/GetContract.selector';
import UpgradeDwongradeModal from '../UpgradeDowngradeModal';
import { ITiers } from '../common/types.d';
import { Cell } from '@fieldnation/platform-components/src/PricingTable/types';

enum PackageName {
  BASIC = "basic",
  PLUS = "plus",
  PREMIER = "premier",
}

enum PackageIndex {
  BASIC = 1,
  PLUS = 2,
  PREMIER = 3,
}

const PricingTableComponent = (): JSX.Element => {
  const { marketplace_buyer } = useRecoilValue(CurrentContractSelector);

  const assetUrl = get(window, 'assetUrl', '');
  const packageName = (marketplace_buyer?.tier || 'basic') as ITiers;

  // Start of static content. In the future we will update them with contract preset data
  const workOrderLimit = 20;
  const overageFeeAmount = 5;
  const overageFeeType = 'percent';
  const overageFee = `${overageFeeAmount}${
    overageFeeType === 'percent' ? '%' : ''
  }`;
  // End of static content.

  let titleColumns = [
    { width: 40, content: 'Field Nation Packages' },
    { width: 20, content: 'BASIC' },
    { width: 20, content: 'PLUS' },
    { width: 20, content: 'PREMIER' },
  ];
  let actionColumns = [
    <Median>
      <MedianAlpha>FEATURE LIST</MedianAlpha>
      <MedianOmega>
        <Link
          href={`${assetUrl ||
            '/'}documents/feature-comparison-matrix.pdf`}
          target="_blank"
        >
          <HorizontalList spacing={1}>
            <HorizontalListItem>
              <div
                className={classNames(
                  css.PricingTableComponent__linkText,
                )}
              >
                View All
              </div>
            </HorizontalListItem>
            <HorizontalListItem>
              <Icon name="openInNew" size={24} block />
            </HorizontalListItem>
          </HorizontalList>
        </Link>
      </MedianOmega>
    </Median>,
    <UpgradeDwongradeModal target="basic" />,
    <UpgradeDwongradeModal target="plus" />,
    <UpgradeDwongradeModal target="premier" />,
  ];

  const packages: PackageName[] = [
    PackageName.BASIC,
    PackageName.PLUS,
    PackageName.PREMIER,
  ];

  if (packageName !== PackageName.BASIC) {
    packages.splice(PackageIndex.BASIC - 1, 1);
    titleColumns = [
      { width: 40, content: 'Field Nation Packages' },
      { width: 30, content: 'PLUS' },
      { width: 30, content: 'PREMIER' },
    ];
    actionColumns.splice(PackageIndex.BASIC, 1);
  }
  const activePkgIdx = packages.findIndex(p => p === packageName) + 1;

  return (
    <div className={classNames(css.PricingTableComponent)}>
      { // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */}
      <PricingTable
        titleColumns={titleColumns}
        actionColumns={actionColumns}
        cells={[
          {
            type: 'item',
            contents: [
              'Work Order Limit (Monthly)',
              <div>
                <div>{workOrderLimit}</div>
                <div
                  className={classNames(
                    css.PricingTableComponent__additionalFee,
                  )}
                >
                  {`${overageFee} charge for additional`}
                </div>
              </div>,
              'Unlimited',
              'Unlimited',
            ],
          },
          {
            type: 'item',
            contents: ['Basic Work Order Management', 'Yes', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Custom Fields', '', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Templates', '', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Flightboard Review Drawer', '', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Selection Rules', '', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Basic Auto Dispatch', '', 'Yes', 'Yes'],
          },
          {
            type: 'item',
            contents: ['Work Order Activity Reports', '', 'Yes', 'Yes'],
          },
          {
            type: 'category',
            contents: ['PeopleSmart Talent Management', '', '', ''],
          },
          {
            type: 'category_item',
            contents: ['Talent Sourcing Services', '', '', 'Yes'],
          },
          {
            type: 'category_item',
            contents: ['Advanced Technician Profiles', '', '', 'Yes'],
          },
          {
            type: 'category_item',
            contents: ['Provider Assessments', '', '', 'Yes'],
          },
          {
            type: 'category_item',
            contents: ['Smart Talent Pools', '', '', 'Yes'],
          },
          {
            type: 'category',
            contents: [
              <Median>
                <MedianAlpha>MarketSmart™ Insights</MedianAlpha>
                <MedianOmega>
                  <MarketSmartInsightsModal />
                </MedianOmega>
              </Median>,
              '',
              '',
              '',
            ],
          },
          {
            type: 'category_item',
            contents: [
              <div>
                On-demand, marketplace-wide pricing
                <br />
                &nbsp;and coverage data
              </div>,
              '',
              '',
              'Yes',
            ],
          },
          {
            type: 'category',
            contents: ['WorkSmart Productivity Suite', '', '', ''],
          },
          {
            type: 'category_item',
            contents: ['Advanced Auto Dispatch', '', '', 'Yes'],
          },
          {
            type: 'category_item',
            contents: ['SmartAudit Approval Automation', '', '', 'Yes'],
          },
        ]?.map((c) => {
          if (packageName !== PackageName.BASIC) {
            c.contents.splice(PackageIndex.BASIC, 1);
          }
          return c as Cell;
        })}
        activeColumnIndex={activePkgIdx}
        stretch
      />
    </div>
  );
};

export default PricingTableComponent;
