import * as AmplitudeClient from 'amplitude-js';

export const PROFILE_FILTERED_RATINGS = 'Filtered Ratings';
export const VIEWED_RELIABILITY_DETAILS = 'Viewed Reliability Details';
export const REVIEWED_SKILLS_SUMMARY = 'Reviewed Skill Summary';
export const CUSTOMIZED_PROVIDER_DRAWER = 'Customized Provider Drawer';
export const VIEWED_SKILL_JOB_DETAIL = 'Viewed Skill Job Detail';
export const PROFILE_OPENED_RATING_MODAL = 'Viewed Ratings';
export const NAVIGATED = 'Navigated';
export const PROFILE_ADD_TO_TALENT_POOL = 'Created Talent Pool';
export const ADDED_TO_TALENT_POOL = 'Added to Talent Pool';
export const OPENED_PROVIDER_PAGE = 'Opened Provider Page';
export const LEARNED_MORE = 'Learned More';
export const STARTED_ADDING_TALENT = 'Started Adding Talent';
export const VIEWED_PRIVATE_FEEDBACK = 'Viewed Private Feedback';

interface Payload {
  category: string;
  ampProps?: Record<string, unknown>;
  link?: string;
  linkEvent?: React.ChangeEvent<HTMLButtonElement | HTMLAnchorElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback?: Function;
}

declare global {
  interface Window {
    _AMPLITUDE_KEY: string;
    _amplitudeClient: AmplitudeClient.AmplitudeClient;
  }
}

export function getAmplitudeClient(): AmplitudeClient.AmplitudeClient | null {
  // eslint-disable-next-line no-underscore-dangle
  if (window._amplitudeClient) {
    // eslint-disable-next-line no-underscore-dangle
    return window._amplitudeClient;
  }

  const amplitudeClient = AmplitudeClient.getInstance();
  const user = window.context;
  // eslint-disable-next-line no-underscore-dangle
  const amplitudeKey = window._AMPLITUDE_KEY || '';
  if (!user || !user?.id || !amplitudeKey) {
    return null;
  }
  amplitudeClient.init(amplitudeKey, user.id.toString());
  amplitudeClient.setUserProperties({
    'Group ID': user?.group?.id || 0,
    'Company ID': user?.company?.id || 0,
    Email: user?.email || '',
    Name: `${user?.first_name} ${user?.first_name}`,
  });

  return amplitudeClient;
}

export default function trackAmplitudeEvent(payload: Payload): void {
  const {
    category, ampProps = {}, link, linkEvent, callback,
  } = payload;

  if (!category) {
    return;
  }

  const client = getAmplitudeClient();
  if (!client) {
    return;
  }

  if (link && linkEvent) {
    linkEvent.preventDefault();
  }

  client.logEvent(category, ampProps, () => {
    if (callback && typeof callback === 'function') {
      callback();
    }
    if (link) {
      document.location.href = link;
    }
  });
}
