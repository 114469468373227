/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import {
  Icon,
  Link,
  MiniDash,
  IconAside,
  IconAsideIcon,
  BodyText,
  Thumbnail,
  HorizontalList,
  HorizontalListItem,
  Heading,
  IconButton,
  Median,
  MedianAlpha,
  Truncate,
} from '@fieldnation/platform-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Email from './Email';
import { getSidebarConfigQuery, toggleSidebarConfigCollapse } from '../queries/snapshot.query';
import { sourceAppQuery } from '../queries/source-app';
import formatRelative from '../../utils/formatRelative';
import CuratedChip from '../../CuratedChip';
import trackAmplitudeEvent, { OPENED_PROVIDER_PAGE} from '../amplitude';
import { MonolithProfile } from '../types.d';

import css from './Header.scss';
import userPermissionState from '../../SectionCard/States/Selectors';

interface IProps {
  user: MonolithProfile,
  workOrderId?: number | null;
  backgroundColor?: string;
  isSummary?: boolean
  toggleExpandAll?: (toggleState: string) => void;
  onClose?: () => void;
  curatedNetworks?: string[];
  overlayPosition?: 'top' | 'right' | 'left' | 'bottom';
}

const Header = ({
  user,
  workOrderId,
  backgroundColor,
  isSummary,
  toggleExpandAll,
  onClose,
  curatedNetworks,
  overlayPosition,
}: IProps): JSX.Element | null => {
  const drawerConfigValue = useRecoilValue(getSidebarConfigQuery);
  const sourceAppName = useRecoilValue(sourceAppQuery);
  const updateDrawerConfigCollapse = useSetRecoilState(toggleSidebarConfigCollapse);
  const { isStaff, isBuyer } = useRecoilValue(userPermissionState);

  const curatedNetworkNames =
    curatedNetworks || (user.networks?.results || [])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((network: any) => network.isCurated).map((n: any) => n.name);

  let showCuratedNetworks = Boolean(
    (isStaff || isBuyer) && curatedNetworkNames.length,
  );

  if (process.env.STORYBOOK === 'true' && curatedNetworks?.length) {
    showCuratedNetworks = true;
  }

  if (!user) {
    return null;
  }

  const {
    id: userId = 0,
    last_active: lastActiveObj = {},
    thumbnail = '',
    first_name: firstName = '',
    last_name: lastName = '',
    middle_initial: middleInitial = '',
    suffix = '',
  } = user;
  const lastActive = new Date(lastActiveObj?.utc || '');
  const userSuffix = suffix ? `, ${suffix}` : '';
  const userMiddleInitial = middleInitial ? `${middleInitial}.` : '';

  const {
    cell = '', phone = '', phone_ext: phoneExt = '', email = '',
  } = user;
  const company = user?.company?.name || '';
  const companyId = user?.company?.id || 0;
  const areAnyExpanded = Boolean(drawerConfigValue.columns.find(({ collapse }) => !collapse));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const style: any = {
    position: 'relative',
    backgroundColor: backgroundColor || '#edeff3',
  };

  return (
    <div className={`u-padding--${isSummary ? 'sm' : 'md'}`} style={style}>
      <Median
        noWrap
        verticalAlign="top"
      >
        <MedianAlpha>
          <HorizontalList verticalAlign="top" spacing={2} noWrap>
            <HorizontalListItem>
              {/* @ts-ignore */}
              <Thumbnail src={thumbnail} profileRing={showCuratedNetworks ? 'curatedNetwork' : null} />
            </HorizontalListItem>
            <HorizontalListItem flexGrow>
              {showCuratedNetworks ? <CuratedChip providerName={`${firstName} ${lastName}`} networks={curatedNetworkNames} source={`Provider Snapshot - ${sourceAppName}`} overlayPosition={overlayPosition} /> : null}
              <Heading
                tag={isSummary ? 'h5' : 'h4'}
                styleLevel="h4"
              >
                <HorizontalList spacing={2}>
                  <HorizontalListItem>
                    <Link
                      href={workOrderId
                        ? `/p/${userId}?work_order_id=${workOrderId}`
                        : `/p/${userId}`}
                      target="_blank"
                      onClick={() => {
                        setTimeout(() => {
                          trackAmplitudeEvent({
                            category: OPENED_PROVIDER_PAGE,
                            ampProps: {
                              Source: sourceAppName || 'Drawer',
                            },
                          });
                        }, 100);
                        return false;
                      }}
                    >
                      <Truncate maxLines={1} wordBreak="break-all" text={`${firstName} ${userMiddleInitial} ${lastName}${userSuffix}`} />
                    </Link>
                  </HorizontalListItem>
                </HorizontalList>
              </Heading>
              <BodyText styleLevel="sm">
                <span className="text-muted">
                  {`Last active ${formatRelative(lastActive)}`}
                </span>
              </BodyText>
            </HorizontalListItem>
            <HorizontalListItem>
              {(onClose && !isSummary) ? (
                <>
                  <div className={css['close--Icon']}>
                    <IconButton
                      title="Close"
                      name="close"
                      onClick={onClose}
                    />
                  </div>
                  {/* pad the truncated title because of absolute positioned close icon */}
                  <div style={{ width: '15px' }} />
                </>
              ) : null}
            </HorizontalListItem>
          </HorizontalList>
        </MedianAlpha>
      </Median>
      {!isSummary ? (
        <MiniDash
          toggleIcon={`unfold${areAnyExpanded ? 'Less' : 'More'}`}
          toggleOnClick={() => {
            // @ts-ignore
            updateDrawerConfigCollapse(areAnyExpanded ? 'COLLAPSE ALL' : 'EXPAND ALL');
            if (toggleExpandAll) {
              toggleExpandAll(areAnyExpanded ? 'COLLAPSE ALL' : 'EXPAND ALL');
            }
          }}
          toggleTitle={`Unfold ${areAnyExpanded ? 'Less' : 'More'}`}
        >
          <div className="u-padding-top--md">
            {(cell || phone) && (
            <IconAside>
              <IconAsideIcon>
                <Icon size={16} name="phone" />
              </IconAsideIcon>
              <div className="u-padding-left--sm">
                <a href={`tel:${(cell || phone).replace(/[^\d]/g, '')}`}>
                  {cell || phone}
                  {phoneExt ? ` x${phoneExt}` : ''}
                </a>
              </div>
            </IconAside>
            )}
            {email && (
            <IconAside>
              <IconAsideIcon>
                <Icon size={16} name="mail" />
              </IconAsideIcon>
              <div className="u-padding-left--sm">
                <Email email={email} />
              </div>
            </IconAside>
            )}
            {companyId > 0 && (
            <IconAside>
              <IconAsideIcon>
                <Icon size={16} name="business" />
              </IconAsideIcon>
              <div className="u-padding-left--sm">
                <Link href={`/company/profile/${companyId}`} target="_blank">
                  {company}
                </Link>
              </div>
            </IconAside>
            )}
          </div>
        </MiniDash>
      ) : (
        <div className="u-padding-top--md">
          {(cell || phone) && (
          <IconAside>
            <IconAsideIcon>
              <Icon size={16} name="phone" />
            </IconAsideIcon>
            <div className="u-padding-left--sm">
              <a href={`tel:${(cell || phone).replace(/[^\d]/g, '')}`}>
                {cell || phone}
                {phoneExt ? ` x${phoneExt}` : ''}
              </a>
            </div>
          </IconAside>
          )}
          {email && (
          <IconAside>
            <IconAsideIcon>
              <Icon size={16} name="mail" />
            </IconAsideIcon>
            <div className="u-padding-left--sm">
              <Email email={email} />
            </div>
          </IconAside>
          )}
          {companyId > 0 && (
          <IconAside>
            <IconAsideIcon>
              <Icon size={16} name="business" />
            </IconAsideIcon>
            <div className="u-padding-left--sm">
              <Link href={`/company/profile/${companyId}`} target="_blank">
                {company}
              </Link>
            </div>
          </IconAside>
          )}
        </div>
      )}
    </div>
  );
};

Header.defaultProps = {
  workOrderId: null,
  backgroundColor: '',
  isSummary: false,
  curatedNetworks: null,
  toggleExpandAll: () => null,
  onClose: () => null,
  overlayPosition: null,
};

export default Header;
