import React, { memo } from 'react';
import UbiRadioOption from './RadioOption';
import UbiNumberInput from './UbiNumberInput';
import classes from './styles.scss';
import { VerificationStatusEnum } from './types.d';

interface UbiInputFieldsProps {
  maintainsBooksAndRecords: string;
  filesIRSExpense: string;
  hasUbi: string;
  ubiNumber: string;
  verificationStatus: VerificationStatusEnum;
  onChangeMaintainRecords(maintainsBooksAndRecords: string): void;
  onChangeFilesWithIrs(filesIRSExpense: string): void;
  onChangeHasUbi(hasUbi: string): void;
  onChangeUbiNumber(ubiNumber: string): void;
  onChangeVerificationStatus(status: VerificationStatusEnum): void;
  isModal: boolean;
}

const UbiInputFields = ({
  maintainsBooksAndRecords,
  filesIRSExpense,
  hasUbi,
  ubiNumber,
  verificationStatus,
  onChangeMaintainRecords,
  onChangeFilesWithIrs,
  onChangeHasUbi,
  onChangeUbiNumber,
  onChangeVerificationStatus,
  isModal,
}: UbiInputFieldsProps): JSX.Element => (
  <div className={isModal ? classes.InputPadding : ''}>
    <UbiRadioOption
      id="maintainsBooksAndRecords"
      label="Do you maintain your own books and records?"
      infoMessage={(
        <span>
          {'Selecting yes confirms you\'re '}
          <br />
          running a business.
        </span>
      )}
      toolTipPosition={window.innerWidth > 600 ? 'top' : 'left'}
      radioValue={maintainsBooksAndRecords}
      onChange={onChangeMaintainRecords}
    />

    <UbiRadioOption
      id="filesIRSExpense"
      label="Do you file a schedule of your business expenses and income on your IRS tax return?"
      infoMessage={(
        <span>
          Select yes if you report your
          {' '}
          <br />
          business income/expenses on
          {' '}
          <br />
          your IRS tax return.
        </span>
      )}
      toolTipPosition={window.innerWidth > 600 ? 'top' : 'left'}
      radioValue={filesIRSExpense}
      onChange={onChangeFilesWithIrs}
    />

    <UbiRadioOption
      id="hasUbi"
      label="Do you have a WA Unified Business Identifier(UBI)?"
      infoMessage={(
        <span>
          {'This ID indicates you\'re '}
          <br />
          registered as a business with
          {' '}
          <br />
          the Washington Department
          {' '}
          <br />
          of Revenue.
        </span>
      )}
      toolTipPosition={window.innerWidth > 600 ? 'top' : 'left'}
      radioValue={hasUbi}
      onChange={onChangeHasUbi}
    />

    <UbiNumberInput
      ubiNumber={ubiNumber}
      hasUbi={hasUbi}
      verificationStatus={verificationStatus}
      onChangeUbiNumber={onChangeUbiNumber}
      onChangeVerificationStatus={onChangeVerificationStatus}
    />
  </div>
);

export default memo(UbiInputFields);
