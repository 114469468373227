import { checkValidCardNumber } from './card-helper';
import { Country, ScreeningInputValues } from './types.d';

export const BACKGROUND_CHECK = 'Background Check';
export const DRUG_TEST = 'Drug Test';
export const DRIVING_RECORDS_CHECK = 'Driving Records Check';
export const CREDIT_CHECK = 'Credit Check';

export const validateOrderFormInputs = (screeningType, values, setValidation) => {
  const error: Partial<ScreeningInputValues> = {};

  if (!values?.first_name?.trim()) {
    error.first_name = 'First name is required';
  }

  if (!values?.last_name?.trim()) {
    error.last_name = 'Last name is required';
  }

  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!values?.email?.trim()) {
    error.email = 'Email is required';
  } else if (!EMAIL_REGEX.test(values?.email)) {
    error.email = 'Email is invalid';
  }

  if (!values?.phone?.trim()) {
    error.phone = 'Phone number is required';
  }

  if (!values?.postal_code?.trim()) {
    error.postal_code = 'Zip code is required';
  }

  if (screeningType === DRIVING_RECORDS_CHECK && !values?.driver_license_number?.trim()) {
    error.driver_license_number = 'Driver licence number is required';
  }

  if (screeningType === DRIVING_RECORDS_CHECK && !values?.driver_license_state?.trim()) {
    error.driver_license_state = 'Driver licence state is required';
  }

  setValidation(error);
  return Object.keys(error)?.length === 0;
};

export const validatePaymnetFormInputs = (values, setValidation) => {
  const error: Partial<ScreeningInputValues> = {};

  if (!values?.card_number?.trim()) {
    error.card_number = 'Card number is required';
  } else {
    if (!checkValidCardNumber(values?.card_number?.trim().replace(/[^\d]/g, ''))) {
      error.card_number = 'Card number is invalid';
    }
    if (values?.card_number?.trim().replace(/[^\d]/g, '').length <= 13) {
      error.card_number = 'Card number is invalid';
    }
  }

  if (!values?.card_exp?.trim()) {
    error.card_exp = 'Expiration date is required';
  } else if (values?.card_exp?.trim()) {
    const card_exp = values?.card_exp?.trim();
    const expLength = (card_exp || '').replace(/\s/g, '')?.length || 0;

    if (expLength < 5) {
      error.card_exp = 'Expiration date is invalid';
    }
  }

  if (!values?.card_cvv?.trim()) {
    error.card_cvv = 'CCV is required';
  } else if (values?.card_cvv?.trim()) {
    const card_cvv = values?.card_cvv?.trim();

    if (card_cvv?.length < 3) {
      error.card_cvv = 'CCV is invalid';
    }
  }

  if (!values?.cardholder_name?.trim()) {
    error.cardholder_name = 'Name on card is required';
  }

  if (!values?.billing_address1?.trim()) {
    error.billing_address1 = 'Address 1 is required';
  }

  if (!values?.billing_city?.trim()) {
    error.billing_city = 'City is required';
  }

  if (!values?.billing_state?.trim() && values?.isStateRequired) {
    error.billing_state = 'State is required';
  }

  if (!values?.billing_zip?.trim()) {
    error.billing_zip = 'Zip code is required';
  }

  if (!values?.billing_country?.trim()) {
    error.billing_country = 'Country is required';
  }

  setValidation(error);
  return Object.keys(error)?.length === 0;
};

export const getAllStatesByISO = (
  countryName: string,
) => (window?._COUNTRIES || []).find(
  ({ iso }) => iso === countryName,
);

export const getAllCountries = () => (window?._COUNTRIES.map((country) => ({
  ...(country as Country),
  label: country?.name,
  value: country?.iso,
})).sort((a, b) => {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
}) || []);

export const detectWindowChange = (callback) => {
  window.addEventListener('resize', callback);
  return () => {
    window.removeEventListener('resize', callback);
  };
};

export const getScreeningFee = (type: string, dataArray: Array<any>) => {
  let screeningTotalFee = '';
  const screening = (dataArray || []).find((item) => item.name === type);

  if (screening !== undefined) {
    screeningTotalFee = `${screening?.currency?.symbol}${screening?.price}`;
  }

  return {
    screeningTotalFee,
    isFreeScreening: Number(screening?.price || 0) === 0,
    packageId: screening?.id || '',
  };
};

export const shouldUpdateUserInfo = (
  userInfo: ScreeningInputValues,
  updateInfo: ScreeningInputValues,
): boolean => {
  if (userInfo?.first_name !== updateInfo?.first_name) {
    return true;
  }
  if (userInfo?.last_name !== updateInfo?.last_name) {
    return true;
  }
  if (userInfo?.email !== updateInfo?.email) {
    return true;
  }
  if (userInfo?.phone !== updateInfo?.phone) {
    return true;
  }
  return false;
};
