import React from 'react';
import PropTypes from 'prop-types';
import {
  Loader,
  Grid,
  GridColumn,
  Section,
  v2,
} from '@fieldnation/platform-components';

const {
  Modal,
  Radio,
  Select,
  TextInput,
} = v2;

const TalentPoolComponent = ({
  errorMessage,
  loading,
  disabled,
  radioValue,
  talentPoolName,
  talentPools,
  talentPoolsSelected,
  talentPoolNameOnChange,
  talentPoolNameOnValid,
  talentPoolNameOnKeyUp,
  talentPoolOnChange,
  talentPoolOnValid,
  radioOnChange,
  closeHandler,
  saveHandler,
  customFooterActions,
  customBanner,
}) => (
  <>
    <Modal
      onClose={closeHandler}
      isOpen
      header="Add to Talent Pool"
      footerActions={customFooterActions?.length > 0
        ? customFooterActions
        : [{
          type: 'secondary',
          label: 'Cancel',
          onClick: closeHandler,
        },
        {
          type: 'primary',
          label: 'Save',
          onClick: saveHandler,
          disabled,
        },
        ]}
      size="medium"
    >
      <Loader isLoading={loading}>
        <Section>
          {customBanner && <div>{customBanner}</div>}
          <Grid>
            <GridColumn>
              <Radio
                options={[
                  {
                    label: 'Add to existing talent pool',
                    value: 'update',
                  },
                  {
                    label: 'Create new talent pool',
                    value: 'create',
                  },
                ]}
                value={radioValue}
                onChange={radioOnChange}
              />
            </GridColumn>
            <GridColumn>
              {radioValue === 'update'
                ? (
                  <Select
                    multi
                    label="Talent pool"
                    options={talentPools}
                    value={talentPoolsSelected}
                    onChange={talentPoolOnChange}
                    onValid={talentPoolOnValid}
                  />
                )
                : (
                  <TextInput
                    label="Talent pool name"
                    value={talentPoolName}
                    onChange={talentPoolNameOnChange}
                    onValid={talentPoolNameOnValid}
                    onKeyUp={talentPoolNameOnKeyUp}
                  />
                )}
            </GridColumn>
            {errorMessage && (
              <GridColumn>
                <p style={{ color: '#a51d2b' }}>{errorMessage}</p>
              </GridColumn>
            )}
          </Grid>
        </Section>
      </Loader>
    </Modal>
  </>
);

const actionPropTypes = PropTypes.shape({
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  menuItem: PropTypes.bool,
  onClick: PropTypes.func,
  customComponent: PropTypes.element,
  type: PropTypes.oneOf(['primary', 'secondary', 'danger', 'text', 'link', 'custom']),
});

TalentPoolComponent.propTypes = {
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  radioValue: PropTypes.string,
  talentPools: PropTypes.array,
  talentPoolsSelected: PropTypes.array,
  talentPoolName: PropTypes.string,
  radioOnChange: PropTypes.func.isRequired,
  talentPoolOnChange: PropTypes.func.isRequired,
  talentPoolOnValid: PropTypes.func.isRequired,
  talentPoolNameOnKeyUp: PropTypes.func.isRequired,
  talentPoolNameOnChange: PropTypes.func.isRequired,
  talentPoolNameOnValid: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  customBanner: PropTypes.element,
  customFooterActions: PropTypes.arrayOf(actionPropTypes),
};

TalentPoolComponent.defaultProps = {
  loading: true,
  radioValue: 'update',
  disabled: true,
  talentPoolName: '',
  errorMessage: '',
  talentPools: [],
  talentPoolsSelected: [],
  customBanner: null,
  customFooterActions: [],
};

export default TalentPoolComponent;
