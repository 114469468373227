import {
  Icon, IconAside, IconAsideIcon,
} from '@fieldnation/platform-components';
import React from 'react';
import css from './styles.scss';
import { IProps } from './types.d';

const InputValidationContent: React.FC<IProps> = ({ validation }: IProps): JSX.Element => (
  !validation.isValid ? (
    <div className={css['invalid--validation']}>
      <IconAside iconPosition="left" iconColor="red">
        <IconAsideIcon verticalAlign="middle">
          <Icon name="warningSolid" size={22} block />
        </IconAsideIcon>
        <div className={css['invalid--validation--content']}>{validation.message}</div>
      </IconAside>
    </div>
  ) : <></>
);

export default InputValidationContent;
