/* eslint-disable quotes */
/* eslint-disable no-template-curly-in-string */

import { AxiosRequestConfig } from 'axios';
import { IValidationPatterns } from './types.d';

export enum UserGroups {
  PROVIDER = 1,
  ADMIN = 2,
  STAFF = 3,
  CALL_COORDINATOR = 4,
  SUPERVISOR = 5,
  MANAGER = 7,
  DISPATCHER = 8,
  ACCOUNTANT = 9,
  CUSTOMER = 10,
  COMPANY_PAYMENT_USER = 11,
}

export enum UserPermissions {
  EDIT_OTHERS_EMAIL_ADDRESSES = 'EDIT_OTHERS_EMAIL_ADDRESSES',
}

export enum ProfileInfoItems {
  Username = 'Username',
  FullName = 'FullName',
  Address = 'Address',
  Email = 'Email',
  CellNumber = 'CellNumber',
  PhoneNumber = 'PhoneNumber',
}

export enum RequestTypes {
  UPDATE_USERNAME = 'Username',
  UPDATE_FULLNAME = 'FullName',
  UPDATE_ADDRESS = 'Address',
  FORCE_UPDATE_ADDRESS = 'ForceUpdateAddress',
  RESOLVE_ADDRESS = 'ResolveAddress',
  UPDATE_EMAIL = 'Email',
  UPDATE_CELLNUMBER = 'CellNumber',
  UPDATE_PHONENUMBER = 'PhoneNumber',
  VERIFY_EMAIL = 'VerifyEmail',
  UNVERIFY_EMAIL = 'UnverifyEmail',
  CONFIRM_PASSWORD = 'PasswordConfirmation',
  GET_USERINFO = 'UserInfo',
}

export enum ResolutionStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  ERROR = 'ERROR',
}

export const PASSWORD_RETRY_DELAY = 3;

const UPDATE_USER_REQUEST_CONFIG: AxiosRequestConfig = {
  url: 'users/${user_id}',
  method: 'POST',
};

export const ROUTE_MAP: {
  [type in RequestTypes]: AxiosRequestConfig;
} = {
  [RequestTypes.VERIFY_EMAIL]: {
    url: 'users/${user_id}/verify/email',
    method: 'POST',
  },
  [RequestTypes.UNVERIFY_EMAIL]: {
    url: 'users/unverify-email',
    method: 'POST',
  },
  [RequestTypes.GET_USERINFO]: {
    url: 'users/${user_id}',
    method: 'GET',
  },
  [RequestTypes.CONFIRM_PASSWORD]: {
    url: 'users/${user_id}/confirm-password',
    method: 'POST',
  },
  [RequestTypes.RESOLVE_ADDRESS]: {
    url: 'locations/places/resolve',
    method: 'POST',
  },
  [RequestTypes.FORCE_UPDATE_ADDRESS]: {
    url: 'users/${user_id}',
    method: 'POST',
    params: {
      force: true,
    },
  },
  [RequestTypes.UPDATE_USERNAME]: {
    url: 'users/${user_id}/username',
    method: 'PATCH',
  },
  [RequestTypes.UPDATE_FULLNAME]: UPDATE_USER_REQUEST_CONFIG,
  [RequestTypes.UPDATE_ADDRESS]: UPDATE_USER_REQUEST_CONFIG,
  [RequestTypes.UPDATE_EMAIL]: UPDATE_USER_REQUEST_CONFIG,
  [RequestTypes.UPDATE_CELLNUMBER]: UPDATE_USER_REQUEST_CONFIG,
  [RequestTypes.UPDATE_PHONENUMBER]: UPDATE_USER_REQUEST_CONFIG,
};

const phonePattern = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const namePattern = /^([^~!@#$%^&*+?|/_={}[\]<>\\;:0-9"]*)+$/i;
const genericPattern = /^(?!\s*$).+/; // any non empty string;

export const MAX_MIDDLE_INITIAL_LENGTH = 1;
export const MAX_SUFFIX_LENGTH = 10;

export const INPUT_FIELD_VALIDATION_PATTERNS: IValidationPatterns = {
  username: {
    pattern: /^[a-zA-Z0-9._\-@]+$/,
    errorMessage: 'Invalid username',
  },
  first_name: {
    pattern: namePattern,
    errorMessage: 'Invalid first name',
  },
  last_name: {
    pattern: namePattern,
    errorMessage: 'Invalid last name',
  },
  middle_initial: {
    pattern: namePattern,
    errorMessage: 'Invalid middle name',
    isOptional: true,
  },
  suffix: {
    pattern: namePattern,
    errorMessage: 'Invalid suffix',
    isOptional: true,
  },
  cell: {
    pattern: phonePattern,
    errorMessage: 'Invalid cell number',
  },
  phone: {
    pattern: phonePattern,
    errorMessage: 'Invalid phone number',
    isOptional: true,
  },
  phone_ext: {
    pattern: /^([0-9]{0,5}|[0-9]{6,11}#)$/,
    errorMessage: 'Invalid phone extension. Up to 5 digits (no # required) or 11 digits followed by #.',
    isOptional: true,
  },
  email: {
    pattern: emailPattern,
    errorMessage: 'Invalid primary email',
  },
  secondary_email: {
    pattern: emailPattern,
    errorMessage: 'Invalid secondary email',
    isOptional: true,
  },
  address1: {
    pattern: genericPattern,
    errorMessage: 'Invalid address 1',
  },
  address2: {
    pattern: genericPattern,
    errorMessage: 'Invalid address 2',
    isOptional: true,
  },
  city: {
    pattern: genericPattern,
    errorMessage: 'Invalid city',
  },
  zip: {
    pattern: genericPattern,
    errorMessage: 'Invalid zip code',
  },
  state: {
    pattern: genericPattern,
    errorMessage: 'Invalid state name',
  },
  country: {
    pattern: /^[A-Z]{2,3}$/,
    errorMessage: 'Invalid country name',
  },
};

export const VALIDATION_DEBOUNCE_TIME = 300; // ms

export enum KeyCode {
  ENTER = 13,
}
