/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Link } from '@fieldnation/platform-components';
import { IDisabledContent } from './types.d';

import css from './styles.scss';
import SUPPORT_CASE_URL from '../../../SectionCard/Components/SupportText/Constants';

export const DisabledContent = ({
  itemName = 'this section',
  disabled = true,
}: IDisabledContent): JSX.Element => {
  if (disabled) {
    return (
      <span className={css['disabled-text']}>
        <Link
          href={SUPPORT_CASE_URL}
          target="_blank"
        >
          Contact support
        </Link>
        {` to change ${itemName}.`}
      </span>
    );
  }
  return <></>;
};
