/* eslint-disable @typescript-eslint/no-explicit-any */
import requestWrapper from '../requestWrapper';
import {
  WorkorderNoteInputValues,
  NoteDeleteValues,
  ProfileNoteInputValues,
} from './types.d';

export async function getUserById(
  userId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`/v2/users/${userId}`, {
    ...options,
    method: 'GET',
  });
}

export async function addWorkorderNote(
  data: WorkorderNoteInputValues,
  options: any = {},
): Promise<any> {
  const copyToProfileValue = data.copyToProfile.find((el) => el > 0);
  const sendData = {
    provider_id: data.provider_id,
    note: data.note,
    profile_copied_to: copyToProfileValue === 1,
  };

  if (copyToProfileValue === 1) {
    const profileNoteData = {
      provider_id: data.provider_id,
      note: data.note,
      wo_id_copy: data.workorder_id,
    };

    await requestWrapper(
      `/provider-notes/profile-notes/${data.provider_id}`,
      {
        ...options,
        method: 'POST',
        body: profileNoteData,
      },
    );
  }

  return requestWrapper(
    `/provider-notes/workorder-notes/${data.workorder_id}`,
    {
      ...options,
      method: 'POST',
      body: sendData,
    },
  );
}

export async function deleteWorkorderNote(
  data: NoteDeleteValues,
  options: any = {},
): Promise<any> {
  return requestWrapper(
    `/provider-notes/workorder-notes/${data?.workorder_id}/${data.note_id}`,
    {
      ...options,
      method: 'DELETE',
    },
  );
}

export function getWorkorderNotesByWorkorderId(
  workorder_id: number,
  provider_id: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`/provider-notes/workorder-notes/${workorder_id}/provider/${provider_id}?page=${options?.page || 1}&per_page=${options?.perPage || 25}`, {
    ...options,
    method: 'GET',
    throwExceptions: true,
  });
}

export async function addProfileNote(
  data: ProfileNoteInputValues,
  options: any = {},
): Promise<any> {
  const sendData = {
    provider_id: data.provider_id,
    note: data.note,
    wo_id_copy: null,
  };

  return requestWrapper(
    `/provider-notes/profile-notes/${data.provider_id}`,
    {
      ...options,
      method: 'POST',
      body: sendData,
    },
  );
}

export async function deleteProfileNote(
  data: NoteDeleteValues,
  options: any = {},
): Promise<any> {
  return requestWrapper(
    `/provider-notes/profile-notes/${data?.provider_id}/${data.note_id}`,
    {
      ...options,
      method: 'DELETE',
    },
  );
}

export function getProfileNotesByProviderId(
  user_id: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`/provider-notes/profile-notes/${user_id}?page=${options?.page || 1}&per_page=${options?.perPage || 25}`, {
    ...options,
    method: 'GET',
    throwExceptions: true,
  });
}
