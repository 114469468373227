/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { Icon, GroupCardListItem } from '@fieldnation/platform-components';
import performance, {
  performanceFilter,
  hasShowPerformanceTile,
} from '../queries/performance.query';
import contextQuery from '../queries/context.query';
import TimelinessModal from './TimelinessModal';
import BackoutsDetailsModal from './BackoutsDetailsModal';
import trackAmplitudeEvent, { VIEWED_RELIABILITY_DETAILS } from '../amplitude';

import { ProviderCommitmentResponse } from '../types.d';

import css from './Rating.scss';

interface Props {
  userId: number;
  providerCommitment: ProviderCommitmentResponse;
}

const getPerformanceFilters = () => {
  const performanceFilters = useRecoilValue(performance);
  const performanceFilterValue = useRecoilValue(performanceFilter);
  const showPerformanceTile = hasShowPerformanceTile();
  const commitmentDays = Number(performanceFilterValue?.dayHistory);
  return ({
    dayHistory: !showPerformanceTile
      ? commitmentDays
      : Number(performanceFilters?.filters?.dayHistory) || 0,
    pastHistory: Number(performanceFilters?.filters?.woHistory) || 0,
    companyId: performanceFilters?.filters?.withCompanyId || 0,
    graphType: 'monthly',
  });
};

const reliabilityChip = (providerCommitment: ProviderCommitmentResponse) => {
  const assignmentCompletionValue = providerCommitment?.showCommitmentPercent
    ? Number(providerCommitment?.commitmentPercent?.toFixed(1))
    : 0;
  const showChip = assignmentCompletionValue >= 90;

  return showChip ? (
    <div className={css['reliability-chip']}>
      <Icon name="check" size={16} />
      High Commitment
    </div>
  ) : (
    <></>
  );
};

const timelinessChip = (providerCommitment: ProviderCommitmentResponse) => {
  const { denominatorCount = 0 } = providerCommitment?.timelinessStats || {};
  const timelinessValue = providerCommitment?.timelinessPercent
    ? Number(providerCommitment.timelinessPercent.toFixed(1))
    : 0;
  const showChip = timelinessValue >= 80 && denominatorCount >= 5;

  return showChip ? (
    <div className={css['timeliness-chip']}>
      <Icon name="check" size={16} />
      Timely
    </div>
  ) : (
    <></>
  );
};

const commitment = (userId: number, providerCommitment: ProviderCommitmentResponse, performanceFilterData) => {
  const [backoutsModal, setBackoutsModal] = useState(false);
  const { numeratorCount = 0, denominatorCount = 0 } = providerCommitment?.commitmentStats || {};
  const value = providerCommitment?.showCommitmentPercent
    ? `${providerCommitment?.commitmentPercent?.toFixed(1)}%`
    : <span className={classNames('text-muted', css['timeliness-sub-text'])}>not enough data</span>;

  return (
    <div className="u-padding-bottom--sm">
      { !!backoutsModal && (
        <BackoutsDetailsModal
          backoutsFilters={performanceFilterData}
          userId={userId || 0}
          onClose={() => setBackoutsModal(false)}
        />
      )}
      <div className={css['timeliness-wrapper']}>
        <GroupCardListItem
          hideExpander
          dottedLine
          middleContent=""
          label="Assignment Completion"
          // @ts-ignore
          data={(
            <span className={css['semi-bold']}>{value}</span>
          )}
          type="default"
          border="none"
        />
      </div>
      <div className={classNames('text-muted', css['timeliness-sub-text'])}>
        {denominatorCount > 0 && (
          <span>
            {numeratorCount}
            &nbsp;of&nbsp;
            {denominatorCount}
            &nbsp;
          </span>
        )}
        <span>
          Assigned work orders completed
        </span>
      </div>
      <div>
        <button
          className={css['see-commitment-link']}
          type="button"
          onClick={() => {
            setBackoutsModal(true);
            trackAmplitudeEvent({
              category: VIEWED_RELIABILITY_DETAILS,
              ampProps: {
                Source: 'Provider Drawer',
                Type: 'Assignment Completion',
                Score: providerCommitment?.showCommitmentPercent
                  ? providerCommitment?.commitmentPercent?.toFixed(1)
                  : 'not enough data',
              },
            });
          }}
        >
          <span className={css['timeliness-sub-text']}>
            See cancellations / removals
          </span>
        </button>
      </div>
    </div>
  );
};

const timeliness = (
  userId: number,
  providerCommitment: ProviderCommitmentResponse,
  performanceFilterData,
  useAlternateNaming = true,
) => {
  const [showModal, setShowModal] = useState(false);
  const { numeratorCount = 0, denominatorCount = 0 } = providerCommitment?.timelinessStats || {};

  const value = providerCommitment?.showTimelinessPercent ? (
    `${providerCommitment?.timelinessPercent?.toFixed(1)}%`
  ) : (
    <span className={classNames('text-muted', css['timeliness-sub-text'])}>not enough data</span>
  );

  return (
    <div className="u-padding-bottom--md">
      {!!showModal && (
        <TimelinessModal
          backoutsFilters={performanceFilterData}
          userId={userId || 0}
          onClose={() => setShowModal(false)}
          useAlternateNaming
        />
      )}
      <div className={css['timeliness-wrapper']}>
        <GroupCardListItem
          hideExpander
          dottedLine
          middleContent=""
          label={useAlternateNaming ? 'Timeliness' : 'Check-in Accuracy'}
          // @ts-ignore
          data={<span className={css['semi-bold']}>{value}</span>}
          type="default"
          border="none"
        />
      </div>
      <div className={classNames('text-muted', css['timeliness-sub-text'])}>
        {useAlternateNaming ? (
          <>
            {denominatorCount > 0 && (
              <span>
                {numeratorCount}
                &nbsp;of&nbsp;
                {denominatorCount}
                &nbsp;
              </span>
            )}
            <span>Hard-start work orders checked in on-time</span>
          </>
        ) : (
          'On-site check-ins at designated start time.'
        )}
      </div>
      <div>
        <button
          className={css['see-commitment-link']}
          type="button"
          onClick={() => {
            setShowModal(true);
            trackAmplitudeEvent({
              category: VIEWED_RELIABILITY_DETAILS,
              ampProps: {
                Source: 'Provider Drawer',
                Type: 'Check In Accuracy',
                Score: providerCommitment?.showTimelinessPercent
                  ? providerCommitment?.timelinessPercent?.toFixed(1)
                  : 'not enough data',
              },
            });
          }}
        >
          <span className={css['timeliness-sub-text']}>See check-in details</span>
        </button>
      </div>
    </div>
  );
};

const ReliabilityCache = ({
  userId,
  providerCommitment = {},
}: Props): JSX.Element => {
  const context = useRecoilValue(contextQuery);
  const isBuyer = (context?.features?.results || []).some(({ id }) => id === 4);
  const { denominatorCount = 0 } = providerCommitment?.timelinessStats || {};
  const performanceFilterData = getPerformanceFilters();
  const shouldShowTimeliness = denominatorCount >= 5;

  return (
    <div>
      {reliabilityChip(providerCommitment)}
      {commitment(userId, providerCommitment, performanceFilterData)}
      {(!isBuyer || shouldShowTimeliness) && timelinessChip(providerCommitment)}
      {(!isBuyer || shouldShowTimeliness) && timeliness(userId, providerCommitment, performanceFilterData)}
    </div>
  );
};

export default ReliabilityCache;
