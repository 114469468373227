import { atom } from 'recoil';
import { ICompany, IContext } from '../Commons/types.d';
import { IOnEditConfig } from '../Components/Section/types.d';

export const companyState = atom<ICompany | undefined>({
  key: 'companyState',
  default: window.company,
});

export const contextState = atom<IContext | undefined>({
  key: 'contextState',
  default: window.context,
});

export const addressConfirmationDescriptionState = atom({
  key: 'addressConfirmationDescriptionState',
  default: '',
});

export const isAddressConfirmationOpenState = atom({
  key: 'isAddressConfirmationOpenState',
  default: false,
});

export const addressConfirmationSectionKeyState = atom({
  key: 'addressConfirmationSectionKeyState',
  default: '',
});

export const addressConfirmationSectionConfigState = atom<IOnEditConfig | undefined>({
  key: 'addressConfirmationSectionConfigState',
  default: undefined,
});
