import { v2, Grid, GridColumn } from '@fieldnation/platform-components';
import css from '../Styles.scss';
import React, { useEffect, useState } from 'react';
import ErrorBlock from './ErrorBlock';
import { getIputedField, getOtherFields } from '../util';
import { CustomFields, VerificationField, VerificationOrg } from '../types';
import { orgDefaultValue } from '../Store/query';
import { getVerificationByOrgId } from '../api';
import CredentialNumber from './CredentialNumber';

const { Select, TextInput } = v2;
const VerifiedCredentialFields = ({
  lcInputs,
  setLcInputs,
  selectedCredential,
  lcValidation,
  setLoading,
  isEdit,
}) => {
  const [selectedOrganization, setSelectedOrganization] = useState<VerificationOrg>(orgDefaultValue);

  // fetch Verification By OrgId
  useEffect(() => {
    const fetchVerificationByOrgId = async (vendorId) => {
      setLoading(true);
      const verificationFields: VerificationOrg = await
        getVerificationByOrgId(vendorId);

      if (verificationFields?.id) {
        if (verificationFields?.fields?.length) {
          setSelectedOrganization(verificationFields);
          const cf = [] as CustomFields[];
          verificationFields?.fields
            ?.filter(verificationField => verificationField?.name !== 'LicenseNumber') // LicenseNumber handling statically
            ?.forEach((field: VerificationField) =>
              cf.push({
                ...field,
                fieldName: field.name,
                fieldValue: isEdit
                  ? lcInputs?.customInputs?.find(({ name }) => name === field.name)?.value
                  : '',
              } as CustomFields),
            );

          setLcInputs({
            ...lcInputs,
            certOrgId: verificationFields.certOrgId,
            fields: cf,
          });
        }
      }
      setLoading(false);
      return verificationFields;
    }
    if (lcInputs?.vendorId && selectedCredential?.verifiedOn && !selectedCredential?.showState) {
      fetchVerificationByOrgId(lcInputs?.vendorId).catch(console.error);
    }
  }, [lcInputs?.vendorId, selectedCredential?.verifiedOn]);

  // ***************** handle Custom input *****************
  const handleCustomFieldsInput = (fields: CustomFields[], name: string, value: any) => {
    const iputedField = getIputedField(fields, name);
    const fieldsData = getOtherFields(fields, name);
    setLcInputs({
      ...lcInputs,
      fields: [...fieldsData, { ...iputedField, fieldValue: value }],
    });
  };

  const renderCustomInputs = () => selectedOrganization?.fields
    ?.filter(credentialCustomInput => credentialCustomInput?.name !== 'LicenseNumber')  // LicenseNumber handling statically
    ?.map((credentialCustomInput) => {
      const customLabel = credentialCustomInput?.name.replace(/([A-Z])/g, ' $1').trim();
      lcValidation?.fields?.find((input) => input?.fieldName === credentialCustomInput?.name);
      const fields = lcInputs?.fields || [];
      if (credentialCustomInput?.type === 'select') {
        const mappedOptions = credentialCustomInput.options?.map((option) => ({
          value: option.value,
          label: option.name,
        }));
        return (
          <GridColumn xs="12" md="6">
            <div key={`custom-input-${credentialCustomInput.name}`} className={css.inputFields}>
              <Select
                label={customLabel}
                value={getIputedField(fields, credentialCustomInput.name)?.fieldValue || ''}
                options={mappedOptions}
                onChange={(v) => {
                  handleCustomFieldsInput(fields, credentialCustomInput.name, v.value);
                }}
              />
              <ErrorBlock msg={lcValidation?.fields?.find((field: CustomFields) => field.fieldName === credentialCustomInput.name)?.fieldValue} />
            </div>
          </GridColumn>
        );
      }
      return (
        <GridColumn xs="12" md="6">
          <div key={`custom-input-${credentialCustomInput.name}`} className={css.inputFields}>
            <TextInput
              label={customLabel}
              onChange={(e) => {
                handleCustomFieldsInput(fields, credentialCustomInput.name, e.target.value);
              }}
              value={getIputedField(fields, credentialCustomInput.name)?.fieldValue || ''}
            />
            <ErrorBlock msg={lcValidation?.fields?.find((field: CustomFields) => field.fieldName === credentialCustomInput.name)?.fieldValue} />
          </div>
        </GridColumn>
      );
    });

  return <Grid vSpace={false}>
    <GridColumn xs="12" md="6">
      <div className={css.inputFields}>
        <Select
          label="State issued"
          options={selectedCredential?.organizations?.map((data) => ({
            label: data.name,
            value: data.id,
          }))}
          onChange={(e) => {
            setLcInputs({
              ...lcInputs,
              vendorId: `${e?.value}`,
              vendorName: `${e?.label}`,
            });
          }}
          // @ts-ignore default value for ignoring component default error message
          value={lcInputs?.vendorId}
        />
        <ErrorBlock msg={lcValidation?.vendorId} />
      </div>
    </GridColumn>

    {/*Credential Number*/}
    <GridColumn xs="12" md="6">
      <CredentialNumber
        credentialType={selectedCredential?.type}
        value={lcInputs.credentialIdNumber}
        validation={lcValidation?.credentialIdNumber}
        onChange={(v) => (setLcInputs({
          ...lcInputs,
          credentialIdNumber: v,
        }))}
      />
    </GridColumn>

    {/*Custom Inputs*/}
    {renderCustomInputs()}
  </Grid>
};

export default VerifiedCredentialFields;

