/* eslint-disable no-template-curly-in-string */
import {
  ContactRole,
  ContentKey,
  ContentReference,
  ContentType,
  CustomEditComponent,
  HttpMethod,
  InputSize,
  InputType,
  InputValidation,
  InputValidationType,
  ISectionCard,
  SectionCardKey,
  SectionCardResource,
  SectionKey,
} from '../SectionCard/Components/Section/types.d';
import { UserGroup } from '../SectionCard/Commons/types.d';

const AccountAdministratorConfig: ISectionCard = {
  title: 'Account Administrator',
  key: SectionCardKey.ACCOUNT_ADMIN,
  resourceReference: SectionCardResource.CONTACT,
  description: 'The main contact at your company for Field Nation account-related communications, updates and issues.',
  windowReference: 'accountAdministrator',
  onCreateConfig: {
    url: 'company/${company_id}/contacts/',
    method: HttpMethod.POST,
    body: {
      role: ContactRole.ADMIN,
    },
  },
  createPermissionGroup: [
    UserGroup.STAFF,
    UserGroup.ACCOUNTANT,
    UserGroup.ADMIN,
  ],
  sections: [
    {
      displayName: 'Name',
      key: SectionKey.ACCOUNT_ADMIN_NAME,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.ACCOUNT_ADMIN_FIRST_NAME,
          contentReference: ContentReference.FIRST_NAME,
          displayName: 'First Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.ACCOUNT_ADMIN_LAST_NAME,
          contentReference: ContentReference.LAST_NAME,
          displayName: 'Last Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Email',
      key: SectionKey.ACCOUNT_ADMIN_EMAIL,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.ACCOUNT_ADMIN_EMAIL_ADDRESS,
          contentReference: ContentReference.EMAIL_ADDRESS,
          displayName: 'Email Address',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.EMAIL,
          inputValidation: InputValidation[InputValidationType.EMAIL],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Phone',
      key: SectionKey.ACCOUNT_ADMIN_PHONE,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.ACCOUNT_ADMIN_PHONE,
          contentReference: ContentReference.PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.ACCOUNT_ADMIN_PHONE_EXTENSION,
          contentReference: ContentReference.PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputSize: InputSize.SMALL,
          inputDescription: '5 digits max',
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Secondary Phone',
      key: SectionKey.ACCOUNT_ADMIN_SECONDARY_PHONE,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.ACCOUNT_ADMIN_SECONDARY_PHONE,
          contentReference: ContentReference.SECONDARY_PHONE,
          displayName: 'Phone Number',
          isOptional: true,
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.ACCOUNT_ADMIN_SECONDARY_PHONE_EXTENSION,
          contentReference: ContentReference.SECONDARY_PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputDescription: '5 digits max',
          inputSize: InputSize.SMALL,
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
  ],
};

const SecondaryAccountContactConfig: ISectionCard = {
  title: 'Secondary Account Contact',
  key: SectionCardKey.SECONDARY_CONTACT,
  resourceReference: SectionCardResource.CONTACT,
  description: 'Contact who can serve as an escalation point on support cases if previous attempts at contact have been unsuccessful.',
  windowReference: 'secondaryAccountContact',
  onCreateConfig: {
    url: 'company/${company_id}/contacts/',
    method: HttpMethod.POST,
    body: {
      role: ContactRole.SECONDARY,
    },
  },
  createPermissionGroup: [
    UserGroup.STAFF,
    UserGroup.ACCOUNTANT,
    UserGroup.ADMIN,
  ],
  sections: [
    {
      displayName: 'Name',
      key: SectionKey.SECONDARY_ACCOUNT_NAME,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.SECONDARY_CONTACT_FIRST_NAME,
          contentReference: ContentReference.FIRST_NAME,
          displayName: 'First Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.SECONDARY_CONTACT_LAST_NAME,
          contentReference: ContentReference.LAST_NAME,
          displayName: 'Last Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Email',
      key: SectionKey.SECONDARY_ACCOUNT_EMAIL,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.SECONDARY_CONTACT_EMAIL_ADDRESS,
          contentReference: ContentReference.EMAIL_ADDRESS,
          displayName: 'Email Address',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.EMAIL,
          inputValidation: InputValidation[InputValidationType.EMAIL],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Phone',
      key: SectionKey.SECONDARY_ACCOUNT_PHONE,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.SECONDARY_CONTACT_PHONE,
          contentReference: ContentReference.PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.SECONDARY_CONTACT_PHONE_EXTENSION,
          contentReference: ContentReference.PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputDescription: '5 digits max',
          inputSize: InputSize.SMALL,
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Secondary Phone',
      key: SectionKey.SECONDARY_ACCOUNT_SECONDARY_PHONE,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.SECONDARY_CONTACT_SECONDARY_PHONE,
          isOptional: true,
          contentReference: ContentReference.SECONDARY_PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.SECONDARY_CONTACT_SECONDARY_PHONE_EXTENSION,
          contentReference: ContentReference.SECONDARY_PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputSize: InputSize.SMALL,
          inputDescription: '5 digits max',
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
  ],
};

const BillingContactConfig: ISectionCard = {
  title: 'Billing Contact',
  key: SectionCardKey.BILLING_CONTACT,
  resourceReference: SectionCardResource.CONTACT,
  description: 'Point of contact for financial and tax-related communications, updates and escalations.',
  windowReference: 'billingContact',
  onCreateConfig: {
    url: 'company/${company_id}/contacts/',
    method: HttpMethod.POST,
    body: {
      role: ContactRole.BILLING,
    },
  },
  createPermissionGroup: [
    UserGroup.STAFF,
    UserGroup.ACCOUNTANT,
    UserGroup.ADMIN,
  ],
  sections: [
    {
      displayName: 'Name',
      key: SectionKey.BILLING_CONTACT_NAME,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.BILLING_CONTACT_FIRST_NAME,
          contentReference: ContentReference.FIRST_NAME,
          displayName: 'First Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.BILLING_CONTACT_LAST_NAME,
          contentReference: ContentReference.LAST_NAME,
          displayName: 'Last Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Email',
      key: SectionKey.BILLING_CONTACT_EMAIL,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.BILLING_CONTACT_EMAIL_ADDRESS,
          contentReference: ContentReference.EMAIL_ADDRESS,
          displayName: 'Email Address',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.EMAIL,
          inputValidation: InputValidation[InputValidationType.EMAIL],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Phone',
      key: SectionKey.BILLING_CONTACT_PHONE,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.BILLING_CONTACT_PHONE,
          contentReference: ContentReference.PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.BILLING_CONTACT_PHONE_EXTENSION,
          contentReference: ContentReference.PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputDescription: '5 digits max',
          inputSize: InputSize.SMALL,
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Secondary Phone',
      key: SectionKey.BILLING_CONTACT_SECONDARY_PHONE,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.BILLING_CONTACT_SECONDARY_PHONE,
          isOptional: true,
          contentReference: ContentReference.SECONDARY_PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.BILLING_CONTACT_SECONDARY_PHONE_EXTENSION,
          contentReference: ContentReference.SECONDARY_PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputSize: InputSize.SMALL,
          inputDescription: '5 digits max',
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
  ],
};

const TechnicalContactConfig: ISectionCard = {
  title: 'Technical Contact',
  key: SectionCardKey.TECHNICAL_CONTACT,
  resourceReference: SectionCardResource.CONTACT,
  description: 'Point of contact for any Field Nation account-related integrations, security or technical issues.',
  windowReference: 'technicalContact',
  onCreateConfig: {
    url: 'company/${company_id}/contacts/',
    method: HttpMethod.POST,
    body: {
      role: ContactRole.TECHNICAL,
    },
  },
  createPermissionGroup: [
    UserGroup.STAFF,
    UserGroup.ACCOUNTANT,
    UserGroup.ADMIN,
  ],
  sections: [
    {
      displayName: 'Name',
      key: SectionKey.TECHNICAL_CONTACT_NAME,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.TECHNICAL_CONTACT_FIRST_NAME,
          contentReference: ContentReference.FIRST_NAME,
          displayName: 'First Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.TECHNICAL_CONTACT_LAST_NAME,
          contentReference: ContentReference.LAST_NAME,
          displayName: 'Last Name',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.TEXT,
          inputValidation: InputValidation[InputValidationType.NAME],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Email',
      key: SectionKey.TECHNICAL_CONTACT_EMAIL,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.TECHNICAL_CONTACT_EMAIL_ADDRESS,
          contentReference: ContentReference.EMAIL_ADDRESS,
          displayName: 'Email Address',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.EMAIL,
          inputValidation: InputValidation[InputValidationType.EMAIL],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Phone',
      key: SectionKey.TECHNICAL_CONTACT_PHONE,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.TECHNICAL_CONTACT_PHONE,
          contentReference: ContentReference.PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.TECHNICAL_CONTACT_PHONE_EXTENSION,
          contentReference: ContentReference.PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputDescription: '5 digits max',
          inputSize: InputSize.SMALL,
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Secondary Phone',
      key: SectionKey.TECHNICAL_CONTACT_SECONDARY_PHONE,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.TECHNICAL_CONTACT_SECONDARY_PHONE,
          isOptional: true,
          contentReference: ContentReference.SECONDARY_PHONE,
          displayName: 'Phone Number',
          displayRow: 0,
          isInputLabelHidden: false,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.TECHNICAL_CONTACT_SECONDARY_PHONE_EXTENSION,
          contentReference: ContentReference.SECONDARY_PHONE_EXTENSION,
          displayName: 'Ext.',
          displayRow: 0,
          displayPrefix: 'ext.',
          inputPlaceHolder: 'Extension #',
          inputSize: InputSize.SMALL,
          inputDescription: '5 digits max',
          isInputLabelHidden: false,
          isOptional: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE_EXTENSION],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/contacts/${contact_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
  ],
};

const CompanyContactsConfig = [
  AccountAdministratorConfig,
  SecondaryAccountContactConfig,
  BillingContactConfig,
  TechnicalContactConfig,
];

export default CompanyContactsConfig;
