import * as React from 'react';
import {
  v2,
  Loader,
} from '@fieldnation/platform-components';
import Padded from './Padded';

const { Select, Tile, TileDivision } = v2;

export const ReliabilityFallback = (): JSX.Element => (
  <Padded>
    <div style={{ height: '400px' }}>
      <Loader isLoading>
        <Tile>
          <TileDivision heading="Reliability">
            <div className="u-padding-bottom--md">
              <Padded size="lg">
                <Select
                  label=""
                  options={[]}
                  value=""
                  onChange={() => { /* empty */ }}
                />
              </Padded>
            </div>
          </TileDivision>
        </Tile>
      </Loader>
    </div>
  </Padded>
);

export default ReliabilityFallback;
