import React, { useState, useContext } from 'react';
import { v2 } from '@fieldnation/platform-components';
import { store } from '../Store';
import '../Styles.scss';
import Form from './Form';
import ContextTable from './ContextTable';
import {
  saveRequirements,
  getRequirementsByWorkOrder,
  getRequirementsByTemplate,
} from '../../api';
import trackAmplitudeEvent, {
  WORK_ORDER_DETAILS,
  TEMPLATE_DETAILS,
} from '../amplitude';
import { mapV2Requirements, mapRequirements } from '../utils';
import { CategoryType, V2Qualification } from '../../types.d';

const { Modal } = v2;

const EditModal = (): JSX.Element => {
  const ctx = useContext(store);
  const {
    dispatch,
    state: {
      canAdd, invalid = '', requirements,
      templateId,
      workOrderId,
      newRequirements,
      // eslint-disable-next-line no-shadow
      v2,
    },
  } = ctx;

  const reqs = newRequirements || requirements;

  const [isInvalid, setInvalid] = useState(invalid);
  const [loading, setLoading] = useState(false);

  let filteredWoQualifications: V2Qualification[] = [];

  if (!v2) {
    const filteredRequirements = reqs?.filter(({ canEdit }) => !canEdit);
    filteredWoQualifications = filteredRequirements.filter(
      ({ qualification }) => [
        CategoryType.CERTIFICATION,
        CategoryType.LICENSE,
        CategoryType.INSURANCE,
        CategoryType.EQUIPMENT,
        CategoryType.REQUIRE_C19_VACCINE,
      ].includes(Number(qualification.category)),
    );
  }

  return (
    <Modal
      size="large"
      isOpen
      onClose={() => dispatch({ type: 'edit' })}
      header="Edit Work Order Qualifications"
      footerActions={[
        {
          label: 'Close',
          type: 'secondary',
          onClick: () => {
            dispatch({ type: 'edit' });
          },
        },
        canAdd && {
          disabled: loading,
          label: 'Save Changes',
          type: 'primary',
          onClick: async () => {
            setInvalid('');
            // saving WO / template qualifications
            setLoading(true);
            const sr = await saveRequirements({
              templateId,
              workOrderId,
              requirements: reqs,
              qualificationsV2: v2 ? mapV2Requirements(reqs) : false,
            });
            if (sr?.error) {
              setInvalid(sr.error);
              setLoading(false);
            } else {
              const resp = workOrderId > 0
                ? await getRequirementsByWorkOrder(workOrderId)()
                : await getRequirementsByTemplate(templateId)();

              if (resp) {
                dispatch({ type: 'loader', loading: true });

                const [formattedQualifications, formattedRequirements] = mapRequirements(resp);
                dispatch({ type: 'edit' });
                dispatch({
                  type: 'refresh',
                  requirements: formattedRequirements,
                  qualifications: formattedQualifications,
                  actionData: resp,
                });
                // Amplitude Tracking
                const qTypes = new Set();
                const Qualifications: unknown[] = [];
                // eslint-disable-next-line no-unused-expressions
                formattedRequirements?.map((q) => {
                  qTypes.add(q?.qualification?.categoryDisplayName);
                  Qualifications.push({
                    category: q?.qualification?.categoryDisplayName || '',
                    name: q?.qualification?.name || '',
                  });
                });

                trackAmplitudeEvent({
                  category: 'Added Qualification',
                  ampProps: {
                    // eslint-disable-next-line lodash/prefer-includes
                    Source: (window.location?.href.indexOf('templates') > -1)
                      ? TEMPLATE_DETAILS : WORK_ORDER_DETAILS,
                    Type: Array.from(qTypes),
                    Qualifications,
                  },
                });
              }
            }
          },
        },
      ].filter((f) => f)}
    >
      {isInvalid && (
        <div className="text-danger u-margin-bottom--md">
          {isInvalid}
        </div>
      )}
      {!canAdd ? (
        <div>
          <span className="text-muted">
            <strong>Note:</strong>
            {' '}
            New qualifications can only be added in
            draft status.
          </span>
        </div>
      ) : (
        <div>
          <div className="u-margin-bottom--md" data-testid="edit-modal-form">
            <Form
              useV2Data={v2}
              onChangeData={
                  (qualifications) => {
                    dispatch({ type: 'add', value: qualifications });
                  }
                }
            />
          </div>
        </div>
      )}
      {filteredWoQualifications.length > 0 && (
        <div>
          <hr />
          <strong> Selection Rule qualifications </strong>
          <br />
          <div>
            The following items have been imported from the selection rule
            qualifications applied to this template or work order and cannot be edited.
            To remove these qualifications,
            remove the selection rule applied to this template or work order.
          </div>
          <br />
          <ContextTable
            requirements={filteredWoQualifications}
          />
        </div>
      )}
    </Modal>
  );
};

export default EditModal;
