import * as React from 'react';
import classNames from 'classnames';
import { v2, InfoIcon, ProgressBar } from '@fieldnation/platform-components';
import { ScoreType, ProgressBarValue, IReliabilityScore } from './types.d';
import css from './Reliability.scss';

const { Modal } = v2;

interface TimelinessDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  providerId: number;
  reliabilityScore: IReliabilityScore;
}

export default function TimelinessDetails(props: TimelinessDetailsProps): JSX.Element {
  const {
    isOpen, onClose, providerId, reliabilityScore,
  } = props;
  const timelinessScore = reliabilityScore?.[ScoreType.Timeliness] ?? {};
  const totalTimeLog = timelinessScore?.details?.totalTimeLog ?? 0;
  const totalOnTime = timelinessScore?.details?.totalOnTime ?? 0;
  const totalEarlyOrLate = (timelinessScore?.details?.totalEarly ?? 0)
    + (timelinessScore?.details?.totalLate ?? 0);
  return (
    <Modal
      isOpen={isOpen}
      header="Timeliness"
      onClose={onClose}
      size="medium"
    >
      <div className={css['r-modal-container']}>
        <p className={css['r-body-title']}>
          {window?.context?.id && (window?.context?.id === providerId) ? 'Your ' : ''}
          Check in Details
        </p>
        <p
          className={classNames(
            'text-muted u-padding-bottom--sm',
            css['flex-vertical-center'],
          )}
        >
          <span className={css['r-body-sub-text']}>On recent “Hard Start” assignments</span>
          <InfoIcon color="default">
            Recent assignments are defined as the last 30 &quot;Hard Start&quot;
            assignments occurring within the past 2 years.
          </InfoIcon>
        </p>
        <div className={classNames('u-padding-bottom--lg', css['flex-space-between'])}>
          <div className={css['r-inline-count-wrapper']}>
            <span className={css['r-inline-count-number']}>{totalTimeLog}</span>
            <span className={css['r-inline-count-label']}>“Hard Start” assignments</span>
          </div>
          <div className={css['r-inline-count-wrapper']}>
            <span className={css['r-inline-count-number']}>{totalEarlyOrLate}</span>
            <span className={css['r-inline-count-label']}>Early / Late check ins</span>
          </div>
        </div>
        <div className="u-padding-bottom--sm">
          <div className="u-padding-bottom--sm">
            <div className={classNames('u-padding-bottom--sm', css['flex-space-between'])}>
              <div>On-time</div>
              <strong>{totalOnTime}</strong>
            </div>
            <ProgressBar
              currentProgress={totalOnTime as ProgressBarValue}
              maxProgress={totalTimeLog as ProgressBarValue}
              fillColor="teal"
            />
          </div>
          <div className="u-padding-bottom--sm">
            <div className={classNames('u-padding-bottom--sm', css['flex-space-between'])}>
              <div>Early / Late</div>
              <strong>{totalEarlyOrLate}</strong>
            </div>
            <ProgressBar
              currentProgress={totalEarlyOrLate as ProgressBarValue}
              maxProgress={totalTimeLog as ProgressBarValue}
              fillColor="yellow"
            />
          </div>
        </div>
        <div className="u-padding-top--md">
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>Early:</strong>
            {' '}
            31+ minutes before scheduled start time
          </p>
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>On-time:</strong>
            {' '}
            between 30 mins before to 15 mins after start time
          </p>
          <p className={classNames(css['r-text-smaller'], css['r-spacing-xs'])}>
            <strong>Late:</strong>
            {' '}
            16+ minutes after scheduled start time
          </p>
        </div>
      </div>
    </Modal>
  );
}
