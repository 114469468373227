import * as React from 'react';
import { parse, format } from 'date-fns';
import { useState } from 'react';

import {
  Loader, Toolbar, Link, Stars,
} from '@fieldnation/platform-components';
import jobCss from './JobSummary.scss';
import css from './WorkAndSkillWidget.scss';
import useJobHistory, { JobHistoryRequestDto } from '../queries/use-job-history';
import useSkills from '../queries/use-skills';
import useNetworks from '../queries/use-networks';

import { Cbsa } from '../types.d';

export interface JobSummaryProps {
  userId?: number;
  skill?: { id: number; name: string };
  typeOfWork?: { id: number; name: string };
  network?: { id: number; name: string };
}

interface LabelAndValue {
  label?: string;
  value?: string | number;
}

interface FilterPropTypes {
  id?: string;
  name?: string;
  type?: string;
  pattern?: RegExp;
  placeholder?: string;
  section?: number;
  options?: {
    label?: string;
    value?: string | number;
  }[];
  helpText?: string;
  getLazyLoadOptions?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  onUpdate?: (value: string) => Promise<LabelAndValue[]>;
  maximum?: number;
  minimum?: number;
}

export default function JobSummary({
  userId,
  skill,
  typeOfWork,
  network,
}: JobSummaryProps): JSX.Element {
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  // silly but setFilters(...new...) doesn't re-render because it's an object,
  // and the hook needs it to in order to refetch, so using an incrementing version:
  const [version, setVersion] = useState(0);
  const opts = useSkills();
  const { skills = skill ? [skill] : [], categories = [], subCategories = [] } = opts || {};
  const networks = useNetworks();

  const availableFilters: FilterPropTypes[] = [
    {
      id: 'cbsa',
      name: 'Location',
      type: 'typeahead',
      placeholder: 'Example: Minneapolis',
      onUpdate: async (s: string) => new Promise((resolve, reject) => fetch(`/skill-discovery/v1/cbsa?search=${encodeURIComponent(s)}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'GET',
        credentials: 'same-origin',
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(
            (resp as Cbsa[]).map(({ id, name }) => ({
              value: id,
              label: name,
            })),
          );
        })
        .catch((e) => {
          reject(e);
        })),
    },
    {
      id: 'dates',
      name: 'Date Range',
      type: 'date-range',
    },
    {
      id: 'onlyMyCompany',
      name: 'Only my company',
      type: 'boolean',
      options: [
        {
          label: 'Yes',
          value: '1',
        },
        {
          label: 'No - all of marketplace',
          value: '0',
        },
      ],
    },
    {
      id: 'networkIds',
      name: 'Talent Type',
      type: 'multi-select',
      options: (() => {
        if (networks) {
          const all = networks.map(({ id, name }) => ({
            value: id,
            label: name,
          }));

          if (network && !all.find(({ value }) => value === network.id)) {
            return [...all, { value: network.id, label: network.name }];
          }

          return all;
        }

        // networks are still being fetched but our filter provided
        // the name/value, so set the options as that so it loads with a
        // name/title in the filter box
        if (network) {
          return [{ value: network.id, label: network.name }];
        }

        return [];
      })(),
    },
    {
      id: 'categoryIds',
      name: 'Skill Category',
      type: 'multi-select',
      options: categories.map(({ id, name }) => ({ value: id, label: name })),
    },
    {
      id: 'subCategoryIds',
      name: 'Skill Sub-category',
      type: 'multi-select',
      options: subCategories.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    },
    {
      id: 'skillIds',
      name: 'Skill',
      type: 'multi-select',
      options: skills.map(({ id, name }) => ({ value: id, label: name })),
    },
    {
      id: 'starRating',
      name: 'Star Rating',
      type: 'number',
      placeholder: 'Minimum rating required',
      pattern: /^(1|2|3|4|5)(\.\d+)?$/,
    },
    {
      id: 'typeOfWorkIds',
      name: 'Type of work',
      type: 'multi-select',
      // eslint-disable-next-line no-underscore-dangle
      options: (window._TYPES_OF_WORK || []).map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
  ];

  const [filters, setFilters] = useState<FilterPropTypes[]>(
    (() => {
      if (skill) {
        return [
          {
            ...availableFilters.find(({ id }) => id === 'skillIds'),
            value: [skill.id],
          },
        ];
      }

      if (network) {
        return [
          {
            ...availableFilters.find(({ id }) => id === 'networkIds'),
            value: [network.id],
          },
        ];
      }

      if (typeOfWork) {
        return [
          {
            ...availableFilters.find(({ id }) => id === 'typeOfWorkIds'),
            value: [typeOfWork.id],
          },
        ];
      }

      return [];
    })(),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addFromFilter = (name: string, convert?: (value: any) => any) => {
    const value = filters.find(({ id }) => id === name)?.value;

    if (convert && value) {
      return { [name]: convert(value) };
    }

    return value ? { [name]: value } : {};
  };

  const dto: JobHistoryRequestDto = {
    userId: userId || 0,
    search: searchText,
    ...addFromFilter('starRating'),
    ...addFromFilter('networkIds'),
    ...addFromFilter('typeOfWorkIds'),
    ...addFromFilter('onlyMyCompany'),
    ...addFromFilter('skillIds'),
    ...addFromFilter('categoryIds'),
    ...addFromFilter('subCategoryIds'),
    ...addFromFilter('cbsa', (value) => value.map(({ value: v }: { value: number }) => v)),
    ...addFromFilter('dates', (value) => [value.startDate, value.endDate]),
  };

  const jobHistory = useJobHistory(dto);

  if (!jobHistory) {
    return (
      <Loader isLoading>
        <div className={css.LoadingSkills} />
      </Loader>
    );
  }

  return (
    <div className={jobCss.JobSummary}>
      {/* @ts-ignore */}
      <Toolbar
        alwaysExpandedSearch
        disableSaveFilter
        views={[]}
        searchValue={search}
        onSearch={() => {
          setSearchText(search);
        }}
        onSearchChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
        activeFilters={filters}
        onRemoveFilter={(f: FilterPropTypes) => {
          setFilters(filters.filter(({ id }) => id !== f.id));
          setVersion(version + 1);
        }}
        onApplyFilter={(
          _: FilterPropTypes,
          newActiveFilters: FilterPropTypes[],
        ) => {
          setFilters(newActiveFilters);
          setVersion(version + 1);
        }}
        onClearSearch={() => {
          setSearch('');
          setSearchText('');
        }}
        filters={availableFilters}
      />

      <div className={jobCss.JobSummaryTableContainer}>
        <table className="tags table table-striped">
          <thead>
            <tr>
              <th className="title-cell">Title</th>
              <th className="title-cell">Type of work</th>
              <th className="title-cell">Completed On</th>
              <th className="title-cell">Rating</th>
            </tr>
          </thead>
          <tbody>
            {(jobHistory.results || []).map((row) => (
              <tr key={row.id}>
                <td>
                  <strong>
                    {row.isSameCompany ? (
                      <Link href={`/workorders/${row.id}`} target="_blank">
                        {row.title}
                      </Link>
                    ) : (
                      row.title
                    )}
                  </strong>
                  <small>{row.location}</small>
                </td>
                <td>{row.typeOfWork.name}</td>
                <td>
                  {format(
                    parse(
                      row.completedAt.split(' ')?.[0] || '',
                      'yyyy-MM-dd',
                      new Date(),
                    ),
                    'MMM yyyy',
                  )}
                </td>
                <td>
                  <Stars
                    rating={row.starRating}
                    color={row.isSameCompany ? 'green' : 'yellow'}
                    size="sm"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
