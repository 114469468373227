import React from 'react';
import classNames from 'classnames';
import {
  Tooltip,
  TooltipComponent,
} from '@fieldnation/platform-components';
import applyTruncation from '../utils/truncate';

import FnLogo from './Svg/FnLogo';
import PopoverContent from './PopoverContent';

import css from './CuratedChip.scss';

const TRUNCATE_THRESHOLD = 50;

interface IProps {
  label?: string;
  labelColor: 'black' | 'gray';
  size: 'sm' | 'lg';
}

const Chip = ({ label, labelColor, size }: IProps) => (
  <div className={css.Chip__Outer}>
    <div role="presentation" className={classNames([css.Chip, labelColor === 'gray' ? css.Label__Gray : ''])} data-testid="curated-chip">
      <div className={css.Chip__Icon}>
        <FnLogo width={size === 'sm' ? 12 : 14} height={size === 'sm' ? 14 : 16} />
      </div>
      <span className={classNames([css.Chip__Label, size === 'sm' ? css['Chip__Label--sm'] : ''])}>
        {`${applyTruncation(label, true, TRUNCATE_THRESHOLD)}`}
      </span>
    </div>
  </div>
);

Chip.defaultProps = {
  label: '',
};

interface Props {
  label?: string;
  providerName: string;
  networks: string[];
  labelColor?: 'black' | 'gray';
  size?: 'sm' | 'lg';
  source?: string;
  overlayPosition?: 'top' | 'right' | 'left' | 'bottom';
}

const CuratedChip = ({
  label = 'Select Tech', providerName, networks, labelColor = 'black', size = 'lg', source, overlayPosition,
}: Props): JSX.Element => (
  <Tooltip showOnEvent="click" position={overlayPosition || 'top'} hoverOnChild>
    <span>
      <Chip label={label} labelColor={labelColor} size={size} />
    </span>
    {/* @ts-ignore */}
    <TooltipComponent widthAuto isFlush noArrow>
      <PopoverContent
        label={label}
        providerName={providerName}
        networks={networks}
        source={source}
      />
    </TooltipComponent>
  </Tooltip>
);

CuratedChip.defaultProps = {
  label: 'Select Tech',
  labelColor: 'black',
  size: 'lg',
  source: undefined,
  overlayPosition: null,
};

export default CuratedChip;
