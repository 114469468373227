import { FileType, IJsObject, UserGroup } from "../../Commons/types.d";

export interface IProps {
  cardKey: string;
  section: ISection;
  windowRef: string;
}

export enum SectionCardKey {
  COMPANY_OVERVIEW = 'company_overview',
  ACCOUNT_ADMIN = 'account_admin',
  SECONDARY_CONTACT = 'secondary_contact',
  BILLING_CONTACT = 'billing_contact',
  TECHNICAL_CONTACT = 'technical_contact',
}

export enum SectionCardResource {
  CONTACT = 'company_contact_id',
}

export enum SectionKey {
  COMPANY_LOGO = 'company_logo',
  COMPANY_NAME = 'company_name',
  COMPANY_EMAIL = 'company_email',
  COMPANY_PHONE = 'company_phone',
  COMPANY_SECONDARY_PHONE = 'company_secondary_phone',
  COMPANY_ADDRESS = 'company_address',
  COMPANY_WEBSITE = 'company_website',
  ACCOUNT_ADMIN_NAME = 'account_administrator_name',
  ACCOUNT_ADMIN_EMAIL = 'account_administrator_email',
  ACCOUNT_ADMIN_PHONE = 'account_administrator_phone',
  ACCOUNT_ADMIN_SECONDARY_PHONE = 'account_administrator_secondary_phone',
  SECONDARY_ACCOUNT_NAME = 'secondary_account_name',
  SECONDARY_ACCOUNT_EMAIL = 'secondary_account_email',
  SECONDARY_ACCOUNT_PHONE = 'secondary_account_phone',
  SECONDARY_ACCOUNT_SECONDARY_PHONE = 'secondary_account_secondary_phone',
  BILLING_CONTACT_NAME = 'billing_contact_name',
  BILLING_CONTACT_EMAIL = 'billing_contact_email',
  BILLING_CONTACT_PHONE = 'billing_contact_phone',
  BILLING_CONTACT_SECONDARY_PHONE = 'billing_contact_secondary_phone',
  TECHNICAL_CONTACT_NAME = 'technical_contact_name',
  TECHNICAL_CONTACT_EMAIL = 'technical_contact_email',
  TECHNICAL_CONTACT_PHONE = 'technical_contact_phone',
  TECHNICAL_CONTACT_SECONDARY_PHONE = 'technical_contact_secondary_phone',
}

export enum ContentKey {
  COMPANY_OVERVIEW_LOGO = 'company_overview_photo',
  COMPANY_OVERVIEW_NAME = 'company_overview_name',
  COMPANY_OVERVIEW_EMAIL = 'company_overview_email',
  COMPANY_OVERVIEW_PHONE = 'company_overview_phone',
  COMPANY_OVERVIEW_PHONE_EXTENSION = 'company_overview_phone_extension',
  COMPANY_OVERVIEW_ADDRESS1 = 'company_overview_address1',
  COMPANY_OVERVIEW_ADDRESS2 = 'company_overview_address2',
  COMPANY_OVERVIEW_CITY = 'company_overview_city',
  COMPANY_OVERVIEW_STATE = 'company_overview_state',
  COMPANY_OVERVIEW_ZIP = 'company_overview_zip',
  COMPANY_OVERVIEW_COUNTRY = 'company_overview_country',
  COMPANY_OVERVIEW_WEBSITE = 'company_overview_website',
  ACCOUNT_ADMIN_FIRST_NAME = 'account_admin_first_name',
  ACCOUNT_ADMIN_LAST_NAME = 'account_admin_last_name',
  ACCOUNT_ADMIN_EMAIL_ADDRESS = 'account_admin_email_address',
  ACCOUNT_ADMIN_PHONE = 'account_admin_phone',
  ACCOUNT_ADMIN_PHONE_EXTENSION = 'account_admin_phone_extension',
  ACCOUNT_ADMIN_SECONDARY_PHONE = 'account_admin_secondary_phone',
  ACCOUNT_ADMIN_SECONDARY_PHONE_EXTENSION = 'account_admin_secondary_phone_extension',
  SECONDARY_CONTACT_FIRST_NAME = 'secondary_contact_first_name',
  SECONDARY_CONTACT_LAST_NAME = 'secondary_contact_last_name',
  SECONDARY_CONTACT_EMAIL_ADDRESS = 'secondary_contact_email_address',
  SECONDARY_CONTACT_PHONE = 'secondary_contact_phone',
  SECONDARY_CONTACT_PHONE_EXTENSION = 'secondary_contact_phone_extension',
  SECONDARY_CONTACT_SECONDARY_PHONE = 'secondary_contact_secondary_phone',
  SECONDARY_CONTACT_SECONDARY_PHONE_EXTENSION = 'secondary_contact_secondary_phone_extension',
  BILLING_CONTACT_FIRST_NAME = 'billing_contact_first_name',
  BILLING_CONTACT_LAST_NAME = 'billing_contact_last_name',
  BILLING_CONTACT_EMAIL_ADDRESS = 'billing_contact_email_address',
  BILLING_CONTACT_PHONE = 'billing_contact_phone',
  BILLING_CONTACT_PHONE_EXTENSION = 'billing_contact_phone_extension',
  BILLING_CONTACT_SECONDARY_PHONE = 'billing_contact_secondary_phone',
  BILLING_CONTACT_SECONDARY_PHONE_EXTENSION = 'billing_contact_secondary_phone_extension',
  TECHNICAL_CONTACT_FIRST_NAME = 'technical_contact_first_name',
  TECHNICAL_CONTACT_LAST_NAME = 'technical_contact_last_name',
  TECHNICAL_CONTACT_EMAIL_ADDRESS = 'technical_contact_email_address',
  TECHNICAL_CONTACT_PHONE = 'technical_contact_phone',
  TECHNICAL_CONTACT_PHONE_EXTENSION = 'technical_contact_phone_extension',
  TECHNICAL_CONTACT_SECONDARY_PHONE = 'technical_contact_secondary_phone',
  TECHNICAL_CONTACT_SECONDARY_PHONE_EXTENSION = 'technical_contact_secondary_phone_extension',
}

export enum ContentReference {
  LOGO = 'photo',
  NAME = 'name',
  EMAIL = 'email',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zip',
  COUNTRY = 'country',
  WEBSITE = 'website',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL_ADDRESS = 'email_address',
  PHONE = 'phone',
  PHONE_EXTENSION = 'phone_extension',
  SECONDARY_PHONE = 'secondary_phone',
  SECONDARY_PHONE_EXTENSION = 'secondary_phone_extension',
}

export enum ContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
};

export enum SectionMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
};

export enum SectionCardMode {
  CREATE = 'CREATE',
  EMPTY = 'EMPTY',
  INTERACTIVE = 'INTERACTIVE',
}

export type InputTypes = 'text' | 'email' | 'search' | 'phone' | 'number' | 'password';

export enum CustomEditComponent {
  NONE = 'None',
  SMART_ADDRESS = 'SmartAddress',
}

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  SEARCH = 'search',
  PHONE = 'phone',
  NUMBER = 'number',
  PASSWORD = 'password',
}

export enum InputValidationType {
  PHONE = 'PHONE',
  PHONE_EXTENSION = 'PHONE_EXTENSION',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  ANY = 'ANY',
}

export interface IInputValidation {
  [key in InputValidationType]: RegExp;
}

export const InputValidation: IInputValidation = {
  PHONE: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  PHONE_EXTENSION: /^[0-9]{0,5}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NAME: /^([^~!@#$%^&*+?|/_={}[\]<>\\;:0-9"]*)+$/i,
  ANY: /^(?!\s*$).+/,
}


export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
} 

export enum ContactRole {
  ADMIN = 'Account Administrator',
  SECONDARY = 'Secondary Account Contact',
  BILLING = 'Billing Contact',
  TECHNICAL = 'Technical Contact',
}

export interface IOnEditConfig {
  url: string;
  method: HttpMethod,
}

export interface IOnCreateConfig {
  url: string;
  method: HttpMethod,
  body?: IJsObject,
}

export enum InputSize {
  SMALL = 'small',
  LARGE = 'large',
}

export interface IContentConfig {
  key: any;
  contentReference: string;
  displayName: string;
  inputPlaceHolder?: string;
  inputSize?: InputSize;
  displayRow: number;
  displayPrefix?: string;
  isInputLabelHidden: boolean;
  inputDescription?: string;
  inputType?: InputType;
  inputValidation?: InputValidation;
  acceptFiles?: FileType[];
  useMultipleFiles?: boolean;
  contentType: ContentType;
  isOptional?: boolean;
}

export interface ISection {
  displayName: string;
  key: any;
  isOptional?: boolean;
  contentConfigs: IContentConfig[];
  customEditComponent?: CustomEditComponent;
  onEditConfig: IOnEditConfig;
  editPermissionGroup: UserGroup[];
}

export interface ISectionCard {
  title: string;
  key: any;
  resourceReference?: string;
  description?: string;
  windowReference: string;
  sections: ISection[];
  onCreateConfig?: IOnCreateConfig;
  createPermissionGroup?: UserGroup[];
}

export interface ISectionContent {
  key?: any;
  value?: string;
  contentConfig?: IContentConfig;
  version?: number;
}

export interface IContentValidation {
  isValid: boolean;
  message?: string;
}
