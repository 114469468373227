import React from 'react';

interface Props {}

const Footer = ({
}: Props): JSX.Element => (
  <div style={{ marginLeft: '1.6rem' }}>
    Footer section will go here
  </div>
);

Footer.defaultProps = {};

export default Footer;
