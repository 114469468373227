/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Button,
  Loader,
  v2,
  Grid,
  GridColumn,
} from '@fieldnation/platform-components';

import {
  passwordProtectedRequestAtom,
  userContextSelector,
} from '../PersonalInfo/state';

import css from './styles.scss';
import { makePasswordRequest } from '../../api';
import { PASSWORD_RETRY_DELAY, KeyCode } from '../../constants';

const { TextInput, Modal } = v2;

export const PasswordProtectedRequest = (): JSX.Element => {
  const { originalUserId } = useRecoilValue(userContextSelector);
  const [password, setPassword] = useState('');
  const [helpText, setHelpText] = useState('');
  const [waitTimer, setWaitTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const prevPasswordRef = useRef('');

  const [callback, setCallback] = useRecoilState(passwordProtectedRequestAtom);

  const handleClose = () => {
    setPassword('');
    setHelpText('');
    setIsLoading(false);
    setCallback(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const passwordConfirmation = await makePasswordRequest(
        Number(originalUserId),
        password,
      );

      if (passwordConfirmation?.is_valid === true) {
        if (typeof callback === 'function') {
          await callback();
        }
        handleClose();
      }

      setIsLoading(false);
      prevPasswordRef.current = '';

      if (!passwordConfirmation?.is_valid) {
        setHelpText('Invalid password. Try again');
        setWaitTimer(PASSWORD_RETRY_DELAY);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => setWaitTimer((waitTimer || 1) - 1), 1000);
    return () => clearInterval(timer);
  }, [waitTimer]);

  return (
    <Modal
      size="small"
      header="Confirm Password"
      isOpen={Boolean(callback)}
      // @ts-ignore
      onClose={undefined}
    >
      <Loader isLoading={isLoading} size="sm">
        <div className={css['password-protected-request-content']}>
          <div className={css['confirmation-text']}>
            Enter your password to edit this section of the profile.
          </div>
          <TextInput
            label="Password"
            type="password"
            disabled={Boolean(waitTimer || isLoading)}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onKeyUp={(e) => {
              if (
                e.keyCode === KeyCode.ENTER &&
                password &&
                password !== prevPasswordRef.current
              ) {
                prevPasswordRef.current = password;
                handleSubmit();
              }
            }}
          />
          <span className={css['confirmation-help-text']}>
            {helpText}
            {waitTimer > 0 && (
              <span>
                &nbsp;in
                {` ${waitTimer} `}
                sec.
              </span>
            )}
          </span>
          <div className={css['password-submit']}>
            <Grid vSpace>
              <GridColumn sm="6">
                <Button type="secondary" block onClick={handleClose}>
                  Cancel
                </Button>
              </GridColumn>
              <GridColumn sm="6">
                <Button
                  disabled={Boolean(waitTimer || !password)}
                  block
                  onClick={handleSubmit}
                  label="Submit"
                  type="primary"
                />
              </GridColumn>
            </Grid>
          </div>
        </div>
      </Loader>
    </Modal>
  );
};
