import React from 'react';
import { DialogueModal } from '@fieldnation/platform-components';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  addressConfirmationDescriptionState,
  isAddressConfirmationOpenState,
  addressConfirmationSectionKeyState,
  addressConfirmationSectionConfigState,
} from '../../States/Atoms';
import userPermissionState from '../../States/Selectors';
import { onSaveSection } from '../../States/Callbacks';
import { SectionKey } from '../Section/types.d';
import { useAmplitudeTracker } from '../../../Amplitude/useAmplitudeTracker';

const AddressConfirmation = (): JSX.Element => {
  const { isStaff } = useRecoilValue(userPermissionState);
  const [isOpen, setIsOpen] = useRecoilState(isAddressConfirmationOpenState);
  const description = useRecoilValue(addressConfirmationDescriptionState);
  const sectionKey = useRecoilValue(addressConfirmationSectionKeyState);
  const editConfig = useRecoilValue(addressConfirmationSectionConfigState);
  const onSaveCallback = useRecoilCallback(onSaveSection);
  const trackOnAmplitude = useAmplitudeTracker();

  const onCancel = () => setIsOpen(false);
  const onSave = async () => {
    setIsOpen(false);
    onSaveCallback(
      sectionKey as SectionKey,
      editConfig,
      true,
      '',
      '',
      '',
      trackOnAmplitude,
    );
  };
  const onClose = (save) => (save ? onSave() : onCancel());

  return (
    <DialogueModal
      positiveText="Save Address"
      negativeText="Cancel"
      header="Invalid Address"
      isOpen={isStaff && isOpen}
      onClose={onClose}
    >
      {description}
    </DialogueModal>
  );
};

export default AddressConfirmation;
