import React from 'react';
import Attribute from '../Attribute';
import CreateSectionContent from '../CreateSectionContent';
import SectionLabel from '../SectionLabel';
import { IProps } from './types.d';

const CreateSection: React.FC<IProps> = ({
  section,
}: IProps): JSX.Element => {
  const {
    displayName,
    key,
    isOptional,
    contentConfigs,
  } = section;
  return (
    <Attribute
      key={`attribute_create_${key}`}
      label={<SectionLabel label={displayName} isOptional={isOptional} />}
      content={(
        <CreateSectionContent
          sectionKey={key}
          contentConfigs={contentConfigs}
        />
      )}
    />
  );
};

export default CreateSection;
