/* eslint-disable no-console */
import { atom, selector } from 'recoil';
import { JSObject } from '../../types.d';

export enum EVENTS {
  CLICKED_UPGRADE_PACKAGE = 'Clicked Upgrade Package',
  CLICKED_DOWNGRADE_PACKAGE = 'Clicked Downgrade Package',
  UPGRADED_PACKAGE = 'Upgraded Package',
  DOWNGRADED_PACKAGE = 'Downgraded Package',
}

export interface IEventProps extends JSObject {
  'Old Package'?: string;
  'Current Package'?: string;
  'New Package'?: string;
}

export type ITrackEvent = (
  eventType: EVENTS,
  eventProperties: IEventProps,
  callback?: () => void,
) => number | undefined;

export const defaultTracker: ITrackEvent = (
  eventType: EVENTS,
  eventProperties: IEventProps,
  callback?: () => void,
) => {
  console.warn(`Failed to setup Amplitude. Event [${eventType}]`, {
    eventProperties,
    callback,
  });
  return undefined;
};

export interface IAmplitudeClient {
  logEvent: ITrackEvent;
}

class AmplitudeClient {
  public logEvent: ITrackEvent = defaultTracker;
}

export const AmplitudeClientAtom = atom<IAmplitudeClient>({
  key: 'AmplitudeClientAtom',
  default: new AmplitudeClient(),
});

export const EventTrackerSelector = selector({
  key: 'EventTrackerSelector',
  get: async ({ get }) => {
    const amplitudeClient = get(AmplitudeClientAtom);
    return (
      eventType: EVENTS,
      eventProperties: IEventProps,
      callback?: () => void,
    ) => amplitudeClient?.logEvent(eventType, eventProperties, callback);
  },
});
