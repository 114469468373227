import { MonolithProfile } from '../types.d';

export default function canEdit(data: MonolithProfile, context: MonolithProfile): boolean {
  return Boolean(context?.id === data?.id
    || [3, 9].includes(context?.group?.id || 0)
    || (
      context?.company?.id
      && context?.company?.id === data?.company?.id
    ));
}
