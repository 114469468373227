/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import {
  v2,
  Grid,
  GridColumn,
  Icon,
} from '@fieldnation/platform-components';
import css from './Styles.scss';
import { getAllStatesByISO, DRIVING_RECORDS_CHECK } from './helpers';
import { OrderFormProps, Validator } from './types.d';

const {
  Select,
  TextInput,
  // @ts-ignore
  PhoneInput,
  // @ts-ignore
  EmailInput,
  // @ts-ignore
  SocialSecurityNumberInput,
} = v2;

const OrderForm = ({
  values,
  onChange,
  formError,
  screeningType,
  checkValidation,
}: OrderFormProps): JSX.Element => {
  const [dlStates, setDlStates] = useState([]);
  const handleOnValid = (key: string) => (): Validator => {
    checkValidation();
    const hasError = formError?.[key] || '';
    if (hasError) {
      return {
        type: 'error',
        message: formError?.[key],
      };
    }
    return {} as Validator;
  };
  useEffect(() => {
    const countries = getAllStatesByISO(values?.country || 'US');
    setDlStates(countries?.state?.values || []);
  });

  return (
    <div className={css.orderFormWrapper}>
      <Grid>
        <GridColumn xs="12">
          <div className={css.profileInfo}>
            <strong>Profile information</strong>
            <p className={css.profileInfoText}>
              Please confirm the following profile details are accurate.
              Details will be sent to our screening partner, checkr, to start the screening process.
            </p>
          </div>
        </GridColumn>
        <GridColumn xs="12">
          <div className={css.screeningAlert}>
            <span className={css.icon}>
              <Icon
                size={24}
                name="infoSolid"
                disablePointerEvent
              />
            </span>
            <span className={css.textInfo}>
              If you need to make changes to any of the disabled fields, you can make updates via
              your profile settings, or contact support for assistance.
            </span>
          </div>
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn xs="12" md="6">
          <TextInput
            id="firstName"
            label="First name"
            disabled
            value={values?.first_name || ''}
            // @ts-ignore
            autoComplete="firstName"
            onValid={handleOnValid('first_name')}
          />
        </GridColumn>
        <GridColumn xs="12" md="6">
          <TextInput
            id="lastName"
            label="Last name"
            disabled
            value={values?.last_name || ''}
            // @ts-ignore
            autoComplete="lastName"
            onValid={handleOnValid('last_name')}
          />
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn xs="12" md="6">
          <PhoneInput
            disabled
            id="phone"
            data-testid="phone"
            label="Cell phone number"
            showCountrySelect={false}
            onChange={(e) => onChange({ ...values, phone: e.target.value })}
            value={values?.cell || values?.phone || ''}
            autoComplete="phone"
          />
        </GridColumn>
        <GridColumn xs="12" md="6">
          <EmailInput
            disabled
            label="Email address"
            id="email"
            data-testid="email"
            onChange={(e) => onChange({ ...values, email: e.target.value })}
            value={values?.email || ''}
            autoComplete="email"
          />
        </GridColumn>
        {screeningType !== DRIVING_RECORDS_CHECK ? (
          <>
            <GridColumn xs="12" md="6">
              <div className={css.inputFieldGap}>
                <TextInput
                  id="postal_code"
                  label="Current zip code"
                  disabled
                  value={values?.postal_code || ''}
                  // @ts-ignore
                  autoComplete="postal_code"
                />
                <span className={css.helpText}>
                  To change zip code, you&apos;ll need to update your address in profile settings.
                </span>
              </div>
            </GridColumn>
          </>
        ) : (
          <>
            <GridColumn xs="12" md="6">
              <div className={css.inputFieldGap}>
                <TextInput
                  id="driver_license_number"
                  label="Driver licence number"
                  value={values?.driver_license_number || ''}
                  onChange={(e) => onChange({ ...values, driver_license_number: e.target.value })}
                  // @ts-ignore
                  autoComplete="driver_license_number"
                  onValid={handleOnValid('driver_license_number')}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" md="6">
              <div className={css.inputFieldGap}>
                <Select
                  label="Driver licence state"
                  value={values?.driver_license_state || ''}
                  options={dlStates}
                  onChange={(v) => {
                    onChange({ ...values, driver_license_state: String(v?.value || '') });
                  }}
                  onValid={handleOnValid('driver_license_state')}
                />
              </div>
            </GridColumn>
          </>
        )}
      </Grid>
    </div>
  );
};
export default OrderForm;
