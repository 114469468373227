/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultValue } from 'recoil';

const SNAPSHOT_KEY = 'PROVIDER_SNAPSHOT';
// const DRAWER_VERSION_KEY = 'DRAWER_VERSION';

const LoggedInUserId = window?.context?.id || 0;

const getLocalStoreKey = (key: string): string => {
  const storageKey = `${LoggedInUserId}:${key}`;
  return localStorage.getItem(storageKey) || '';
};

const removeLocalStoreKey = (key: string): void => {
  localStorage.removeItem(`${LoggedInUserId}:${key}`);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const setLocalStoreKey = (key: string, value: any): void => {
  localStorage.setItem(`${LoggedInUserId}:${key}`, JSON.stringify(value));
};

/**
 * Use this as an atom effect for atoms you want to store in local storage.
 * @param key string value of key to store
 * @returns void
 */
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const localStorageAtomEffect = (key: string) => ({ setSelf, onSet }) => {
  const savedValue = getLocalStoreKey(key);
  if (savedValue !== '') {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any, _oldValue: any, isReset: boolean) => {
    if (isReset || newValue instanceof DefaultValue) {
      removeLocalStoreKey(key);
    } else {
      setLocalStoreKey(key, newValue);
    }
  });
};

export {
  getLocalStoreKey,
  removeLocalStoreKey,
  setLocalStoreKey,
  localStorageAtomEffect,
  SNAPSHOT_KEY,
};
