import React, { 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import {
  Grid,
  GridColumn,
  Option,
  v2,
} from '@fieldnation/platform-components';
import { Tax, TaxProps } from './types';
import {
  getCountryStates,
  getMappedTaxData,
  llcTaxClassOptions,
  taxEntityOptions,
  taxIDOptions
} from './Helpers';
import css from './Styles.scss';

const {
  Select, TextInput,
} = v2;

const TaxForm = ({
  taxData,
  isEinSelected,
  isServiceCompany,
  onDataChange,
}: TaxProps): JSX.Element => {

  const data: Tax = getMappedTaxData(taxData);
  const stateList = useMemo(() => getCountryStates(data?.country), [data]);
  const [selectedTaxType, setSelectedTaxType] = useState(data?.selectedTaxType);
  const [selectedTaxId, setSelectedTaxId] = useState(isEinSelected ? 'ein' : data?.selectedTaxId);
  const [tin, setTin] = useState(data?.tinPlaceHolder);
  const [otherReason, setOtherReason] = useState(data?.otherEntityReason);
  const [taxClassOfLLC, setTaxClassOfLLC] = useState(data?.taxClassOfLLC);
  const [name, setName] = useState(selectedTaxId === 'ein' ? data?.companyName : data?.ssnName || '');
  const [doingBuisnessAs, setDoingBuisnessAs] = useState(data?.doingBuisnessAs);
  const [addressLine1, setAddressLine1] = useState(data?.addressLine1);
  const [addressLine2, setAddressLine2] = useState(data?.addressLine2);
  const [city, setCity] = useState(data?.city);
  const [state, setState] = useState(data?.state);
  const [zip, setZip] = useState(data?.zip);
  const [electronic1099, setElectronic1099] = useState(data?.electronic1099 || false);

  useEffect(() => {
    if (isServiceCompany) {
      taxEntityOptions.shift();
    }
  }, []);

  useEffect(() => {
    if (selectedTaxId === 'ssn' && selectedTaxType !== 'I') {
      setSelectedTaxId('ein');
    }
  }, [selectedTaxType, selectedTaxId]);

  useEffect(() => {
   if(data?.hasEin && selectedTaxId !== 'ein' ) {
    setTin('');
   }
   if(!data?.hasEin && selectedTaxId !== 'ssn'){
    setTin('');
   }
   },[selectedTaxId]);

  return (
    <Grid>
      <GridColumn xs="12" md="6">
        <div className={css.EntryForm}>
          <Select
            label="Tax entity type"
            onChange={(t: any) => {
              setSelectedTaxType(t.value);
              setTaxClassOfLLC('');
              onDataChange('entity_type', t.value);
            }}
            value={selectedTaxType}
            options={taxEntityOptions}
            disabled={false}
          />
        </div>
      </GridColumn>

      {selectedTaxType !== ''
        && (
          <GridColumn xs="12" md="6">
            <div className={css.EntryForm}>
              <div className={css.EinLabel}>
                {selectedTaxId === 'ein'
                  ? 'Employer Identification Number (EIN)' : 'Social Security Number (SSN)'}
              </div>
              <div style={{ display: 'flex', marginLeft: '-8px' }}>
                {selectedTaxType === 'I' && (
                  <GridColumn xs="4">
                    <div className={css.EntryForm}>
                      <Select
                        label=""
                        onChange={(t: any) => {
                          setSelectedTaxId(String(t.value));
                          onDataChange('has_ein', selectedTaxId === 'ein' ? 1 : 0);
                        }}
                        value={selectedTaxId}
                        options={taxIDOptions}
                        disabled={isEinSelected && selectedTaxId === 'ein'}
                      />
                    </div>
                  </GridColumn>
                )}
                <GridColumn xs={selectedTaxType !== 'I' ? '12' : '8'}>
                  {selectedTaxId === 'ein'
                    ? (
                      <div >
                        <TextInput
                          label=""
                          value={tin}
                          // @ts-ignore
                          placeholder={tin}
                          // @ts-ignore
                          mask="99-9999999"
                          inputMode="number"
                          onChange={(e: any) => {
                            setTin(String(e.target.value));
                            onDataChange('tin', e.target.value);
                            //onDataChange('has_ein', 1);
                          }}
                          disabled={false}
                        />
                      </div>
                    )
                    : (
                      <div className={css.EntryForm}>
                        <TextInput
                          label=""
                          value={''}
                          // @ts-ignore
                          placeholder={tin}
                          // @ts-ignore
                          mask="999-99-9999"
                          inputMode="number"
                          onChange={(e: any) => {
                            setTin(String(e.target.value));
                            onDataChange('tin', String(e.target.value));
                            //onDataChange('has_ein', 0);
                          }}
                        />
                      </div>
                    )}
                </GridColumn>
              </div>
            </div>
          </GridColumn>
        )}

      {selectedTaxType === 'O' && (
        <GridColumn xs="12">
          <div className={css.EntryForm}>
            <TextInput
              label="Reason for selecting other as entity type"
              value={otherReason || ''}
              onChange={(e: any) => {
                setOtherReason(e.target.value);
                onDataChange('other', e.target.value);
              }}
              disabled={false}
            />
          </div>
        </GridColumn>
      )}
      {selectedTaxType === 'LL' && (
        <GridColumn xs="12">
          <div className={css.EntryForm}>
            <Select
              label="Tax classification of LLC"
              value={taxClassOfLLC}
              options={llcTaxClassOptions}
              onChange={(e: any) => {
                setTaxClassOfLLC(e);
                onDataChange('entity_type', e?.value);
              }}
              disabled={false}
            />
          </div>
        </GridColumn>
      )}
      {selectedTaxType !== ''
        && (
          <>
            <GridColumn xs="12" md="6">
              <div className={css.EntryForm}>
                <TextInput
                  label={`${selectedTaxId === 'ein' ? 'Company Name' : 'Full Name'} associated with TIN`}
                  value={name}
                  disabled={selectedTaxId === 'ssn'}

                  onChange={(e: any) => {
                    setName(e.target.value);
                    onDataChange('name', e.target.value);

                  }}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" md="6">
              <div className={css.EntryForm}>
                <TextInput
                  label="Doing Business As"
                  value={doingBuisnessAs || ''}
                  optional
                  disabled={false}
                  onChange={(e: any) => {
                    setDoingBuisnessAs(e.target.value);
                    onDataChange('business_name', e.target.value);
                  }}
                  helpText={`Disregarded entity name from IRS form W-9`}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" md="6">
              <div className={css.EntryForm}>
                <TextInput
                  label="Address Line 1"
                  value={addressLine1}
                  disabled={false}
                  onChange={(e: any) => {
                    setAddressLine1(e.target.value);
                    onDataChange('address1', e.target.value);
                  }}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" md="6">
              <div className={css.EntryForm}>
                <TextInput
                  label="Address Line 2"
                  value={addressLine2 || ''}
                  optional
                  disabled={false}
                  onChange={(e: any) => {
                    setAddressLine2(e.target.value);
                    onDataChange('address2', e.target.value);
                  }}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" md="6">
              <div className={css.EntryForm}>
                <TextInput
                  label="City"
                  value={city}
                  disabled={false}
                  onChange={(e: any) => {
                    setCity(e.target.value);
                    onDataChange('city', e.target.value);
                  }}
                />
              </div>
            </GridColumn>
            <GridColumn xs="8" md="4">
              <div className={css.EntryForm}>
                <Select
                  label="State"
                  disabled={false}
                  options={stateList}
                  onChange={(e: any) => {
                    setState(e.value);
                    onDataChange('state', e.value);
                  }}
                  value={state}
                />
              </div>
            </GridColumn>
            <GridColumn xs="4" md="2">
              <div className={css.EntryForm}>
                <TextInput
                  label="Zip Code"
                  value={zip}
                  disabled={false}
                  onChange={(e: any) => {
                    setZip(e.target.value);
                    onDataChange('zip', e.target.value);
                  }}
                />
              </div>
            </GridColumn>
            <div style={{ marginTop: '1.2rem', marginBottom: '1.2rem' }}>
              <GridColumn xs="12">
                <Option
                  checked={!!electronic1099}
                  disabled={false}
                  value="electronic1099"
                  onChange={() => {
                    setElectronic1099(!electronic1099);
                    onDataChange('electronic_consent', !electronic1099);
                  }}
                >
                  Receive my 1099 electronically.
                </Option>
              </GridColumn>
            </div>
          </>
        )}
    </Grid>
  )
}

export default React.memo(TaxForm);