import * as React from 'react';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  v2, ActionTabs, InfoIcon, Grid, GridColumn,
} from '@fieldnation/platform-components';
import { backoutsDetailsFilters, backoutsDetails } from '../queries/backouts-details.query';
import BackoutsList from './BackoutsList';
import css from './backouts-details.scss';
import BackoutsChart from './BackoutsChart';
import ModalFilters from './ModalFilters';

import { BackoutsDetailsTab, BackoutsModalProps } from '../types.d';

const { Modal } = v2;

const BackoutsDetailsModal = (
  { userId, onClose, backoutsFilters }: BackoutsModalProps,
): JSX.Element => {
  const [tab, setTab] = React.useState('provider');
  const setFilters = useSetRecoilState(backoutsDetailsFilters);
  const filters = useRecoilValue(backoutsDetailsFilters);
  const { canViewList, tabs } = useRecoilValue(backoutsDetails);
  const dayHistory = backoutsFilters?.dayHistory === 0 && backoutsFilters?.pastHistory === 0
    ? 0 : backoutsFilters?.dayHistory;

  useEffect(() => {
    setFilters({
      userId,
      causedBy: 'provider',
      ...backoutsFilters,
      dayHistory,
      graphType: 'monthly',
    });
  }, []);

  const modalHeader = () => (
    <div className={css['backouts-details-header']}>
      <span className={css['header-label']}>Assignment removals</span>
      <InfoIcon>
        Assignment removals are based on work order completions excluding buyer at-fault events.
      </InfoIcon>
    </div>
  );

  return (
    <Modal
      isOpen
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      header={modalHeader()}
      onClose={onClose}
      size="large"
    >
      <div className={css['backouts-details-modal-wrapper']}>
        <div>
          <Grid>
            <GridColumn md="12">
              <Grid>
                <GridColumn md="4" sm="12">
                  <ModalFilters
                    filters={filters}
                    setFilters={setFilters}
                  />
                </GridColumn>
                <GridColumn md="8" sm="12" data-testid="chart-container-id">
                  <BackoutsChart />
                </GridColumn>
              </Grid>
            </GridColumn>
            <GridColumn md="12">
              <div className={css['horizontal-divider']}><hr /></div>
            </GridColumn>
            <GridColumn md="12">
              {canViewList && (
              <>
                <div className="u-padding-bottom--sm">
                  <ActionTabs
                    items={tabs.map((l: BackoutsDetailsTab) => ({
                      key: l.id,
                      active: l.backoutsBy === tab,
                      label: l.label,
                      onClick: () => setTab(l.backoutsBy),
                      count: `${l.count}`,
                    }))}
                  />
                </div>
                <BackoutsList tab={tab} />
              </>
              )}
            </GridColumn>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

BackoutsDetailsModal.defaultProps = {
  backoutsFilters: {},
};

export default BackoutsDetailsModal;
