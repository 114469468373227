import { useState, useEffect, useCallback } from 'react';
import makeApiRequest from '../api/makeRequest';
import { success, error } from '../../legacy/flash-messenger';
import { WaWorkerCompEventSaveStatus, WaWorkerCompEventSource, WaWorkerCompEvents } from '../tracker/types.d';
import { transformBooleanAnswerToString } from '../tracker/trackerUtils';
import { trackAnsweredQuestionEvent, trackSaveEvent } from '../tracker/amplitude';
import { VerificationStatusEnum } from '../types.d';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUbiFormHook = (source) => {
  const [maintainsBooksAndRecords, setMaintainsBooksAndRecords] = useState('');
  const [filesIRSExpense, setFilesIRSExpense] = useState('');
  const [hasUbi, setHasUbi] = useState('');
  const [ubiNumber, setUbiNumber] = useState('');
  const [postingData, setPostingData] = useState(false);
  const [submissionEnabled, setSubmissionEnabled] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(
    VerificationStatusEnum.NEW,
  );
  const [currentStatus, setCurrentStatus] = useState('none');

  const onChangeMaintainRecords = useCallback((maintainsRecords: string) => {
    setMaintainsBooksAndRecords(maintainsRecords);
    trackAnsweredQuestionEvent(
      WaWorkerCompEvents.ANSWERED_FIRST_QUESTION,
      transformBooleanAnswerToString(maintainsRecords),
      source,
    );
  }, []);

  const onChangeFilesWithIrs = useCallback((filesIrsExpense: string) => {
    setFilesIRSExpense(filesIrsExpense);
    trackAnsweredQuestionEvent(
      WaWorkerCompEvents.ANSWERED_SECOND_QUESTION,
      transformBooleanAnswerToString(filesIrsExpense),
      source,
    );
  }, []);

  const onChangeHasUbi = useCallback((hasUbiInput: string) => {
    setHasUbi(hasUbiInput);

    if (!hasUbiInput || hasUbiInput === '0') {
      setUbiNumber('');
    }
    trackAnsweredQuestionEvent(
      WaWorkerCompEvents.ANSWERED_THIRD_QUESTION,
      transformBooleanAnswerToString(hasUbiInput),
      source,
    );
  }, []);

  const onChangeUbiNumber = useCallback((ubiNumberInput: string) => {
    if (ubiNumberInput?.match(/^\d{9,9}$/)?.length) {
      trackAnsweredQuestionEvent(WaWorkerCompEvents.ENTERED_UBI_NUMBER, ubiNumber, source);
    }
    setUbiNumber(ubiNumberInput);
  }, []);

  const onChangeVerificationStatus = useCallback(
    (status: VerificationStatusEnum) => {
      setVerificationStatus(status);
    },
    [],
  );

  useEffect(() => {
    const isValid =
      hasUbi === '0' || (hasUbi && ubiNumber?.match(/^\d{9,9}$/)?.length);

    if (maintainsBooksAndRecords && filesIRSExpense && isValid) {
      setSubmissionEnabled(true);
    } else {
      setSubmissionEnabled(false);
    }
  }, [maintainsBooksAndRecords, filesIRSExpense, hasUbi, ubiNumber]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(async () => {
    async function fetchUbiInfo() {
      const userId = window?.user?.id || window?.context?.id;
      const url = `/users/${userId}/ubi`;
      setPostingData(true);

      try {
        const ubiInfo = await makeApiRequest({
          url,
          method: 'GET',
        });

        if (ubiInfo && ubiInfo.success) {
          const {
            self_maintenance,
            has_irs_file,
            ubi_number,
            verification_status,
          } = ubiInfo.results;

          setMaintainsBooksAndRecords(self_maintenance ? '1' : '0');
          setFilesIRSExpense(has_irs_file ? '1' : '0');
          setHasUbi(ubi_number ? '1' : '0');
          setUbiNumber(ubi_number);
          setVerificationStatus(verification_status);
          setCurrentStatus((verification_status && ubi_number) ? verification_status : 'none');
        }

        setPostingData(false);
      } catch (err) {
        setPostingData(false);
      }
    }

    await fetchUbiInfo();
  }, []);

  const saveUbiData = useCallback(async () => {
    const userId = window?.user?.id || window?.context?.id;
    const isAccountant = window?.context?.group?.id === 9;
    const url = isAccountant ? `/users/${userId}/ubi/verify` : `/users/${userId}/ubi`;
    setPostingData(true);
    setSubmissionEnabled(false);
    const eventData = [
      transformBooleanAnswerToString(maintainsBooksAndRecords),
      transformBooleanAnswerToString(filesIRSExpense),
      transformBooleanAnswerToString(hasUbi),
      hasUbi ? ubiNumber : '',
    ];

    try {
      const response = await makeApiRequest({
        url,
        method: 'POST',
        data: {
          self_maintenance: maintainsBooksAndRecords,
          has_irs_file: filesIRSExpense,
          has_ubi_number: hasUbi,
          ubi_number: hasUbi ? ubiNumber : '',
          verification_status: verificationStatus,
        },
      });

      if (response?.success) {
        success(source === WaWorkerCompEventSource.PROFILE ?
          "Washington workers' compensation information is saved"
          : "Your Washington Workers' Compensation information has been saved."
          + ' To make any changes, go to the Insurance section of your Profile settings.');
        trackSaveEvent(eventData, WaWorkerCompEventSaveStatus.SUCCESS, source);
      } else {
        error('An unexpected error has occurred');
        trackSaveEvent(eventData, WaWorkerCompEventSaveStatus.FAILURE, source);
      }
      const { verification_status, ubi_number } = response?.results;
      setPostingData(false);
      setSubmissionEnabled(true);
      setCurrentStatus((verification_status && ubi_number) ? verification_status : 'none');
    } catch (err) {
      error("Could not save the Washington workers' compensation information");
      trackSaveEvent(eventData, WaWorkerCompEventSaveStatus.FAILURE, source);
      setPostingData(false);
      setSubmissionEnabled(true);
    }
  }, [maintainsBooksAndRecords, filesIRSExpense, hasUbi, ubiNumber, verificationStatus]);

  return {
    maintainsBooksAndRecords,
    filesIRSExpense,
    hasUbi,
    ubiNumber,
    postingData,
    saveUbiData,
    verificationStatus,
    currentStatus,
    submissionEnabled,
    onChangeHasUbi,
    onChangeUbiNumber,
    onChangeMaintainRecords,
    onChangeFilesWithIrs,
    onChangeVerificationStatus,
  };
};

export default useUbiFormHook;
