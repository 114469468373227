/* eslint-disable no-template-curly-in-string */
import {
  ContentKey,
  ContentReference,
  ContentType,
  CustomEditComponent,
  HttpMethod,
  InputType,
  InputValidation,
  InputValidationType,
  ISectionCard,
  SectionCardKey,
  SectionKey,
} from '../SectionCard/Components/Section/types.d';
import { FileType, UserGroup } from '../SectionCard/Commons/types.d';

const SectionCardConfig: ISectionCard = {
  title: 'Company Overview',
  key: SectionCardKey.COMPANY_OVERVIEW,
  windowReference: 'company',
  sections: [
    {
      displayName: 'Company Logo',
      key: SectionKey.COMPANY_LOGO,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_LOGO,
          contentReference: ContentReference.LOGO,
          displayName: 'Company Logo',
          displayRow: 0,
          isInputLabelHidden: true,
          acceptFiles: [FileType.JPEG, FileType.PNG],
          useMultipleFiles: false,
          contentType: ContentType.IMAGE,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}/photo',
        method: HttpMethod.POST,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Company Name',
      key: SectionKey.COMPANY_NAME,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_NAME,
          contentReference: ContentReference.NAME,
          displayName: 'Company Name',
          displayRow: 0,
          isInputLabelHidden: true,
          inputType: InputType.TEXT,
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
      ],
    },
    {
      displayName: 'Company Email',
      key: SectionKey.COMPANY_EMAIL,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_EMAIL,
          contentReference: ContentReference.EMAIL,
          displayName: 'Company Email',
          displayRow: 0,
          isInputLabelHidden: true,
          inputType: InputType.EMAIL,
          inputValidation: InputValidation[InputValidationType.EMAIL],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Company Phone',
      key: SectionKey.COMPANY_PHONE,
      isOptional: false,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_PHONE,
          contentReference: ContentReference.PHONE,
          displayName: 'Company Phone',
          displayRow: 0,
          isInputLabelHidden: true,
          inputType: InputType.PHONE,
          inputValidation: InputValidation[InputValidationType.PHONE],
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Company Address',
      key: SectionKey.COMPANY_ADDRESS,
      isOptional: false,
      customEditComponent: CustomEditComponent.SMART_ADDRESS,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_ADDRESS1,
          contentReference: ContentReference.ADDRESS1,
          displayName: 'Address 1',
          displayRow: 0,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.COMPANY_OVERVIEW_ADDRESS2,
          contentReference: ContentReference.ADDRESS2,
          displayName: 'Address 2',
          displayRow: 0,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
          isOptional: true,
        },
        {
          key: ContentKey.COMPANY_OVERVIEW_CITY,
          contentReference: ContentReference.CITY,
          displayName: 'City',
          displayRow: 1,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.COMPANY_OVERVIEW_STATE,
          contentReference: ContentReference.STATE,
          displayName: 'State',
          displayRow: 1,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.COMPANY_OVERVIEW_ZIP,
          contentReference: ContentReference.ZIP,
          displayName: 'Zip',
          displayRow: 1,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
        },
        {
          key: ContentKey.COMPANY_OVERVIEW_COUNTRY,
          contentReference: ContentReference.COUNTRY,
          displayName: 'Country',
          displayRow: 2,
          isInputLabelHidden: false,
          contentType: ContentType.TEXT,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}?error_type=html',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
    {
      displayName: 'Company Website',
      key: SectionKey.COMPANY_WEBSITE,
      isOptional: true,
      customEditComponent: CustomEditComponent.NONE,
      contentConfigs: [
        {
          key: ContentKey.COMPANY_OVERVIEW_WEBSITE,
          contentReference: ContentReference.WEBSITE,
          displayName: 'Company Website',
          displayRow: 0,
          isInputLabelHidden: true,
          inputType: InputType.TEXT,
          contentType: ContentType.TEXT,
          isOptional: true,
        },
      ],
      onEditConfig: {
        url: 'company/${company_id}',
        method: HttpMethod.PATCH,
      },
      editPermissionGroup: [
        UserGroup.STAFF,
        UserGroup.ACCOUNTANT,
        UserGroup.ADMIN,
      ],
    },
  ],
};

export default SectionCardConfig;
