import React, { useState } from 'react';
import {
  v2
} from '@fieldnation/platform-components';
import CredentialForm from '../LicenseCertificaton/CredentialForm';
import PropTypes from 'prop-types';
import { EVENTS, useAmplitudeTracker } from '../Amplitude/useAmplitudeTracker';
import { EditCredentialsProps } from './types';

const { Modal } = v2;
const EnhancedCredentialsEdit = ({
  userCredentialId, source, userId, onSubmitSuccessCallback, onClose, setParentLoading,
}: EditCredentialsProps): JSX.Element => {
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  const trackOnAmplitude = useAmplitudeTracker();

  return (
    <Modal
      size="medium"
      header={'Edit license or credentials'}
      position="center"
      footerActions={[
        {
          label: 'Update',
          type: 'primary',
          onClick: () => setTriggerSubmit(!triggerSubmit),
        },
        {
          label: 'Cancel',
          type: 'secondary',
          onClick: () => onClose?.(),
        }
      ]}
      onClose={() => {
        trackOnAmplitude(EVENTS.ADDED_LICENSE_OR_CERTIFICATION, {
          Action: 'close',
          Source: source,
        });
        onClose?.();
      }}
      isOpen={true}
    >
      <CredentialForm
        setParentLoading={setParentLoading}
        userId={userId}
        triggerSubmit={triggerSubmit}
        onSubmitSuccessCallback={onSubmitSuccessCallback}
        onClose={onClose}
        userCredentialId={userCredentialId}
      />
    </Modal>
  );
}

EnhancedCredentialsEdit.propTypes = {
  userCredentialId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  setParentLoading: PropTypes.func,
  setHeaderText: PropTypes.func,
  onSubmitSuccessCallback: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  hideModal: PropTypes.bool,
  source: PropTypes.string,
};

EnhancedCredentialsEdit.defaultProps = {
  setParentLoading: () => {},
  onClose: () => {},
  hideModal: false,
};
export default EnhancedCredentialsEdit;
