import { MonolithProfile } from '../types.d';

export default function canEditPpn(data: MonolithProfile, context: MonolithProfile): boolean {
  return Boolean(
    ![1].includes(context?.group?.id || 0)
    && (
      context?.company?.id
      && context?.company?.id !== data?.company?.id
    ),
  );
}
