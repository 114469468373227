import * as React from 'react';
import { NumberOfJobsProps } from '../types.d';

const NumberofJobs = ({
  numJobs,
}: NumberOfJobsProps): JSX.Element => (
  <div>
    <span>
      {numJobs}
    </span>
    {' '}
    Job
    {numJobs === 1 ? '' : 's'}
  </div>
);

NumberofJobs.defaultProps = {
  numJobs: 0,
};

export default NumberofJobs;
