import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
// import { ApiResponse, IJsObject } from './types.d';

const getAxiosInstance = (): AxiosInstance => axios.create({
  baseURL: '/v2',
  timeout: 5 * 1000,
  timeoutErrorMessage: 'Request timed out, please try again later',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeApiRequest = async (options: AxiosRequestConfig): Promise<any> => {
  const axiosInstance = getAxiosInstance();
  const response = await axiosInstance.request(options);
  return response?.data;
};

export default makeApiRequest;
