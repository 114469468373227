import React from 'react';
import PropTypes from 'prop-types';
import {
  Loader,
  Grid,
  GridColumn,
  Section,
  v2,
} from '@fieldnation/platform-components';
import css from './styles.scss';
import { supportMessage } from './utils';

const {
  Modal,
  TextInput,
  // @ts-ignore
  NotificationBand,
} = v2;

const OtpComponentModal = ({
  email,
  loading,
  closeHandler,
  submitHandler,
  contactSupportHandler,
}): JSX.Element => (
  <>
    <Modal
      onClose={closeHandler}
      isOpen
      header="Edit this section"
      footerActions={[
        {
          type: 'secondary',
          label: 'Cancel',
          onClick: closeHandler,
        },
        {
          type: 'primary',
          label: 'Continue',
          onClick: submitHandler,
        },
      ]}
      size="medium"
    >
      <Loader isLoading={loading}>
        <Section>
          <Grid>
            <GridColumn>
              <p className={css['Body--title']}>Receive a code by email</p>
            </GridColumn>
            <GridColumn>
              You will receive a code at the following email:
            </GridColumn>
            <GridColumn>
              <TextInput
                label="Account Email"
                value={email}
                disabled
                onChange={() => {}}
              />
            </GridColumn>
            <GridColumn>
              <NotificationBand
                secondaryText={supportMessage(contactSupportHandler)}
              />
            </GridColumn>
          </Grid>
        </Section>
      </Loader>
    </Modal>
  </>
);

OtpComponentModal.propTypes = {
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  contactSupportHandler: PropTypes.func.isRequired,
};

OtpComponentModal.defaultProps = {
  loading: false,
};

export default OtpComponentModal;
