import React, { Suspense } from 'react';
import { Loader, v2 } from '@fieldnation/platform-components';
import {
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import css from './styles.scss';
import { IProps } from './types.d';
import {
  ContentKey, ISectionContent, SectionCardMode, SectionKey,
} from '../Section/types.d';
import AddressConfirmation from '../AddressConfirmation';
import {
  contentInputState,
  sectionCardResourceId,
  sectionCardSections,
  sectionContentsState,
  sectionCardCreateConfig,
  sectionCardCreatePermissionGroup,
  sectionCardModeState,
} from '../../States/AtomFamilies';
import SectionCardContent from '../SectionCardContent';

const { Tile, TileDivision } = v2;

const setSectionContents = (
  sections,
  windowReference,
  sectionCardKey,
  resourceReference,
  createPermissionGroup,
  onCreateConfig,
) => {
  const ref = get(window, windowReference);
  sections.forEach((section) => {
    const setSectionContent = useSetRecoilState(sectionContentsState(section.key as SectionKey));
    const contents: ISectionContent[] = [];
    section.contentConfigs.forEach((config) => {
      const setContentInput = useSetRecoilState(contentInputState(config.key as ContentKey));
      const { key, contentReference } = config;
      const refKey = get(ref, contentReference) || '';
      const content: ISectionContent = {
        key,
        value: String(refKey),
        contentConfig: config,
      };
      contents.push(content);
      setContentInput(content);
    });
    setSectionContent(contents);
  });
  useSetRecoilState(sectionCardSections(sectionCardKey))(sections);

  const resourceRefKey = ref[resourceReference];
  useSetRecoilState(sectionCardResourceId(sectionCardKey))(String(resourceRefKey));
  useSetRecoilState(sectionCardCreateConfig(sectionCardKey))(onCreateConfig);
  useSetRecoilState(sectionCardCreatePermissionGroup(sectionCardKey))(createPermissionGroup);
};

const SectionCard: React.FC<IProps> = ({ sectionCardConfig }: IProps): JSX.Element => {
  const {
    title,
    key,
    resourceReference,
    description,
    windowReference,
    sections,
    createPermissionGroup,
    onCreateConfig,
  } = sectionCardConfig;
  setSectionContents(
    sections,
    windowReference,
    key,
    resourceReference,
    createPermissionGroup,
    onCreateConfig,
  );

  const [cardMode, setCardMode] = useRecoilState(sectionCardModeState(key));
  const isRefEmpty = isEmpty(get(window, windowReference));
  const restMode = cardMode === SectionCardMode.CREATE
    ? SectionCardMode.CREATE
    : SectionCardMode.INTERACTIVE;
  setCardMode(isRefEmpty && restMode !== SectionCardMode.CREATE
    ? SectionCardMode.EMPTY
    : restMode);

  return (
    <div className={css.container}>
      <Suspense fallback={<Loader fixed isLoading />}>
        <Tile>
          <TileDivision heading={title}>
            <SectionCardContent
              description={description || ''}
              cardMode={cardMode}
              title={title}
              cardKey={key}
              sections={sections}
              windowReference={windowReference}
            />
          </TileDivision>
        </Tile>
        <AddressConfirmation />
      </Suspense>
    </div>
  );
};

export default SectionCard;
