export default (target: string): void => {
    if (target) {
        const timmy = setInterval(() => { // timmy......🙃
            const elements = document.getElementsByName(target);
            if (elements.length) {
                elements[0].scrollIntoView({ behavior: 'smooth' });
                clearInterval(timmy);
            }
        }, 500);
    }
};
