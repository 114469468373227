/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Button, Loader, v2 } from '@fieldnation/platform-components';
import { FooterAction } from '@fieldnation/platform-components/src/v2/Modal/types.d';
import { requestWrapper } from '../common/api';
import { UpgradeDwongradeModalProps } from './types.d';
import { PossibleUpdateOptions } from '../common/UpdateOptions.selector';
import { TransitionPackageAtom } from '../common/TransitionPackage.atom';
import UpgradeDowngradeModalContent from './UpgradeDowngradeModalContent';
import { IPackageUpdateResponse, ITransitionPackage } from '../common/types.d';
import { CardTooltip } from '../../components';
import { EVENTS, IEventProps, EventTrackerSelector } from '../common/amplitude';

import css from './UpgradeDowngradeModal.scss';

const { Modal } = v2;

const UpgradeDwongradeModal = (
  props: UpgradeDwongradeModalProps,
): JSX.Element => {
  const { target = '', openModal = false, hideButtons = false } = props;
  const [transitionPackage, setTransitionPackage] = useRecoilState(
    TransitionPackageAtom,
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [transitionInProgress, setTransitionInProgress] = useState(false);
  const [packageUpdateResp, setPackageUpdateResp] = useState<
    boolean | IPackageUpdateResponse
  >(false);

  const updateOptions = useRecoilValue(PossibleUpdateOptions);
  const trackEvent = useRecoilValue(EventTrackerSelector);

  const isCurrentPackage =
    updateOptions?.[target]?.operation === 'current package';
  const label = !isCurrentPackage
    ? `Get ${target}`
    : updateOptions?.[target]?.operation;

  const eventTrackerAction = (postReqSuccess = false) => {
    let eventName = '' as EVENTS;
    let eventProps: IEventProps;
    const ops = updateOptions?.[target]?.operation;
    if (ops === 'upgrade') {
      eventName = EVENTS.CLICKED_UPGRADE_PACKAGE;
    } else if (ops === 'downgrade') {
      eventName = EVENTS.CLICKED_DOWNGRADE_PACKAGE;
    }
    eventProps = {
      'Current Package': updateOptions?.[target]?.current_tier,
      'New Package': target,
    };

    if (postReqSuccess === true) {
      eventProps = {
        'Old Package': updateOptions?.[target]?.current_tier,
        'New Package': target,
      };
      if (ops === 'upgrade') {
        eventName = EVENTS.UPGRADED_PACKAGE;
      } else if (ops === 'downgrade') {
        eventName = EVENTS.DOWNGRADED_PACKAGE;
      }
    }

    if (eventName && eventProps) {
      trackEvent(eventName, eventProps);
    }
  };

  const dispatchPackageUpdate = async (
    targetTier?: string,
    operation?: string,
  ) => {
    setTransitionInProgress(true);
    setPackageUpdateResp(false);
    const resp = (await requestWrapper(
      `/company/account-overview/package-update/${targetTier}/${operation}`,
      { method: 'PATCH' },
    )) as IPackageUpdateResponse;
    if (resp?.result?.statusCode === 200) {
      eventTrackerAction(true);
    }
    setPackageUpdateResp(resp);
    setTransitionInProgress(false);
  };

  const getModalHeader = (): string =>
    `${!packageUpdateResp ? 'Get ' : ''} ${startCase(target)}`;

  const cleanupAndClose = () => {
    setPackageUpdateResp(false);
    setTransitionInProgress(false);
    setTransitionPackage(null);

    if (props.callBack) props.callBack();

    setModalOpen(false);
  };

  const getModalFooterAction = (): FooterAction[] => {
    const transitionAction: FooterAction = {
      label:
        target === 'premier'
          ? 'Contact Sales'
          : `${startCase(updateOptions?.[target]?.operation)} now`,
      type: 'primary',
      disabled: Boolean(transitionInProgress),
      onClick: () => {
        if (target === 'premier') {
          const intercom: (m: string) => void = get(
            window,
            'Intercom',
            (m: string) => console.warn(`Intercom(${m}) not found`),
          );
          intercom('showNewMessage');
          cleanupAndClose();
        } else {
          dispatchPackageUpdate(
            transitionPackage?.target_tier,
            transitionPackage?.operation,
          );
        }
      },
    };
    const done: FooterAction = {
      label:
        get(packageUpdateResp, 'result.statusCode') === 200 ? 'Done' : 'Close',
      type: 'secondary',
      onClick: () => {
        if (get(packageUpdateResp, 'result.statusCode') !== 200) {
          cleanupAndClose();
        } else {
          window.location.reload();
        }
      },
    };

    let resp: FooterAction[] = [];

    if (transitionPackage) resp = [transitionAction];
    if (packageUpdateResp) resp = [done];

    return resp;
  };

  useEffect(() => {
    setModalOpen(openModal);
    if (!transitionPackage) {
      setTransitionPackage({
        target_tier: target,
        ...updateOptions?.[target],
      });
    }
  }, [openModal]);

  return (
    <div className="UpgradeDwongradeModal">
      {!hideButtons && isCurrentPackage && (
        <div className={classNames(css['current-package-text'])}>
          Current Package
        </div>
      )}
      {!hideButtons && !isCurrentPackage && (
        <Button
          type="secondary"
          size="lg"
          block={false}
          disabled={updateOptions?.[target]?.value === false}
          label={label}
          onClick={() => {
            setTransitionPackage({
              target_tier: target,
              ...updateOptions?.[target],
            });
            setModalOpen(true);
            eventTrackerAction();
          }}
        />
      )}

      {!hideButtons && !updateOptions?.[target]?.value && !isCurrentPackage && (
        <>
          {' '}
          &nbsp;
          <CardTooltip
            heading={`${startCase(
              updateOptions?.[target]?.operation,
            )} not available`}
            content={updateOptions?.[target]?.disable_reason}
          />
        </>
        // eslint-disable-next-line indent
      )}

      {Boolean(isModalOpen) && (
        <Modal
          header={getModalHeader()}
          size="large"
          isOpen
          onClose={() => cleanupAndClose()}
          footerActions={getModalFooterAction()}
        >
          <Loader isLoading={transitionInProgress}>
            <UpgradeDowngradeModalContent
              transition_package={transitionPackage as ITransitionPackage}
              package_update_resp={packageUpdateResp as IPackageUpdateResponse}
            />
          </Loader>
        </Modal>
      )}
    </div>
  );
};

export default UpgradeDwongradeModal;
