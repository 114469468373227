import React from 'react';
import { Grid, GridColumn, v2 } from '@fieldnation/platform-components';

import SimpleAddressInput from './simple-address-input';
import { useCountryList, useSelectInputFormatter } from './hooks';
import { IFullAddressInput } from './types.d';
import { DEFAULT_COUNTRY_LIST_PATH } from './constants';

const { TextInput, Select } = v2;

const FullAddressInput = ({
  values,
  onChange,
  inputAttribute = {},
  countryListValuePath = DEFAULT_COUNTRY_LIST_PATH,
  autocompletionRequest,
  placeDetailsRequest,
  addressComponentMapping,
  additionalInfo,
}: IFullAddressInput): JSX.Element => {
  useSelectInputFormatter(['state_input', 'country_input'], ['height']);
  const { allCountries, currentCountryValue } = useCountryList(
    values?.country,
    countryListValuePath,
  );
  return (
    <div className="FullAddressInput">
      <Grid>
        <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
          <Grid>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <div className="address1_input">
                <SimpleAddressInput
                  additionalInfo={additionalInfo}
                  autocompletionRequest={autocompletionRequest}
                  placeDetailsRequest={placeDetailsRequest}
                  addressComponentMapping={addressComponentMapping}
                  targetCountry={values?.country}
                  inputAttribute={inputAttribute}
                  value={values?.address1 || ''}
                  label={
                    currentCountryValue?.address1?.label || 'Address Line 1'
                  }
                  onChange={({ fullAddress, value = '' }) => {
                    if (fullAddress) {
                      onChange(fullAddress);
                    } else {
                      onChange({
                        ...values,
                        address1: String(value),
                      });
                    }
                  }}
                />
              </div>
            </GridColumn>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <TextInput
                label={currentCountryValue?.address2?.label || 'Address Line 2'}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                placeholder="Apartment, suite, etc."
                optional={!currentCountryValue?.address2?.required}
                value={values?.address2}
                onChange={(e) => {
                  onChange({ ...values, address2: e.target.value });
                }}
                disabled={inputAttribute?.address2?.disabled}
              />
              <>{inputAttribute?.address2?.additionalContent}</>
            </GridColumn>
          </Grid>
        </GridColumn>
        <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
          <Grid>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <TextInput
                label={currentCountryValue?.city?.label || 'City'}
                optional={!currentCountryValue?.city?.required}
                value={values?.city}
                onChange={(e) => {
                  onChange({ ...values, city: e.target.value });
                }}
                disabled={inputAttribute?.city?.disabled}
              />
              <>{inputAttribute?.city?.additionalContent}</>
            </GridColumn>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <div className="state_input">
                <Select
                  label={currentCountryValue?.state?.label || 'State'}
                  optional={!currentCountryValue?.state?.required}
                  value={values.state || {}}
                  options={currentCountryValue?.state?.values}
                  onChange={(v) => {
                    onChange({ ...values, state: String(v?.value || '') });
                  }}
                  disabled={inputAttribute?.state?.disabled}
                />
                <>{inputAttribute?.state?.additionalContent}</>
              </div>
            </GridColumn>
          </Grid>
        </GridColumn>
        <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
          <Grid>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <TextInput
                label={currentCountryValue?.zip?.label || 'Zip Code'}
                optional={!currentCountryValue?.zip?.required}
                pattern={currentCountryValue?.zip?.pattern}
                value={values.zip}
                onChange={(e) => onChange({ ...values, zip: e.target.value })}
                disabled={inputAttribute?.zip?.disabled}
              />
              <>{inputAttribute?.zip?.additionalContent}</>
            </GridColumn>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <div className="country_input">
                <Select
                  label="Country"
                  optional={false}
                  value={currentCountryValue}
                  options={allCountries}
                  onChange={(v) => {
                    onChange({
                      ...values,
                      country: String(v?.value || ''),
                      state: '',
                      zip: '',
                    });
                  }}
                  disabled={inputAttribute?.country?.disabled}
                />
                <>{inputAttribute?.country?.additionalContent}</>
              </div>
            </GridColumn>
          </Grid>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default FullAddressInput;
