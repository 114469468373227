import { useQuery } from 'react-query';

import { TechnicianNetworks, Network } from '../types.d';

export function useTechnicianNetworks(userId?: number): TechnicianNetworks[] | null {
  const query = useQuery<TechnicianNetworks[] | null>(
    ['users', userId, 'networks'],
    async () => {
      if (!userId) {
        return [];
      }

      return new Promise<TechnicianNetworks[]>((resolve) => {
        fetch(`/skill-discovery/v1/technicians/${userId}/networks`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'GET',
          credentials: 'same-origin',
        })
          .then((resp) => resp.json())
          .then((resp) => {
            resolve(resp as TechnicianNetworks[]);
          })
          .catch((_e) => {
            resolve([]);
          });
      });
    },
  );

  return query.status === 'success' ? query.data : null;
}

export default function useNetworks(): Network[] | null {
  const query = useQuery<Network[] | null>(
    ['networks', 'listing'],
    async () => new Promise<Network[]>((resolve) => {
      fetch('/skill-discovery/v1/networks', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'GET',
        credentials: 'same-origin',
      })
        .then((resp) => resp.json())
        .then((resp) => {
          resolve(
            (resp).map(
              ({ id, name }: { id: number; name: string }) => ({
                id,
                name,
              }),
            ),
          );
        })
        .catch((_e) => {
          resolve([]);
        });
    }),
  );

  return query.status === 'success' ? query.data : null;
}
