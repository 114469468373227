/* eslint-disable import/prefer-default-export */
import React from 'react';
import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst';
import get from 'lodash/get';
import { IJsObject } from '../types.d';

interface INotificationFlashAlert {
  level: 'info' | 'success' | 'warning' | 'error';
  title?: string;
  message: string;
  timer?: number;
  children?: string;
  dismissible?: 'both' | 'button'
}

function haveDuplicate(message: string, title: string): boolean {
  const notifications = get(
    window,
    '_notificationSystem.notificationSystem.state.notifications',
    [],
  );
  if (notifications.length) {
    return (
      notifications.some(
        (i: IJsObject) => i.title === title && i.message === message,
      ) || false
    );
  }
  return false;
}

export const NotificationFlashAlert = ({
  level,
  message,
  title = '',
  timer = 0,
  children = '',
  dismissible = 'both',
}: INotificationFlashAlert): void => {
  const notificationSystem = get(window, '_notificationSystem', {});
  if (notificationSystem && !haveDuplicate(message, title)) {
    notificationSystem.addNotification({
      level,
      message: !children ? (message || '').split('. ').map(upperFirst).join('. ') : '',
      title: title || capitalize(level),
      autoDismiss: timer,
      // eslint-disable-next-line react/no-danger
      children: children ? <span dangerouslySetInnerHTML={{ __html: children }} /> : '',
      position: 'br',
      dismissible,
    });
  }
};
