import { atom, selector } from 'recoil';
import { getJobHistory } from '../api';
import userQuery, { userIdQuery } from './user.query';
import contextQuery from './context.query';
import isManagedOrSelf from './is-managed-or-self';
import contractQuery from './contract.query';
import companyListQuery from './company-list.query';
import { Group, myCompanyOption } from '../Components/utils';

import { JobHistory, JobHistoryFilters } from '../types.d';

export const jobFilter = atom<JobHistoryFilters>({
  key: 'jobFilter',
  default: {},
});

const jobHistoryQuery = selector<JobHistory | null>({
  key: 'jobHistory',
  get: async ({ get }) => {
    const user = get(userQuery);
    const userId = get(userIdQuery);
    const context = get(contextQuery);

    if (!userId || !user || !context) {
      return null;
    }

    const contract = get(contractQuery);
    const filters = get(jobFilter);

    let companyList = [myCompanyOption[0]];
    if (context?.group?.id === Group?.Staff) {
      companyList = get(companyListQuery) || companyList;
      const comlist = Number(context?.company?.id) > 0
        ? companyList?.filter(({ value }) => Number(value) === context?.company?.id)
        : companyList;
      companyList = [myCompanyOption[0]].concat(comlist || []);
    }

    const hasContract = Boolean(contract?.curated_recruitment?.enabled);
    const managedOrSelf = isManagedOrSelf(user, context);
    const contextGroupId = context?.group?.id || 0;
    const contextUserId = context?.id || 0;
    const companyId = filters?.withCompanyId || context?.company?.id || 0;
    const jobHistory = await getJobHistory({
      userId,
      contextUserId,
      contextGroupId,
      companyId,
      managedOrSelf,
      filters: {
        ...filters,
        hasContract: hasContract || [3, 9].includes(contextGroupId),
      },
    });

    return {
      ...jobHistory,
      companyList,
    };
  },
});

export default jobHistoryQuery;
