/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { createElement, isValidElement } from 'react';
import { useRecoilValue } from 'recoil';
import { JSObject } from '../../types.d';
import { UiUserContext } from '../../widgets/common/UiUserContext.atom';
import Intercept from '../Intercept';
import { ContextGuardProps } from './types.d';

export const ContextGuard = ({ ...props }: ContextGuardProps): JSX.Element => {
  const { feature, groupId, experiment, fallBack, source } = props;
  const { features, group, experiments } =
    source || useRecoilValue<JSObject>(UiUserContext);

  const errorResponse = fallBack || <Intercept code="403" />;

  if (groupId) {
    // @ts-ignore
    const requiredGroupId = [groupId].flat();
    const exstingGroupId = group?.id || 0;
    if (!requiredGroupId.includes(exstingGroupId)) {
      return errorResponse;
    }
  }

  if (feature) {
    // @ts-ignore
    const requiredFeature = [feature].flat();
    const exstingFeatures = (features?.results || []).map(
      (i: JSObject) => i?.id,
    );
    if (!requiredFeature.every((id: number) => exstingFeatures.includes(id))) {
      return errorResponse;
    }
  }

  if (experiment) {
    // @ts-ignore
    const requiredExperiment = [experiment].flat();
    const exstingExperiment = (experiments?.results || []).map(
      (i: JSObject) => i?.id,
    );
    if (
      !requiredExperiment.every((id: number) => exstingExperiment.includes(id))
    ) {
      return errorResponse;
    }
  }

  const component = props.component || props.children || (
    <>Nothing do display</>
  );

  return isValidElement(component)
    ? component
    : createElement(component as any);
};
