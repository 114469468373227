import React from 'react';
import {
  IconAside, IconAsideIcon, Icon, FileInput,
} from '@fieldnation/platform-components';
import css from '../Styles.scss';

type ValueType = string | number;

interface FileUploadFieldProps {
  label: string;
  accept: string;
  helpText?: string;
  multiple?: boolean;
  files?: any[];
  onDrop: (file: any) => void;
  onDeleteFile: () => void;
  hasValidationError?: boolean;
  errorMessage?: ValueType;
  wrapperStyle?: any;
}

const FileUploadField = (props: FileUploadFieldProps) => {
  const {
    label,
    accept,
    helpText,
    multiple,
    files,
    onDrop,
    onDeleteFile,
    hasValidationError,
    errorMessage,
    wrapperStyle,
  } = props;

  return (
    <div style={wrapperStyle}>
      <div className={css.helpText} style={{ marginBottom: '0.6rem' }}>
        Upload the scan or PDF for verification
      </div>
      <div className={css.inputFields}>
        <FileInput
          // @ts-ignore
          buttonLabel={label}
          accept={accept}
          helpText={helpText}
          multiple={multiple}
          onDrop={onDrop}
          files={files}
          onDeleteFile={onDeleteFile}
        />
      </div>
      {hasValidationError && (
        <div style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
          <IconAside iconPosition="left" iconColor="red">
            <IconAsideIcon verticalAlign="middle">
              <Icon name="warningSolid" size={22} block />
            </IconAsideIcon>
            <div className={css.validationErrorText}>
              {errorMessage}
            </div>
          </IconAside>
        </div>
      )}
    </div>
  );
};

FileUploadField.defaultProps = {
  helpText: '',
  multiple: false,
  files: [],
  hasValidationError: false,
  errorMessage: '',
  wrapperStyle: {},
};

export default FileUploadField;
