import * as React from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';
import { Loader } from '@fieldnation/platform-components';
import WrapSection from './WrapSection';
import FeedbackList from '../../FeedbackList';
import trackAmplitudeEvent, { VIEWED_PRIVATE_FEEDBACK } from '../amplitude';

interface Props {
  feedbackData: any;
  onNext: () => any;
  onPrev: () => any;
  collapse?: boolean;
  loading: boolean;
  providerId: number
}

const newStyle = {
  backgroundColor: '#E0F2FF',
  border: '1px solid #9ED4FA',
  borderRadius: '3px',
  color: '#162741',
  fontSize: '14px',
  fontWeight: 400,
  padding: '0 5px 0 5px',
};

const Feedback = ({
  feedbackData, collapse, onNext, onPrev, loading, providerId,
}: Props): JSX.Element | null => {
  useEffect(() => {
    if (providerId && feedbackData?.metadata?.totalItems) {
      trackAmplitudeEvent({
        category: VIEWED_PRIVATE_FEEDBACK,
        ampProps: {
          Source: 'Drawer',
          Destination: 'Provider Drawer',
          'Provider ID': providerId,
          'Feedback Count': feedbackData?.metadata?.totalItems || 0,
        },
      });
    }
  }, [providerId, feedbackData?.metadata?.totalItems]);
  return (
    <WrapSection
      label="Private Feedback"
      value={feedbackData?.metadata?.totalItems}
      collapse={collapse}
      fullWidth
      subNode={<span style={newStyle}>NEW</span>}
    >
      <div className={classNames('u-margin-left--md', 'u-margin-right--md')}>
        <Loader isLoading={loading}>
          <FeedbackList
            feedback={feedbackData}
            onNext={() => onNext()}
            onPrev={() => onPrev()}
            isDrawer
          />
        </Loader>
      </div>
    </WrapSection>
  );
};

Feedback.defaultProps = {
  collapse: false,
};

export default Feedback;
