/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React from 'react';
import { atom, selectorFamily } from 'recoil';
import startCase from 'lodash/startCase';
import { JSObject, requestWrapper, RequestOptions } from './fetchAPI';

const tableWidth = {
  small: 100,
  mid: 200,
  large: 200,
};

const renderTableHeader = (data: any) => <div className="table-cell-header" style={{ fontSize: '1.6rem' }}>{data}</div>;
const renderTableCell = (data: any) => <div className="table-cell" style={{ fontSize: '1.4rem' }}>{data}</div>;

export const tableColumnsAtom = atom({
  key: 'tax-exemption-table-col-atom',
  default: [
    {
      id: 'state_name',
      key: 'state_name',
      header: renderTableHeader('State'),
      sortable: true,
      width: tableWidth.large,
      render: (row) => renderTableCell(row?.state_name || ''),
    },
    {
      id: 'status',
      key: 'status',
      header: renderTableHeader('Status'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.status || ''),
    },
    {
      id: 'cert_id',
      key: 'cert_id',
      header: renderTableHeader('Cert ID'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.cert_id || ''),
    },
    {
      id: 'cert_type',
      key: 'cert_type',
      header: renderTableHeader('Type'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.cert_type || ''),
    },
    {
      id: 'created',
      key: 'created',
      header: renderTableHeader('Upload Date'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.created || ''),
    },
    {
      id: 'effective_date',
      key: 'effective_date',
      header: renderTableHeader('Effective Date'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.effective_date || ''),
    },
    {
      id: 'expiration_date',
      key: 'expiration_date',
      header: renderTableHeader('Expiration Date'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.expiration_date || ''),
    },
    {
      id: 'rejection_date',
      key: 'rejection_date',
      header: renderTableHeader('Rejection Date'),
      sortable: true,
      width: tableWidth.mid,
      render: (row) => renderTableCell(row?.rejection_date || ''),
    },
  ],
});

export const tableSortAtom = atom({
  key: 'tax-exemption-table-sort-atom',
  default: null,
});

export const compareASC = (itemA, itemB) => {
  if (itemA < itemB) return -1;
  if (itemA > itemB) return 1;
  return 0;
};

export const compareDESC = (itemA, itemB) => {
  if (itemA > itemB) return -1;
  if (itemA < itemB) return 1;
  return 0;
};

const STATUS_UP_TO_DATE = 'Active';
const STATUS_EXPIRED = 'Expired';
const STATUS_NOT_FOUND = 'Missing';
const STATUS_REJECTED = 'Rejected';
const CERTIFICATE_NOT_FOUND = 'Certificate not found';

const emptyCellData = '—';

const getStatus = (certificate) => {
  if (
    certificate?.error &&
    certificate?.errorMessage === CERTIFICATE_NOT_FOUND
  ) {
    return STATUS_NOT_FOUND;
  }
  const currentDate = new Date();
  const expirationDate = new Date(certificate?.expirationDate);
  currentDate.setHours(0, 0, 0, 0);
  expirationDate.setHours(0, 0, 0, 0);

  if (!certificate?.valid && certificate?.id) {
    return STATUS_REJECTED;
  }
  if (!certificate?.id) {
    return STATUS_NOT_FOUND;
  }
  if (currentDate > expirationDate) {
    return STATUS_EXPIRED;
  }
  if (currentDate < expirationDate) {
    return STATUS_UP_TO_DATE;
  }
  return emptyCellData;
};

export interface PropsToOptions extends JSObject {
  rawData: boolean | JSObject[];
  dataURL?: string;
  dataURLOptions?: RequestOptions;
}

export const ExemptionsDataSelector = selectorFamily({
  key: 'ExemptionsDataSelector',
  get: (options: PropsToOptions) => async () => {
    try {
      if (options?.rawData && Array.isArray(options?.rawData)) {
        return options?.rawData;
      }

      const data = await requestWrapper(
        options?.dataURL || '',
        options?.dataURLOptions || {},
      );
      const required_data = (data || []).map((item) => ({
        cert_id: item?.id || emptyCellData,
        state_name: startCase((item?.state?.name || '').toLowerCase()),
        status: getStatus(item),
        created: (item?.createdDate || emptyCellData).split(' ')?.[0] || emptyCellData,
        effective_date: item?.signedDate || emptyCellData,
        expiration_date: item?.expirationDate || emptyCellData,
        rejection_date: !item?.valid
          ? (item?.modifiedDate || emptyCellData).split(' ')?.[0] || emptyCellData
          : emptyCellData,
        cert_type: startCase((item?.entity || '').includes('EXPOSURE') ? '' : (item?.entity || '').toLowerCase()) || emptyCellData,
      }));

      const respStatusUpToDate: any[] = [];
      const respStatusExpired: any[] = [];
      const respStatusRejected: any[] = [];
      const respStatusNotFound: any[] = [];

      required_data.forEach((cert: any) => {
        switch (cert.status) {
          case STATUS_UP_TO_DATE:
            respStatusUpToDate.push(cert);
            break;
          case STATUS_EXPIRED:
            respStatusExpired.push(cert);
            break;
          case STATUS_REJECTED:
            respStatusRejected.push(cert);
            break;
          case STATUS_NOT_FOUND:
            respStatusNotFound.push(cert);
            break;
          default:
            break;
        }
      });

      return [
        ...respStatusUpToDate,
        ...respStatusExpired,
        ...respStatusRejected,
        ...respStatusNotFound,
      ];
    } catch (error) {
      return null;
    }
  },
});
