import { atomFamily } from 'recoil';
import { UserGroup } from '../Commons/types.d';
import {
  ContentKey,
  HttpMethod,
  IContentValidation,
  IOnCreateConfig,
  ISection,
  ISectionContent,
  SectionCardKey,
  SectionCardMode,
  SectionKey,
  SectionMode,
} from '../Components/Section/types.d';

export const sectionContentsState = atomFamily<ISectionContent[], SectionKey>({
  key: 'sectionContentsState',
  default: [],
});

export const sectionIsLoading = atomFamily<boolean, SectionKey>({
  key: 'sectionIsLoading',
  default: false,
});

export const sectionHasErrorState = atomFamily<boolean, SectionKey>({
  key: 'sectionHasErrorState',
  default: false,
});

export const sectionFilesState = atomFamily<File[], SectionKey>({
  key: 'sectionFilesState',
  default: [],
});

export const sectionModeState = atomFamily<SectionMode, SectionKey>({
  key: 'sectionModeState',
  default: SectionMode.VIEW,
});

export const sectionCardSections = atomFamily<ISection[], SectionCardKey>({
  key: 'sectionCardSections',
  default: [],
});

export const sectionCardResourceId = atomFamily<string, SectionCardKey>({
  key: 'sectionCardResourceId',
  default: '',
});

export const sectionCardModeState = atomFamily<SectionCardMode, SectionCardKey>({
  key: 'sectionCardModeState',
  default: SectionCardMode.EMPTY,
});

export const sectionCardIsLoading = atomFamily<boolean, SectionCardKey>({
  key: 'sectionCardIsLoading',
  default: false,
});

export const sectionCardCreatePermissionGroup = atomFamily<UserGroup[], SectionCardKey>({
  key: 'sectionCardCreatePermissionGroup',
  default: [],
});

export const sectionCardCreateConfig = atomFamily<IOnCreateConfig, SectionCardKey>({
  key: 'sectionCardCreateConfig',
  default: {
    url: '',
    method: HttpMethod.POST,
  },
});

export const contentInputState = atomFamily<ISectionContent, ContentKey>({
  key: 'contentInputState',
  default: {},
});

export const contentValidationState = atomFamily<IContentValidation, ContentKey>({
  key: 'contentValidationState',
  default: {
    isValid: true,
  },
});
