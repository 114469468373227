import React from 'react';
import css from './ProviderCard.scss';

interface Props {}

const Recommended = ({
}: Props): JSX.Element => (
  <div>
    <div className={css.Card__Recommended__Line} />
    <div className={css.Card__Recommended__text}>
      Recommended section will go here
    </div>
  </div>
);

Recommended.defaultProps = {};

export default Recommended;
