/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { format, fromUnixTime } from 'date-fns';
import {
  Truncate,
  Status,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
  Stars,
  Icon,
} from '@fieldnation/platform-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Padded from './Padded';

import RatingComment from './RatingComment';
import CommentForm from './CommentForm';

import contextQuery from '../queries/context.query';
import { jobFilter } from '../queries/job-history.query';

import { JobHistoryFilters, JobHistoryItem } from '../types.d';

import css from './JobHistoryModal.scss';

interface Props extends Partial<JobHistoryItem> {
  children?: React.ReactNode | React.ReactNode[];
  // eslint-disable-next-line react/require-default-props
  filters?: JobHistoryFilters;
}

const JobHistoryTile = ({
  children,
  title,
  workOrderId,
  rating,
  typeOfWork,
  location,
  dateOfService,
  withCompany,
  comments,
  canComment,
  status,
  withPqap,
  filters = {},
}: Props): JSX.Element | null => {
  const [isCommenting, setIsCommenting] = useState(false);
  const [newComment, setNewComment] = useState<string | null>(null);
  const context = useRecoilValue(contextQuery);
  const setFilter = useSetRecoilState(jobFilter);

  if (!context) {
    return null;
  }

  const {
    thumbnail = '/images/missing.png',
    first_name: firstName,
    last_name: lastName,
  } = context;

  const applyFilter = async () => {
    const f = { ...filters, version: (filters?.version || 0) + 1 };
    // it will update recoil state and update the list
    await setFilter(f);
  };

  return (
    <div className={css['rating-tile']}>
      {!children && (
        <div>
          <Padded size="sm">
            <Median noWrap verticalAlign="top">
              <MedianAlpha>
                <strong>
                  <Truncate maxLines={2} wordBreak="break-word" text={title} />
                </strong>
                <span className="text-muted">{` ID #${workOrderId}`}</span>
                {withCompany && (
                  <div>
                    <a
                      href={`/workorders/${workOrderId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <small>Open in new window</small>
                    </a>
                  </div>
                )}
              </MedianAlpha>
              <MedianOmega>
                {rating && rating > 0 && (
                  <div style={{ whiteSpace: 'nowrap' }}>
                    <Stars
                      size="md"
                      rating={Math.ceil(rating)}
                      color={withCompany ? 'green' : 'yellow'}
                    />
                  </div>
                )}
                {withPqap && (
                  <div className={css['rating-pqap']}>
                    <Icon name="warningSolid" size={18} />
                    <small>At-fault event</small>
                  </div>
                )}
              </MedianOmega>
            </Median>
          </Padded>
          <div className={css['rating-icons']}>
            <div className={css['rating-icon']}>
              <Icon name="workHistory" size={18} />
            </div>
            <div className={css['rating-title']}>{typeOfWork}</div>
            <div className={css['rating-icon']}>
              <Icon name="location" size={18} />
            </div>
            <div className={css['rating-title']}>{`${location}`}</div>
            <div className={css['rating-icon']}>
              <Icon name="dateRange" size={18} />
            </div>
            {dateOfService && (
              <div className={css['rating-title']}>
                {format(fromUnixTime(dateOfService), 'MMM d, yyyy')}
              </div>
            )}
            {status && (
              <div className={css['rating-status']}>
                <Status
                  title={status}
                  type={(() => {
                    switch (status) {
                      case 'Canceled':
                        return 'danger';

                      case 'Assigned':
                        return 'info';

                      case 'Work Done':
                      case 'Approved':
                      case 'Paid':
                        return 'success';

                      default:
                        return 'default';
                    }
                  })()}
                  size="sm"
                >
                  {status}
                </Status>
              </div>
            )}
          </div>
          {(comments || [])
            .map((c) => (
              <RatingComment
                key={(c.id || 0) + Math.random()}
                onApprovedOrFlaged={applyFilter}
                workOrderId={workOrderId || 0}
                {...c}
              />
            ))}
          {newComment !== null && (
            <RatingComment
              onApprovedOrFlaged={applyFilter}
              workOrderId={workOrderId || 0}
              photo={thumbnail}
              id={-1}
              name={`${firstName} ${lastName}`}
              message={newComment}
              createdAt={new Date().toDateString()}
            />
          )}
          {isCommenting && (
            <div className={classNames(css['comment-container'], 'u-padding--md', 'u-margin-top--sm')}>
              <CommentForm
                workOrderId={workOrderId || 0}
                onClose={() => setIsCommenting(false)}
                onSave={async (msg) => {
                  setNewComment(msg);
                  await applyFilter();
                }}
              />
            </div>
          )}
          {!isCommenting && newComment === null && canComment && (
            <Padded position="top" size="sm">
              <Link
                onClick={() => {
                  setIsCommenting(true);
                }}
              >
                Add reply
              </Link>
            </Padded>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

JobHistoryTile.defaultProps = {
  children: undefined,
};

export default JobHistoryTile;
