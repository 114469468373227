/* eslint-disable implicit-arrow-linebreak */
import template from 'lodash/template';
import { selector, selectorFamily } from 'recoil';
import { Options } from '@fieldnation/platform-components/src/v2/Select/types.d';

import { getInputFieldAtom, openForEditAtom, userInfoAtom } from '.';
import {
  ProfileInfoItems,
  RequestTypes,
  ROUTE_MAP,
  UserGroups,
} from '../../../constants';
import { IJsObject } from '../../../types.d';
import { AddressVerificationStatus } from '../../../../AddressVerifiedChip/types.d';

export const userContextSelector = selector({
  key: 'userContextSelector',
  get: () => {
    const {
      id: originalUserId,
      group: { id: originalGroupId = 0 } = {},
      features: { results: featureResult = [] } = {},
      permissions = [],
    } = window?.context || {};

    const isStaff = [UserGroups.ACCOUNTANT, UserGroups.STAFF].includes(
      Number(originalGroupId),
    );

    const isProvider = originalGroupId === 1;

    const isServiceCompany = featureResult?.some(({ id }) => id === 33);

    const isBuyer = featureResult?.some(({ id }) => id === 4);

    return {
      originalUserId,
      originalGroupId,
      isStaff,
      isProvider,
      isServiceCompany,
      isBuyer,
      permissions,
    };
  },
});

export const canEditPermissionSelector = selectorFamily({
  key: 'canEditPermissionSelector',
  get: (itemName: ProfileInfoItems | string) => ({ get }) => {
    const { isStaff, isServiceCompany, isBuyer } = get(userContextSelector);
    let canEdit = isStaff;

    switch (itemName) {
      case ProfileInfoItems.Username:
        break;
      case ProfileInfoItems.FullName:
        canEdit = isStaff || (isBuyer && !isServiceCompany);
        break;
      case ProfileInfoItems.Address:
      case ProfileInfoItems.Email:
      case ProfileInfoItems.CellNumber:
      case ProfileInfoItems.PhoneNumber:
        canEdit = true;
        break;
      default:
        break;
    }

    return canEdit;
  },
});

export const isEditingSelector = selectorFamily({
  key: 'isEditingSelector',
  get: (itemName: ProfileInfoItems) => ({ get }) =>
    get(openForEditAtom) === itemName,
});

export const requestConfigSelector = selectorFamily({
  key: 'requestConfigSelector',
  get: (itemName: RequestTypes) => ({ get }) => {
    const { id: user_id } = get(userInfoAtom);
    const itemRequestConfig = ROUTE_MAP[itemName];
    itemRequestConfig.url = template(itemRequestConfig.url)({ user_id });
    let data: IJsObject = {};
    let params: IJsObject = {};

    const getAtomValue = (name: string) => {
      const field = get(getInputFieldAtom(name));
      return typeof field === 'string' ? field : field?.value;
    };

    const address = {
      address1: getAtomValue('address1'),
      address2: getAtomValue('address2'),
      city: getAtomValue('city'),
      zip: getAtomValue('zip'),
      state: getAtomValue('state'),
      country: getAtomValue('country'),
    };

    switch (itemName) {
      case RequestTypes.UPDATE_USERNAME:
        data = {
          username: getAtomValue('username'),
        };
        break;
      case RequestTypes.UPDATE_FULLNAME:
        data = {
          first_name: getAtomValue('first_name')?.trim(),
          last_name: getAtomValue('last_name')?.trim(),
          middle_initial: getAtomValue('middle_initial')?.trim(),
          suffix: getAtomValue('suffix')?.trim(),
        };
        break;
      case RequestTypes.UPDATE_ADDRESS:
        data = {
          ...address,
        };
        break;
      case RequestTypes.RESOLVE_ADDRESS:
        data = {
          ...address,
        };
        break;
      case RequestTypes.FORCE_UPDATE_ADDRESS:
        data = {
          ...address,
        };
        params = {
          force: true,
        };
        break;
      case RequestTypes.UPDATE_EMAIL:
        data = {
          email: getAtomValue('email'),
          secondary_email: getAtomValue('secondary_email'),
        };
        break;
      case RequestTypes.UPDATE_CELLNUMBER:
        data = {
          cell: getAtomValue('cell'),
          cell_provider: getAtomValue('cell_provider'),
        };
        break;
      case RequestTypes.UPDATE_PHONENUMBER:
        data = {
          phone: getAtomValue('phone'),
          phone_ext: getAtomValue('phone_ext'),
        };
        break;
      default:
        break;
    }

    itemRequestConfig.data = data;
    itemRequestConfig.params = params;
    return itemRequestConfig;
  },
});

export const cellProviderListSelector = selector({
  key: 'cellProviderListSelector',
  get: (): Options[] => [
    { label: 'ATT', value: 1 },
    { label: 'Cingular', value: 2 },
    { label: 'Nextel', value: 3 },
    { label: 'Sprint', value: 4 },
    { label: 'T-Mobile', value: 5 },
    { label: 'Verizon', value: 6 },
    { label: 'US Cellular', value: 7 },
    { label: 'Alltel', value: 8 },
    { label: 'Virgin Mobile', value: 9 },
    { label: 'Qwest', value: 10 },
    { label: 'Boost Mobile', value: 11 },
    { label: 'MetroPCS', value: 12 },
    { label: 'Cricket', value: 13 },
    { label: 'Cellular South', value: 14 },
    { label: 'Ntelos', value: 15 },
    { label: 'Cincinnati Bell Wireless', value: 16 },
    { label: 'Southern LINC', value: 17 },
    { label: 'Inland Cellular', value: 18 },
    { label: 'Koodo Mobile', value: 19 },
    { label: 'Fido', value: 20 },
    { label: 'Bell Mobility/Solo Mobile', value: 21 },
    { label: 'Aliant', value: 22 },
    { label: 'PC Telecom', value: 23 },
    { label: 'SaskTel', value: 24 },
    { label: 'MTS Mobility', value: 25 },
    { label: 'Virgin Mobile (Canada)', value: 26 },
    { label: 'Wind Mobile', value: 27 },
    { label: 'Rogers Wireless', value: 28 },
    { label: 'Telus Mobility', value: 29 },
    { label: 'IWireless', value: 30 },
    { label: 'Simple Mobile', value: 31 },
    { label: 'Straight Talk', value: 32 },
    { label: 'Cellcom', value: 33 },
    { label: 'Republic Wireless', value: 34 },
    { label: 'Project Fi', value: 35 },
    { label: 'Xfinity Mobile', value: 36 },
    { label: 'Lycamobile', value: 37 },
  ],
});

export const addressVerificationSelector = selector({
  key: 'addressVerificationSelector',
  get: ({ get }) => {
    const { address_verification_status: addressVerificationStatus } = get(
      userInfoAtom,
    );
    return addressVerificationStatus === AddressVerificationStatus.VERIFIED;
  },
});
