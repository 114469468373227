/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import {
  v2,
  Loader,
  MedianOmega,
  Median,
  MedianAlpha,
  // @ts-ignore
  GaugeBar,
  // @ts-ignore
  currencyFormatter,
} from '@fieldnation/platform-components';
import css from './SubscriptionCard.scss';
import { CurrentContractSelector } from '../common/GetContract.selector';
import { ContractAnnualSpendSelector } from '../common/GetCompanySpend.selector';
import { getContractDateLabel } from '../common/utils';
import { EVENTS, ITrackEvent, useAmplitudeTracker } from '../../../Amplitude/useAmplitudeTracker';

const { Tile, TileDivision } = v2;

const OVERAGE_LABEL = 'WO over Sub';

enum SubscriptionContractEventData {
  AMPLITUDE_EVENT_PROP_DESTINATION = 'Subscription Contract Details',
  AMPLITUDE_EVENT_PROP_SOURCE = 'Package Overview or Intercom',
}

const trackPackageUsageNavigation = (amplitudeTracker: ITrackEvent) => {
  amplitudeTracker(EVENTS.PACKAGE_USAGE_NAVIGATED, {
    Source: SubscriptionContractEventData.AMPLITUDE_EVENT_PROP_SOURCE,
    Destination: SubscriptionContractEventData.AMPLITUDE_EVENT_PROP_DESTINATION,
  });
};

const SubscriptionCard = (): JSX.Element => {
  const contract = useRecoilValue(CurrentContractSelector);

  const companySpend = useRecoilValue(ContractAnnualSpendSelector);
  const amplitudeTracker = useAmplitudeTracker();

  const { marketplace_buyer } = contract;
  const totalAmount = marketplace_buyer?.work_order_fee?.amounts?.[0]?.max || 0;
  const spentAmount = companySpend?.amount || 0;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  const title = 'Subscription Contract';

  const subHeading = (
    <div className={classNames(css['SubscriptionCard--subHeading'])}>
      <Median verticalAlign="middle" noWrap>
        <MedianOmega alignRight>
          <a onClick={() => trackPackageUsageNavigation(amplitudeTracker)} href="/company/package-usage">View Details</a>
          <br />
          Total Amount
          <span>
            &nbsp;
            {currencyFormatter(totalAmount, { forceDecimal: true })}
          </span>
        </MedianOmega>
      </Median>
    </div>
  );

  return (
    <div className={classNames(css['SubscriptionCard--main'])}>
      <Suspense fallback={<Loader fixed isLoading />}>
        <Tile>
          {/* @ts-expect-error */}
          <TileDivision heading={title} subHeading={subHeading}>
            <div
              className={classNames(
                css['SubscriptionCard--gaugeBar_container'],
              )}
            >
              {/* @ts-ignore */}
              <GaugeBar totalAmount={totalAmount} spentAmount={spentAmount} overageLabel={OVERAGE_LABEL} />
            </div>
            <div
              className={classNames(css['SubscriptionCard--footer_container'])}
            >
              <Median verticalAlign="middle" noWrap>
                <MedianAlpha>
                  Period
                  <br />
                  <span>
                    {getContractDateLabel(
                      contract?.metadata?.startsAt,
                      timezone,
                    )}
                    &mdash;
                    {getContractDateLabel(contract?.metadata?.endsAt, timezone)}
                  </span>
                </MedianAlpha>
                <MedianOmega alignRight>
                  Total Spent
                  <br />
                  <span>
                    {currencyFormatter(spentAmount, { forceDecimal: true })}
                  </span>
                </MedianOmega>
              </Median>
            </div>
          </TileDivision>
        </Tile>
      </Suspense>
    </div>
  );
};

export default SubscriptionCard;
