import * as React from 'react';
import * as PropTypes from 'prop-types';
import { GroupCardListItem } from '@fieldnation/platform-components';
import css from './style.scss';

interface Props {
  label: string;
  value: string;
}

const WrapListItem: React.FC<Props> = ({ label, value }: Props) => (
  <div key={label} className={css.DrawerListItem}>
    <GroupCardListItem
      hideExpander
      dottedLine
      middleContent=""
      label={label}
      data={value}
      type="default"
      border="none"
    />
  </div>
);

WrapListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default WrapListItem;
