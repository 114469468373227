import requestWrapper from '../requestWrapper';
import { CredentialInputData } from './types';

export const credentialApiPath = '/credentials';
export async function getCredentials(
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/credentials?f_status=active&page=1&per_page=5000`, {
    ...options,
    method: 'GET',
  });
}

export async function getCredentialDetailsById(
  id: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/credentials/${id}`, {
    ...options,
    method: 'GET',
  });
}

export async function getVerificationByOrgId(
  orgId: string,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/verifications/${orgId}`, {
    ...options,
    method: 'GET',
  });
}

export async function getUserCredentialDetailsById(
  userCredentialId: number,
  userId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/user/${userId}/userCredential/${userCredentialId}`, {
    ...options,
    method: 'GET',
  });
}

export async function saveCredentials(
  credentialInputs: CredentialInputData,
  userId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/user/${userId}/userCredential/${credentialInputs.credentialId}`, {
    ...options,
    method: 'POST',
    body: credentialInputs,
  });
}

export async function updateCredentials(
  lcInputs: CredentialInputData,
  userId: number,
  userCredentialId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/user/${userId}/userCredential/${userCredentialId}`, {
    ...options,
    method: 'PUT',
    body: lcInputs,
  });
}

export async function updateCredentialsByManualVerification(
  lcInputs: CredentialInputData,
  userId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`${credentialApiPath}/user/${userId}/userCredential/${lcInputs.credentialId}/verify`, {
    ...options,
    method: 'PUT',
    body: lcInputs,
  });
}


export async function uploadLcFile(
  file: any,
  userId: number,
): Promise<any> {
  const url = `${credentialApiPath}/file/file-upload`;
  return fetch(url, {
    method: 'POST',
    body: file,
    headers: {
      'x-user-id': userId.toString(),
    },
  });
}

