import React from 'react';
import { RecoilRoot } from 'recoil';
import SectionCard from '../SectionCard';
import CompanyContactsConfig from './config';

const CompanyContacts: React.FC = (): JSX.Element => (
  <RecoilRoot>
    {CompanyContactsConfig.map((config) => (
      <SectionCard key={config.key} sectionCardConfig={config} />
    ))}
  </RecoilRoot>
);

export default CompanyContacts;
