import { useQuery } from 'react-query';
import requestWrapper from '../../requestWrapper';
import useWorkOrderId from './use-work-order-id';

import { SkillsResponse } from '../types.d';

export default function useTechnicianSkills(
  userId?: number,
  defaultWorkOrderId?: number,
): SkillsResponse | null {
  const workOrderId = useWorkOrderId(defaultWorkOrderId);

  if (!userId) {
    return null;
  }

  const skills = useQuery<SkillsResponse | null>(
    ['users', userId, 'skills', workOrderId],
    async () => {
      const skillResp = await requestWrapper(
        `/skill-discovery/v1/technicians/${userId}/skills${
          workOrderId ? `/${workOrderId}` : ''
        }`,
        {
          method: 'GET',
        },
      );

      return skillResp as SkillsResponse;
    },
  );

  return skills.status === 'success' ? skills.data : null;
}
