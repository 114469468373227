/* eslint-disable import/prefer-default-export */
import React from 'react';
import { BodyText } from '@fieldnation/platform-components';

import css from './styles.scss';

export const OptionalText = (): JSX.Element => (
  <BodyText styleLevel="md" tag="span">
    <span className={css['optional-text']}> - Optional</span>
  </BodyText>
);
