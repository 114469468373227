/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { IJsObject } from '../types.d';

export function useCountryAndState(
  countryName: string,
  stateName: string,
): { country: IJsObject; state: IJsObject } {
  return useMemo(() => {
    const country = (window?._COUNTRIES || []).find(
      ({ iso }) => iso === countryName,
    ) || {
      name: countryName,
    };

    const state = (country?.state?.values || []).find(
      ({ value }) => value === stateName,
    ) || {
      label: stateName,
    };

    return { country, state };
  }, [countryName, stateName]);
}
