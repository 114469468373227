import { Button } from '@fieldnation/platform-components';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { onCloseSectionCard, onCreateSectionCard } from '../../States/Callbacks';
import { isSectionCardOnSaveDisabled } from '../../States/SelectorFamilies';
import Attribute from '../Attribute';
import { SectionCardKey } from '../Section/types.d';
import { IProps } from './types.d';

const CreateActionBar: React.FC<IProps> = ({
  cardKey, windowRef, sectionCardDisplayName,
}: IProps): JSX.Element => {
  const onCreateClose = useRecoilCallback(onCloseSectionCard);
  const isOnSaveDisabled = useRecoilValue(isSectionCardOnSaveDisabled(cardKey as SectionCardKey));
  const onSave = useRecoilCallback(onCreateSectionCard);
  return (
    <Attribute
      key={`attribute_action_${cardKey}`}
      label=""
      content={(
        <div>
          <Button
            label="Save"
            type="primary"
            size="lg"
            onClick={() => onSave(cardKey as SectionCardKey, windowRef, sectionCardDisplayName)}
            disabled={isOnSaveDisabled}
          />
          &nbsp;&nbsp;
          <Button
            label="Close"
            type="text"
            size="lg"
            onClick={() => onCreateClose(cardKey)}
          />
        </div>
      )}
    />
  );
};

export default CreateActionBar;
