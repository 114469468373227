/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React, { ReactNode, useMemo } from 'react';
import {
  Icon,
  IconAside,
  IconAsideIcon,
} from '@fieldnation/platform-components';

import { IValidationError } from '../../widgets/PersonalInfo/state/types.d';

import css from './styles.scss';

export const InvalidInputHelpMessage = ({
  validationError,
  message,
}: {
  validationError?: IValidationError;
  message?: ReactNode;
}): JSX.Element =>
  useMemo(() => {
    const { isValid, errorMessage } = validationError || {};
    const content = isValid === false && errorMessage ? errorMessage : message;
    if (content) {
      return (
        <div className={css['invalid-help-message-container']}>
          <IconAside iconPosition="left" iconColor="red">
            <IconAsideIcon verticalAlign="middle">
              <Icon name="warningSolid" size={22} block />
            </IconAsideIcon>
            <div className={css['invalid-help-message']}>{content}</div>
          </IconAside>
        </div>
      );
    }
    return <></>;
  }, [validationError, message]);
