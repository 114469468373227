/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import template from 'lodash/template';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Grid,
  GridColumn,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
  v2,
} from '@fieldnation/platform-components';

import css from '../styles.scss';
import { useEditPermission } from '../../../hooks';
import {
  ProfileInfoItems,
  RequestTypes,
  ROUTE_MAP,
  UserPermissions,
  VALIDATION_DEBOUNCE_TIME,
} from '../../../constants';
import {
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
  InvalidInputHelpMessage,
  InfoBox,
} from '../../../components';
import {
  getInputFieldAtom,
  getInputValidationFieldAtom,
  getInputValidatorCallback,
  getRequestConfigCallback,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  passwordProtectedRequestAtom,
  resetFormCallback,
  resetValidationErrorsCallback,
  userContextSelector,
  userInfoAtom,
} from '../state';
import { IEmailVerificationStatus } from './types.d';

const { TextInput } = v2;

const EmailVerificationStatus = ({
  userId,
  disabled,
  isVerified,
  emailField,
  currentEmail,
  previousEmail,
}: IEmailVerificationStatus): JSX.Element => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const handleOnClick = async (action: 'verify' | 'unverify') => {
    let requestConfig: AxiosRequestConfig = {};
    let successMessage = '';

    if (action === 'verify') {
      requestConfig = ROUTE_MAP.VerifyEmail;
      requestConfig.url = template(requestConfig.url)({ user_id: userId });
      requestConfig.data = { email: currentEmail, email_field: emailField };
      successMessage = `Resent verification email to ${currentEmail}`;
    } else {
      requestConfig = ROUTE_MAP.UnverifyEmail;
      requestConfig.url = template(requestConfig.url)({ user_id: userId });
      requestConfig.data = { emails: [currentEmail], user_id: userId };
      successMessage = `${currentEmail} has been unverified`;
    }
    await makeRequest(requestConfig, successMessage, true);
  };

  if (disabled) {
    return <></>;
  }

  if (previousEmail && currentEmail === previousEmail) {
    return (
      <span className={css['email-verification-status']}>
        This email is
        {isVerified ? ' verified.' : ' unverified.'}
        {isVerified && isStaff && (
          <Link onClick={() => handleOnClick('unverify')}>
            &nbsp;Click here to Unverify.
          </Link>
        )}
        {!isVerified && (
          <Link onClick={() => handleOnClick('verify')}>
            &nbsp;Resend verification email.
          </Link>
        )}
      </span>
    );
  }

  return <></>;
};

const EmailEditFrom = () => {
  const { isStaff, permissions } = useRecoilValue(userContextSelector);
  const isAllowedToEditEmails = !isStaff || (isStaff && permissions
    .includes(UserPermissions.EDIT_OTHERS_EMAIL_ADDRESSES));
  const {
    id: userId,
    email,
    email_verification,
    secondary_email,
    secondary_email_verification,
  } = useRecoilValue(userInfoAtom);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);
  const isEditing = useRecoilValue(isEditingSelector(ProfileInfoItems.Email));

  const atoms = ['email', 'secondary_email'];

  const [
    [currentPrimaryEmail, setCurrentPrimaryEmail],
    [currentSecondaryEmail, setCurrentSecondaryEmail],
  ] = atoms.map((atomName) => useRecoilState(getInputFieldAtom(atomName)));

  const [primaryEmailError, secondaryEmailError] = atoms.map((atomName) =>
    useRecoilValue(getInputValidationFieldAtom(atomName)),
  );
  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);

  const onSave = async () => {
    const requestConfig = await getRequestConfig(RequestTypes.UPDATE_EMAIL);

    const updateCallback = async () => {
      const success = await makeRequest(
        requestConfig,
        'Successfully updated email address',
      );
      if (success) await resetForm(atoms);
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)(...atoms);

  const inputFieldValidator = useRecoilCallback(getInputValidatorCallback);
  const resetInputErrors = useRecoilCallback(resetValidationErrorsCallback);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'email';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentPrimaryEmail, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'secondary_email';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentSecondaryEmail, isEditing]);

  return (
    <div>
      {!isEditing && (
        <Grid>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <BodyText styleLevel="md" tag="span">
              <span
                className={classNames({
                  [css['list-item-content']]: true,
                  [css['list-item-content-empty']]: !currentPrimaryEmail,
                })}
              >
                {currentPrimaryEmail || 'No primary email'}
              </span>
            </BodyText>
          </GridColumn>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <BodyText styleLevel="md" tag="span">
              <span
                className={classNames({
                  [css['list-item-content']]: true,
                  [css['list-item-content-empty']]: !currentSecondaryEmail,
                })}
              >
                {currentSecondaryEmail || 'No secondary email'}
              </span>
            </BodyText>
          </GridColumn>
        </Grid>
      )}
      {isEditing && (
        <div>
          <Grid vSpace={false}>
            <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
              <Grid>
                <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                  <TextInput
                    label="Primary Email"
                    value={currentPrimaryEmail}
                    type="email"
                    onChange={(e) => {
                      setCurrentPrimaryEmail(e.target.value);
                    }}
                  />
                  <InvalidInputHelpMessage
                    validationError={primaryEmailError}
                  />
                  <EmailVerificationStatus
                    userId={userId}
                    disabled={!primaryEmailError?.isValid}
                    emailField="primary"
                    isVerified={email_verification}
                    previousEmail={email}
                    currentEmail={currentPrimaryEmail}
                  />
                </GridColumn>
                <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                  <TextInput
                    label="Secondary Email"
                    optional
                    value={currentSecondaryEmail}
                    type="email"
                    onChange={(e) => {
                      setCurrentSecondaryEmail(e.target.value);
                    }}
                  />
                  <InvalidInputHelpMessage
                    validationError={secondaryEmailError}
                  />
                  <EmailVerificationStatus
                    userId={userId}
                    disabled={!secondaryEmailError?.isValid}
                    emailField="secondary"
                    isVerified={secondary_email_verification}
                    previousEmail={secondary_email}
                    currentEmail={currentSecondaryEmail}
                  />
                </GridColumn>
              </Grid>
              {!isAllowedToEditEmails && (
                <InfoBox
                  message="You do not have the permissions to change user emails."
                  icon={{ name: 'warningHollow' }}
                />
              )}
              <ProfileInfoFormActionButton
                onClose={onClose}
                onSave={onSave}
                isValid={
                  primaryEmailError.isValid && secondaryEmailError.isValid && isAllowedToEditEmails
                }
              />
            </GridColumn>
          </Grid>
        </div>
      )}
    </div>
  );
};

export const Email = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.Email);
  const isEditing = useRecoilValue(isEditingSelector(ProfileInfoItems.Email));
  const setOpenForEdit = useSetRecoilState(openForEditAtom);

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="div">
        <span className={css['list-item-name']}>Email Address</span>
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median noWrap verticalAlign="top">
        <MedianAlpha>
          <EmailEditFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="emails" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.Email);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
