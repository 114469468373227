import { selector } from 'recoil';
import { Company, CertCaptureCustomerAtom } from './CertCaptureToken.selector';

export const TaxableStatesSelector = selector({
  key: 'TaxableStatesSelector',
  get: async ({ get }) => {
    const customer = get<Company | null>(CertCaptureCustomerAtom);
    if (!customer?.id) {
      return [];
    }
    try {
      let response = await fetch('/micro/calculate-pay/taxable-states', { method: 'GET' });
      const taxableStates = response.ok ? await response.json() : [];
      return taxableStates;
    } catch (err) {
      console.log('Taxable states fetching error', err);
    }
    return [];
  }
});
