import React from 'react';
import Recommended from './Recommended';
import Header from './Header';
import Pill from './Pill';
import JobHistory from './JobHistory';
import CounterOffer from './CounterOffer';
import Footer from './Footer';
import css from './ProviderCard.scss';

interface Props {}

const ProviderCard = ({
}: Props): JSX.Element => (
  <div className={css.Card__Outer}>
    <div className={css.Card__Container}>
      <div  className={css.Card__Recommended}>
        <Recommended />
      </div>
      <div className={css.Card__Header}>
        <Header />
      </div>
      <div className={css.Card__section}>
        <Pill />
      </div>
      <div className={css.Card__section}>
        <JobHistory />
      </div>
      <div className={css.Card__section}>
        <CounterOffer />
      </div>
      <div className={css.Card__Footer}>
        <Footer />
      </div>
    </div>
  </div>
);

ProviderCard.defaultProps = {};

export default ProviderCard;
