export const DIRECTIONS = [
  { label: 'Shipping to work order location', value: 'to site' },
  { label: 'Shipping to provider', value: 'to provider' },
  { label: 'Shipping to other location', value: 'to other location' },
  { label: 'Ship from site or return to carrier location', value: 'from site' },
];

export const DIRECTIONS_FOR_PROVIDERS = [
  { label: 'Shipping to work order location', value: 'to site' },
  { label: 'Ship from site or return to carrier location', value: 'from site' },
];

export const DIRECTIONS_WITHOUT_OTHER_LOCATION = [
  { label: 'Shipping to work order location', value: 'to site' },
  { label: 'Shipping to provider', value: 'to provider' },
  { label: 'Ship from site or return to carrier location', value: 'from site' },
];

export const CARRIERS = [
  {
    value: 'ups',
    label: 'UPS',
    url: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums={trackingNumber}',
    regs: [
      /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/,
    ],
  },
  {
    value: 'fedex',
    label: 'Fedex',
    url: 'https://www.fedex.com/apps/fedextrack/?tracknumbers={trackingNumber}',
    regs: [
      /(\b96\d{20}\b)|(\b\d{15}\b)|(\b\d{12}\b)/,
      /\b((98\d\d\d\d\d?\d\d\d\d|98\d\d) ?\d\d\d\d ?\d\d\d\d( ?\d\d\d)?)\b/,
      /^[0-9]{15}$/,
    ],
  },
  {
    value: 'usps',
    label: 'USPS',
    url: 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1={trackingNumber}',
    regs: [
      /(\b\d{30}\b)|(\b91\d+\b)|(\b\d{20}\b)/,
      /^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/,
      /^91[0-9]+$/,
      /^[A-Za-z]{2}[0-9]+US$/,
    ],
  },
  {
    value: 'other',
    label: 'Other',
  },
];
