import { Median, MedianAlpha, MedianOmega } from '@fieldnation/platform-components';
import groupBy from 'lodash/groupBy';
import React, { useMemo } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { UserGroup, Country } from '../../Commons/types.d';
import { sectionContentsState } from '../../States/AtomFamilies';
import { onChangeMode } from '../../States/Callbacks';
import userPermissionState from '../../States/Selectors';
import AdminText from '../AdminText';
import EditButton from '../EditButton';
import EmptyContent from '../EmptyContent';
import ImageContent from '../ImageContent';
import { ContentKey, ContentType } from '../Section/types.d';
import SupportText from '../SupportText';
import TextContent from '../TextContent';
import { IProps } from './types.d';

const ViewSectionContent: React.FC<IProps> = ({
  label,
  cardKey,
  sectionKey,
  contentConfigs,
  editPermissionGroup,
}: IProps): JSX.Element => {
  const contentConfigRows = Object.values(
    groupBy(contentConfigs, (config) => config?.displayRow),
  );
  const contents = useRecoilValue(
    sectionContentsState(sectionKey),
  );
  const { userGroup } = useRecoilValue(userPermissionState);
  const isEditEnabled = editPermissionGroup?.includes(userGroup as UserGroup);
  const setMode = useRecoilCallback(onChangeMode);

  const disabledContent = useMemo(() => {
    if (userGroup === UserGroup.ADMIN) {
      return <SupportText label={label} />;
    }
    return <AdminText label={label} />;
  }, [userGroup]);

  return (
    <div>
      <Median verticalAlign="top">
        <MedianAlpha>
          {(!contents.length || !contents.map((c) => c.value).join('').trim()) && (
            <EmptyContent label={label} />
          )}
          {contentConfigRows.map((row) => {
            const rowBodyContents: Array<string> = [];
            row.forEach((config) => {
              let rowBody = contents.find(
                (content) => content?.key === config?.key,
              )?.value || '';
              rowBody = (rowBody === 'null' || rowBody === 'undefined') ? '' : rowBody;
              rowBody = (config?.displayPrefix && rowBody) ? config?.displayPrefix?.concat(` ${rowBody}`) : rowBody;
              rowBodyContents.push(rowBody);
            });
            let textRowBodyContents = rowBodyContents.join(' ');

            const isRowCountry = row[0]?.key === ContentKey.COMPANY_OVERVIEW_COUNTRY;
            if (isRowCountry) {
              textRowBodyContents =
                Country[textRowBodyContents.trim()] || textRowBodyContents;
            }

            const rowContentType = row[0]?.contentType;
            return rowContentType === ContentType.IMAGE ? (
              <ImageContent
                key={row[0].key}
                href={
                  contents.find((content) => content?.key === row[0]?.key)?.value || ''
                }
                alt={row[0]?.displayName}
                version={contents.find((content) => content?.key === row[0]?.key)?.version || 0}
              />
            ) : (
              <TextContent key={row[0].key} content={textRowBodyContents} />
            );
          })}
        </MedianAlpha>
        <MedianOmega>
          <EditButton
            isDisabled={!isEditEnabled}
            disabledContent={disabledContent}
            onClick={() => setMode(sectionKey, cardKey)}
          />
        </MedianOmega>
      </Median>
    </div>
  );
};

export default ViewSectionContent;
