import React from 'react';
import {
  v2, IconAside, IconAsideIcon, Icon,
} from '@fieldnation/platform-components';
import { CredentialSelect, CustomSelectOptionsProps } from '../types';
import css from '../Styles.scss';

const { Select } = v2;

type ValueType = string | number;

interface SubOptions {
  label?: string;
  value?: string | number;
}

interface Options {
  value?: ValueType;
  label?: string;
  type?: ValueType;
  options?: SubOptions[];
}

interface SelectDropdownProps {
  label: string;
  value: string | CredentialSelect;
  options: Options[];
  disabled?: boolean;
  onChange: (value: Options) => void;
  optionComponent?: React.FC<CustomSelectOptionsProps>;
  hasValidationError?: boolean;
  errorMessage?: ValueType;
  wrapperStyle?: any;
}

const SelectDropdown = (props: SelectDropdownProps) => {
  const {
    label,
    value,
    options,
    disabled,
    onChange,
    optionComponent,
    hasValidationError,
    errorMessage,
    wrapperStyle,
  } = props;

  return (
    <div className={css.inputFields} style={wrapperStyle}>
      <Select
        label={label}
        value={value}
        options={options}
        disabled={disabled}
        onChange={onChange}
        // @ts-ignore
        optionComponent={optionComponent}
      />
      {!!hasValidationError && (
        <IconAside iconPosition="left" iconColor="red">
          <IconAsideIcon verticalAlign="middle">
            <Icon name="warningSolid" size={22} block />
          </IconAsideIcon>
          <div className={css.validationErrorText}>
            {errorMessage}
          </div>
        </IconAside>
      )}
    </div>
  );
};

SelectDropdown.defaultProps = {
  disabled: false,
  optionComponent: undefined,
  hasValidationError: false,
  errorMessage: '',
  wrapperStyle: {},
};

export default SelectDropdown;
