import { BodyText } from '@fieldnation/platform-components';
import React from 'react';
import css from './styles.scss';
import { IProps } from './types.d';

const SectionDescription: React.FC<IProps> = ({
  description,
}: IProps): JSX.Element => (
  <BodyText styleLevel="md" tag="span">
    <span className={css['section--description']}>{description}</span>
  </BodyText>
);

export default SectionDescription;
