/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Status, InfoIcon,
} from '@fieldnation/platform-components';
import Resolution from './Resolution';
import '../Styles.scss';

interface IProps {
  store: any;
  requirements: any[];
  isManager: boolean;
  hasAction: boolean;
  hasStatus: boolean;
}

const Table = ({
  store,
  requirements,
  isManager,
  hasAction,
  hasStatus,
}: IProps): JSX.Element => {
  const showStatus = hasStatus && requirements?.[0]?.users?.length === 1;
  return (
    <table className="RequirementsTable table table-striped">
      <thead>
        <tr>
          <th className="LeftPadded Narrow">Type</th>
          <th className="">Qualification</th>
          {isManager && (
            <th className="RightPadded">Verification</th>
          )}
          {!isManager && showStatus && <th>Status</th>}
          {hasAction && showStatus && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {requirements.map((requirement) => {
          const {
            id,
            users = [],
            qualification: {
              resolution,
              name,
              categoryDisplayName,
              verificationDisplayName,
              category,
            },
          } = requirement;
          const [user] = users;

          return (
            <tr key={id}>
              <td className="LeftPadded">{categoryDisplayName}</td>
              <td>
                {name}
                {category === 15 && !isManager && (
                  <span style={{ position: 'relative', marginLeft: 4 }}>
                    <InfoIcon position="right" showOnEvent="click">
                      <div>
                        <div style={{ paddingBottom: 8 }}>
                          Some work orders require that providers show proof of vaccination
                          against COVID-19 on-site. If you are interested in being
                          assigned this work, you must indicate that you are willing to
                          show proof of vaccination upon arrival to the worksite.
                        </div>
                        <a target="_blank" rel="noreferrer" href="https://support.fieldnation.com/s/article/vax-proof-on-site">Learn More</a>
                      </div>
                    </InfoIcon>
                  </span>
                )}
              </td>
              {isManager && (
                <td className="RightPadded">{verificationDisplayName}</td>
              )}
              {!isManager && showStatus && typeof user !== 'undefined' && (
                <td>
                  {!user?.match ? (
                    <Status type="danger" size="sm">
                      Missing
                    </Status>
                  ) : (
                    <Status type="success" size="sm">
                      Passed
                    </Status>
                  )}
                </td>
              )}
              {hasAction && showStatus && (
                <td>
                  {!user?.match && resolution ? (
                    <Resolution store={store} requirement={requirement} userId={user.userId} />
                  ) : null}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.any.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.object).isRequired,
  isManager: PropTypes.bool,
  hasAction: PropTypes.bool,
  hasStatus: PropTypes.bool,
};

Table.defaultProps = {
  isManager: false,
  hasAction: false,
  hasStatus: false,
};

export default Table;
