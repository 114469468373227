import { useQuery } from 'react-query';

import { SkillResponse, CategoryResponse, SubCategoryResponse } from '../types.d';

export interface SkillIndex {
  skills: Pick<SkillResponse, 'id' | 'name'>[];
  categories: Pick<CategoryResponse, 'id' | 'name' | 'subCategories'>[];
  subCategories: Pick<SubCategoryResponse, 'id' | 'name' | 'skills'>[];
}

export default function useSkills(): SkillIndex | null {
  const query = useQuery<SkillIndex | null>(
    ['skill', 'listing'],
    async () => new Promise<SkillIndex>((resolve, reject) => {
      fetch('/skill-discovery/v1/segments', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        method: 'GET',
        credentials: 'same-origin',
      })
        .then((resp) => resp.json())
        .then((resp) => {
          const si: SkillIndex = {
            categories: [],
            subCategories: [],
            skills: [],
          };

          // eslint-disable-next-line no-restricted-syntax
          for (const category of resp) {
            let cat = si.categories.find(({ id }) => id === category.id);
            if (!cat) {
              cat = {
                id: category.id,
                name: category.category || '',
                subCategories: [],
              };

              si.categories.push(cat);
            }

            // eslint-disable-next-line no-restricted-syntax
            for (const subCat of category.subcategories || []) {
              let sub = si.subCategories.find(({ id }) => id === subCat.id);
              if (!sub) {
                sub = {
                  id: subCat.id,
                  name: subCat.subcategory || '',
                  skills: [],
                };

                si.subCategories.push(sub);
                cat.subCategories.push(sub as SubCategoryResponse);
              }

              // eslint-disable-next-line no-restricted-syntax
              for (const skill of subCat.segments || []) {
                const sk = { id: skill.id, name: skill.name };
                si.skills.push(sk);
                sub.skills.push(sk as SkillResponse);
              }
            }
          }

          resolve(si);
        })
        .catch((e) => {
          reject(e);
        });
    }),
  );

  return query.status === 'success' ? query.data : null;
}
