import { atom, selector } from 'recoil';
import { getUserRatings } from '../api';
import userQuery, { userIdQuery } from './user.query';
import contextQuery from './context.query';

import isManagedOrSelf from './is-managed-or-self';
import contractQuery from './contract.query';
import { Group, myCompanyOption } from '../Components/utils';
import companyListQuery from './company-list.query';

import { JobHistoryFilters, LabelValue, StarRatings } from '../types.d';

type PerformanceFilters = JobHistoryFilters;

interface Performance {
  marketplace: StarRatings;
  myCompany: StarRatings;
  filters: JobHistoryFilters;
  companyList: LabelValue[] | null;
}

export const performanceFilter = atom<PerformanceFilters>({
  key: 'performanceFilter',
  default: {
    dayHistory: 0,
    woHistory: 0,
    version: 0,
    withCompanyId: 0,
  },
});

export const showProviderReliabilityTile = selector<boolean>({
  key: 'showProviderReliabilityTile',
  get: async ({ get }) => {
    const user = get(userQuery);
    const userId = get(userIdQuery);
    const context = get(contextQuery);
    const isServiceCompany = (context?.features?.results || []).some(({ id }) => id === 33);
    const isBuyer = (context?.features?.results || []).some(({ id }) => id === 4);

    if (
      !userId
      || !user
      || !context
      || user?.role_type !== 'provider'
      || !([
        Group.Provider,
        Group.Admin,
        Group.Manager,
        Group.Dispatcher,
        Group.Staff,
      ].includes(context?.group?.id || 0))
      || (context?.group?.id === Group.Provider && user?.id !== context.id)
      || (!isBuyer && isServiceCompany && user?.company?.id !== context?.company?.id)
    ) {
      return false;
    }

    return true;
  },
});

export const hasShowPerformanceTile = (): boolean => {
  const { context } = window;
  const query = new URLSearchParams(window.location.search);
  const experiments = context?.experiments?.results || [];
  return Boolean(
    // 79 => Provider Reliability Metrics
    experiments?.find(({ id, value }) => id === 79 && (Number(value) === 1 || value === 'new')),
  ) || (
    // query param in the URL ?performance=1 as a staff
    Number(query?.get('performance')) === 1 && context?.group?.id === Group?.Staff as number
  );
};

const performanceQuery = selector<Performance | null>({
  key: 'performance',
  get: async ({ get }) => {
    const user = get(userQuery);
    const userId = get(userIdQuery);
    const context = get(contextQuery);

    if (!userId || !user || !context || user?.role_type !== 'provider') {
      return null;
    }

    const showPerformanceTitle = hasShowPerformanceTile();
    if (!showPerformanceTitle) {
      return null;
    }

    const contract = get(contractQuery);
    const filters = get(performanceFilter);

    let companyList = [myCompanyOption[0]];
    if (context?.group?.id === Group?.Staff) {
      companyList = get(companyListQuery) || companyList;
      const comlist = Number(context?.company?.id) > 0
        ? companyList?.filter(({ value }) => Number(value) === context?.company?.id)
        : companyList;
      companyList = [myCompanyOption[0]].concat(comlist || []);
    }

    const managedOrSelf = isManagedOrSelf(user, context);
    const contextCompanyId = context?.company?.id || 0;
    const contextGroupId = context?.group?.id || 0;
    const contextUserId = context?.id || 0;
    const companyId = filters?.withCompanyId || context?.company?.id || 0;
    const hasContract = (
      Boolean(contract?.curated_recruitment?.enabled)
      || [Group?.Staff, Group?.Accountant].includes(contextGroupId)
    );

    const performance = await getUserRatings({
      userId,
      companyId,
      filters: {
        ...filters,
        hasContract,
        contextUserId,
        contextGroupId,
        contextCompanyId,
        managedOrSelf,
      },
    });

    return {
      ...performance,
      companyList,
    } as unknown as Performance;
  },
});

export default performanceQuery;
