import * as React from 'react';
import {
  Link,
  Thumbnail,
  Icon,
  Stars,
  Loader,
} from '@fieldnation/platform-components';
import { format, parseISO } from 'date-fns';
import { dataUri as profile } from './profile';
import css from './TechnicianInfo.scss';
import trackAmplitudeEvent, { NAVIGATED } from '../amplitude';
import useProfile from '../queries/use-profile';
import useDistance from '../queries/use-distance';

export interface TechnicianInfoProps {
  userId?: number;
  workOrderId?: number;
}

export default function TechnicianInfo({
  userId,
  workOrderId,
}: TechnicianInfoProps): JSX.Element {
  const data = useProfile(userId || undefined);
  const distance = useDistance(workOrderId, userId);

  if (!data) {
    return (
      <div className={css.TechnicianInfo}>
        <Thumbnail title="..." src={profile} />

        <div>
          <div className={css.LinkLine}>
            <Loader isLoading>Loading ...</Loader>
          </div>

          <div className={css.RatingLine}>
            <div>
              <Stars color="yellow" rating={5} size="sm" />
              <i>...</i>
            </div>
            <div>
              <Icon name="assignment" size={16} />
              <i className="icon">... jobs</i>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={css.TechnicianInfo}>
      <Thumbnail
        title={`${data.first_name} ${data.last_name}`}
        src={data.thumbnail}
      />

      <div>
        <div className={css.LinkLine}>
          <Link
            target="_blank"
            href={`/p/${data.id}`}
            onClick={() => {
              trackAmplitudeEvent({
                category: NAVIGATED,
                ampProps: {
                  Source: 'Skill Discovery Modal',
                  Destination: 'Profile',
                },
              });
            }}
          >
            {data.first_name}
            {' '}
            {data.last_name}
          </Link>
          <small>
            Last active
            {' '}
            {data.last_active?.utc
              ? format(parseISO(data.last_active.utc), 'MMM yyyy')
              : null}
          </small>
        </div>

        <div className={css.RatingLine}>
          {data.rating?.marketplace?.stars ? (
            <div>
              <Stars
                color="yellow"
                rating={data.rating.marketplace.stars}
                size="sm"
              />
              <i>{data.rating.marketplace.stars}</i>
            </div>
          ) : null}
          {data.rating?.my_company?.stars ? (
            <div>
              <Stars
                color="green"
                rating={data.rating.my_company.stars}
                size="sm"
              />
              <i>{data.rating.my_company.stars}</i>
            </div>
          ) : null}
          {data.jobs?.marketplace ? (
            <div>
              <Icon name="assignment" size={16} />
              <i className="icon">
                {data.jobs?.marketplace}
                {' '}
                job
                {data.jobs?.marketplace === 1 ? '' : 's'}
              </i>
            </div>
          ) : null}
          {distance?.driving?.miles || distance?.crow?.miles ? (
            <div>
              <Icon name="vehicle" size={16} />
              <i className="icon">
                {Math.round(
                  (distance?.driving?.miles || distance?.crow?.miles || 0)
                    * 10.0,
                ) / 10.0}
                {' '}
                mi
              </i>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
