import React from 'react';

export default React.memo(({ width = 20, height = 20 }: { width: number, height: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ display: 'block' }}
  >
    <title>FN_Logomark_orange</title>
    <defs>
      <path
        d="M20 0H1.978A1.975 1.975 0 0 0 0 1.973v16.054C0 19.117.886 20 1.978 20H8v-6.667h6V6.667h6V0Z"
        id="a"
      />
      <path
        d="M14 6.667v2.666S11.381 6.667 1.667 6.667H14Zm-6 6.666v1.334s-1.783-1.26-6.533-1.334H8Z"
        id="b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill="#F16A22" xlinkHref="#a" />
      <use fill="#D04D0B" xlinkHref="#b" />
    </g>
  </svg>
));
