import React from 'react';
import { MessageModal, Heading } from '@fieldnation/platform-components';
import PropTypes from 'prop-types';
import { ScreeningStatusModalProps } from './types.d';

const ScreeningStatusModal = ({
  tryAgain,
  onClose,
  isSuccessful = false,
  screeningType,
  checkrUrl,
}: ScreeningStatusModalProps): JSX.Element => {
  const errorHeading = 'There was a problem processing your order!';
  const successBody = `You will need to access the Checkr application portal within 7 days to authorize your ${screeningType}. Access this page via the button below. An email with this link will also be sent to you directly from Checkr. Visit the screenings page in your profile settings to see your screening status.`;
  const errorBody = `We encountered an error processing your ${screeningType} order. Please try again or create a Field Nation support case for assistance.`;

  const succesFooterActions = [
    {
      onClick: onClose,
      label: 'Close',
      type: 'secondary',
    },
    {
      onClick: () => {
        window.open(checkrUrl, '_blank');
        onClose();
      },
      target: '_blank',
      label: 'Continue to Checkr',
      type: 'primary',
    },
  ];
  const errorFooterActions = [
    {
      onClick: () => {
        window.open('https://support.fieldnation.com', '_blank');
        onClose();
      },
      target: '_blank',
      label: 'Contact support',
      type: 'secondary',
    },
    {
      label: 'Try again',
      onClick: tryAgain,
      type: 'primary',
    },
  ];

  const successBodyWrapper = (
    <div style={{ color: 'rgba(0,0,0,0.6)' }}>
      <Heading styleLevel="h2">
        Your order has been placed!
      </Heading>
      <Heading styleLevel="h2">
        <div>
          Authorization via Checkr is required to finish your screening.
        </div>
      </Heading>
      <br />
      <div style={{ color: 'rgba(0,0,0,0.87)' }}>{successBody}</div>
    </div>
  );

  return (
    <MessageModal
      type={isSuccessful ? '' : 'danger'}
      bodyCopy={isSuccessful ? successBodyWrapper : errorBody}
      header={isSuccessful ? '' : errorHeading}
      isOpen
      footerActions={isSuccessful ? succesFooterActions : errorFooterActions}
      onClose={onClose}
    />
  );
};

ScreeningStatusModal.propTypes = {
  tryAgain: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  isSuccessful: PropTypes.bool,
  screeningType: PropTypes.string,
  checkrUrl: PropTypes.string,
};

ScreeningStatusModal.defaultProps = {
  isSuccessful: false,
  screeningType: '',
  checkrUrl: 'https://www.checkr.com/',
  tryAgain: () => {},

};

export default ScreeningStatusModal;
