/* eslint-disable import/prefer-default-export */
import { Grid, GridColumn } from '@fieldnation/platform-components';
import React from 'react';
import { gridSizeTokens } from '..';
import { IStandardGridLayout } from './types.d';

import css from './styles.scss';

export const StandardGridLayout = ({
  itemName,
  itemContent,
}: IStandardGridLayout): JSX.Element => {
  const itemNameComponent = [itemName].flat().map((itemNameNode, index) => (
    <div className={css['item-name-column']} key={`${index + 1}`}>
      {itemNameNode}
    </div>
  ));

  const itemContentComponent = [itemContent]
    .flat()
    .map((itemContentNode, index) => (
      <div className={css['item-content-column']} key={`${index + 1}`}>
        {itemContentNode}
      </div>
    ));

  return (
    <div className={css['standard-grid-container']}>
      <Grid>
        <GridColumn
          xs={gridSizeTokens.full}
          sm={gridSizeTokens.full}
          md={gridSizeTokens.listItemName}
          lg={gridSizeTokens.listItemName}
          xl={gridSizeTokens.listItemName}
        >
          {itemNameComponent}
        </GridColumn>
        <GridColumn
          xs={gridSizeTokens.full}
          sm={gridSizeTokens.full}
          md={gridSizeTokens.listItemContent}
          lg={gridSizeTokens.listItemContent}
          xl={gridSizeTokens.listItemContent}
        >
          {itemContentComponent}
        </GridColumn>
      </Grid>
    </div>
  );
};
