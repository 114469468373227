import { selector } from 'recoil';
import { getScreeningFeeByType } from '../api';
import screeningAtom from './screening.atom';
import { userDataQuery } from './user.query';
import { getScreeningFee } from '../helpers';

const screeningFeeQuery = selector({
  key: 'screeningFeeQuery',
  get: async ({ get }) => {
    const { userId, screeningType } = get(screeningAtom);
    if (!userId) {
      return {
        screeningTotalFee: '$0',
        isFreeScreening: true,
      };
    }

    const user = get(userDataQuery);
    const { state = '', country = '' } = user || {};
    const resp = await getScreeningFeeByType(state, country);
    const {
      screeningTotalFee = '$0',
      isFreeScreening = true,
      packageId,
    } = getScreeningFee(screeningType, resp?.results || []);

    return {
      screeningTotalFee,
      isFreeScreening,
      packageId,
    };
  },
});

export default screeningFeeQuery;
