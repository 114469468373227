import * as React from 'react';
import WrapSection from './WrapSection';
import WorkSummaryWidget from '../Components/WorkSummaryWidget';
import { WorkSummarySectionProps } from '../types.d';

const WorkSummary = ({
  providerId,
  workOrderId,
  collapse,
}: WorkSummarySectionProps): JSX.Element => (
  <WrapSection
    label="Work Summary"
    collapse={collapse}
    fullWidth
  >
    <WorkSummaryWidget
      userId={providerId}
      workOrderId={workOrderId}
    />
  </WrapSection>
);

WorkSummary.defaultProps = {
  collapse: false,
};

export default WorkSummary;
