import { atom, selector } from 'recoil';
import {
  getCredentials
} from '../api';
import { Credential, CredentialInputData, CredentialSelect, VerificationOrg } from '../types';

interface LicenseCertification {
  id?: number, // same as userCredentialId, keeping for compatibility
  userCredentialId?: number,
  userId: number,
  source: string,
  isEditMode?: boolean,
}

export const LicenseCertificationAtom = atom<LicenseCertification>({
  key: 'licenseCertificationAtom',
  default: {
    id: 0,
    userCredentialId: 0,
    userId: 0,
    source: '',
    isEditMode: false,
  },
});

export const lcDefaultInputs: CredentialInputData = {
  id: 0,
  credentialId: 0,
  issuingOrgId: 0,
  credentialIdNumber: '',
  issuedAt: '',
  expiresAt: '',
};

export const credentialDefaultValue: Credential = {
  id: 0,
  name: '',
  type: '',
  organizations: []
};

export const orgDefaultValue: VerificationOrg = {
  id: 0,
  name: '',
  certOrgId: '',
  vendorName: '',
  stateId: 0,
  fields: []
};

export const lcSelectDataQuery = selector({
  key: 'lcSelectDataQuery',
  get: async ({ }) => {
    const data = await getCredentials();
    const selectOptions: Credential[] = data?.results || [];
    return selectOptions.map(credential => ({
      value: credential.id,
      label: credential.name,
      type: credential.type,
      inDemand: credential.inDemand,
      verifiedOn: credential.verifiedOn,
    })) as Array<CredentialSelect | GroupLists>;
  }
});
