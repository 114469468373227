/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Link, Section } from '@fieldnation/platform-components';
import WrapSection from './WrapSection';
import trackAmplitudeEvent, { REVIEWED_SKILLS_SUMMARY } from '../amplitude';
import SkillSummary from '../Components/SkillSummary';
import { useSkillsModal } from '../Components/WorkAndSkillWidget';

export interface SkillMatchProps {
  userId: number;
  collapse?: boolean;
  workOrderId?: number | null;
}

export default function SkillMatch({
  userId,
  workOrderId,
  collapse,
}: SkillMatchProps): JSX.Element {
  const [toggleSkillsModal, skillsModal] = useSkillsModal('profile');

  return (
    <WrapSection
      label={workOrderId ? 'Matching Skills' : 'Provider Skills'}
      collapse={collapse}
      fullWidth
    >
      <div style={{ margin: '0rem 1.6rem' }}>
        {skillsModal}
        <SkillSummary
          userId={userId}
          workOrderId={workOrderId === null ? undefined : workOrderId}
        />
        <Section>
          <div className="text-xs-center u-padding-bottom--md u-padding-top--md">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={() => {
                trackAmplitudeEvent({
                  category: REVIEWED_SKILLS_SUMMARY,
                  ampProps: {
                  // one of: Provider Profile, Drawer, Talent List, WOD
                    Source: 'Drawer',
                  },
                });
                toggleSkillsModal({
                  userId: userId || 0,
                });
              }}
            >
              See all provider's skills
            </Link>
          </div>
        </Section>
      </div>
    </WrapSection>
  );
}
