import React, { memo } from 'react';
import { Loader } from '@fieldnation/platform-components';
import UbiHeader from './Header';
import ActionButton from './ActionButton';
import UbiInputFields from './InputFields';
import useUbiFormHook from './hooks/ubiFormHook';
import classes from './styles.scss';
import { WaWorkerCompEventSource } from './tracker/types.d';
import { UbiFormProps, VerificationStatusEnum } from './types.d';
import VerificationStatus from './VerificationStatus';

const UbiForm = ({ isOnboarding, source = WaWorkerCompEventSource.ACCEPT_ROUTED_WO }: UbiFormProps): JSX.Element => {
  const {
    maintainsBooksAndRecords,
    filesIRSExpense,
    hasUbi,
    ubiNumber,
    postingData,
    saveUbiData,
    verificationStatus,
    currentStatus,
    submissionEnabled,
    onChangeHasUbi,
    onChangeUbiNumber,
    onChangeMaintainRecords,
    onChangeFilesWithIrs,
    onChangeVerificationStatus,
  } = useUbiFormHook(source);

  return (
    <Loader fixed isLoading={postingData}>
      <UbiHeader isOnboarding={isOnboarding} />
      <div className={classes.FormSection}>
        <UbiInputFields
          maintainsBooksAndRecords={maintainsBooksAndRecords}
          filesIRSExpense={filesIRSExpense}
          hasUbi={hasUbi}
          ubiNumber={ubiNumber}
          verificationStatus={verificationStatus}
          onChangeHasUbi={onChangeHasUbi}
          onChangeUbiNumber={onChangeUbiNumber}
          onChangeMaintainRecords={onChangeMaintainRecords}
          onChangeFilesWithIrs={onChangeFilesWithIrs}
          onChangeVerificationStatus={onChangeVerificationStatus}
          isModal={false}
        />
        <VerificationStatus
          verificationStatus={currentStatus as VerificationStatusEnum}
        />

        <ActionButton onSave={saveUbiData} isPostingData={!submissionEnabled} />
      </div>
    </Loader>
  );
};

UbiForm.defaultProps = {
  isOnboarding: false,
};

export default memo(UbiForm);
