/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import 'isomorphic-fetch';

async function request(url, options = {}) {
  try {
    const resp = await fetch(
      !options.absolute ? `/qualifications${url}` : url,
      {
        ...options,
        absolute: undefined,
        headers: {
          ...(options.headers || {}),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'same-origin',
        cache: 'no-cache',
        mode: 'cors',
      },
    );

    if (!resp.ok) {
      const msg = resp.status >= 400 && resp.status < 600
        ? await resp.json()
        : resp.statusText;

      return { error: msg };
    }

    const json = await resp.json();

    return json;
  } catch (e) {
    // eslint-disable-next-line no-console
    return { error: e.message };
  }
}

export const getRequirementsByUserAndWorkOrder = (
  userId,
  workOrderId,
) => async () => {
  if (!userId || !workOrderId) {
    return {
      requirements: [],
    };
  }
  const reqs = await request(
    `/v2/workorders/${workOrderId}/requirements`,
    {
      method: 'GET',
      absolute: true,
    },
  );
  const role = reqs && reqs.role ? reqs.role : window.work_order?.role || null;

  const requirements = await request(
    `/workorders/${workOrderId}/users/${userId}`,
    {
      method: 'POST',
      body: JSON.stringify({ role }),
    },
  );
  return requirements || { requirements: [] };
};

export const getRequirementsByWorkOrder = (workOrderId) => async () => {
  const reqs = await request(
    `/v2/workorders/${workOrderId}/requirements`,
    {
      method: 'GET',
      absolute: true,
    },
  );
  const role = reqs && reqs.role ? reqs.role : window.work_order?.role || null;

  const requirements = await request(`/workorders/${workOrderId}?f_role=${JSON.stringify(role)}`);
  return requirements || { requirements: [] };
};

export const getV2RequirementsByWorkOrder = (workOrderId) => async () => {
  const requirements = await request(`/v2/workorders/${workOrderId}`);
  return requirements || { requirements: [] };
};

export const getRequirementsByTemplate = (templateId) => async () => {
  const requirements = await request(`/templates/${templateId}`, {
    method: 'POST',
  });
  return requirements || { requirements: [] };
};

export const getRequirementsByCompany = (companyId) => async () => {
  const requirements = await request(`/company-requirements/${companyId}`);
  return requirements || { requirements: [] };
};

export const searchQualificationsByType = async (input, type) => {
  const options = await request(
    `/qualifications/search/${type}/${encodeURIComponent(input)}`,
  );
  return options || [];
};

export const getQualificationsByType = async (type, verified = '') => {
  const options = await request(
    `/qualifications/items?list=${type}&f_verified=${verified}&per_page=10000`,
  );
  return options?.results || [];
};

export const getCredentialsByType = async (type = 'license') => {
  const credentials = await request(`/qualifications/credentials/${type}`);
  return credentials || [];
};

export const getv2RequirementOptions = async () => {
  const credentials = await request('/v2/requirement-options');
  return credentials || [];
};

export const saveRequirements = async (payload) => {
  const {
    templateId, workOrderId, requirements, qualificationsV2 = false,
  } = payload;
  let updatedRequirements;

  if (templateId) {
    if (qualificationsV2) {
      updatedRequirements = await request(`/v2/templates/${templateId}`, {
        method: 'PUT',
        body: JSON.stringify(qualificationsV2),
      });
    } else {
      updatedRequirements = await request(`/templates/${templateId}`, {
        method: 'PUT',
        body: JSON.stringify(requirements),
      });
    }
  } else if (workOrderId) {
    if (qualificationsV2) {
      updatedRequirements = await request(`/v2/workorders/${workOrderId}`, {
        method: 'PUT',
        body: JSON.stringify(qualificationsV2),
      });
    } else {
      updatedRequirements = await request(`/workorders/${workOrderId}`, {
        method: 'PUT',
        body: JSON.stringify(requirements),
      });
    }
  }

  return updatedRequirements?.error
    ? { error: updatedRequirements.error?.message || 'Request failed' }
    : { success: true };
};

export const resolveRequirement = async (
  {
    serviceUri, path, method, body,
  },
  userId = 0,
) => {
  const tokenized = path.replace(/:user_id:/g, userId || 0);
  return request(`${serviceUri}${tokenized}`, {
    absolute: true,
    method,
    body,
  });
};
