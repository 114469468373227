import React from 'react';
import { BodyText } from '@fieldnation/platform-components';
import css from './styles.scss';
import OptionalText from '../OptionalText';
import { IProps } from './types.d';
import AddressVerifiedChip from '../../../AddressVerifiedChip';

const SectionLabel: React.FC<IProps> = ({
  label,
  isOptional,
  addressVerified,
}: IProps): JSX.Element => (
  <div>
    <BodyText styleLevel="mdSemiBold" tag="span">
      <span className={css['section--label']}>{label}</span>
    </BodyText>
    {isOptional && <OptionalText />}
    <br />
    {addressVerified && label === 'Company Address' && <AddressVerifiedChip />}
  </div>
);

export default SectionLabel;
