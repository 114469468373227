/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Median,
  MedianAlpha,
  MedianOmega,
} from '@fieldnation/platform-components';

import { SmartAddressWidget } from '../../../..';
import { IFullAddressInputValues } from '../../../../index.d';

import css from '../styles.scss';
import { useCountryAndState, useEditPermission } from '../../../hooks';
import { ProfileInfoItems, RequestTypes } from '../../../constants';
import {
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
} from '../../../components';
import AddressVerifiedChip from '../../../../AddressVerifiedChip';

import {
  addressVerificationSelector,
  getInputFieldAtom,
  getRequestConfigCallback,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  passwordProtectedRequestAtom,
  resetFormCallback,
  userContextSelector,
} from '../state';
import { useAddressInputAttribute } from '../../../hooks/useAddressInputAttribute';
import { useAmplitudeTracker } from '../../../../Amplitude/useAmplitudeTracker';

const AddressEditFrom = () => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);
  const isEditing = useRecoilValue(isEditingSelector(ProfileInfoItems.Address));

  const atoms = ['address1', 'address2', 'city', 'zip', 'state', 'country'];

  const [
    [currentAddress1, setCurrentAddress1],
    [currentAddress2, setCurrentAddress2],
    [currentCity, setCurrentCity],
    [currentZip, setCurrentZip],
    [currentState, setCurrentState],
    [currentCountry, setCurrentCountry],
  ] = atoms.map((atomName) => useRecoilState(getInputFieldAtom(atomName)));

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);
  const trackOnAmplitude = useAmplitudeTracker();

  const onSave = async () => {
    const initialRequestConfig = await getRequestConfig(
      isStaff ? RequestTypes.RESOLVE_ADDRESS : RequestTypes.UPDATE_ADDRESS,
    );

    const updateCallback = async () => {
      let success = await makeRequest(
        initialRequestConfig,
        'Successfully updated address',
        false,
        trackOnAmplitude,
      );

      if (success && isStaff) {
        const subsequentRequestConfig = await getRequestConfig(
          RequestTypes.UPDATE_ADDRESS,
        );
        success = await makeRequest(
          subsequentRequestConfig,
          'Successfully updated address',
          false,
          trackOnAmplitude,
        );
      }
      if (success) {
        await resetForm(atoms);
      }
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)(...atoms);

  const handleAddressChange = (v: IFullAddressInputValues) => {
    const { address1, address2, city, state, zip, country } = v;
    setCurrentAddress1(address1);
    setCurrentAddress2(address2);
    setCurrentCity(city);
    setCurrentState(state);
    setCurrentZip(zip);
    setCurrentCountry(country);
  };

  const { country, state } = useCountryAndState(currentCountry, currentState);
  const { inputAttribute, canSubmit } = useAddressInputAttribute({
    address1: currentAddress1,
    address2: currentAddress2,
    city: currentCity,
    state: currentState,
    zip: currentZip,
    country: currentCountry,
  });

  return (
    <div>
      {!isEditing && (
        <div className={css['list-item-content']}>
          <BodyText styleLevel="md" tag="div">
            <span
              className={classNames({
                [css['list-item-content-empty']]: !currentAddress1,
              })}
            >
              {currentAddress1 || 'No address line'}
            </span>
            {currentAddress2 && <span>{`, ${currentAddress2}`}</span>}
          </BodyText>
          <BodyText styleLevel="md" tag="div">
            {`${currentCity || ''}`}
            {state?.label ? `, ${state?.label}` : ''}
            {currentZip ? `, ${currentZip}` : ''}
          </BodyText>
          <BodyText styleLevel="md" tag="div">
            {`${country?.name || ''}`}
          </BodyText>
        </div>
      )}
      {isEditing && (
        <div className={css['address-edit-form']}>
          <SmartAddressWidget
            values={{
              address1: currentAddress1,
              address2: currentAddress2,
              city: currentCity,
              state: currentState,
              zip: currentZip,
              country: currentCountry,
            }}
            inputAttribute={inputAttribute}
            onChange={handleAddressChange}
          />
          <ProfileInfoFormActionButton
            onClose={onClose}
            onSave={onSave}
            isValid={canSubmit}
          />
        </div>
      )}
    </div>
  );
};

export const Address = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.Address);
  const isEditing = useRecoilValue(isEditingSelector(ProfileInfoItems.Address));
  const setOpenForEdit = useSetRecoilState(openForEditAtom);
  const addressVerified = useRecoilValue(addressVerificationSelector);

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="div">
        <span className={css['list-item-name']}>Address</span>
        {addressVerified && (
          <div>
            <AddressVerifiedChip />
          </div>
        )}
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top">
        <MedianAlpha>
          <AddressEditFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="address" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.Address);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
