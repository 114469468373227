import { Loader } from '@fieldnation/platform-components';
import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';

import ProviderSnapshot from './ProviderSnapshot';

import { ProviderSnapshotProps } from './types.d';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // don't refresh any data more than once per minute
      staleTime: 60 * 1000,
    },
  },
});

const ProviderSnapshotWidget = (props: ProviderSnapshotProps): JSX.Element => {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loader isLoading fixed />}>
          <ProviderSnapshot {...props} />
        </Suspense>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

export default ProviderSnapshotWidget;
