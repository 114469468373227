/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { LatLngLiteralVerbose } from '@google/maps';
import { useQuery } from 'react-query';
import WrapSection from './WrapSection';
import NoMap from '../Components/NoMap';
import { getUserData } from '../api';
import requestWrapper from '../../requestWrapper';
import { MonolithProfile } from '../types.d';

interface Props {
  user: MonolithProfile | null;
  collapse?: boolean;
  workOrderId?: number | null | undefined;
}

interface MyMarkerType extends LatLngLiteralVerbose {
  icon: string;
}

// google.maps.MapOptions
const mapOptions: any = {
  fullscreenControl: false,
  mapTypeControl: false,
  minZoom: 3,
  maxZoom: 12,
  streetViewControl: false,
  zoomControl: false,
};

const Location = ({
  user = {}, collapse, workOrderId,
}: Props): JSX.Element => {
  const [myCoords, setMyCoords] = React.useState<any>({});

  const {
    city = '',
    state = '',
    coords = {},
    id = 0,
  } = user || {};

  React.useEffect(() => {
    setMyCoords(coords);
  }, [id]);

  React.useEffect(() => {
    let coordsToSet = coords;
    if (!coordsToSet?.latitude) {
      getUserData(`${id}`, { throwExceptions: true }).then((d) => {
        coordsToSet = d?.coords || {};
        setMyCoords(d?.coords || {});
      // eslint-disable-next-line no-console
      }).catch((e) => console.error(e));
    } else {
      setMyCoords(coordsToSet);
    }
  }, [id]);

  const workorderId = workOrderId || window?.work_order?.id;
  const workorderLocationFromWindow = window?.work_order?.location?.coordinates;

  const {
    data, isLoading, isError,
  } = useQuery(
    ['provider_distance_from_wo', workorderId, id],
    async () => {
      const wo: any = await requestWrapper(`/v2/workorders/${workorderId}/providers/${id}?columns=coords`, {
        method: 'GET',
        throwExceptions: true,
      });
      return wo?.coords?.distance ? Math.round(wo?.coords?.distance) : null;
    },
    { keepPreviousData: true, enabled: !!workorderId },
  );

  const {
    data: workorderCoords, isLoading: workorderCoordsLoading, isError: workorderCoordsError,
  } = useQuery(
    ['wo_location', workorderId],
    async () => {
      const wo: any = await requestWrapper(`/v2/workorders/${workorderId}/?columns=location`, {
        method: 'GET',
        throwExceptions: true,
      });
      return wo?.location?.coordinates;
    },
    { keepPreviousData: true, enabled: !!workorderId },
  );

  // eslint-disable-next-line no-underscore-dangle
  const mapKey = window._GOOGLE_MAP_KEY || '';
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapKey,
  });

  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [markers, setMarkers] = React.useState<MyMarkerType[]>([]);

  const onMapLoad = React.useCallback((m: google.maps.Map) => {
    setMap(m);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  React.useEffect(() => {
    if (!isLoaded || (!workorderLocationFromWindow && workorderCoordsLoading)) return;

    const bounds = new window.google.maps.LatLngBounds();
    const workorderLocation = workorderLocationFromWindow || workorderCoords;

    const newMarkers: MyMarkerType[] = [];
    if (workorderLocation && workorderLocation.latitude && workorderLocation.longitude) {
      newMarkers.push(
        { latitude: workorderLocation.latitude, longitude: workorderLocation.longitude, icon: '/images/map_location_end.png' },
      );
    }
    if (myCoords && myCoords.latitude && myCoords.longitude) {
      newMarkers.push({ latitude: myCoords.latitude, longitude: myCoords.longitude, icon: '/images/map_location_start.png' });
    }

    if (newMarkers.length === 0) {
      map?.setZoom(3);
      map?.setCenter({ lat: 39, lng: -98 });
    } else {
      setMarkers(newMarkers);
      newMarkers.forEach((marker: MyMarkerType) => {
        bounds.extend({ lat: marker.latitude, lng: marker.longitude });
      });
      map?.fitBounds(bounds);
    }
  }, [
    myCoords,
    isLoaded,
    map,
    workorderCoordsLoading,
    workorderCoords,
    workorderLocationFromWindow,
  ]);

  return (
    <WrapSection
      label="Location"
      subHeading={`${city}, ${state}`}
      value={(!workorderId || isLoading || isError || !data || (!workorderLocationFromWindow && workorderCoordsError)) ? '' : `${data} mi away`}
      collapse={collapse}
      fullWidth
    >
      {isLoaded ? (
        <div style={{ marginLeft: '-16px', marginRight: '-16px', height: '100%' }}>
          <GoogleMap
            id="ProviderSnapshotLocationMap"
            mapContainerStyle={{ height: '200px', width: '100%' }}
            options={mapOptions}
            zoom={9}
            onLoad={onMapLoad}
            onUnmount={onUnmount}
          >
            {markers.map((m: MyMarkerType, i: number) => (
              <Marker
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                position={{ lat: m.latitude, lng: m.longitude }}
                icon={m.icon}
              />
            ))}
          </GoogleMap>
        </div>
      )
        : <NoMap />}
    </WrapSection>
  );
};

Location.defaultProps = {
  collapse: false,
  workOrderId: undefined,
};

export default Location;
