import React from 'react';
import {
  Link,
} from '@fieldnation/platform-components';
import FnLogoWhite from './Svg/FnLogoWhite';
import css from './WorkOrderCuratedChip.scss';
import trackAmplitudeEvent, { LEARNED_MORE } from '../ProviderSnapshot/amplitude';

const SquareIcon = (): JSX.Element => (
  <div className={css.Square__Icon}>
    <FnLogoWhite width={16} height={18} />
  </div>
);

interface IProps {
  networkName: string;
  notes?: string[];
  source?: string;
}

const PopoverContent = ({
  networkName = '',
  notes = [],
  source = '',
}: IProps): JSX.Element => (
  <div className={css.Overlay__Container}>
    <div className={css['Overlay__Top--Border']} />
    <div className={css.Title__Container}>
      <SquareIcon />
      <span className={css.Title}>{networkName}</span>
    </div>
    <div className={css.Overlay__Content}>
      <ul>
        {notes?.map((n: string, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${n}-${i}`} className={css.network__Label}>
            <span/>
            {n}
          </li>
        ))}
      </ul>
    </div>
    <div className={css.Overlay__Footer}>
      <span className={css.Footer__Text}>
        Field Nation recommends Select Techs for their verified experience in <strong>{networkName}</strong> for the best result on this work order.
        <Link
          onClick={() => {
            trackAmplitudeEvent({
              category: LEARNED_MORE,
              ampProps: {
                Source: `Select Tech Network - ${source}`,
              },
            });
          }}
          href="https://support.fieldnation.com/s/article/Select-Networks"
          target="_blank"
        >
          {' Learn More'}
        </Link>
      </span>
    </div>
  </div>
);

PopoverContent.defaultProps = {
  networkName: '',
  notes: [],
  source: '',
};

export default PopoverContent;
