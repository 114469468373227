import * as React from 'react';
import {
  useReducer, createContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  getRequirementsByWorkOrder,
  getRequirementsByTemplate,
  getRequirementsByUserAndWorkOrder,
  resolveRequirement,
} from '../api';
import { ContextInterface, CategoryType } from '../types.d';
import { mapRequirements } from './utils';

const store = createContext<ContextInterface>({
  state: { requirements: [], templateId: -1 },
});
const { Provider } = store;

export { Provider, store };

interface IProps {
  initialState: unknown;
  children: React.ReactNode;
}

const StateProvider = ({ initialState, children }: IProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [state, dispatch] = useReducer((currentState: any, action: any) => {
    let promise: Promise<unknown>;
    switch (action.type) {
      // for create widget
      case 'add': {
        const req = (action.value || []).map(({ id, category: c, qualificationIds: ids }) => (
          ids?.map((i) => ({
            id,
            canEdit: true,
            qualification: {
              id: i,
              name: c?.label,
              category: c?.value,
            },
            credentialId: [CategoryType.LICENSE, CategoryType.CERTIFICATION].includes(c?.value) ? i : null,
          }))
        ));
        const requirements = req?.reduce((acc, i) => (i ? [...acc, ...i] : acc), []);
        return {
          ...currentState,
          newRequirements: requirements,
          newQualifications: action.value,
        };
      }
      case 'edit':
        return {
          ...currentState,
          isEditting: !currentState.isEditting,
          newRequirements: null,
          newQualifications: null,
        };

      case 'loader':
        return {
          ...currentState,
          loading: action.loading || false,
        };

      case 'resolve':
        resolveRequirement(action.api, currentState.userId)
          .then((resp) => dispatch({
            type: resp !== null ? 'on_resolve' : 'error',
            id: action.id,
            error: resp || '404 - not found',
          }))
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));

        return {
          ...currentState,
          requirements: currentState.requirements.map((r) => (r.id !== action.id
            ? r
            : {
              ...r,
              loading: true,
            })),
        };

      case 'on_resolve':
        return {
          ...currentState,
          requirements: currentState.requirements.map((r) => (r.id !== action.id
            ? r
            : {
              ...r,
              loading: false,
              qualification: {
                ...r.qualification,
                isMatch: true,
              },
              isMatch: true,
              users:
                r.users ? r.users.map((u) => {
                  if (u.userId === action.error.id) {
                    return { ...u, match: true };
                  }
                  return u;
                }) : [],
            })),
        };

      case 'error':
        return {
          ...currentState,
          requirements: currentState.requirements.map((r) => (r.id !== action.id
            ? r
            : {
              ...r,
              loading: false,
              error: action.error,
            })),
        };

      case 'invalid':
        return {
          ...currentState, invalid: action.message, loading: false,
        };

      case 'refresh':
        return {
          ...currentState,
          isEditting: false,
          loading: false,
          invalid: null,
          ...(action.actionData || {}),
          newRequirements: null,
          newQualifications: null,
          requirements: action.requirements ? [...action.requirements] : [],
          qualifications: action.qualifications ? [...action.qualifications] : [],
        };

      case 'screening_popup':
        return { ...currentState, selectedScreening: action.selectedScreening };

      case 'remove':
        return {
          ...currentState,
          requirements: currentState.requirements.filter(
            ({ id }) => id !== action.id,
          ),
        };

      case 'reload':
        if (currentState.templateId) {
          promise = getRequirementsByTemplate(currentState.templateId)();
        } else if (currentState.isManager || !action.userId) {
          promise = getRequirementsByWorkOrder(currentState.workOrderId)();
        } else {
          promise = getRequirementsByUserAndWorkOrder(
            action.userId,
            currentState.workOrderId,
          )();
        }

        promise
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((resp: any) => {
            const [formattedQualifications, formattedRequirements] = mapRequirements(resp);
            dispatch({
              type: 'refresh',
              requirements: formattedRequirements,
              qualifications: formattedQualifications,
              actionData: resp,
            });
          })
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));

        return { ...currentState, isLoading: true };

      default:
        throw new Error(`Unhandled action type "${action.type}"`);
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.element.isRequired,
  initialState: PropTypes.any.isRequired,
};

export default StateProvider;
