import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  GridColumn,
  Section,
  v2,
  Link,
  Loader,
} from '@fieldnation/platform-components';
import css from './styles.scss';
import { supportMessage, ERROR_MESSAGE } from './utils';

const {
  // @ts-ignore
  NotificationBand,
  Tile,
  TileDivision,
  OtpInput,
} = v2;

const OtpVerificationComponent = ({
  loading,
  email,
  error,
  otp,
  submitHandler,
  resendOtpHandler,
  contactSupportHandler,
  changeHandler,
}): JSX.Element => (
  <Loader isLoading={loading}>
    <div className={css['inline-container']}>
      <Tile>
        <TileDivision>
          <Section>
            <div style={{ padding: '20px' }}>
              <div style={{ textAlign: 'center' }}>
                <Grid>
                  <GridColumn>
                    <p className={css['Body--title']}>Check your email</p>
                    <p className={css['Body--title']}>
                      Enter the code we sent to
                      {' '}
                      {email}
                    </p>
                  </GridColumn>
                </Grid>
              </div>
              <div style={{ marginTop: '20px' }}>
                <Grid>
                  <GridColumn>
                    <OtpInput
                      label="Enter the 6-digit code"
                      value={otp}
                      fieldValidation={error ? 'error' : ''}
                      onChange={changeHandler}
                    />
                    {error && (
                      <p style={{ color: '#a51d2b' }}>{ERROR_MESSAGE}</p>
                    )}
                    <p style={{ marginTop: '16px' }} className={css['Body--resend-text']}>
                      Didn’t receive it?
                      {' '}
                      <Link
                        onClick={() => resendOtpHandler()}
                      >
                        Send it again.
                      </Link>
                    </p>
                  </GridColumn>
                  <GridColumn>
                    <NotificationBand
                      secondaryText={supportMessage(contactSupportHandler)}
                      type="info"
                    />
                  </GridColumn>
                  <GridColumn>
                    <Button
                      label="Continue"
                      type="primary"
                      block
                      onClick={() => submitHandler()}
                    />
                  </GridColumn>
                </Grid>
              </div>
            </div>
          </Section>
        </TileDivision>
      </Tile>
    </div>
  </Loader>
);

OtpVerificationComponent.propTypes = {
  loading: PropTypes.bool,
  email: PropTypes.string.isRequired,
  error: PropTypes.bool,
  otp: PropTypes.any.isRequired,
  submitHandler: PropTypes.func.isRequired,
  resendOtpHandler: PropTypes.func.isRequired,
  contactSupportHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

OtpVerificationComponent.defaultProps = {
  loading: false,
  error: false,
};

export default OtpVerificationComponent;
