import React from 'react';

interface Props {}

const Header = ({
}: Props): JSX.Element => (
  <div>
    Header section will go here
  </div>
);

Header.defaultProps = {};

export default Header;
