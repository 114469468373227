import { format, fromUnixTime } from 'date-fns';
import * as React from 'react';
import { BackoutsLists } from '../../types.d';

const BackoutsAssessmentDate = ({ assignedDatetime }: BackoutsLists): JSX.Element => {
  const assessmentDate = fromUnixTime(assignedDatetime);

  return (
    <span>
      {format(assessmentDate, 'MM/dd/yyyy')}
    </span>
  );
};

export default BackoutsAssessmentDate;
