/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import template from 'lodash/template';
import { NotificationFlashAlert } from '../components';
import { ROUTE_MAP } from '../constants';
import { IJsObject } from '../types.d';
import { IPasswordResponse } from './types.d';

const API_TIMEOUT_MESSAGE =
  'Server took too long to respond, Please try again later';
const API_UNDEFINED_MESSAGE = 'Internal server error. Please try again later.';

export function handleApiError<T = IJsObject>(error: any): T {
  let localError: any = {
    message: error?.message || API_UNDEFINED_MESSAGE,
  };

  if (error?.response?.data) {
    localError = error.response.data;
  }

  NotificationFlashAlert({
    level: 'error',
    message: localError.message,
    children: localError?.extra?.message_html,
    dismissible: localError?.extra?.message_html ? 'button' : 'both',
  });

  return localError;
}

export function getApiInstance(): AxiosInstance {
  return axios.create({
    baseURL: '/v2',
    timeout: 10 * 1000,
    timeoutErrorMessage: API_TIMEOUT_MESSAGE,
  });
}

export async function makeApiRequest<T = IJsObject>(
  options: AxiosRequestConfig,
): Promise<T> {
  try {
    const apiResponse = await getApiInstance().request(options);
    if (!apiResponse.data) {
      throw Error('No data found on response');
    }
    return apiResponse?.data;
  } catch (error) {
    return handleApiError<T>(error);
  }
}

export async function makePasswordRequest(
  user_id: number,
  password: string,
): Promise<IPasswordResponse> {
  if (!Number(user_id) || !password) {
    return handleApiError(
      new Error('Invalid data provided for password validation'),
    );
  }
  const config = ROUTE_MAP.PasswordConfirmation;

  const monoAPIResponse = await makeApiRequest<IPasswordResponse>({
    ...config,
    url: template(config.url)({ user_id }),
    data: { password },
  });

  if (monoAPIResponse?.is_valid === false) {
    NotificationFlashAlert({
      level: 'error',
      message: 'Invalid Password',
    });
  }

  return monoAPIResponse;
}

export async function makeOtpRequest(): Promise<boolean> {
  const response = await makeApiRequest({
    method: 'POST',
    url: '/otp',
  });

  return response?.created || false;
}

export async function makeOtpVerificationRequest(code: string): Promise<boolean> {
  if (!code) {
    return false;
  }
  const response = await makeApiRequest({
    method: 'GET',
    url: `/otp/${code}/verify`,
  });

  return response?.verified || false;
}

export async function makeOtpPassthroughCheckingRequest(): Promise<boolean> {
  const response = await makeApiRequest({
    method: 'GET',
    url: '/otp/passthrough',
  });

  return response?.canPassthrough || false;
}

export async function disConnectSocialAccount(method: string): Promise<boolean> {
  const response = await fetch(`/profile/settings/disconnect-social-account/${method}`);
  const disconnectResponse = await response.json();

  return disconnectResponse.status;
}
