/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useState } from 'react';
import { parse, formatDistanceToNow } from 'date-fns';
import ProjectType from './ProjectType';
import SkillSet from './SkillSet';
import SkillV4 from './SkillV4';
import { ProviderSkill, ProviderSkillSet } from '../types.d';
import useTechnicianV4WorkHistory from './use-technician-v4-work-history';
import useWorkOrderId from './use-work-order-id';
import css from './WorkSummaryWidget.scss';
import { ACCORDION_VALUE_MODIFIER } from '../utils';

export interface WorkHistoryWidgetProps {
  userId?: number;
  workOrderId?: number;
}

export default function WorkSummaryWidget({
  userId,
  workOrderId: defaultWorkOrderId,
}: WorkHistoryWidgetProps): JSX.Element {
  const workOrderId = useWorkOrderId(defaultWorkOrderId);
  const data = useTechnicianV4WorkHistory(userId, workOrderId);
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([]);
  const toggleAccordion = (accordionId: number) => {
    setExpandedAccordions((prevState) => {
      if (prevState.includes(accordionId)) {
        return prevState.filter((id) => id !== accordionId);
      }
      return [...prevState, accordionId];
    });
  };

  const calculateSkillSetJobsCount = (skillSets: ProviderSkillSet[]): number => {
    if (!skillSets?.length) return 0;
    return skillSets.reduce((total, skillSet) => total + (skillSet.workExperience?.numJobs ?? 0),
      0);
  };

  return (

    <div className={css.WorkSummaryContainer}>
      {(!data?.projectTypes && !data?.projectTypes?.length) && (
      <div className={`${css.NoWorkHistory} text-muted`}>
        <em>No Work history available.</em>
      </div>
      )}

      {data?.projectTypes?.map((projectType) => (
        <React.Fragment key={projectType?.id}>
          <ProjectType
            key={projectType?.id}
            id={projectType?.id}
            userId={userId}
            name={projectType?.name || ''}
            hourlyRate={projectType?.hourlyRate}
            numJobs={projectType?.numJobs}
            skillSetNumjobs={calculateSkillSetJobsCount(projectType?.skillSets || [])}
            onExpand={() => toggleAccordion((projectType?.id || 0) * ACCORDION_VALUE_MODIFIER)}
            expanded={expandedAccordions.includes(
              (projectType?.id || 0) * ACCORDION_VALUE_MODIFIER,
            )}
          >
            {(projectType?.skillSets as ProviderSkillSet[] || []).map(
              (skillSet) => (
                <SkillSet
                  key={skillSet?.id}
                  userId={userId}
                  id={skillSet.id}
                  name={skillSet?.name || ''}
                  numJobs={skillSet?.workExperience?.numJobs}
                  marketplaceRating={skillSet?.workExperience?.ratings?.marketPlace}
                  myCompanyRating={skillSet?.workExperience?.ratings?.myCompany}
                  lastCompletion={
                      skillSet?.workExperience?.lastApproval
                        ? formatDistanceToNow(
                          parse(
                            skillSet?.workExperience?.lastApproval,
                            'yyyy-MM-dd',
                            new Date(),
                          ),
                          {
                            addSuffix: true,
                          },
                        )
                        : ''
                    }
                >
                  {(skillSet?.skills as ProviderSkill[] || []).map(
                    (skill) => (
                      <SkillV4
                        key={skill?.id}
                        name={skill?.name || ''}
                        numJobs={skill?.workExperience?.numJobs}
                        id={skill.id}
                        marketplaceRating={skill?.workExperience?.ratings?.marketPlace}
                        myCompanyRating={skill?.workExperience?.ratings?.myCompany}
                        lastCompletion={
                            skill?.workExperience?.lastApproval
                              ? formatDistanceToNow(
                                parse(
                                  skill?.workExperience?.lastApproval,
                                  'yyyy-MM-dd',
                                  new Date(),
                                ),
                                {
                                  addSuffix: true,
                                },
                              )
                              : ''
                          }
                      />
                    ),
                  )}
                </SkillSet>
              ),
            )}
          </ProjectType>
        </React.Fragment>
      ))}
    </div>
  );
}
