import {
  DEFAULT_ADDRESS_COMPONENT_MAPPING,
  DEFAULT_AUTOCOMPLETE_COUNTRY,
  DEFAULT_PLACE_DETAILS_FIELDS,
  DEFAULT_PLACE_TYPES,
} from './constants';
import { IFullAddressInputValues, ISimpleAddressInputValue } from './types.d';

export const autocompleteRequestBuilder = (
  input: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
  autocompletionRequest?: google.maps.places.AutocompletionRequest | undefined,
  targetCountry?: string,
): google.maps.places.AutocompletionRequest => {
  const req: google.maps.places.AutocompletionRequest = {
    ...(autocompletionRequest || {}),
    input,
  };

  req.sessionToken = sessionToken;
  req.types = autocompletionRequest?.types || DEFAULT_PLACE_TYPES;

  req.componentRestrictions = {
    country: targetCountry
      ? [targetCountry]
      : autocompletionRequest?.componentRestrictions?.country ||
        DEFAULT_AUTOCOMPLETE_COUNTRY,
  };

  return req;
};

export const autocompleteResponseBuilder = (
  input: string,
  suggestions: google.maps.places.AutocompletePrediction[] | null,
): ISimpleAddressInputValue[] => {
  if (suggestions) {
    const googleSuggestions: ISimpleAddressInputValue[] = (suggestions || []).map((suggestion) => ({
      label: suggestion.description,
      value: suggestion.structured_formatting.main_text,
      place_id: suggestion.place_id,
      fullAddress: {
        address1: suggestion.structured_formatting.main_text,
        address2: suggestion.structured_formatting.main_text,
        city: suggestion.terms[2]?.value,
        state: suggestion.terms[3]?.value,
        zip: '',
        country: suggestion.terms[4]?.value?.slice(0, -1),
      },
    }));

    /** We are adding whatever the user typed as an option */
    googleSuggestions.push({
      label: input,
      value: input,
    });

    return googleSuggestions;
  }
  return [
    {
      label: input,
      value: input,
    },
  ];
};

export const placeDetailsRequestBuilder = (
  placeId: string,
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  placeDetailsRequest?: google.maps.places.PlaceDetailsRequest | undefined,
): google.maps.places.PlaceDetailsRequest => ({
  ...(placeDetailsRequest || {}),
  placeId,
  sessionToken,
  fields: placeDetailsRequest?.fields || DEFAULT_PLACE_DETAILS_FIELDS,
});

export const placeDetailsResponseBuilder = (
  placeDetails: google.maps.places.PlaceResult | null,
  placeDetailsStats: google.maps.places.PlacesServiceStatus,
  addressComponentMapping: (
    placeDetails: google.maps.places.PlaceResult | null,
  ) => IFullAddressInputValues = DEFAULT_ADDRESS_COMPONENT_MAPPING,
): ISimpleAddressInputValue | null => {
  if (!placeDetails) return null;
  const { name = '' } = placeDetails;
  if (placeDetailsStats === google.maps.places.PlacesServiceStatus.OK) {
    const fullAddress: IFullAddressInputValues = addressComponentMapping(
      placeDetails,
    );
    return {
      label: name,
      value: name,
      fullAddress,
    };
  }
  // eslint-disable-next-line no-console
  console.log(`PLACE_DETAILS_STATUS: ${placeDetailsStats}`);
  return null;
};

export const getPlaceDetailsFromPlaceId = async (
  placeId: string,
  token: google.maps.places.AutocompleteSessionToken | undefined,
  placeService: google.maps.places.PlacesService | undefined,
  placeDetailsRequest?: google.maps.places.PlaceDetailsRequest | undefined,
  addressComponentMapping?: (
    placeDetails: google.maps.places.PlaceResult | null,
  ) => IFullAddressInputValues,
): Promise<ISimpleAddressInputValue | null> =>
  // eslint-disable-next-line implicit-arrow-linebreak
  new Promise((resolve) => {
    if (placeService) {
      placeService.getDetails(
        placeDetailsRequestBuilder(placeId, token, placeDetailsRequest),
        (placeResult, status) => {
          resolve(
            placeDetailsResponseBuilder(
              placeResult,
              status,
              addressComponentMapping,
            ),
          );
        },
      );
    } else {
      resolve(null);
    }
  });
