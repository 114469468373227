import React from 'react';
import { Link } from '@fieldnation/platform-components';
import css from './styles.scss';
import { IProps } from './types.d';
import SUPPORT_CASE_URL from './Constants';

const SupportText: React.FC<IProps> = ({ label }: IProps): JSX.Element => (
  <span className={css.subtext}>
    <Link
      href={SUPPORT_CASE_URL}
      target="_blank"
    >
      Contact support
    </Link>
    {` to change ${label.toLowerCase()}`}
  </span>
);

export default SupportText;
