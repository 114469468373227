/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Grid,
  GridColumn,
  Median,
  MedianAlpha,
  MedianOmega,
  v2,
} from '@fieldnation/platform-components';

import css from '../styles.scss';
import { useEditPermission } from '../../../hooks';
import {
  MAX_MIDDLE_INITIAL_LENGTH,
  MAX_SUFFIX_LENGTH,
  ProfileInfoItems,
  RequestTypes,
  VALIDATION_DEBOUNCE_TIME,
} from '../../../constants';
import {
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
  InvalidInputHelpMessage,
} from '../../../components';
import {
  getInputFieldAtom,
  getInputValidationFieldAtom,
  getInputValidatorCallback,
  getRequestConfigCallback,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  passwordProtectedRequestAtom,
  resetFormCallback,
  resetValidationErrorsCallback,
  userContextSelector,
} from '../state';

const { TextInput } = v2;

const FullNameEditFrom = () => {
  const { isStaff } = useRecoilValue(userContextSelector);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);

  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.FullName),
  );

  const atoms = ['first_name', 'last_name', 'middle_initial', 'suffix'];

  const [
    [currentFirstName, setCurrentFirstName],
    [currentLastName, setCurrentLastName],
    [currentMiddleInitial, setCurrentMiddleInitial],
    [currentSuffix, setCurrentSuffix],
  ] = atoms.map((atomName) => useRecoilState(getInputFieldAtom(atomName)));

  const [
    firstNameError,
    lastNameError,
    middleNameError,
    suffixError,
  ] = atoms.map((atomName) =>
    useRecoilValue(getInputValidationFieldAtom(atomName)),
  );

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);

  const onSave = async () => {
    const requestConfig = await getRequestConfig(RequestTypes.UPDATE_FULLNAME);

    const updateCallback = async () => {
      const success = await makeRequest(
        requestConfig,
        'Successfully updated name',
      );
      if (success) await resetForm(atoms);
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)(...atoms);

  const inputFieldValidator = useRecoilCallback(getInputValidatorCallback);
  const resetInputErrors = useRecoilCallback(resetValidationErrorsCallback);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'first_name';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentFirstName, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'last_name';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentLastName, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'middle_initial';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentMiddleInitial, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'suffix';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentSuffix, isEditing]);

  return (
    <div>
      {!isEditing && (
        <BodyText styleLevel="md" tag="span">
          <span
            className={classNames({
              [css['list-item-content']]: true,
              [css['list-item-content-empty']]: !currentFirstName,
            })}
          >
            {currentFirstName || 'No first name'}
          </span>
          <span className={css['list-item-content']}>
            {currentMiddleInitial ? ` ${currentMiddleInitial}.` : ''}
          </span>
          <span
            className={classNames({
              [css['list-item-content']]: true,
              [css['list-item-content-empty']]: !currentLastName,
            })}
          >
            {` ${currentLastName || 'No last name'}`}
          </span>
          <span className={css['list-item-content']}>
            {currentSuffix ? `, ${currentSuffix}` : ''}
          </span>
        </BodyText>
      )}
      {isEditing && (
        <Grid vSpace={false}>
          <GridColumn xs="12" sm="12" md="12" lg="9" xl="9">
            <Grid>
              <GridColumn xs="12" sm="12" md="12" lg="8" xl="8">
                <TextInput
                  label="First Name"
                  value={currentFirstName}
                  onChange={(e) => {
                    setCurrentFirstName(e.target.value);
                  }}
                />
                <InvalidInputHelpMessage validationError={firstNameError} />
              </GridColumn>
              <GridColumn xs="12" sm="12" md="12" lg="4" xl="4">
                <TextInput
                  label="Middle Initial"
                  optional
                  // @ts-ignore
                  maxlength={MAX_MIDDLE_INITIAL_LENGTH}
                  value={currentMiddleInitial}
                  onChange={(e) => {
                    setCurrentMiddleInitial(e.target.value);
                  }}
                />
                <InvalidInputHelpMessage validationError={middleNameError} />
              </GridColumn>
              <GridColumn xs="12" sm="12" md="12" lg="8" xl="8">
                <TextInput
                  label="Last Name"
                  value={currentLastName}
                  onChange={(e) => {
                    setCurrentLastName(e.target.value);
                  }}
                />
                <InvalidInputHelpMessage validationError={lastNameError} />
              </GridColumn>
              <GridColumn xs="12" sm="12" md="12" lg="4" xl="4">
                <TextInput
                  label="Suffix"
                  optional
                  // @ts-ignore
                  maxlength={MAX_SUFFIX_LENGTH}
                  value={currentSuffix}
                  onChange={(e) => {
                    setCurrentSuffix(e.target.value);
                  }}
                />
                <InvalidInputHelpMessage validationError={suffixError} />
              </GridColumn>
            </Grid>
            <ProfileInfoFormActionButton
              onClose={onClose}
              onSave={onSave}
              isValid={
                firstNameError.isValid &&
                lastNameError.isValid &&
                middleNameError.isValid &&
                suffixError.isValid
              }
            />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export const FullName = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.FullName);
  const setOpenForEdit = useSetRecoilState(openForEditAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.FullName),
  );

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="span">
        <span className={css['list-item-name']}>Legal Name</span>
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top">
        <MedianAlpha>
          <FullNameEditFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="legal name" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.FullName);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
