import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Descendant } from 'slate';
import {
  useQuery,
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueryClient,
} from 'react-query';
import {
  v2,
  Button,
  Section,
  Loader,
  Grid,
  GridColumn,
  Pagination,
  ActionTabs,
} from '@fieldnation/platform-components';
import Note from './Note';
import SlateEditor from '../SlateEditor';

import {
  getWorkorderNotesByWorkorderId,
  getProfileNotesByProviderId,
  addWorkorderNote,
  addProfileNote,
  deleteWorkorderNote,
  deleteProfileNote,
} from './api';

import css from './ProviderNotes.scss';
import { NoteResponseValues } from './types.d';

const { Checkbox, Modal } = v2;

interface IProps {
  workorderId: number;
  userId: number;
  type: 'Workorder' | 'Profile';
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // don't refresh any data more than once per minute
      staleTime: 60 * 1000,
    },
  },
});

const initialTextTemplate = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const ProviderNotes = ({ workorderId, userId, type = 'Profile' }: IProps) => {
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState<Descendant[]>(initialTextTemplate);
  const [activeNote, setActiveNote] = useState<NoteResponseValues | null>(null);
  const [page, setPage] = useState(1);
  const [noteType, setNoteType] = useState(type);
  const [woNotesCount, setWoNotesCount] = useState(0);
  const [profileNotesCount, setProfileNotesCount] = useState(0);
  const [refreshCounts, setRefreshCounts] = useState(0);
  const [copyToProfile, setCopyToProfile] = useState([0]);
  const isOnWoDPage = !!workorderId;

  const queryKey = [`${noteType}_notes`, { workorderId, userId, page }];
  const perPage = isOnWoDPage ? 5 : 25;

  const queryClientFC = useQueryClient();

  const { isLoading, isError, data } = useQuery(
    queryKey,
    async () =>
      noteType === 'Workorder'
        ? getWorkorderNotesByWorkorderId(workorderId, userId, {
            page,
            perPage,
          }).then((res: any) => {
            setWoNotesCount(res?.metadata?.total || '0');
            return res;
          })
        : getProfileNotesByProviderId(userId, { page, perPage }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    // this is weird, but I can't get counts to stay fresh especially when you can disable and re-enable this section
    // of the drawer since react-query caches the data so gotta refresh the counts manually...
    if (isOnWoDPage) {
      getProfileNotesByProviderId(userId, { page, perPage })
        .then((resp: any) => {
          setProfileNotesCount(resp?.metadata?.total || '0');
        })
        .catch((err) => {
          console.error(err);
        });
      getWorkorderNotesByWorkorderId(workorderId, userId, { page, perPage })
        .then((r: any) => {
          setWoNotesCount(r?.metadata?.total || '0');
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [userId, refreshCounts]);

  const totalPages =
    Math.ceil(data?.metadata?.total / data?.metadata?.per_page) || 1;
  const totalNotes = data?.metadata?.total || 0;

  const mutation = useMutation(
    noteType === 'Workorder' ? addWorkorderNote : addProfileNote,
    {
      onSuccess: () => {
        setAddNoteOpen(false);
        setNoteText(initialTextTemplate);
        queryClientFC.invalidateQueries([
          `${noteType}_notes`,
          { workorderId, userId },
        ]);
        setPage(1);
        setRefreshCounts(refreshCounts + 1);
        setCopyToProfile([0]);
      },
    },
  );

  const deleteNoteMutation = useMutation(
    noteType === 'Workorder' ? deleteWorkorderNote : deleteProfileNote,
    {
      onSuccess: () => {
        setActiveNote(null);
        queryClientFC.invalidateQueries([
          `${noteType}_notes`,
          { workorderId, userId },
        ]);
        setRefreshCounts(refreshCounts + 1);
        setPage(1);
      },
    },
  );

  useEffect(() => {
    if (
      (mutation.isSuccess || deleteNoteMutation.isSuccess) &&
      noteType === 'Workorder'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line no-unused-expressions
      window?._fnRedux?.dispatch?.({
        type: 'FETCH_PROVIDER_NOTES',
        workOrderId: workorderId,
        providerId: userId,
      });
    }
  }, [mutation.isSuccess, deleteNoteMutation.isSuccess]);

  const addNewNote = (textVal: string) => {
    mutation.mutate({
      workorder_id: workorderId,
      provider_id: userId,
      note: textVal,
      copyToProfile,
    });
  };

  const handleNoteDelete = (note: NoteResponseValues): void => {
    setDialogOpen(true);
    setActiveNote(note);
  };

  const handleCopyToProfile = (val) => {
    setCopyToProfile(val);
  };

  return (
    <Loader
      isLoading={
        isLoading || mutation.isLoading || deleteNoteMutation.isLoading
      }
      size="sm"
    >
      <Section>
        {isOnWoDPage && (
          <ActionTabs
            items={[
              {
                label: 'Work order notes',
                count: woNotesCount,
                active: noteType === 'Workorder',
                onClick: () => setNoteType('Workorder'),
              },

              {
                label: 'Profile notes',
                count: profileNotesCount,
                active: noteType === 'Profile',
                onClick: () => setNoteType('Profile'),
              },
            ]}
          />
        )}
        {dialogOpen && (
          <Modal
            isOpen
            roundedCorners
            size="small"
            header="Confirm note deletion"
            onClose={() => setDialogOpen(false)}
            headerDivider={false}
          >
            <div
              style={{
                marginBottom: '2rem',
              }}
            >
              {`By deleting this note, It will no longer be available to anyone in
            ${noteType === 'Workorder' ? 'this work order.' : 'your company.'}`}
            </div>
            <Grid>
              <GridColumn sm="6">
                <Button
                  type="secondary"
                  block
                  onClick={() => setDialogOpen(false)}
                >
                  Cancel
                </Button>
              </GridColumn>
              <GridColumn sm="6">
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    setDialogOpen(false);
                    if (activeNote) {
                      deleteNoteMutation.mutate({
                        workorder_id: activeNote.workorder_id,
                        provider_id: activeNote.provider_id,
                        note_id: activeNote.id,
                      });
                    }
                  }}
                >
                  Delete note
                </Button>
              </GridColumn>
            </Grid>
          </Modal>
        )}
        <div
          style={
            !isOnWoDPage
              ? { display: 'flex', justifyContent: 'space-between' }
              : {}
          }
          className={css['margin-vertical--sm']}
        >
          {!isOnWoDPage && (
            <h4
              style={{
                fontSize: '1.8rem',
                letterSpacing: 0,
                lineHeight: '2.2rem',
              }}
            >
              Notes
            </h4>
          )}
          <Button
            label="Add note"
            icon="icon-plus"
            type="text"
            size="sm"
            onClick={() => setAddNoteOpen((s) => !s)}
          />
        </div>
        {addNoteOpen && (
          <div className={classNames(css['add-note-container'])}>
            <SlateEditor
              initialValue={noteText}
              onCancel={() => {
                setAddNoteOpen(false);
                setNoteText(initialTextTemplate);
              }}
              onSave={addNewNote}
            >
              <p className={css['helper-text']}>
                {`${
                  noteType === 'Workorder'
                    ? 'Notes are specific to this work order. Notes are not visible to the provider.'
                    : 'Notes are specific to this provider and are not visible to the provider.'
                }`}
              </p>
              {noteType === 'Workorder' ? (
                <Grid>
                  <GridColumn xs="12" md="12">
                    <Checkbox
                      onChange={handleCopyToProfile}
                      options={[
                        { label: 'Copy to provider profile notes', value: 1 },
                      ]}
                      value={copyToProfile}
                    />
                  </GridColumn>
                </Grid>
              ) : null}
              <div className={classNames(css['add-note-footer'])} />
            </SlateEditor>
          </div>
        )}
        {isError ? (
          <p>There was an error loading notes for this provider</p>
        ) : (
          data?.response
            ?.filter((n: NoteResponseValues) => n.provider_id === userId)
            .map((note: NoteResponseValues) => (
              <Note
                key={note.id}
                note={note}
                onDelete={() => handleNoteDelete(note)}
                type={noteType}
              />
            ))
        )}
        {!data?.response || data?.response?.length === 0 ? (
          noteType === 'Profile' && (
            <div className={css['margin-sm']}>
              There are no company notes for this technician.
            </div>
          )
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '1.8rem' }} className={css['margin-sm']}>
              {`Showing ${page * perPage - perPage + 1} - ${Math.min(
                page * perPage,
                totalNotes,
              )} of ${totalNotes}`}
            </div>
            <div className={css['margin-sm']}>
              <Pagination
                onNext={() => {
                  setPage(page + 1);
                }}
                disableNext={page === totalPages}
                onPrev={() => {
                  setPage(page - 1);
                }}
                disablePrev={page === 1}
              />
            </div>
          </div>
        )}
        <div className="u-padding-bottom--sm" />
      </Section>
    </Loader>
  );
};

const ProviderNotesWrapper = ({ workorderId, userId, type }: IProps): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <ProviderNotes workorderId={workorderId} userId={userId} type={type} />
  </QueryClientProvider>
);

ProviderNotes.propTypes = {
  workorderId: PropTypes.number,
  userId: PropTypes.number.isRequired,
  type: PropTypes.string,
};

ProviderNotesWrapper.propTypes = {
  workorderId: PropTypes.number,
  userId: PropTypes.number.isRequired,
  type: PropTypes.string,
};

ProviderNotes.defaultProps = {
  workorderId: 0,
  type: 'Workorder',
};

ProviderNotesWrapper.defaultProps = {
  workorderId: 0,
  type: 'Workorder',
};

export default ProviderNotesWrapper;
