/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  QualificationInterface, CategoryType, V2Qualification, V2CategoryType,
} from '../types.d';

export const v2QualificationTypes = {
  CERTIFICATIONS: 'certifications',
  COVID19: 'covid19',
  EIN: 'ein',
  EQUIPMENTS: 'equipments',
  INSURANCES: 'insurances',
  LICENSES: 'licenses',
  REGISTERED_SERVICE_COMPANY: 'registeredServiceCompany',
  BACKGROUND_CHECK: 'backgroundCheck',
  DRUG_TEST: 'drugTest',
};

export function setLocalStorage(key, value, ttl): void {
  const now = new Date();
  const item = {
    value,
    expiry: Number(ttl) ? now.getTime() + Number(ttl) : 0,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorage(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry && item.expiry > 0) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export const defaultSelectedScreening = {
  isScreeningOpen: false,
  userId: 0,
  screeningId: '',
  screening: '',
};

export const formatV2Requirements = (data) => Object.keys(data).reduce((acc: V2Qualification[], key) => {
  if (!data[key].length) return acc;
  const qsRequirements = data[key].map((req) => {
    let catId = req.categoryDisplayName === 'Provider Type' ? 14 : (req.category || 0);
    if (req.categoryDisplayName === 'Company') catId = 9;

    return {
      ...req,
      category: catId,
      id: req.id || -99,
      canEdit: false,
      qualification: {
        id: req.id || -99,
        name: req.name,
        category: catId,
      },
    };
  });
  return [...acc, ...qsRequirements];
}, []).sort((a, b) => {
  if (a.displayOrder < b.displayOrder) return -1;
  if (a.displayOrder < b.displayOrder) return 1;
  return 0;
});

export const formatV2Qualifications = (data) => Object.keys(data).reduce((acc: QualificationInterface[], key) => {
  if (!data[key].length) return acc;
  let catId = data[key][0].categoryDisplayName === 'Provider Type' ? 14 : (data[key][0].category || -99);
  if (data[key][0].categoryDisplayName === 'Company') catId = 9;

  const categories = [{
    id: data[key][0].category || -99,
    category: {
      label: data[key][0].categoryDisplayName,
      value: catId,
      displayOrder: data[key][0].displayOrder,
      disabled: false,
    },
    qualificationIds: data[key].filter((req) => req.id > 0).map((req) => req.id),
  }];
  return [...acc, ...categories];
}, []);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapV2Requirements = (rawData: any) => Object
  .values(v2QualificationTypes)
  .reduce((payload, requirementName) => {
    let requirementIds: number[] = [];
    if (requirementName === v2QualificationTypes.COVID19) {
      requirementIds = rawData
        .filter((req) => (req.qualification?.category === CategoryType.REQUIRE_C19_VACCINE))
        .map((req) => req.qualification?.id);
    } else {
      requirementIds = rawData
        .filter((req) => V2CategoryType[requirementName] === req.qualification?.category)
        .map((req) => req.qualification?.id);
    }
    return {
      ...payload,
      [requirementName]: requirementIds,
    };
  }, {});

export const formatData = (category, actionData): QualificationInterface[] => {
  const qual: QualificationInterface[] = [];
  (category || []).forEach((cat: CategoryType) => {
    switch (cat) {
      case CategoryType.CERTIFICATION: {
        const filterCertification = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.CERTIFICATION && canEdit,
        );
        qual.push({
          id: CategoryType.CERTIFICATION,
          category: {
            label: 'Certifications',
            value: CategoryType.CERTIFICATION,
            disabled: false,
          },
          qualificationIds: filterCertification?.map(({ qualification: q }) => q.id),
        });
        break;
      }
      case CategoryType.LICENSE: {
        const filterLicense = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.LICENSE && canEdit,
        );
        qual.push({
          id: CategoryType.LICENSE,
          category: {
            label: 'Licenses',
            value: CategoryType.LICENSE,
            disabled: false,
          },
          qualificationIds: filterLicense?.map(({ qualification: q }) => q.id),
        });
        break;
      }
      case CategoryType.INSURANCE: {
        const filterInsurance = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.INSURANCE && canEdit,
        );
        qual.push(
          {
            id: CategoryType.INSURANCE,
            category: {
              label: 'Insurance',
              value: CategoryType.INSURANCE,
              disabled: false,
            },
            qualificationIds: filterInsurance?.map(({ qualification: q }) => q.id),
          },

        );
        break;
      }
      case CategoryType.EQUIPMENT: {
        const filterEquipment = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.EQUIPMENT && canEdit,
        );
        qual.push(
          {
            id: CategoryType.EQUIPMENT,
            category: {
              label: 'Equipment',
              value: CategoryType.EQUIPMENT,
              disabled: false,
            },
            qualificationIds: filterEquipment?.map(({ qualification: q }) => q.id),
          },
        );
        break;
      }
      case CategoryType.REQUIRE_C19_VACCINE: {
        const filterC19Vax = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.REQUIRE_C19_VACCINE && canEdit
        );
        qual.push(
          {
            id: CategoryType.REQUIRE_C19_VACCINE,
            category: {
              label: 'COVID-19 Vaccination Proof',
              value: CategoryType.REQUIRE_C19_VACCINE,
              disabled: false,
            },
            qualificationIds: filterC19Vax?.map(({ qualification: q }) => q.id),
          },
        );
        break;
      }
      case CategoryType.BACKGROUND_CHECK: {
        const filterBgTest = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.BACKGROUND_CHECK && canEdit);
        qual.push(
          {
            id: CategoryType.BACKGROUND_CHECK,
            category: {
              label: 'Background Check',
              value: CategoryType.BACKGROUND_CHECK,
              disabled: false,
            },
            qualificationIds: filterBgTest?.map(({ qualification: q }) => q.id),
          },
        );
        break;
      }
      case CategoryType.DRUG_TEST: {
        const filterDT = actionData?.requirements?.filter(
          ({ qualification, canEdit }) => qualification.category === CategoryType.DRUG_TEST && canEdit);
        qual.push(
          {
            id: CategoryType.DRUG_TEST,
            category: {
              label: 'Drug Test',
              value: CategoryType.DRUG_TEST,
              disabled: false,
            },
            qualificationIds: filterDT?.map(({ qualification: q }) => q.id),
          },
        );
        break;
      }
      default:
        break;
    }
  });
  return qual;
};

export const mapRequirements = (actionData) => {
  let formattedQualifications: QualificationInterface[];
  let formattedRequirements: V2Qualification[];

  if (actionData.version === 3) {
    formattedQualifications = formatV2Qualifications(actionData.results);
    formattedRequirements = formatV2Requirements(actionData.results);
  } else {
    const requirements = actionData?.requirements?.filter(({ canEdit }) => canEdit);
    const category = [
      ...new Set(requirements?.map(({ qualification }) => qualification.category)),
    ];
    const formData = { ...actionData, requirements };
    formattedQualifications = formatData(category, formData);
    formattedRequirements = actionData?.requirements;
  }

  return [formattedQualifications, formattedRequirements];
};
