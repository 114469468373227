import React, { Suspense, useEffect } from 'react';
import classNames from 'classnames';
import { RecoilRoot, useRecoilState } from 'recoil';
import get from 'lodash/get';
import { Loader } from '@fieldnation/platform-components';
import { PageHeader, TileManager } from '../../components';
import CurrentPackage from '../CurrentPackageCard';
import { CurrentCompanyAtom } from '../common/CurrentCompany.atom';
import { UiUserContext } from '../common/UiUserContext.atom';
import PricingTable from '../PricingTable';

import css from './AccountOverview.scss';
import SubscriptionCard from '../SubscriptionCard';
import { AmplitudeClientAtom } from '../common/amplitude';

const AccountOverviewComponent = (): JSX.Element => {
  const [, setCurrentCompany] = useRecoilState(CurrentCompanyAtom);
  const [, setUserContext] = useRecoilState(UiUserContext);
  const [, setEventTracker] = useRecoilState(AmplitudeClientAtom);

  useEffect(() => {
    setCurrentCompany(Number(get(window, 'company.id', 0)));
    setUserContext(get(window, 'context', {}));
    if (get(window, '_amplitudeClient.logEvent')) {
      setEventTracker(get(window, '_amplitudeClient'));
    }
  }, []);

  const isSubscription = get(window, 'contract.subscription.enabled', false);

  return (
    <div>
      <PageHeader headline="Package Overview" headlineStyle="h3" isFlash />
      <div className={classNames(css['account-overview-content'])}>
        <TileManager itemsPerRow="2">
          <CurrentPackage />
          {isSubscription && <SubscriptionCard />}
        </TileManager>
        <PricingTable />
      </div>
    </div>
  );
};

const AccountOverview = (): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader fixed isLoading />}>
      <AccountOverviewComponent />
    </Suspense>
  </RecoilRoot>
);

export default AccountOverview;
