/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import { selector } from 'recoil';
import { JSObject } from './types.d';

export interface ICounterResponse {
  limit: number;
  count: number;
  created_at: number;
}

export const CurrentMonthWOCountSelector = selector<
  ICounterResponse | JSObject
>({
  key: 'CurrentMonthWOCountSelector',
  get: async (): Promise<ICounterResponse | JSObject> => {
    try {
      const counterResponse: ICounterResponse = get(
        window,
        'marketplace_wo_limit',
        {},
      );
      // may make api calls here directly to counter
      // currently reusing data that already exists
      return counterResponse;
    } catch (error) {
      return {};
    }
  },
});
