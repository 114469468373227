import { Median, MedianAlpha, Link } from '@fieldnation/platform-components';
import React, { memo } from 'react';
import classes from './styles.scss';

interface UbiHeaderProps {
  isModal?: boolean;
  isOnboarding?: boolean;
}

const UbiHeader = ({ isModal, isOnboarding }: UbiHeaderProps): JSX.Element => (
  <div className={`${isModal ? classes.HeadingPadding : ''}`}>
    <div
      className={
        isModal ? classes.ModalMainHeadingMargin : classes.MainHeadingMargin
      }
    >
      <Median>
        <MedianAlpha>
          <span
            className={`${classes.MainHeading} ${classes.HeadingLineHeight}`}
          >
            Washington Workers&apos; Compensation
          </span>
        </MedianAlpha>
      </Median>
    </div>
    <Median>
      <MedianAlpha>
        <span
          className={`${classes.SubHeading} ${classes.DescriptionLineHeight}`}
        >
          Please answer these questions below regarding Washington Workers&apos;
          Compensation. This will
          { isOnboarding ? <br /> : ' '}
          help Field Nation
          { !isOnboarding ? <br /> : ' '}
          determine your current status. For more information, please review the
          {' '}
          <Link href="https://www.lni.wa.gov/" target="_blank">
            Washington Labor & Industries webpage
          </Link>
          .
        </span>
      </MedianAlpha>
    </Median>
  </div>
);

UbiHeader.defaultProps = {
  isModal: false,
  isOnboarding: false,
};

export default memo(UbiHeader);
