/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  BodyText,
  Grid,
  GridColumn,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
  v2,
} from '@fieldnation/platform-components';

import css from '../styles.scss';
import { useEditPermission } from '../../../hooks';
import {
  ProfileInfoItems,
  RequestTypes,
  VALIDATION_DEBOUNCE_TIME,
} from '../../../constants';
import {
  ProfileInfoEditButton,
  ProfileInfoFormActionButton,
  StandardGridLayout,
  DisabledContent,
  InvalidInputHelpMessage,
  InfoBox,
} from '../../../components';
import {
  cellProviderListSelector,
  getInputFieldAtom,
  getInputValidationFieldAtom,
  getInputValidatorCallback,
  getRequestConfigCallback,
  isEditingSelector,
  makeRequestCallback,
  onEditFromCloseCallback,
  openForEditAtom,
  passwordProtectedRequestAtom,
  resetFormCallback,
  resetValidationErrorsCallback,
  userContextSelector,
  userInfoAtom,
} from '../state';

const { TextInput, Select } = v2;

const CellPhoneNumberEditFrom = () => {
  const { id: userId = 0 } = useRecoilValue(userInfoAtom);
  const { isStaff } = useRecoilValue(userContextSelector);
  const setSecureCallback = useSetRecoilState(passwordProtectedRequestAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.CellNumber),
  );

  const atoms = ['cell', 'cell_provider'];

  const [
    [currentCell, setCurrentCell],
    [currentCellProvider, setCurrentCellProvider],
  ] = atoms.map((atomName) => useRecoilState(getInputFieldAtom(atomName)));

  const cellError = useRecoilValue(getInputValidationFieldAtom('cell'));

  const cellProviderList = useRecoilValue(cellProviderListSelector);
  const getCellProviderValue = useMemo(() => {
    if (typeof currentCellProvider === 'string') {
      return cellProviderList.find(
        ({ label }) => label === currentCellProvider,
      );
    }
    return currentCellProvider;
  }, [currentCellProvider]);

  const getRequestConfig = useRecoilCallback(getRequestConfigCallback);
  const makeRequest = useRecoilCallback(makeRequestCallback);
  const resetForm = useRecoilCallback(resetFormCallback);

  const onSave = async () => {
    const requestConfig = await getRequestConfig(
      RequestTypes.UPDATE_CELLNUMBER,
    );

    const updateCallback = async () => {
      const success = await makeRequest(
        requestConfig,
        'Successfully updated cell phone information',
      );
      if (success) await resetForm(atoms);
    };

    if (isStaff) {
      await updateCallback();
    }

    if (!isStaff) {
      setSecureCallback(() => updateCallback);
    }
  };

  const onClose = useRecoilCallback(onEditFromCloseCallback)(...atoms);

  const inputFieldValidator = useRecoilCallback(getInputValidatorCallback);
  const resetInputErrors = useRecoilCallback(resetValidationErrorsCallback);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'cell';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentCell, isEditing]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const atomName = 'cell_provider';
    if (isEditing) {
      timer = setTimeout(
        () => inputFieldValidator(atomName),
        VALIDATION_DEBOUNCE_TIME,
      );
    }
    return () => {
      resetInputErrors(atomName);
      if (timer) clearTimeout(timer);
    };
  }, [currentCellProvider, isEditing]);

  const hasCellProvider = Boolean(
    getCellProviderValue?.value || getCellProviderValue,
  );

  const itemInfo = useMemo(
    () => (
      <span>
        By providing your cell phone number you allow Field Nation to send text
        alerts to your phone. Standard message and data rates apply. Change
        alerts in
        <Link
          target="_blank"
          href={`/profile/templates/notification_setting_popup.php?user_id=${userId}&nav=1`}
        >
          {' Notifications '}
        </Link>
        tab. View our
        <Link target="_blank" href="https://www.fieldnation.com/privacy">
          {' Privacy Policy '}
        </Link>
        or
        <Link target="_blank" href="/legal/">
          {' Terms & Conditions.'}
        </Link>
      </span>
    ),
    [userId],
  );

  return (
    <div>
      {!isEditing && (
        <Grid>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <BodyText styleLevel="md" tag="span">
              <span
                className={classNames({
                  [css['list-item-content']]: true,
                  [css['list-item-content-empty']]: !currentCell,
                })}
              >
                {currentCell || 'No cell number'}
              </span>
            </BodyText>
          </GridColumn>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <BodyText styleLevel="md" tag="span">
              <span
                className={classNames({
                  [css['list-item-content']]: true,
                  [css['list-item-content-empty']]: !currentCellProvider,
                })}
              >
                {currentCellProvider?.label ||
                  currentCellProvider ||
                  'No cell provider'}
              </span>
            </BodyText>
          </GridColumn>
        </Grid>
      )}
      {isEditing && (
        <Grid vSpace={false}>
          <GridColumn xs="12" sm="12" md="12" lg="6" xl="6">
            <Grid>
              <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                <TextInput
                  label="Cell Phone Number"
                  type="phone"
                  value={currentCell}
                  onChange={(e) => {
                    setCurrentCell(e.target.value);
                    setCurrentCellProvider(getCellProviderValue);
                  }}
                />
                <InvalidInputHelpMessage validationError={cellError} />
              </GridColumn>
              <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
                <Select
                  label="Cell Carrier"
                  value={getCellProviderValue || {}}
                  options={cellProviderList}
                  onChange={setCurrentCellProvider}
                />
                <InvalidInputHelpMessage
                  validationError={{
                    isValid: hasCellProvider,
                    errorMessage: 'Invalid cell provider',
                  }}
                />
              </GridColumn>
            </Grid>
          </GridColumn>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <InfoBox message={itemInfo} />
          </GridColumn>
          <GridColumn xs="12" sm="12" md="12" lg="12" xl="12">
            <ProfileInfoFormActionButton
              onClose={onClose}
              onSave={onSave}
              isValid={cellError?.isValid && hasCellProvider}
            />
          </GridColumn>
        </Grid>
      )}
    </div>
  );
};

export const CellNumber = (): JSX.Element => {
  const canEdit = useEditPermission(ProfileInfoItems.CellNumber);
  const setOpenForEdit = useSetRecoilState(openForEditAtom);
  const isEditing = useRecoilValue(
    isEditingSelector(ProfileInfoItems.CellNumber),
  );

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="div">
        <span className={css['list-item-name']}>Cell Phone</span>
      </BodyText>
    </div>
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top" noWrap>
        <MedianAlpha>
          <CellPhoneNumberEditFrom />
        </MedianAlpha>
        {!isEditing && (
          <MedianOmega>
            <ProfileInfoEditButton
              disabled={!canEdit}
              disabledContent={<DisabledContent itemName="cell number" />}
              onClick={() => {
                setOpenForEdit(ProfileInfoItems.CellNumber);
              }}
            />
          </MedianOmega>
        )}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
