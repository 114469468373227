import React from 'react';
import { Link } from '@fieldnation/platform-components';

// eslint-disable-next-line import/prefer-default-export
export const supportMessage = (contactSupportHandler): JSX.Element => (
  <>
    If you no longer use the email address associated with your Field Nation account, you can
    {' '}
    <Link
      onClick={() => contactSupportHandler()}
    >
      Contact Support
    </Link>
    {' '}
    for help restoring access to your account.
  </>
);

export const ERROR_MESSAGE = 'Code is invalid. Please try again.';
