import * as React from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { format } from 'date-fns';
import { timelinessDetails } from '../queries/timeliness-details.query';
import Chart from './Chart';

import css from './chart.scss';

import { ChartToolTips } from '../types.d';

const CustomizedTooltip = ({ active, payload = [] }: ChartToolTips) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const {
    late, startDate, endDate,
  } = payload[0]?.payload;
  return (
    <div className={classNames(css['chart-tooltip'], 'u-padding--md')}>
      <div style={{ color: '#636b88' }} className="u-padding-bottom--sm">
        {format(new Date(startDate), 'MMMM/dd')}
        {' - '}
        {format(new Date(endDate), 'MMMM/dd')}
      </div>
      <div style={{ color: '#162741' }}>
        Inaccurate Check-ins:
        {' '}
        {late}
      </div>
    </div>
  );
};

CustomizedTooltip.defaultProps = {
  active: false,
  payload: [],
};

const TimelinessChart = (): JSX.Element => {
  const { graphData = [] } = useRecoilValue(timelinessDetails);
  const count = graphData.reduce((acc, cur) => acc + (cur.late || 0), 0);

  return (
    <div style={{ height: '100%' }}>
      <Chart
        legendAlign="right"
        data={graphData}
        areas={[
          {
            id: 'one',
            name: ` (${count}) Inaccurate Check-ins`,
            dataKey: 'late',
            colorHash: '#268ed9',
          },
        ]}
        tooltTip={
          <CustomizedTooltip />
        }
      />
    </div>
  );
};

export default TimelinessChart;
