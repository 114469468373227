import {
  CredentialCustomInput,
  CredentialInputData, CustomFields,
} from './types';

export const validateLCInputs = (
  values,
  setValidation,
  customInputs,
  isAtlasVerified,
  vendorName = '',
  dynamicInputRecords
) => {
  const error: Partial<CredentialInputData> = {};
  if (!values?.credentialId) {
    error.credentialId = 1;
  }

  if (vendorName?.toLowerCase() !== 'atlas') {
    if (!values?.issuedAt?.trim()) {
      error.issuedAt = 'Issue date is required';
    }
    if (!values?.expiresAt?.trim()) {
      error.expiresAt = 'Expiration date is required';
    }
  }
  if (isAtlasVerified === false) {
    if ((!values?.credentialIdNumber?.trim())) {
      error.credentialIdNumber = 'credentialIdNumber is required';
    }
    if ((!values?.uploadImageUrl?.trim())) {
      error.uploadImageUrl = 'Document scan or PDF is required for verification';
    }
  } else {
    if (!values?.issuingOrgId) {
      error.issuingOrgId = 1;
    }
    if (values?.issuingOrgId) {
      const fields = [] as CredentialCustomInput[];
      dynamicInputRecords.forEach((record) => {
        const foundInput: CredentialCustomInput = customInputs.find(input => input.fieldName === record);
        if (foundInput === undefined) {
          fields.push({
            fieldName: record,
            fieldValue: `${record} is Required`
          });
        } if (foundInput && foundInput?.fieldValue?.trim() === '') {
          fields.push({
            fieldName: record,
            fieldValue: `${record} is Required`
          });
        }
      });
      if (fields?.length) {
        error.customInputs = fields;
      }
    }
  }
  setValidation(error);
  return Object.keys(error)?.length === 0;
};

export const validateCredentialInputs = (values: CredentialInputData, ignoreFields: string[] = []) => {
  const error: Partial<CredentialInputData> = {};
  ignoreFields =
    values?.credential?.verifiedOn
      ? [...ignoreFields, 'issuedAt', 'expiresAt']
      : [];

  if (values?.credential?.verifiedOn && !values?.vendorId) {
    error.vendorId = 'Please select the issuing state';
  }

  if (values?.credential?.verifiedOn && !values?.isEdit && values?.typesOfWorks?.length === 0) {
    error.typesOfWorks = 'Please select at least one option below';
  }

  if (values?.credential?.verifiedOn && !values?.isEdit && values?.typesOfWorks?.includes('Other')) {
    error.typesOfWorks = 'Please specify other value';
  }

  if (!values?.credential?.verifiedOn && !values?.isEdit && !values?.file?.trim()) {
    error.file = `Please upload a preview of your ${values?.credential?.type?.toLowerCase()}`;
  }

  if (!values?.credentialIdNumber?.trim()) {
    error.credentialIdNumber = `Please enter a valid ${values?.credential?.type?.toLowerCase()} number`;
  }

  if (!values?.issuedAt?.trim?.() && !ignoreFields?.find(field => field === 'issuedAt')) {
    error.issuedAt = 'Please provide an issue date';
  }

  if (
    !values?.expiresAt?.trim?.() &&
    !values.noExpireDate &&
    !ignoreFields?.find(field => field === 'expiresAt')
  ) {
    error.expiresAt = 'Please indicate an expiration';
  }

  if (values?.fields?.length) {
    const fields: CustomFields[]  = [];
    values.fields.forEach((field) => {
      if (field.fieldValue === '') {
        fields.push({
          ...field,
          fieldValue: `Please enter a valid ${(
            generateLabel(field.fieldName) || ''
          ).toLowerCase()}`,
        });
      }
    });

    if (fields?.length) {
      error.fields = fields;
    }
  }

  return error;
};
export const generateLabel = (label: string) => (label.split(/(?=[A-Z])/) || []).join(' ');
