import * as React from 'react';

export interface QualificationInterface {
    id: CategoryType;
    category?: {
        label: string;
        value: CategoryType;
        displayOrder?: number;
        disabled: boolean;
    };
    qualificationIds: number[];
}
export interface ContextInterface {
    dispatch?: any;
    state?: any;
    isEditting?: boolean,
    isManager?: boolean,
    canDelete?: boolean,
    isLoading?: boolean,
    requirements?: any,
    qualifications?: any,
    users?: any,
    workOrderId?: number,
    templateId?: number,
    userId?: number,
}
export interface QsOptionsInterface {
    category?: string;
    options?: QDropdownOptionsInterface[];
}

export interface QDropdownOptionsInterface {
    value?: any;
    label?: string;
    [key: string]: any;
}

interface QualificationRequirementsWidgetWrapperProps {
    companyId?: number;
    templateId?: number;
    userId: number;
    workOrderId?: number;
    isWoDetails?: boolean;
}

type SaveQualificationsResponse =
    | {error: any, success?: boolean}
    | {error?: never, success: boolean}

export enum CategoryType {
  CERTIFICATION = 1,
  LICENSE = 2,
  INSURANCE = 3,
  BACKGROUND_CHECK = 4,
  DRUG_TEST = 5,
  EQUIPMENT = 8,
  REGISTERED_SERVICE_COMPANY = 9,
  TALENT_POOL = 10,
  TYPE_OF_WORK = 11,
  REQUIRE_C19_VACCINE = 15
}

export enum V2CategoryType {
  certifications = 1,
  licenses = 2,
  insurances = 3,
  equipments = 8,
  registeredServiceCompany = 9,
  covid19 = 15,
  backgroundCheck = 4,
  drugTest = 5,
}

export enum VerificationType {
  SELF_REPORTED = 1,
  FIELD_NATION = 3
}

interface IResolution {
  type: string;
  actionLabel: string;
  api: ResolutionApi;
  link: string;
}

export interface V2Qualification {
    id: number;
    name: stgring;
    dynamicTermId?: number;
    type?: string;
    verifiedById?: number;
    sourceId?: number;
    isMatch: boolean;
    required?: boolean;
    categoryDisplayName?: string;
    name?: string;
    resolution: IResolution;
    verificationDisplayName?: string;
    category: CategoryType;
    displayOrder: number;
    loading?: boolean;
}

interface MonolithWorkOrderRole {
  role: string;
  status_id: number;
  assigned_user_id: number;
  manager_id: number;
  service_company_id: number;
  sub_roles: string[];
  active_managed_provider: number;
  work_order_company_id: number;
  managed_work_order: boolean;
}

export class QualificationRequirementsWidgetWrapper extends React.Component<QualificationRequirementsWidgetWrapperProps, any> {}

