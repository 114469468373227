import React from 'react';
import PropTypes from 'prop-types';
import {
  Loader,
  Grid,
  GridColumn,
  Section,
  v2,
  Link,
} from '@fieldnation/platform-components';
import css from './styles.scss';
import { supportMessage, ERROR_MESSAGE } from './utils';

const {
  Modal,
  // @ts-ignore
  NotificationBand,
  OtpInput,
} = v2;

const OtpVerificationComponentModal = ({
  email,
  error,
  loading,
  otp,
  closeHandler,
  submitHandler,
  changeHandler,
  resendOtpHandler,
  contactSupportHandler,
}): JSX.Element => {
  return (
    <>
      <Modal
        onClose={closeHandler}
        isOpen
        header="Edit this section"
        footerActions={[
          {
            type: 'secondary',
            label: 'Cancel',
            onClick: closeHandler,
          },
          {
            type: 'primary',
            label: 'Continue',
            onClick: submitHandler,
          },
        ]}
        size="medium"
      >
        <Loader isLoading={loading}>
          <Section>
            <Grid>
              <GridColumn>
                <p className={css['Body--title']}>Check your email</p>
              </GridColumn>
              <GridColumn>
                Enter the code we sent to
                {' '}
                {email}
              </GridColumn>
              <GridColumn>
                <OtpInput
                  label="Enter the 6-digit code"
                  value={otp}
                  fieldValidation={error ? 'error' : ''}
                  onChange={changeHandler}
                />
                {error && (
                  <p style={{ color: '#a51d2b' }}>{ERROR_MESSAGE}</p>
                )}
                <p style={{ marginTop: '16px' }} className={css['Body--resend-text']}>
                  Didn’t receive it?
                  {' '}
                  <Link
                    onClick={() => resendOtpHandler()}
                  >
                    Send it again.
                  </Link>
                </p>
              </GridColumn>
              <GridColumn>
                <NotificationBand
                  secondaryText={supportMessage(contactSupportHandler)}
                  type="info"
                />
              </GridColumn>
            </Grid>
          </Section>
        </Loader>
      </Modal>
    </>
  );
};

OtpVerificationComponentModal.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  otp: PropTypes.any.isRequired,
  closeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
  resendOtpHandler: PropTypes.func.isRequired,
  contactSupportHandler: PropTypes.func.isRequired,
  changeHandler: PropTypes.func.isRequired,
};

OtpVerificationComponentModal.defaultProps = {
  loading: false,
  error: false,
};

export default OtpVerificationComponentModal;
