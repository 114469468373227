/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import React, { ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  IFullAddressInputAttribute,
  IFullAddressInputValues,
} from '../../SmartAddress/types.d';
import { DisabledContent, InvalidInputHelpMessage } from '../components';

import {
  userContextSelector,
  userInfoAtom,
} from '../widgets/PersonalInfo/state';
import { useCountryAndState } from './useCountryAndState';

interface IUseAddressInputAttribute {
  inputAttribute: IFullAddressInputAttribute;
  canSubmit: boolean;
}
const getInvalidContent = (message?: string) => {
  if (message) {
    return (
      <InvalidInputHelpMessage message={`Invalid ${message.toLowerCase()}`} />
    );
  }
  return undefined;
};

export function useAddressInputAttribute(
  currentInputValue: IFullAddressInputValues,
): IUseAddressInputAttribute {
  const { isStaff } = useRecoilValue(userContextSelector);
  const { country: initialCountry = '' } = useRecoilValue(userInfoAtom);
  const { country } = useCountryAndState(
    currentInputValue?.country,
    currentInputValue?.state,
  );

  return useMemo<IUseAddressInputAttribute>(() => {
    const isCountryDisabled = initialCountry && !isStaff;
    let canSubmit = true;
    let countryContent: ReactNode;

    let address1Content: string | undefined;
    let address2Content: string | undefined;
    let cityContent: string | undefined;
    let stateContent: string | undefined;
    let zipContent: string | undefined;

    if (isCountryDisabled) {
      countryContent = <DisabledContent itemName="country" />;
    }

    if (!currentInputValue?.country && !isCountryDisabled) {
      canSubmit = false;
      countryContent = <InvalidInputHelpMessage message="Invalid Country" />;
    }

    if (country?.address1?.required && !currentInputValue?.address1) {
      canSubmit = false;
      address1Content = country?.address1?.label || 'address line 1';
    }

    if (country?.address2?.required && !currentInputValue?.address2) {
      canSubmit = false;
      address2Content = country?.address2?.label || 'address line 2';
    }

    if (country?.city?.required && !currentInputValue?.city) {
      canSubmit = false;
      cityContent = country?.city?.label || 'city';
    }

    if (country?.state?.required && !currentInputValue?.state) {
      canSubmit = false;
      stateContent = country?.state?.label || 'state';
    }

    if (country?.zip?.required && !currentInputValue?.zip) {
      canSubmit = false;
      zipContent = country?.zip?.label || 'zip code';
    }

    return {
      inputAttribute: {
        address1: {
          additionalContent: getInvalidContent(address1Content),
        },
        address2: {
          additionalContent: getInvalidContent(address2Content),
        },
        city: {
          additionalContent: getInvalidContent(cityContent),
        },
        state: {
          additionalContent: getInvalidContent(stateContent),
        },
        zip: {
          additionalContent: getInvalidContent(zipContent),
        },
        country: {
          disabled: isCountryDisabled,
          additionalContent: countryContent,
        },
      },
      canSubmit,
    };
  }, [...Object.values(currentInputValue), initialCountry]);
}
