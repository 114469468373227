import { BodyText } from '@fieldnation/platform-components';
import React from 'react';
import css from './styles.scss';
import { IProps } from './types.d';

const OptionalText: React.FC<IProps> = ({
  label = ' - Optional',
}: IProps): JSX.Element => (
  <BodyText styleLevel="md" tag="span">
    <span className={css['optional--text']}>{label}</span>
  </BodyText>
);

export default OptionalText;
