/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import css from './WorkAndSkillWidget.scss';
import Rating from './Rating';
import { useSkillsModal } from './WorkAndSkillWidget';

import { SkillResponse } from '../types.d';

export interface SkillProps {
  name: string;
  numJobs?: number;
  marketplaceRating?: number;
  myCompanyRating?: number;
  additional?: SkillResponse[];
  lastJobCompleted?: string;
  id?: number;
  userId?: number;
}

export default function Skill({
  additional,
  lastJobCompleted,
  userId,
  id,
  ...props
}: SkillProps): JSX.Element {
  const [toggleSkillsModal, skillsModal] = useSkillsModal('profile');

  const inner = ({
    name,
    numJobs,
    marketplaceRating,
    myCompanyRating,
  }: SkillProps) => (
    <div
      key={name}
      className={css.SkillOuter}
    >
      {skillsModal}
      <div className={css.SkillWrapper}>
        <div className={css.SkillTitleWrapper}>
          <strong className={css.SkillTitle}>
            {name}
          </strong>
          <Rating
            rating={marketplaceRating}
            type="marketplace"
            hideType
          />
          <Rating
            rating={myCompanyRating}
            type="my_company"
            hideType
          />
        </div>
        {numJobs ? (
          <div>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleSkillsModal({
                  userId: userId || 0,
                  skill: { id: id || 0, name },
                });
              }}
            >
              <b>{numJobs}</b>
              {' '}
              job
              {numJobs === 1 ? '' : 's'}
            </a>
            {lastJobCompleted ? (
              <div className={css.SubTitle}>
                {' '}
                &#8226;
                {' Last job completed '}
                {lastJobCompleted}
                {' ago'}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className={css.Skill}>
      {inner(props)}
      {(additional || []).map((subProps) => inner({
        ...subProps,
        numJobs: subProps?.workExperience?.numJobs,
        marketplaceRating: subProps?.workExperience?.ratings?.marketplace,
        myCompanyRating: subProps?.workExperience?.ratings?.myCompany,
        lastJobCompleted: subProps?.workExperience?.lastApproval,
      }))}
    </div>
  );
}
