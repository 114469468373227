/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Button } from '@fieldnation/platform-components';

import { DisabledContent } from './item-disabled-content';
import {
  IProfileInfoEditButton,
  IProfileInfoFormActionButton,
} from './types.d';

import css from './styles.scss';

export const ProfileInfoEditButton = ({
  disabled = false,
  disabledContent = <DisabledContent />,
  onClick,
}: IProfileInfoEditButton): JSX.Element => (
  <div className={css['edit--button']}>
    {disabled ? (
      <div className={css['edit--disabled--content']}>{disabledContent}</div>
    ) : (
      <Button
        icon="edit"
        onClick={onClick}
        key="edit"
        shouldUseIconComponent
        type="text"
        className={css['edit--button--icon']}
      />
    )}
  </div>
);

export const ProfileInfoFormActionButton = ({
  onClose,
  onSave,
  isValid = false,
}: IProfileInfoFormActionButton): JSX.Element => (
  <div className={css['edit-form-action-buttons']}>
    <Button
      type="primary"
      size="lg"
      onClick={onSave}
      label="Save"
      disabled={!isValid}
    />
    &nbsp;&nbsp;
    <Button type="text" size="lg" onClick={onClose} label="Close" />
  </div>
);
