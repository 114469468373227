import * as React from 'react';
import { Section } from '@fieldnation/platform-components';
import { useRecoilValue } from 'recoil';
import WrapListItem from '../Components/WrapListItem';
import WrapSection from './WrapSection';
import workOrderTypeOfWorkQuery from '../queries/work-order-type-of-work.query';
import typesOfWorkQuery from '../queries/types-of-work.query';

import { MonolithProfile, CustomJobDisplay } from '../types.d';

interface Props {
  user: MonolithProfile;
  collapse?: boolean;
  customJobDisplay?: CustomJobDisplay;
}

const Jobs = ({
  user, collapse, customJobDisplay,
}: Props): JSX.Element | null => {
  const tow = useRecoilValue(workOrderTypeOfWorkQuery);
  const tows = useRecoilValue(typesOfWorkQuery);
  if (!tows || !user) {
    return null;
  }

  const matched = (tows?.data || []).find(
    ({ user_type_of_work_id }) => (tow?.id || 0) === user_type_of_work_id,
  );

  // Ability to specify custom job types and counts
  const hasCustomJobs = (customJobDisplay
    && Array.isArray(customJobDisplay?.jobs)
    && customJobDisplay?.jobs.length > 0);

  // Provide custom count for top-level number
  const matchCount = customJobDisplay?.customMatchCount || matched?.jobs || 0;

  return (
    <WrapSection
      label="Jobs"
      subHeading={tow ? tow.name : ''}
      value={`${matchCount}`}
      collapse={collapse}
    >
      {hasCustomJobs && customJobDisplay?.jobs?.map((job) => (
        <Section key={`${job?.label}`}>
          <Section>
            <WrapListItem
              label={job?.label || ''}
              value={`${job?.count || 0}`}
            />
          </Section>
        </Section>
      ))}
      <Section key="Marketplace">
        <Section>
          <WrapListItem
            label="Marketplace"
            value={`${user?.jobs?.marketplace || 0}`}
          />
        </Section>
      </Section>
    </WrapSection>
  );
};

Jobs.defaultProps = {
  collapse: false,
  customJobDisplay: undefined,
};

export default Jobs;
