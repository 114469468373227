import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Grid, GridColumn, Icon, IconAside, IconAsideIcon, Search,
} from '@fieldnation/platform-components';
import styles from './styles.scss';

const GroupList = ({
  errorMessage, preSelected, enableSearch, searchPlaceholder, items, sideComponent, onChange,
}: GroupListsProps): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const [lists, setLists] = useState<GroupItems[]>(items || []);
  const [selected, setSelected] = useState<GroupLists | null>(preSelected);

  useEffect(() => {
    const filteredItems = (items || []).map((g: GroupItems) => {
      const groupName = g?.name || '';
      const searchTerms = search.toLowerCase().split(/\s+/);
      const groupLists = (g.lists || []).filter((c: GroupLists) =>
        searchTerms.every(term =>
          (c?.label || '').toLowerCase().includes(term.replace(/\W/g, ''))
        )
      ) || [];
      return {
        name: groupName,
        lists: groupLists.length > 0 ? groupLists : [],
      };
    });
    setLists(filteredItems);
  }, [search, items]);

  const handleSelected = (list: GroupLists) => {
    setSelected(list);
    onChange(list);
  }

  return (
    <Grid>
      <GridColumn xs="12">
        {!!enableSearch && (
          <div className={styles.searchWrapper}>
            <div className={styles.search}>
              <Search
                applyFocus
                value={search}
                disableAutoComplete
                iconDirection="right"
                placeholder={searchPlaceholder}
                onChange={(e) => setSearch(e.target.value || '')}
                onClearSearch={() => setSearch('')}
              />
            </div>
            <div className={styles.separator}></div>
          </div>
        )}
        {!!errorMessage && (
          <div className={styles.errorWrapper}>
            <IconAside iconPosition="left" iconColor="red">
              <IconAsideIcon verticalAlign="middle">
                <Icon name="alertSolid" size={16} block />
              </IconAsideIcon>
              <div className={styles.errorMessage}>
                {errorMessage}
              </div>
            </IconAside>
          </div>
        )}
        <div className={styles.groupListsWrapper}>
          <div className={styles.listsBody}>
            {(lists || []).map((item: GroupItems) => {
              if ((item.lists || []).length) {
                return (
                  <div className={styles.group}>
                    <div className={styles.groupName}>{item.name || ''}</div>
                    {(item.lists || []).map((list: GroupLists) => (
                      <div className={classNames({
                        [styles.listItem]: true,
                        [styles.activeItem]: !!(selected?.value && selected?.value === list?.value),
                      })} aria-hidden="true" onClick={() => handleSelected(list)}>
                        <div className={styles.listContent}>
                          <div className={styles.listName}>{list.label || ''}</div>
                          <div className={styles.listType}>
                            <span>{list.type || ''}</span>
                            {list?.showSideComponent &&
                              sideComponent
                            }
                          </div>
                        </div>
                        {!!(selected?.value && selected?.value === list?.value) && (
                          <div className={styles.listIcon}>
                            <Icon name="check" size={24} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              }
              return <></>;
            })}
          </div>
        </div>
      </GridColumn>
    </Grid>
  );
};

GroupList.propTypes = {
  enableSearch: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  value: PropTypes.object,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  preSelected: PropTypes.object
};

GroupList.defaultProps = {
  enableSearch: true,
  searchPlaceholder: '',
  hasError: false,
  preSelected: null,
};
export default GroupList;
