import * as React from 'react';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  v2, InfoIcon, Grid, GridColumn,
} from '@fieldnation/platform-components';
import TimelinessList from './TimelinessList';
import TimelinessChart from './TimelinessChart';
import { modalfilters, timelinessDetails } from '../queries/timeliness-details.query';
import ModalFilters from './ModalFilters';

import css from './backouts-details.scss';

import { BackoutsModalProps } from '../types.d';

const { Modal } = v2;

const TimelinessModal = ({
  userId,
  onClose,
  backoutsFilters,
  useAlternateNaming,
}: BackoutsModalProps): JSX.Element => {
  const { canViewList } = useRecoilValue(timelinessDetails);
  const setFilters = useSetRecoilState(modalfilters);
  const filters = useRecoilValue(modalfilters);
  const dayHistory = backoutsFilters?.dayHistory === 0 && backoutsFilters?.pastHistory === 0
    ? 0 : backoutsFilters?.dayHistory;

  useEffect(() => {
    setFilters({
      userId,
      ...backoutsFilters,
      dayHistory,
      graphType: 'monthly',
    });
  }, []);

  const modalHeader = () => (
    <div className={css['backouts-details-header']}>
      <span className={css['header-label']}>{useAlternateNaming ? 'Timeliness' : 'Check-in Accuracy'}</span>
      <InfoIcon>
        Timeliness is measured on work orders with a hard start and only check-ins within 15 minutes
        of the work order&apos;s start time are considered on time.
      </InfoIcon>
    </div>
  );

  return (
    <Modal
      isOpen
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      header={modalHeader()}
      onClose={onClose}
      size="large"
    >
      <div className={css['backouts-details-modal-wrapper']}>
        <div>
          <Grid>
            <GridColumn md="12">
              <Grid>
                <GridColumn md="4" sm="12">
                  <ModalFilters
                    filters={filters}
                    setFilters={setFilters}
                  />
                </GridColumn>
                <GridColumn md="8" sm="12" data-testid="chart-container-id">
                  <TimelinessChart />
                </GridColumn>
              </Grid>
            </GridColumn>
            <GridColumn md="12">
              <div className={css['horizontal-divider']}><hr /></div>
            </GridColumn>
            <GridColumn md="12">
              {canViewList && <TimelinessList />}
            </GridColumn>
          </Grid>
        </div>
      </div>
    </Modal>
  );
};

TimelinessModal.defaultProps = {
  backoutsFilters: {},
};

export default TimelinessModal;
