/* eslint-disable lodash/import-scope */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { get as lodashGet, memoize } from 'lodash';
import { atom, atomFamily, selectorFamily } from 'recoil';
import { ProfileInfoItems } from '../../../constants';
import { IJsObject } from '../../../types.d';
import { IValidationError } from './types.d';

export const userInfoAtom = atom<IJsObject>({
  key: 'userInfoAtom',
  default: {},
});

export const openForEditAtom = atom<ProfileInfoItems>({
  key: 'openForEditAtom',
  default: '' as ProfileInfoItems,
});

export const isLoadingAtom = atom({
  key: 'isLoadingAtom',
  default: false,
});

export const getInputFieldAtom = memoize((fieldName: string) =>
  atomFamily({
    key: `getInputFieldAtom_${fieldName}`,
    default: selectorFamily({
      key: `getInputFieldSelector_${fieldName}`,
      get: (param: string) => ({ get }) =>
        lodashGet(get(userInfoAtom), param, ''),
    }),
  })(fieldName),
);

type callbackType = () => Promise<void>;

export const passwordProtectedRequestAtom = atom<boolean | callbackType>({
  key: 'passwordProtectedRequestAtom',
  default: false,
});

export const getInputValidationFieldAtom = memoize((fieldName: string) =>
  atom<IValidationError>({
    key: `getInputValidationFieldAtom_${fieldName}`,
    default: { isValid: false },
  }),
);
