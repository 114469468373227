import { selector } from 'recoil';
import currentWorkOrder from './current-work-order.atom';
import { getTypeOfWorkByWorkOrder } from '../api';

import { MonolithTypeOfWork } from '../types.d';

const workOrderTypeOfWorkQuery = selector<null | MonolithTypeOfWork>({
  key: 'workOrderTypeOfWork',
  get: async ({ get }) => {
    const workOrderId = get(currentWorkOrder);

    if (!workOrderId) {
      return null;
    }

    const tow = await getTypeOfWorkByWorkOrder(workOrderId);

    return tow || null;
  },
});

export default workOrderTypeOfWorkQuery;
