/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo } from 'react';
import { InfoIcon, v2 } from '@fieldnation/platform-components';
import classes from './styles.scss';

const { Radio } = v2;

interface UbiRadioOptionProps {
    id: string,
    label: string,
    radioValue: string,
    infoMessage: JSX.Element,
    onChange(value: string): void,
    toolTipPosition: 'top' | 'right' | 'bottom' | 'bottom-left' | 'left',
}

const UbiRadioOption = ({
  id,
  label,
  radioValue,
  infoMessage,
  toolTipPosition,
  onChange,
}: UbiRadioOptionProps): JSX.Element => (
  <div className={classes.RadioOptions}>
    <Radio
      id={id}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      legend={(
        <div className={classes.RadioContainer}>
          <div className={classes.OptionLabel}>{label}</div>
          <div className={classes.InfoSpacing}>
            <InfoIcon position={toolTipPosition}>{infoMessage}</InfoIcon>
          </div>
        </div>
      )}
      value={radioValue}
      // @ts-ignore
      onChange={(value) => onChange(value)}
      options={[
        {
          label: 'Yes',
          value: '1',
        },
        { label: 'No', value: '0' },
      ]}
      viewType="vertical"
    />
  </div>
);

export default memo(UbiRadioOption);
