/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import Accordion from '../Components/Accordion';
import AccordionContent from '../Components/AccordionContent';
import { toggleSidebarConfigCollapse } from '../queries/snapshot.query';

interface Props {
  label: string;
  subHeading?: string;
  value?: string;
  children: React.ReactNode;
  customHeaderContent?: React.ReactNode;
  headerLeftIcon?: React.ReactNode;
  headerRightIcon?: React.ReactNode;
  collapse?: boolean;
  fullWidth?: boolean;
  subNode?: React.ReactNode;
}

const WrapSection: React.FC<Props> = ({
  label,
  subHeading,
  value = '',
  children,
  collapse,
  customHeaderContent = undefined,
  headerLeftIcon = undefined,
  headerRightIcon = undefined,
  fullWidth = false,
  subNode = undefined,
}: Props) => {
  const updateDrawerConfigCollapse = useSetRecoilState(toggleSidebarConfigCollapse);

  return (
    <Accordion
      label={label}
      onExpand={() => {
        // @ts-ignore
        updateDrawerConfigCollapse(label);
      }}
      onCollapse={() => {
        // @ts-ignore
        updateDrawerConfigCollapse(label);
      }}
      subHeading={subHeading || ''}
      value={value}
      border="top"
      showBadge={false}
      expand={!collapse}
      middleContent=""
      // @ts-ignore
      subNode={subNode}
      expanderHideButtonStyling={false}
      customHeaderContent={customHeaderContent}
      headerLeftIcon={headerLeftIcon}
      headerRightIcon={headerRightIcon}
    >
      <AccordionContent fullWidth={fullWidth}>
        {children}
      </AccordionContent>
    </Accordion>
  );
};

WrapSection.defaultProps = {
  subHeading: undefined,
  value: '',
  customHeaderContent: undefined,
  headerLeftIcon: undefined,
  headerRightIcon: undefined,
  collapse: false,
  fullWidth: false,
  subNode: undefined,
};

export default WrapSection;
