import React from 'react';
import { Icon } from '@fieldnation/platform-components';
import styles from './FormError.scss';

interface FormErrorProps {
  // eslint-disable-next-line react/require-default-props
  message?: string;
}

const FormError: React.FC<FormErrorProps> = ({ message = 'This value is required' }: FormErrorProps) => {
  if (!message) return null;
  return (
    <div className={styles.errorWrapper}>
      <span className={styles.iconWrapper}>
        <Icon
          name="warningSolid"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          size={20}
        />
      </span>
      <span className={styles.messageText}>
        {message}
      </span>
    </div>
  );
};

export default FormError;
