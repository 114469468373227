import { MonolithProfile } from '../types.d';

export default function isAdminUser(
  context: MonolithProfile,
  user: MonolithProfile,
): boolean {
  if (!context || !context.id) {
    return false;
  }

  return ['admin', 'staff', 'accountant', 'manager', 'dispatcher'].includes(user?.role_type || '');
}
