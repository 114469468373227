/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import {
  v2,
  Grid,
  GridColumn,
  Popover,
  PopoverContainer,
} from '@fieldnation/platform-components';
import css from './Styles.scss';
import {
  detectWindowChange,
  getAllCountries,
  getAllStatesByISO,
} from './helpers';
import {
  PaymentFormProps,
  Validator,
  CountryStateLabel,
} from './types.d';
import {
  formatCardNumber,
  formatCvc,
  formatExp,
  getCardTypeByValue,
  getCardTypeForState,
  hasCardNumberReachedMaxLength,
} from './card-helper';

const {
  Select,
  TextInput,
} = v2;

const defaultStateData: CountryStateLabel = {
  label: '',
  required: false,
  values: [],
};

const PaymentForm = ({
  values,
  onChange,
  formError,
  checkValidation,
}: PaymentFormProps): JSX.Element => {
  const countries = getAllCountries();
  const [selectedStateData, setSelectedStateData] = useState<CountryStateLabel>(defaultStateData);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    detectWindowChange(handleWindowSizeChange);
  }, []);

  const isMobile = width <= 768;

  const onChangeExp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const exp = e.target.value;
    onChange({ ...values, card_exp: formatExp(exp) });
  };

  const onCardChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value.replace(/[^\d]/g, '');

    const cardType = getCardTypeByValue(value);
    let limitReached = false;
    if (cardType?.type === 'amex') {
      limitReached = hasCardNumberReachedMaxLength(value, value.length);
    } else {
      limitReached = hasCardNumberReachedMaxLength(value, value.length - 1);
    }
    if (!limitReached) {
      const fullCardname = getCardTypeForState(value);
      onChange({ ...values, card_type: fullCardname, card_number: formatCardNumber(value) });
    }
  };

  const onChangeCvv = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cvv = e.target.value.trim().slice(0, 4);
    const formatedCvv = formatCvc(cvv);
    onChange({ ...values, card_cvv: formatedCvv });
  };

  const cardOnBlur = () => {
    onChange({ ...values, card_number: values.card_number.replace(/[^\d]/g, '') });
  };

  const handleOnValid = (key: string) => (): Validator => {
    checkValidation();
    const hasError = formError?.[key] || '';
    if (hasError) {
      return {
        type: 'error',
        message: formError?.[key],
      };
    }
    return {} as Validator;
  };

  useEffect(() => {
    const country = getAllStatesByISO(values?.billing_country || 'US');
    setSelectedStateData(country?.state || defaultStateData);
  });

  return (
    <div className={css.paymentFormWrapper}>
      <Grid>
        <GridColumn xs="12">
          <div className={css.paymentInfo}>
            <strong>Credit card information</strong>
          </div>
        </GridColumn>
      </Grid>
      <div className={css.paymentFieldGap}>
        <Grid>

          <GridColumn xs="12" md="6">
            <TextInput
              id="card_number"
              label="Card Number"
              onChange={onCardChangeNumber}
              onBlur={cardOnBlur}
              value={values?.card_number || ''}
              // @ts-ignore
              autoComplete="card_number"
              onValid={handleOnValid('card_number')}
            />
          </GridColumn>
          <GridColumn xs="12" md="6">
            <TextInput
              id="cardholder_name"
              label="Name on card"
              onChange={(e) => onChange({ ...values, cardholder_name: e.target.value })}
              value={values?.cardholder_name || ''}
              // @ts-ignore
              autoComplete="cardholder_name"
              onValid={handleOnValid('cardholder_name')}
            />
          </GridColumn>

        </Grid>
      </div>
      <div className={css.paymentFieldGap}>
        <Grid>
          <GridColumn xs="12" md="6">
            <TextInput
              id="card_exp"
              label="Expiration"
              onChange={onChangeExp}
              value={values?.card_exp || ''}
              // @ts-ignore
              placeholder="MM / YY"
              // @ts-ignore
              autoComplete="card_exp"
              onValid={handleOnValid('card_exp')}
            />
          </GridColumn>
          <GridColumn xs="12" md="6">

            <TextInput
              id="card_cvv"
              data-testid="cvv"
              // @ts-ignore
              label={(
                <span>
                  CVV/Security Code&nbsp;
                  <Popover position={isMobile ? 'top' : 'right'} showOnEvent="hover">
                    <em className="icon-info-circle" />
                    <PopoverContainer>
                      <Grid>
                        <GridColumn xs="4">
                          <img src="https://assets.fieldnation.com/images/accounting/card_standard.png" width="60" height="45" />
                        </GridColumn>
                        <GridColumn xs="8">
                          <div><strong>Visa, Mastercard or Discover</strong></div>
                          <span>3 digits on the back</span>
                        </GridColumn>
                      </Grid>
                      <Grid>
                        <GridColumn xs="4">
                          <img src="https://assets.fieldnation.com/images/accounting/card_amex.png" width="60" height="45" />
                        </GridColumn>
                        <GridColumn xs="8">
                          <div><strong>American Express</strong></div>
                          <span>4 digits on the back</span>
                        </GridColumn>
                      </Grid>
                    </PopoverContainer>
                  </Popover>
                </span>
)}
              onChange={onChangeCvv}
              value={values?.card_cvv || ''}
              // @ts-ignore
              autoComplete="card_cvv"
              onValid={handleOnValid('card_cvv')}
            />
          </GridColumn>
        </Grid>
      </div>
      <Grid>
        <GridColumn xs="12">
          <div className={css.paymentInfo}>
            <strong>Billing Address</strong>
          </div>
        </GridColumn>
      </Grid>
      <div className={css.paymentFieldGap}>
        <Grid>
          <GridColumn xs="12" md="6">
            <TextInput
              id="address1"
              label="Address 1"
              onChange={(e) => onChange({ ...values, billing_address1: e.target.value })}
              value={values?.billing_address1 || ''}
              // @ts-ignore
              autoComplete="adress1"
              onValid={handleOnValid('billing_address1')}
            />
          </GridColumn>
          <GridColumn xs="12" md="6">
            <TextInput
              id="address2"
              label="Address 2"
              optional
              onChange={(e) => onChange({ ...values, billing_address2: e.target.value })}
              value={values?.billing_address2 || ''}
              // @ts-ignore
              autoComplete="address2"
            />
          </GridColumn>
        </Grid>
      </div>
      <div className={css.paymentFieldGap}>
        <Grid>
          <GridColumn xs="12" md="6">
            <TextInput
              id="city"
              label="City"
              onChange={(e) => onChange({ ...values, billing_city: e.target.value })}
              value={values?.billing_city || ''}
               // @ts-ignore
              autoComplete="city"
              onValid={handleOnValid('billing_city')}
            />
          </GridColumn>
          <GridColumn xs="12" md="6">
            <Select
              id="state"
              label={selectedStateData?.label}
              value={values?.billing_state || ''}
              options={selectedStateData?.values}
              optional={selectedStateData?.required === false}
              onChange={(v) => {
                onChange({ ...values, billing_state: String(v?.value || '') });
              }}
              onValid={handleOnValid('billing_state')}
            />
          </GridColumn>
        </Grid>
      </div>
      <div className={css.paymentFieldGap}>
        <Grid>
          <GridColumn xs="12" md="6">
            <TextInput
              id="zip"
              label="Zip Code"
              onChange={(e) => onChange({ ...values, billing_zip: e.target.value })}
              value={values?.billing_zip || ''}
              // @ts-ignore
              autoComplete="zip"
              onValid={handleOnValid('billing_zip')}
            />
          </GridColumn>
          <GridColumn xs="12" md="6">
            <Select
              id="country"
              label="Country"
              value={values?.billing_country || ''}
              options={countries}
              onChange={(v) => {
                onChange({ ...values, billing_country: String(v?.value || 'US') });
              }}
              onValid={handleOnValid('billing_country')}
            />
          </GridColumn>
        </Grid>
      </div>

    </div>
  );
};
export default PaymentForm;
