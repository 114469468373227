import { Loader } from '@fieldnation/platform-components';
import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import get from 'lodash/get';
import Attribute from '../Attribute';
import { IProps, SectionMode } from './types.d';
import { AddressVerificationStatus, AddressVerificationRef } from '../../../AddressVerifiedChip/types.d';
import { sectionIsLoading, sectionModeState } from '../../States/AtomFamilies';
import SectionLabel from '../SectionLabel';
import EditSectionContent from '../EditSectionContent';
import ViewSectionContent from '../ViewSectionContent';

const Section: React.FC<IProps> = ({ section, cardKey, windowRef }: IProps): JSX.Element => {
  const {
    displayName,
    key,
    isOptional,
    contentConfigs,
    customEditComponent,
    onEditConfig,
    editPermissionGroup,
  } = section;
  const mode = useRecoilValue(sectionModeState(key));
  const isLoading = useRecoilValue(sectionIsLoading(key));
  const addressVerified =
    get(window, AddressVerificationRef.WINDOW_REF) === AddressVerificationStatus.VERIFIED;
  return (
    <Suspense fallback={<Loader fixed isLoading />}>
      <Loader isLoading={isLoading} size="md">
        <Attribute
          key={`attribute_${key}`}
          label={(
            <SectionLabel
              label={displayName}
              isOptional={isOptional}
              addressVerified={addressVerified}
            />
            )}
          content={
            mode === SectionMode.VIEW ? (
              <ViewSectionContent
                label={displayName}
                sectionKey={key}
                contentConfigs={contentConfigs}
                editPermissionGroup={editPermissionGroup}
                cardKey={cardKey}
              />
            ) : (
              <EditSectionContent
                label={displayName}
                sectionKey={key}
                contentConfigs={contentConfigs}
                customEditComponent={customEditComponent}
                onEditConfig={onEditConfig}
                sectionDisplayName={displayName}
                cardKey={cardKey}
                windowRef={windowRef}
              />
            )
          }
        />
      </Loader>
    </Suspense>
  );
};

export default Section;
