export default function useWorkOrderId(defaultWorkOrderId?: number): number | undefined {
  if (typeof defaultWorkOrderId === 'number') {
    return defaultWorkOrderId || undefined;
  }

  const m = window?.location?.pathname?.match(/workorders\/(\d+)/g);

  if (m) {
    return Number(m[1]) || undefined;
  }

  const m2 = window?.location?.search?.match?.(
    /[?&](?:work_order_id|workorder_id|workOrderId)=(\d+)/,
  );

  if (m2) {
    return Number(m2[1]) || undefined;
  }

  return undefined;
}
