/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import { selector } from 'recoil';
import { CurrentContractSelector } from './GetContract.selector';
import {
  IUpdateOptions,
  ITiers,
  IOperations,
  IUpdateOptionValue,
} from './types.d';

export const PossibleUpdateOptions = selector({
  key: 'PossibleUpdateOptionsSelector',
  get: async ({ get }): Promise<IUpdateOptions> => {
    const { marketplace_buyer, subscription, contract_type, metadata } = get(
      CurrentContractSelector,
    );
    const current_tier = marketplace_buyer?.tier;

    // SF does not return any hour. So dates are always 0 houred.
    const contractStartsAtUTCtimestamp = Math.floor(
      new Date(metadata?.startsAt).getTime() / 1000,
    );

    const currentUTCTimestamp = Math.floor(new Date().getTime() / 1000);

    const validContractDelay =
      currentUTCTimestamp - contractStartsAtUTCtimestamp >= 86400; // 86400 = 1 day

    const subscriptionOrSigned =
      contract_type?.type?.toLocaleUpperCase() ===
        'CREATED FROM CLOSED/WON OPP' || subscription?.enabled;

    const getOperation = (target_tier: ITiers): IUpdateOptionValue => {
      let operation: IOperations = 'current package';
      let value = false;
      let disable_reason = subscriptionOrSigned
        ? `Your current package is ${current_tier} with an annual contract, therefore downgrade is not available. For more information, please contact your Field Nation account representative.`
        : 'Package upgrade/downgrade not available';
      switch (current_tier) {
        case 'basic':
          if (['plus', 'premier'].includes(target_tier)) {
            operation = 'upgrade';
            value = true;
          }
          break;
        case 'plus':
          if (target_tier === 'basic') {
            operation = 'downgrade';
            value = validContractDelay && !subscriptionOrSigned;
            if (!validContractDelay) {
              disable_reason =
                'You have recently changed the package. Please wait 24 hours before changing the package again.';
            }
          }
          if (target_tier === 'premier') {
            operation = 'upgrade';
            value = true;
          }
          break;
        case 'premier':
          if (['basic', 'plus'].includes(target_tier)) {
            operation = 'downgrade';
            value = !subscriptionOrSigned;
          }
          break;
        default:
          break;
      }
      return {
        operation,
        value,
        disable_reason,
        current_tier,
      };
    };

    return {
      basic: getOperation('basic'),
      plus: getOperation('plus'),
      premier: getOperation('premier'),
    };
  },
});
