import { v2 } from '@fieldnation/platform-components';
import css from '../Styles.scss';
import React from 'react';
import ErrorBlock from './ErrorBlock';

const { TextInput } = v2;

const CredentialNumber = ({
  credentialType,
  onChange,
  value,
  validation,
}) => (<div className={css.inputFields}>
    <TextInput
      id='number'
      label={`${credentialType} number`}
      onChange={(e) => onChange(e.target.value.trim())}
      value={value || ''} />
    <ErrorBlock msg={validation} />
  </div>
);

export default CredentialNumber;

