import * as React from 'react';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import * as PropTypes from 'prop-types';
import TalentPoolComponent from '../../TalentPoolComponent';
import { setTalentPoolsForUser } from '../api';
import trackAmplitudeEvent, {
  ADDED_TO_TALENT_POOL,
  PROFILE_ADD_TO_TALENT_POOL,
  STARTED_ADDING_TALENT,
} from '../amplitude';
import userQuery, { versionState } from '../queries/user.query';
import talentTypeQuery from '../queries/talent-type.query';
import { useTechnicianNetworks } from '../queries/use-networks';

interface Props {
  onClose: () => void,
}

let talentPoolNameKeyUpTimeout: NodeJS.Timeout | null = null;

const EditTalentPools: React.FC<Props> = ({ onClose }) => {
  const data = useRecoilValue(talentTypeQuery);

  if (!data) {
    return null;
  }

  const talentPools = data?.monolithTalentType?.results?.map((ppn) => ({
    label: ppn.name,
    value: Number(ppn.id),
    isTalentPoolRequirements: !!ppn?.isTalentPoolRequirements,
  })) || [];

  const selectTech = useTechnicianNetworks(data?.id);
  const isSelectTech = selectTech && selectTech.length > 0;

  React.useEffect(() => {
    let source = '';
    if (
      window.location.href.includes('/workorders')
          || window.location.href.includes('/market-smart-insights')
    ) {
      source = 'Drawer';
    } else if (window.location.href.includes('/providers')) {
      source = 'Talent';
    } else if (window.location.href.includes('/p/')) {
      source = 'Profile';
    }

    trackAmplitudeEvent({
      category: STARTED_ADDING_TALENT,
      ampProps: {
        Source: source,
        'Select Tech': isSelectTech,
        'Provider ID': data?.id,
        'Provider Count': 1,
      },
    });
  }, [!!onClose]);

  const talentPoolsSelectedDefault: Array<{ label: string, value: number, isTalentPoolRequirements?: boolean }> = [];
  const [disabled, setDisabled] = React.useState(true);
  const [radioValue, setRadioValue] = React.useState('update');
  const [talentPoolsSelected, setTalentPoolsSelected] = React.useState(talentPoolsSelectedDefault);
  const [talentPoolName, setTalentPoolName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const user = useRecoilValue(userQuery);
  const [version, setVersion] = useRecoilState(versionState);

  const talentPoolNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTalentPoolName(e.target.value);
  };

  const talentPoolOnChange = (value: Array<{ label: string, value: number, isTalentPoolRequirements?: boolean }>) => {
    setTalentPoolsSelected(value);
    if (value.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const talentPoolOnValid = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (e.target.value.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const radioOnChange = (value: string) => {
    setRadioValue(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = async (err: Response | Error | any) => {
    setLoading(false);
    if (err instanceof Response) {
      const json = await err.json();
      setErrorMessage(json.message);
    } else if (err instanceof Error) {
      setErrorMessage(err.message);
    } else {
      setErrorMessage('Error creating talent pool.');
    }
  };

  const talentPoolNameOnKeyUp = () => {
    if (talentPoolNameKeyUpTimeout != null) {
      clearTimeout(talentPoolNameKeyUpTimeout);
    }

    talentPoolNameKeyUpTimeout = setTimeout(() => {
      talentPoolNameKeyUpTimeout = null;
      if (talentPoolName) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, 500);
  };

  const talentPoolNameOnValid = (e: { target: { value: string; }; }) => {
    let response = {};
    if (e.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
      response = {
        type: 'error',
        message: 'Text is empty',
      };
      return response;
    }

    if (talentPools?.find((talentPool) => talentPool.label.toLowerCase()
    === e.target.value.toLowerCase())) {
      setDisabled(true);
      response = {
        type: 'error',
        message: 'Talent pool name is already taken. Please try another name.',
      };
    }
    return response;
  };

  const onSave = async () => {
    setLoading(true);

    let talentPoolIds = talentPoolsSelected?.map((talentPool) => Number(talentPool.value)) || [];
    let talentPoolNames = talentPoolsSelected?.map((talentPool) => String(talentPool.label)) || [];
    const { id, networks } = user || {};
    if (!id) {
      return;
    }

    const talentPoolRequirements = talentPoolsSelected
      ?.filter(({ isTalentPoolRequirements }) => !!isTalentPoolRequirements)
      ?.map(({ value }) => value);

    const response: any = await setTalentPoolsForUser(
      id,
      talentPoolIds,
      radioValue === 'create' ? talentPoolName : '',
      { onError },
    );

    setVersion(version + 1);
    let source = '';
    if (
      window.location.href.includes('/workorders')
      || window.location.href.includes('/market-smart-insights')
    ) {
      source = 'Drawer';
    } else if (window.location.href.includes('/providers')) {
      source = 'Talent';
    } else if (window.location.href.includes('/p/')) {
      source = 'Profile';
    }

    if (radioValue === 'create') {
      talentPoolIds = [response?.id];
      talentPoolNames = [talentPoolName];
      trackAmplitudeEvent({
        category: PROFILE_ADD_TO_TALENT_POOL,
        ampProps: {
          Source: source,
          Name: talentPoolNames,
        },
      });
    }

    trackAmplitudeEvent({
      category: ADDED_TO_TALENT_POOL,
      ampProps: {
        Source: source,
        type: radioValue === 'create' ? 'New' : 'Existing',
        'Talent Pool ID': talentPoolIds,
        'Provider ID': [id],
        'Provider Count': 1,
        'Select Tech': !!networks?.results?.find((network: any) => network.isCurated),
        'Smart Talent Pool': !!talentPoolRequirements?.find((tid) => talentPoolIds.includes(tid)),
        Name: talentPoolNames,
      },
    });

    onClose();
  };

  return (
    <TalentPoolComponent
      radioValue={radioValue}
      talentPools={talentPools}
      talentPoolsSelected={talentPoolsSelected}
      talentPoolName={talentPoolName}
      loading={loading}
      disabled={disabled}
      errorMessage={errorMessage}
      radioOnChange={radioOnChange}
      talentPoolOnChange={talentPoolOnChange}
      talentPoolOnValid={talentPoolOnValid}
      talentPoolNameOnChange={talentPoolNameOnChange}
      talentPoolNameOnValid={talentPoolNameOnValid}
      talentPoolNameOnKeyUp={talentPoolNameOnKeyUp}
      closeHandler={onClose}
      saveHandler={onSave}
    />
  );
};

EditTalentPools.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default EditTalentPools;
