import React, { useEffect, useState } from 'react';
import { v2 } from '@fieldnation/platform-components';
import {
  usePlacesLoadOptions,
  useSelectInputFormatter,
  useSelectOnChange,
} from './hooks';
import { ISimpleAddressInput, ISimpleAddressInputValue } from './types.d';
import { DEFAULT_ADDITIONAL_INFO } from './constants';

const { Select } = v2;

const SimpleAddressInput = ({
  label = 'Address Line 1',
  value = '',
  onChange,
  inputAttribute = {},
  autocompletionRequest,
  placeDetailsRequest,
  addressComponentMapping,
  targetCountry,
  additionalInfo,
}: ISimpleAddressInput): JSX.Element => {
  useSelectInputFormatter(['SimpleAddressInput'], ['arrow', 'height']);
  const [addressLineInputValue, setAddressLineInputValue] = useState<
    ISimpleAddressInputValue
  >({
    value,
    label: value,
  });
  const [sessionToken, setSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken | undefined
  >(undefined);

  const loadOptionsPlaces = usePlacesLoadOptions(
    sessionToken,
    addressLineInputValue,
    onChange,
    autocompletionRequest,
    targetCountry,
  );

  const handleOnSelect = useSelectOnChange(
    setAddressLineInputValue,
    sessionToken,
    setSessionToken,
    placeDetailsRequest,
    addressComponentMapping,
  );

  const tooltipMessage = additionalInfo === null
    ? undefined
    : additionalInfo || DEFAULT_ADDITIONAL_INFO;

  useEffect(() => {
    if (window?.google?.maps?.places) {
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
    }
  }, []);

  return (
    <div className="SimpleAddressInput">
      <Select
        additionalInfo={tooltipMessage}
        disabled={inputAttribute?.address1?.disabled}
        label={label}
        id="simpleAddressInput"
        data-testid="simpleAddressInput"
        typeahead
        searchable
        ignoreCase={false}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cache={false}
        noResultsText="No result"
        onChange={handleOnSelect}
        value={{
          // To handle any additional data like fullAddress
          ...addressLineInputValue,
          /**
           * To handle old ui rendering. That uses react life cycle methods.
           * And passes down the value in a wonky way. so eventually this component gets a new prop
           * but the useState for addressLineInputValue above does not update.
           * So we are prioritizing the values here again.
           * First the current internal value, then passed down prop for address line 1, then empty string
           */
          value: addressLineInputValue?.value || value || '',
          label: addressLineInputValue?.label || value || '',
        }}
        loadOptions={loadOptionsPlaces}
      />
      <>{inputAttribute?.address1?.additionalContent}</>
    </div>
  );
};

export default SimpleAddressInput;
