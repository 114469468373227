import { atom, selector } from 'recoil';
import { getBackoutsDetails } from '../api';
import { BackoutsDetailsFilters, BackoutsDetails } from '../types.d';

export const backoutsDetailsFilters = atom<BackoutsDetailsFilters>({
  key: 'backoutsFilters',
  default: {},
});

export const backoutsDetails = selector<BackoutsDetails>({
  key: 'backoutsDetailsData',
  get: async ({ get }) => {
    const filters = get(backoutsDetailsFilters);
    if (!filters?.userId) {
      return {
        canViewList: false, tabs: [], results: [], graphData: [],
      };
    }
    const backoutsDetailsData = await getBackoutsDetails(filters);
    return backoutsDetailsData;
  },
});
