import React from 'react';

interface Props {}

const Pill = ({
}: Props): JSX.Element => (
  <div>
    Pill section will go here
  </div>
);

Pill.defaultProps = {};

export default Pill;
