/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import classNames from 'classnames';
import { parse, formatDistanceToNowStrict } from 'date-fns';
import useTechnicianSkills from './use-technician-skills';
import Category from './Category';
import Skill from './Skill';
import useWorkOrderId from './use-work-order-id';

import css from './WorkAndSkillWidget.scss';

import { SkillResponse } from '../types.d';

export interface SkillSummaryProps {
  userId?: number;
  workOrderId?: number;
}

export default function SkillSummary({
  userId,
  workOrderId: defaultWorkOrderId,
}: SkillSummaryProps): JSX.Element | null {
  const workOrderId = useWorkOrderId(defaultWorkOrderId);
  const data = useTechnicianSkills(userId, workOrderId);

  const matchingCategories =
    (!workOrderId ? data?.categories : data?.matching?.categories) || [];

  if (!data?.categories?.length && workOrderId) {
    return (
      <span className={classNames(css['no-skills-text'], 'text-muted')}>
        There are no matching skills.
      </span>
    );
  }

  if (!data?.categories?.length && !workOrderId) {
    return (
      <span className={classNames(css['no-skills-text'], 'text-muted')}>
        No skills detected from work history.
      </span>
    );
  }

  return (
    <div>
      {matchingCategories.length ? (
        <>
          {matchingCategories.map((category, i) => {
            const [skill, ...rest] = Array.from(
              category?.subCategories
                .reduce((acc, subCat) => {
                  subCat?.skills?.forEach((sk) => acc.add(sk));
                  return acc;
                }, new Set<SkillResponse>())
                .values(),
            );

            return (
              <React.Fragment key={category?.id}>
                <Category name={category?.name} highlighted compact padded={i !== 0} />
                <Skill
                  key={skill?.id}
                  id={skill?.id}
                  name={skill?.name}
                  userId={userId || 0}
                  numJobs={skill?.workExperience?.numJobs}
                  marketplaceRating={
                    skill?.workExperience?.ratings?.marketplace
                  }
                  myCompanyRating={skill?.workExperience?.ratings?.myCompany}
                  lastJobCompleted={
                    skill?.workExperience?.lastApproval
                      ? formatDistanceToNowStrict(
                        parse(
                            skill?.workExperience?.lastApproval,
                            'yyyy-MM-dd',
                            new Date(),
                        ),
                      )
                      : ''
                  }
                  additional={rest}
                />
              </React.Fragment>
            );
          })}
        </>
      ) : null}
    </div>
  );
}
