import { Grid, GridColumn } from '@fieldnation/platform-components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { sectionContentsState } from '../../States/AtomFamilies';
import FileUpload from '../FileUpload';
import ImageContent from '../ImageContent';
import InputTextContent from '../InputTextContent';
import { ContentType } from '../Section/types.d';
import css from './styles.scss';
import { IProps } from './types.d';

const CreateSectionContent: React.FC<IProps> = ({
  sectionKey,
  contentConfigs,
}: IProps): JSX.Element => {
  const contents = useRecoilValue(sectionContentsState(sectionKey));
  const contentStyle = contents.length > 1 ? 'multiple' : 'single';
  return (
    <div>
      <Grid>
        <GridColumn
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          {contentConfigs.map((config) => (
            <div key={config.key} className={css[`section--create--${contentStyle}`]}>
              {config.contentType === ContentType.IMAGE ? (
                <div>
                  <ImageContent
                    href={
                      contents.find((content) => content?.key === config.key)
                        ?.value || ''
                    }
                    version={
                      contents.find((content) => content?.key === config.key)
                        ?.version || 0
                    }
                    alt={config.displayName}
                  />
                  <FileUpload
                    sectionKey={sectionKey}
                    acceptFiles={config.acceptFiles || []}
                    multiple={config.useMultipleFiles || false}
                  />
                </div>
              ) : (
                <InputTextContent
                  sectionKey={sectionKey}
                  config={config}
                  shouldFocus={false}
                />
              )}
            </div>
          ))}
        </GridColumn>
      </Grid>
    </div>
  );
};

export default CreateSectionContent;
