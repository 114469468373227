import React from 'react';

interface Props {}

const CounterOffer = ({
}: Props): JSX.Element => (
  <div>
    Counter offer section will go here
  </div>
);

CounterOffer.defaultProps = {};

export default CounterOffer;
