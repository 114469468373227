import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  v2, Loader, Grid, GridColumn,
} from '@fieldnation/platform-components';
import { emptyForm, formValidate } from './licenses-certifications-utils';
import {
  saveUserLicensesCertifications,
  getLicenseOrCertificationByInput,
  saveDynamicTerm,
  updateUserLicensesCertifications,
} from '../api';
import LicensesAndCertificationsForm from './LicensesAndCertificationsForm';
import Padded from './Padded';
import licenseCertificationQuery from '../queries/licenses-certifications.query';
import { versionState } from '../queries/user.query';

import { UserQualifications } from '../types.d';

const { Modal, Select } = v2;

interface ComProps {
  editId?: number;
  isEdit?: boolean;
  onClose: () => void;
}

const AddLicensesAndCertifications: React.SFC<ComProps> = ({
  onClose,
  editId,
  isEdit,
}) => {
  const licensesCertifications = useRecoilValue(licenseCertificationQuery);
  if (!licensesCertifications) {
    return null;
  }

  const {
    id: userId, data,
  } = licensesCertifications;

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<UserQualifications>({});
  const [values, setValues] = useState<UserQualifications>({});
  const [category, setCategory] = useState('');

  const [version, setVersion] = useRecoilState(versionState);

  useEffect(() => {
    if (editId) {
      setValues({
        ...data.filter((f) => f.id === editId)[0],
      });
    }
  }, []);

  // set form values as state
  const onChange = (lc: UserQualifications) => {
    setValues({
      ...values,
      ...lc,
    });
  };

  // licenses and certifications searching
  const typeaheadByTypeAndInput = (type: string) => (
    input: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback: Function,
  ) => {
    if (input.length < 2) {
      callback(null, {
        options: [],
        complete: true,
      });
      return;
    }
    getLicenseOrCertificationByInput(input, type)
      .then((options) => {
        let opts = (options || []).map(
          ({
            sourceid,
            sourceId,
            name,
          }: {
            sourceid: number;
            sourceId: number;
            name: string;
          }) => ({
            value: sourceId || sourceid,
            label: name,
          }),
        );

        if (options.length === 0 && input) {
          opts = [
            {
              value: 0,
              label: `+ Create "${input}" in ${type}`,
            },
          ];
        }

        setTimeout(() => {
          callback(null, {
            options: opts,
            complete: true,
          });
        }, 500);
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  // upload handle
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFile = (e: any, id: number) => {
    if (e?.target?.files?.length !== 1 || typeof FileReader === 'undefined') {
      onChange({ file: '' });
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      onChange({ file: reader.result || '', id });
    };
  };

  // form validation
  const formValidation = () => {
    const v = formValidate({ ...values, isEdit });
    setValidation(v);
    return Object.keys(v).length === 0;
  };

  const createNewLC = async (name: string) => {
    const text = name.replace(/^\+ Create "([^"]+)" in .*$/, '$1');
    const res = await saveDynamicTerm(text, category) as UserQualifications;
    setValues({
      ...emptyForm,
      ...res,
      dt_number: res?.id,
      category: category === 'certification' ? 1 : 2,
    });
  };

  // save or store the form data
  const onSave = async () => {
    if (values.id === 0) {
      return;
    }
    if (!formValidation()) {
      return;
    }
    setLoading(true);

    if (isEdit) {
      // update
      await updateUserLicensesCertifications(userId, values);
    } else {
      // save
      await saveUserLicensesCertifications(userId, values);
    }

    setVersion(version + 1);
    onClose();
  };

  const heading = isEdit
    ? `Edit ${values.name}`
    : ' Add License or certification';
  return (
    <Modal
      header={heading}
      onClose={onClose}
      isOpen
      footerActions={[
        {
          label: 'Cancel',
          type: 'secondary',
          onClick: onClose,
        },
        {
          label: 'Save Changes',
          type: 'primary',
          onClick: onSave,
        },
      ]}
    >
      {loading && <Loader fixed isLoading />}

      {!isEdit && ( // show only for add
        <Padded>
          <Grid>
            <GridColumn xs="3">
              <Select
                labelHidden
                label="Type"
                options={[
                  { label: 'License', value: 'license' },
                  { label: 'Certification', value: 'certification' },
                ]}
                value={category}
                onChange={(v) => {
                  setCategory(v.value?.toString() || '');
                }}
              />
            </GridColumn>
            <GridColumn xs="9">
              <Select
                optional
                labelHidden
                ignoreCase={false}
                label="Add Licenses & certifications"
                placeholder="Please select a license or certification"
                disabled={!category}
                value=""
                onChange={async ({
                  label: l,
                  value: v,
                }) => {
                  const label = String(l);
                  const value = Number(v);
                  // onchange clear the previous validation if exist
                  setValidation({});
                  if (!value) {
                    await createNewLC(label);
                  } else {
                    setValues({
                      ...emptyForm,
                      id: value,
                      dt_number: value,
                      name: label,
                      category: category === 'license' ? 2 : 1,
                    });
                  }
                }}
                loadOptions={typeaheadByTypeAndInput(category)}
              />
            </GridColumn>
          </Grid>
        </Padded>
      )}

      <Padded>
        <LicensesAndCertificationsForm
          values={values}
          validation={validation}
          onFile={onFile}
          onChange={onChange}
        />
      </Padded>
    </Modal>
  );
};

AddLicensesAndCertifications.propTypes = {
  onClose: PropTypes.func.isRequired,
  editId: PropTypes.number,
  isEdit: PropTypes.bool,
};

AddLicensesAndCertifications.defaultProps = {
  editId: undefined,
  isEdit: false,
};

export default AddLicensesAndCertifications;
