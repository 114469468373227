import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LoaderIndicator } from '@fieldnation/platform-components';
import {
  Wrapper as GoogleMapsWrapper,
  Status,
} from '@googlemaps/react-wrapper';

import {
  DEFAULT_GOOGLE_MAPS_KEY,
  DEFAULT_GOOGLE_MAPS_LIBRARIES,
  DEFAULT_COUNTRY_LIST_PATH,
  DEFAULT_ADDITIONAL_INFO,
} from './constants';
import { ISmartAddressWidget } from './types.d';

import FullAddressInput from './full-address-input';

const SmartAddressWidget = (props: ISmartAddressWidget): JSX.Element => {
  // TODO - Put these props into context instead of passing down to nested components like a mad man
  const {
    apiKey = DEFAULT_GOOGLE_MAPS_KEY,
    countryListValuePath,
    values,
    onChange,
    inputAttribute,
    autocompletionRequest,
    placeDetailsRequest,
    addressComponentMapping,
    additionalInfo,
  } = props;

  const render = useCallback((status: Status) => {
    switch (status) {
      case Status.LOADING:
        return <LoaderIndicator size="sm" />;
      case Status.FAILURE:
        return <>Filed To Load Google Maps</>;
      default:
        return <LoaderIndicator size="sm" />;
    }
  }, []);

  return (
    <div className="SmartAddressWidget">
      <GoogleMapsWrapper
        apiKey={apiKey}
        render={render}
        libraries={DEFAULT_GOOGLE_MAPS_LIBRARIES}
      >
        <FullAddressInput
          values={values}
          onChange={onChange}
          inputAttribute={inputAttribute}
          countryListValuePath={countryListValuePath}
          autocompletionRequest={autocompletionRequest}
          placeDetailsRequest={placeDetailsRequest}
          addressComponentMapping={addressComponentMapping}
          additionalInfo={additionalInfo}
        />
      </GoogleMapsWrapper>
    </div>
  );
};

SmartAddressWidget.propTypes = {
  apiKey: PropTypes.string,
  values: PropTypes.object.isRequired,
  inputAttribute: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  countryListValuePath: PropTypes.string,
  autocompletionRequest: PropTypes.object,
  placeDetailsRequest: PropTypes.object,
  addressComponentMapping: PropTypes.func,
  additionalInfo: PropTypes.string,
};

SmartAddressWidget.defaultProps = {
  apiKey: DEFAULT_GOOGLE_MAPS_KEY,
  inputAttribute: undefined,
  countryListValuePath: DEFAULT_COUNTRY_LIST_PATH,
  autocompletionRequest: undefined,
  placeDetailsRequest: undefined,
  addressComponentMapping: undefined,
  additionalInfo: DEFAULT_ADDITIONAL_INFO,
};

export default SmartAddressWidget;
