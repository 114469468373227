import { selector } from 'recoil';
import canEdit from '../Components/can-edit';
import userQuery from './user.query';
import contextQuery from './context.query';
import contractQuery from './contract.query';
import {
  getIndustries,
  getUserTypeOfWorkData,
  getTowRatingByCompany,
} from '../api';
import isAdminUser from '../Components/is-admin-user';

import { TypeOfWorkAnalytics, MonolithIndustry, TypeOfWorkCompanyRatings } from '../types.d';

interface TypesOfWork {
  id: number;
  hasContract: boolean;
  data: TypeOfWorkAnalytics[];
  industries: MonolithIndustry[];
  isStaffOrAccountant: boolean;
  edit?: boolean;
  canEdit: boolean;
  isViewingAdminsProfile: boolean;
}

const towByCompanyQuery = selector<null | TypeOfWorkCompanyRatings[]>({
  key: 'towByCompany',
  get: async ({ get }) => {
    const user = get(userQuery);

    if (!user?.id) {
      return null;
    }

    return getTowRatingByCompany(user.id);
  },
});

const analyticsQuery = selector<null | TypeOfWorkAnalytics[]>({
  key: 'analytics',
  get: async ({ get }) => {
    const contract = get(contractQuery);
    const user = get(userQuery);

    if (!contract || !user?.id) {
      return null;
    }

    const analytics = await getUserTypeOfWorkData(user.id, contract);

    return analytics;
  },
});

const industriesQuery = selector<MonolithIndustry[]>({
  key: 'industries',
  get: async () => {
    const industries = await getIndustries();
    return industries;
  },
});

const typesOfWorkQuery = selector<null | TypesOfWork>({
  key: 'typesOfWork',
  get: async ({ get }) => {
    const data = get(userQuery);
    const context = get(contextQuery);
    const contract = get(contractQuery);
    const industries = get(industriesQuery);
    const analytics = get(analyticsQuery);
    const rating = get(towByCompanyQuery);
    const contextGroupId = context?.group?.id || 0;
    const isStaffOrAccountant = [3, 9].includes(contextGroupId);

    if (!data || !context || !contract || !industries || !analytics) {
      return null;
    }

    const isViewingAdminsProfile = isAdminUser(context, data);

    const tows = data.types_of_work?.results || [];
    const hasContract = Boolean(contract?.curated_recruitment?.enabled);
    const res: TypeOfWorkAnalytics[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const tow of tows) {
      const a = analytics
        ? analytics.find(
          ({ user_type_of_work_id }) => user_type_of_work_id === tow.id,
        )
        : null;

      if (a && rating?.length) {
        const companyRating = rating.find(({ tow_id }) => tow_id === tow.id);

        res.push({
          ...a,
          rating: {
            ...(a.rating || {}),
            marketplace: a.rating?.marketplace || 0,
            company: companyRating?.company_rating || 0,
          },
        });

        // eslint-disable-next-line no-continue
        continue;
      }

      if (!a && tow.id && tow.name) {
        res.push({
          id: tow.id || 0,
          user_type_of_work_id: tow.id || 0,
          name: tow.name || '',
        });

        // eslint-disable-next-line no-continue
        continue;
      }

      if (a) {
        res.push(a);
      }
    }

    return {
      id: data.id || 0,
      canEdit: canEdit(data, context),
      data: res.sort((a, b) => (b?.jobs || 0) - (a?.jobs || 0)),
      industries,
      hasContract,
      isStaffOrAccountant,
      isViewingAdminsProfile,
    };
  },
});

export default typesOfWorkQuery;
