/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';
import { selector } from 'recoil';
import { JSObject } from './types.d';

export interface ICompanySpendResponse {
  amount: number;
  created_at: number;
}

export const ContractAnnualSpendSelector = selector<
  ICompanySpendResponse | JSObject
>({
  key: 'ContractAnnualSpendSelector',
  get: async (): Promise<ICompanySpendResponse | JSObject> => {
    try {
      const counterResponse: ICompanySpendResponse = get(
        window,
        'company_spend',
        {},
      );
      // may make api calls here directly to counter
      // currently reusing data that already exists
      return counterResponse;
    } catch (error) {
      return {};
    }
  },
});
