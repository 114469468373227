import * as React from 'react';
import { Section } from '@fieldnation/platform-components';
import WrapListItem from '../Components/WrapListItem';
import WrapSection from './WrapSection';

import { MonolithProfile } from '../types.d';

interface Props {
  user: MonolithProfile;
  collapse?: boolean;
}

const Tags = ({
  user, collapse,
}: Props): JSX.Element | null => {
  if (!user) {
    return null;
  }

  const customFields = user.custom_fields?.results || [];
  const tags = customFields.map((t) => ({
    name: t.name || '',
    value: Array.isArray(t.value) ? t.value.join(',') : t.value,
  }));

  return (
    <WrapSection
      label="Tags"
      value={`${tags.length}`}
      collapse={collapse}
    >
      <Section>
        {tags.map((m) => (
          <WrapListItem
            key={m.name}
            label={m.name}
            value={`${m.value || 'N/A'}`}
          />
        ))}
      </Section>
    </WrapSection>
  );
};

Tags.defaultProps = {
  collapse: false,
};

export default Tags;
