/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, v2 } from '@fieldnation/platform-components';
import classNames from 'classnames';
import classes from './MessageContainer.scss';
import { Message, Props } from './types.d';
import messageData from './util';

const MessageContainer = ({
  results,
  messageType,
  activeTab,
  parentElement,
  childElement,
  contentHeight,
  composeMessage,
  composeReply,
  messageOnChange,
  replyOnChange,
  messageOnSubmit,
  replyOnSubmit,
  messageOnKeyDown,
  replyOnKeyDown,
  messageDisabled,
  replyDisabled,
  messageDetailsViewed,
  replyClicked,
  emptyStateNode,
  disableMessageSending,
  displayOnDrawer,
  attachmentEnabled,
  attachments,
  onChangeAttachments,
  onRemoveAttachment,
  showMessageCharCount,
  messageCharacterLimit,
  inputPlaceholder,
}: Props): JSX.Element => {
  const [extendMessages, setExtendMessages] = useState<number[]>([]);
  const [extendMessagesReply, setExtendMessagesReply] = useState(0);
  const messageContentRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const { InputBox, MessageContent } = v2;

  useEffect(() => {
    if (messageContentRef.current && extendMessagesReply === 0) {
      const element = messageContentRef.current;
      element.scrollTop = element.scrollHeight;
    }
    setExtendMessagesReply(0);
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView();
    }
  }, [results]);

  function extendMessageReply(message: Message) {
    setExtendMessagesReply(Number(message?.msg_id));
    if (replyClicked) {
      replyClicked(message);
    }
  }

  function sendMessageReply() {
    setExtendMessages([...extendMessages, Number(extendMessagesReply)]);
    if (replyOnSubmit) {
      replyOnSubmit();
    }
  }

  const getSingleReply = (reply: Message) => (
    reply?.actions?.includes('canReply') && activeTab === 'inbox' ? (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => extendMessageReply(reply)}
        onClick={() => extendMessageReply(reply)}
        className={classes.SingleReply}
      >
        <Icon name="reply" size={24} />
        <span className={classes.SingleReplyText}>Reply</span>
      </div>
    ) : <div />
  );

  const getReplyThreads = (replies: Message[]) => (
    <section>
      {replies?.length && replies.map((thread, index) => (
        <div className={classes.GroupMessage} key={index}>
          <MessageContent thread={thread} />
          {index + 1 === replies?.length && (
          <div className={classes.SingleReplyTextInThread}>
            { Number(extendMessagesReply) === Number(thread?.msg_id)
              ? (
                <div className={classes.ThreadInputBox}>
                  <InputBox
                    value={composeReply}
                    onChange={replyOnChange}
                    onKeyDown={replyOnKeyDown}
                    onSubmit={sendMessageReply}
                    disabled={replyDisabled}
                  />
                </div>
              )
              : getSingleReply(thread)}
          </div>
          )}
        </div>
      ))}
    </section>
  );

  const getAvaterGroup = (replies: Message[]) => {
    const selectedAvater: boolean[] = [];
    return replies
      ?.map((r, i) => {
        if (!selectedAvater[r?.from?.id]) {
          selectedAvater[r?.from?.id] = true;
          return (
            <div key={i} className={classes.User}>
              <div
                className={classes.GroupThumb}
                style={{
                  backgroundImage: `url(${r?.from?.thumbnail})`,
                }}
              />
            </div>
          );
        }
        return null;
      })
      .filter((i) => i)
      .splice(0, 5);
  };

  function viewMessageDetails(msg_id: number, thread: Message) {
    setExtendMessages([...extendMessages, Number(msg_id)]);
    if (messageDetailsViewed) {
      messageDetailsViewed(thread);
    }
  }

  const getReply = (thread: Message) => {
    const { replies = [], msg_id } = thread;
    const { length } = replies;

    if (extendMessages.includes(Number(msg_id))) {
      return getReplyThreads(replies);
    }

    if (!length) {
      return getSingleReply(thread);
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => viewMessageDetails(msg_id, thread)}
        onClick={() => viewMessageDetails(msg_id, thread)}
        className={classes.GroupMargin}
      >
        {getAvaterGroup(replies)}
        {' '}
        {length}
        {' '}
        {length > 1 ? 'replies' : 'reply'}
      </div>
    );
  };

  const renderMessages = () => {
    const data = messageData(results, messageType);
    return data?.map((item, iIndex) => (
      <section key={iIndex}>
        <div className={classes.GroupTitle}>{item?.title}</div>
        {item?.data?.length && item.data.map((thread: Message, index: number) => (
          <section key={index} className={classes.GroupMessage}>
            <MessageContent thread={thread} />
            {messageType === 'workorder' && (
            <div className={classes.Reply}>
              { Number(extendMessagesReply) === Number(thread?.msg_id)
                ? (
                  <div className={classes.ReplyBox}>
                    <InputBox
                      value={composeReply}
                      onChange={replyOnChange}
                      onKeyDown={replyOnKeyDown}
                      onSubmit={sendMessageReply}
                      disabled={replyDisabled}
                      showCharCount={showMessageCharCount}
                      characterLimit={messageCharacterLimit}
                    />
                  </div>
                )
                : getReply(thread ?? {})}
            </div>
            )}
          </section>
        ))}
      </section>
    ));
  };

  return (
    <section className={classes.Body}>
      <div>{parentElement}</div>
      <div>{childElement}</div>
      <section>
        {emptyStateNode !== '' ? emptyStateNode : (
          <div
            ref={messageContentRef}
            style={!displayOnDrawer ? { height: `${contentHeight}vh` } : {}}
            className={
              classNames({
                [classes.MessageList]: !displayOnDrawer,
                [classes.MessageListForDrawerWithCounter]: displayOnDrawer && showMessageCharCount,
                [classes.MessageListForDrawer]: displayOnDrawer && !showMessageCharCount,
                [classes.ContentInsideDiv]: true,
              })
            }
          >
            {renderMessages()}
            {displayOnDrawer && <div ref={scrollToRef} />}
          </div>
        )}
        {!disableMessageSending && activeTab === 'inbox' && (
          <div
            className={`${!displayOnDrawer ? classes.MessageInputBox : classes.FixedMessageInputBox} ${!attachmentEnabled ? classes.FixedMessageInputBoxPad : ''}`}
          >
            <InputBox
              value={composeMessage}
              onChange={messageOnChange}
              onKeyDown={messageOnKeyDown}
              onSubmit={messageOnSubmit}
              disabled={messageDisabled}
              showCharCount={showMessageCharCount}
              characterLimit={messageCharacterLimit}
              attachmentEnabled={attachmentEnabled}
              attachments={attachments}
              onChangeAttachments={onChangeAttachments}
              onRemoveAttachment={onRemoveAttachment}
              inputPlaceholder={inputPlaceholder}
            />
            {attachmentEnabled && (
              <div className={classes.AttachmentFileTypeIndicator}>
                <p>
                  Maximum size 25 MB, maximum 15 MB per file.
                </p>
                <span className={classes.AttachmentFileTypeIndicatorIcon}>
                  <Icon name="infoHollow" size={14} />
                </span>
              </div>
            )}
          </div>
        )}
      </section>
    </section>
  );
};

MessageContainer.propTypes = {
  results: PropTypes.array,
  messageType: PropTypes.string,
  activeTab: PropTypes.string,
  parentElement: PropTypes.node,
  childElement: PropTypes.node,
  contentHeight: PropTypes.number,
  composeMessage: PropTypes.string.isRequired,
  composeReply: PropTypes.string,
  messageOnChange: PropTypes.func.isRequired,
  replyOnChange: PropTypes.func,
  messageOnSubmit: PropTypes.func.isRequired,
  replyOnSubmit: PropTypes.func,
  messageOnKeyDown: PropTypes.func,
  replyOnKeyDown: PropTypes.func,
  messageDisabled: PropTypes.bool,
  replyDisabled: PropTypes.bool,
  messageDetailsViewed: PropTypes.func,
  replyClicked: PropTypes.func,
  emptyStateNode: PropTypes.string,
  disableMessageSending: PropTypes.bool,
  displayOnDrawer: PropTypes.bool,
  showMessageCharCount: PropTypes.bool,
  messageCharacterLimit: PropTypes.number,
  attachmentEnabled: PropTypes.bool,
  attachments: PropTypes.array,
  onChangeAttachments: PropTypes.func,
  onRemoveAttachment: PropTypes.func,
  inputPlaceholder: PropTypes.string,
};

MessageContainer.defaultProps = {
  results: [],
  messageType: '',
  activeTab: 'inbox',
  parentElement: <></>,
  childElement: <></>,
  contentHeight: 75,
  composeReply: () => null,
  replyOnChange: () => null,
  replyOnSubmit: () => null,
  messageOnKeyDown: () => null,
  replyOnKeyDown: () => null,
  messageDisabled: false,
  replyDisabled: false,
  messageDetailsViewed: () => null,
  replyClicked: () => null,
  emptyStateNode: '',
  disableMessageSending: false,
  displayOnDrawer: false,
  showMessageCharCount: false,
  messageCharacterLimit: 0,
  attachmentEnabled: false,
  attachments: [],
  onChangeAttachments: () => null,
  onRemoveAttachment: () => null,
  inputPlaceholder: '',
};

export default MessageContainer;
