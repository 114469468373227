import * as React from 'react';
import { Section, GroupCardListItem, Icon } from '@fieldnation/platform-components';
import WrapSection from './WrapSection';
import css from './Rating.scss';

import { SelectionRuleCriteria } from '../types.d';

interface Props {
  selectionRule: SelectionRuleCriteria[];
  collapse?: boolean;
}

const Ranking = ({
  selectionRule,
  collapse,
}: Props): JSX.Element => {
  const criterion = selectionRule || [];
  let totalPoints = 0;
  let value = 0;
  let matched = true;
  let requiredMatched = true;

  criterion.forEach((criteria: SelectionRuleCriteria) => {
    totalPoints += criteria?.availablePoints || 0;
    value += criteria?.points || 0;

    if (criteria.required && !criteria.matched) {
      matched = false;
    }

    if (!matched && requiredMatched) {
      requiredMatched = false;
    }
  });

  const icon = !matched && !requiredMatched
    ? <Icon name="close" size={18} />
    : <Icon name="check" size={18} />;
  const iconColor = !matched && !requiredMatched ? 'red' : 'green';
  const headerIcon = (
    <span style={{
      color: iconColor,
      verticalAlign: 'text-top',
    }}
    >
      {icon}
    </span>
  );

  const customContent = (
    <div>
      {headerIcon}
      <span className="u-margin-left--xs">
        <strong>
          {`${value} / ${totalPoints} pts`}
        </strong>
      </span>
    </div>
  );

  return (
    <WrapSection
      label="Ranking"
      collapse={collapse}
      customHeaderContent={customContent}
    >
      <Section>
        <div className={css['ranking-wrapper']}>
          {criterion?.map((criteria: SelectionRuleCriteria) => {
            const listIcon = !criteria?.matched ? 'close' : 'check';
            let listIconColor = '';

            if (criteria?.matched && criteria?.required) {
              listIconColor = 'green';
            }

            if (!criteria?.matched && criteria?.required) {
              listIconColor = 'red';
            }

            return (
              <GroupCardListItem
                hideExpander
                dottedLine
                icon={listIcon}
                iconColor={listIconColor}
                middleContent=""
                label={criteria?.label}
                data={`${criteria?.points} / ${criteria?.availablePoints} pts`}
                type="default"
                border="none"
              />
            );
          })}
        </div>
      </Section>
    </WrapSection>
  );
};

Ranking.defaultProps = {
  collapse: false,
};

export default Ranking;
