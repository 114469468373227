import React from 'react';
import { IProps } from './types.d';
import css from './styles.scss';

const EmptyText: React.FC<IProps> = ({ label }: IProps): JSX.Element => (
  <span className={css['content--empty']}>
    {`No ${label.toLowerCase()}`}
  </span>
);

export default EmptyText;
