/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { AxiosRequestConfig } from 'axios';
import template from 'lodash/template';
import { CallbackInterface } from 'recoil';

import {
  getInputFieldAtom,
  getInputValidationFieldAtom,
  openForEditAtom,
  requestConfigSelector,
  userContextSelector,
  isLoadingAtom,
  userInfoAtom,
} from '.';
import { makeApiRequest } from '../../../api';
import { NotificationFlashAlert } from '../../../components';
import {
  addressConfirmationDescriptionAtom,
  isAddressConfirmationOpenAtom,
} from '../../AddressConfirmation/address-confirmation.atom';
import {
  INPUT_FIELD_VALIDATION_PATTERNS,
  RequestTypes,
  ResolutionStatus,
  ROUTE_MAP,
} from '../../../constants';
import { IJsObject } from '../../../types.d';
import { EVENTS, ITrackEvent } from '../../../../Amplitude/useAmplitudeTracker';

export const makeRequestCallback = ({
  set,
  reset,
  snapshot,
}: CallbackInterface) => async (
  config: AxiosRequestConfig,
  successMessage = 'Information updated successfully',
  enableLoader = false,
  trackOnAmplitude?: ITrackEvent,
): Promise<boolean> => {
  let success = false;
  const release = snapshot.retain();
  try {
    const {
      address1: originalAddress1 = '',
      city: originalCity = '',
      state: originalState = '',
      zip: originalZip = '',
      country: originalCountry = '',
    } = snapshot.getLoadable(userInfoAtom).getValue();
    const { isStaff } = snapshot.getLoadable(userContextSelector).getValue();

    const originalAddress = {
      current_address_1: originalAddress1,
      current_city: originalCity,
      current_state: originalState,
      current_zip: originalZip,
      current_country: originalCountry,
    };

    if (isStaff || enableLoader) {
      set(isLoadingAtom, true);
    }

    const data = await makeApiRequest(config);

    const cleanUp = () => {
      success = true;
      NotificationFlashAlert({
        level: 'success',
        message: successMessage,
      });
      reset(openForEditAtom);
    };

    /**
     * This function will fetch user info and set it for page state
     */
    const fetchAndSetUserInfo = async (userId: number) => {
      const userInfoConfig = ROUTE_MAP.UserInfo;
      const newUserInfoData = await makeApiRequest({
        ...userInfoConfig,
        url: template(userInfoConfig.url)({ user_id: userId }),
      });
      if (newUserInfoData?.id) {
        set(userInfoAtom, newUserInfoData);
      }
    };

    if (data?.id) {
      set(userInfoAtom, data);
      cleanUp();
    } else if (data?.success === true) {
      // backend just returned success. No user info state change.
      // Example: resend verification email
      cleanUp();
    } else if (Array.isArray(data) && data[0] === config?.data?.emails?.[0]) {
      // backend sent wonky data. need to update user info state
      // Example: un-verify email
      await fetchAndSetUserInfo(config?.data?.user_id);
      cleanUp();
    } else if (config.url === ROUTE_MAP.ResolveAddress.url) {
      if (data?.resolution_status !== ResolutionStatus.VALID) {
        set(addressConfirmationDescriptionAtom, data?.resolution_message);
        set(isAddressConfirmationOpenAtom, true);
      } else {
        success = true;
      }
    }

    if (trackOnAmplitude && config.url !== ROUTE_MAP.ResolveAddress.url) {
      let validated = false;
      if (config?.params?.force === undefined) {
        validated = success;
      } else {
        validated = !config?.params?.force;
      }
      trackOnAmplitude(EVENTS.UPDATED_ADDRESS, {
        type: 'User',
        validated: Boolean(validated),
        ...originalAddress,
        new_address_1: config.data?.address1,
        new_city: config.data?.city,
        new_state: config.data?.state,
        new_zip: config.data?.zip,
        new_country: config.data?.country,
      });
    }
    if (isStaff || enableLoader) {
      reset(isLoadingAtom);
    }
  } catch (error) {
    console.log(error);
  } finally {
    release();
  }
  return success;
};

export const resetFormCallback = ({ reset }: CallbackInterface) => async (
  atomNames: string[],
): Promise<void> => {
  if (Array.isArray(atomNames)) {
    atomNames.forEach((name) => reset(getInputFieldAtom(name)));
  }
};

export const onEditFromCloseCallback = ({ reset }: CallbackInterface) => (
  ...args: string[]
) => (): void =>
  ((inputAtoms: string[] = []) => {
    if (Array.isArray(inputAtoms)) {
      inputAtoms.forEach((atomName) => reset(getInputFieldAtom(atomName)));
    }
    reset(openForEditAtom);
  })(args);

export const getRequestConfigCallback = ({
  snapshot,
}: CallbackInterface) => async (
  param: RequestTypes,
): Promise<AxiosRequestConfig> => {
  const release = snapshot.retain();
  let config: AxiosRequestConfig = {};
  try {
    config = snapshot.getLoadable(requestConfigSelector(param)).getValue();
  } catch (error) {
    console.error(error);
  } finally {
    release();
  }
  return config;
};

export const getInputValidatorCallback = ({
  snapshot,
  set,
}: CallbackInterface) => (...atomNames: string[]): void => {
  try {
    atomNames.forEach((atomName) => {
      const atomValue = snapshot
        .getLoadable<string | IJsObject>(getInputFieldAtom(atomName))
        .getValue();

      const validationAtom = getInputValidationFieldAtom(atomName);
      const { pattern, errorMessage, isOptional = false } =
        INPUT_FIELD_VALIDATION_PATTERNS[atomName] || {};

      const currentData =
        typeof atomValue === 'object' ? atomValue?.value : atomValue;

      if (pattern) {
        const isValid = currentData ? pattern.test(currentData) : isOptional;

        set(validationAtom, {
          isValid,
          errorMessage: !isValid ? errorMessage : '',
        });
      } else {
        set(validationAtom, {
          isValid: true,
          errorMessage: '',
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetValidationErrorsCallback = ({ reset }: CallbackInterface) => (
  ...atomNames: string[]
): void => {
  if (Array.isArray(atomNames)) {
    atomNames.forEach((name) => reset(getInputValidationFieldAtom(name)));
  }
};
