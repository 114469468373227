import { selector } from 'recoil';
import canEdit from '../Components/can-edit';
import userQuery from './user.query';
import contextQuery from './context.query';
import contractQuery from './contract.query';
import { getUserLicensesCertifications } from '../api';
import isAdminUser from '../Components/is-admin-user';

import { UserQualifications, MonolithProfile, LCPayload } from '../types.d';

interface LicenseCertification {
  id: number;
  data: UserQualifications[];
  canEdit: boolean;
  isManagedOrSelf: boolean;
  showStatus: boolean;
  isViewingAdminsProfile: boolean;
}

const isManagedOrSelf = (user: MonolithProfile, context: MonolithProfile) => {
  if (!context || !context?.id) {
    return false;
  }

  const myUserId = context?.id;
  const myCompanyId = context?.company?.id;
  const userId = user?.id;
  const userCompanyId = user?.company?.id;

  if (myUserId === userId) {
    return true;
  }

  if (
    context?.company?.role?.marketplace_manager
    && myCompanyId
    && myCompanyId === userCompanyId
  ) {
    return true;
  }

  if (
    myCompanyId
    && user?.company?.parent?.id === myCompanyId
    && user?.company?.is_only_client
  ) {
    return true;
  }

  if (
    context?.group?.id === 2
    && myCompanyId
    && myCompanyId === userCompanyId
  ) {
    return true;
  }

  if ([3, 9].includes(context?.group?.id || 0)) {
    return true;
  }

  return false;
};

export const formUtility = {
  capitalizeFirstChar: (text: string): string => text.charAt(0).toUpperCase() + text.slice(1),
  empty: {
    id: 0,
    name: '',
    number: '',
    issue_date: { utc: '' },
    expire_date: { utc: '' },
    region: '',
  } as LCPayload,
  validation: ({
    id,
    number,
    issue_date,
    expire_date,
    file,
    type = '',
    isEdit = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: LCPayload): any => [
    !number && {
      number: `${formUtility.capitalizeFirstChar(type)} number is required`,
    },
    !issue_date
        || typeof issue_date !== 'object'
        || (!issue_date.utc && { issue_date: 'Select start date' }),
    !expire_date
        || typeof expire_date !== 'object'
        || (!expire_date.utc && { expire_date: 'Select start date' }),
    !isEdit && !file && { file: 'Upload a file' },
  ].reduce((acc, item) => (item ? { ...acc, ...(item as Record<string, unknown>), id } : acc), {}),
};

const licenseCertificationQuery = selector<null | LicenseCertification>({
  key: 'licensesCertifications',
  get: async ({ get }) => {
    const data = get(userQuery);
    const context = get(contextQuery);
    const contract = get(contractQuery);

    if (!data || !context || !contract) {
      return null;
    }

    const userQualification = await getUserLicensesCertifications(data?.id || 0);
    const hasContract = Boolean(contract?.specialized_skill_fee?.enabled);
    const isManagedProviderOrSelf = isManagedOrSelf(data, context);
    const showStatus = [
      [3, 9].includes(data?.group?.id || 0),
      hasContract,
      isManagedProviderOrSelf,
    ].filter((i) => i).length > 0;
    const isViewingAdminsProfile = isAdminUser(context, data);

    return {
      id: data.id || 0,
      canEdit: canEdit(data, context),
      data: userQualification,
      isManagedOrSelf: isManagedProviderOrSelf,
      showStatus,
      isViewingAdminsProfile,
    };
  },
});

export default licenseCertificationQuery;
