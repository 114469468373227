import * as React from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { format } from 'date-fns';
import { backoutsDetails } from '../queries/backouts-details.query';
import Chart from './Chart';

import css from './chart.scss';

import { GraphData } from '../types.d';

interface Payload {
  payload: GraphData;
}
interface ChartToolTips {
  active?: boolean;
  payload?: Payload[];
}

const CustomizedTooltip = ({ active, payload = [] }: ChartToolTips) => {
  if (!active || !payload.length) {
    return null;
  }

  const {
    provider, buyer, startDate, endDate,
  } = payload[0]?.payload;
  return (
    <div className={classNames(css['chart-tooltip'], 'u-padding--md')}>
      <div style={{ color: '#636b88' }} className="u-padding-bottom--sm">
        {format(new Date(startDate), 'MMMM dd')}
        {' - '}
        {format(new Date(endDate), 'MMMM dd')}
      </div>
      <div style={{ color: '#162741' }}>
        Provider cancellations:
        {' '}
        {provider}
      </div>
      <div style={{ color: '#162741' }}>
        Buyer removals:
        {' '}
        {buyer}
      </div>
    </div>
  );
};

CustomizedTooltip.defaultProps = {
  active: false,
  payload: [],
};

const BackoutsChart = (): JSX.Element => {
  const { graphData } = useRecoilValue(backoutsDetails);
  const providerCount = graphData.reduce((acc, cur) => acc + (cur.provider || 0), 0);
  const buyerCount = graphData.reduce((acc, cur) => acc + (cur.buyer || 0), 0);

  return (
    <div style={{ height: '100%' }}>
      <Chart
        legendAlign="right"
        data={graphData}
        areas={[
          {
            id: 'one',
            name: ` (${providerCount}) Provider cancellations`,
            dataKey: 'provider',
            colorHash: '#268ed9',
          },
          {
            id: 'two',
            name: ` (${buyerCount}) Buyer removals`,
            dataKey: 'buyer',
            colorHash: '#753b9d',
          },
        ]}
        tooltTip={
          <CustomizedTooltip />
        }
      />
    </div>
  );
};

export default BackoutsChart;
