import { atom, selector } from 'recoil';

export const sourceApp = atom<string | null>({
  key: 'sourceAppState',
  default: null,
});

export const sourceAppQuery = selector<null | string>({
  key: 'sourceAppQuery', // keep keys different
  get: async ({ get }) => {
    const sa = get(sourceApp);
    return sa || null;
  },
  set: ({ set, get }, newValue) => {
    const sa = get(sourceApp);
    if (newValue !== sa) {
      set(sourceApp, newValue);
    }
  },
});

export default sourceApp;
