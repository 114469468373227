import React, {
  StrictMode, useState, useEffect,
} from 'react';
import { Loader } from '@fieldnation/platform-components';
import { IOtpGuardProps } from './types';
import OtpComponent from '../../../OtpComponent';
import OtpVerificationComponent from '../../../OtpComponent/OtpVerificationComponent';
import {
  makeOtpRequest,
  makeOtpVerificationRequest,
  makeOtpPassthroughCheckingRequest,
} from '../../api';
import { NotificationFlashAlert } from '../../../SectionCard/Commons/Notification';

const contactSupportHandler = () => {
  window.open('https://support.fieldnation.com');
};

export const OtpGuard = ({
  children,
}: IOtpGuardProps): JSX.Element => {
  const [isValid, setIsValid] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    makeOtpPassthroughCheckingRequest()
      .then((response) => {
        setShowLoader(false);
        if (response) {
          setIsValid(true);
        }
      });
  }, []);

  const otpRequestHandler = async () => {
    setLoading(true);
    const otpSent = await makeOtpRequest();
    if (otpSent) {
      setIsRequested(true);
      // Resetting Otp and error message for resend OTP request.
      setOtp('');
      setError(false);
      NotificationFlashAlert({
        level: 'success',
        message: 'One-time passcode sent!',
      });
    }
    setLoading(false);
  };

  const otpVerificationHandler = async () => {
    setLoading(true);
    const verified = await makeOtpVerificationRequest(otp);
    if (verified) {
      setIsValid(true);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const otpComponent = isRequested ? (
    <OtpVerificationComponent
      loading={loading}
      email={window?.context?.email || ''}
      otp={otp}
      error={error}
      submitHandler={otpVerificationHandler}
      contactSupportHandler={contactSupportHandler}
      resendOtpHandler={otpRequestHandler}
      changeHandler={(e) => { setOtp(e.currentTarget.value); }}
    />
  ) : (
    <OtpComponent
      loading={loading}
      email={window?.context?.email || ''}
      submitHandler={otpRequestHandler}
      contactSupportHandler={contactSupportHandler}
    />
  );

  const protectedContent = isValid
    ? children
    : otpComponent;

  return (
    <StrictMode>
      {
        showLoader
          ? <Loader isLoading fixed fillParent />
          : protectedContent
      }
    </StrictMode>
  );
};

export default OtpGuard;
