import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './accordion.scss';

interface Props {
    children: React.ReactNode;
    midSizeLeftPadding?: boolean;
    fullWidth?: boolean;
}

const AccordionContentContainer = ({
  children,
  midSizeLeftPadding,
  fullWidth,
}: Props): JSX.Element => (
  <div className={css.AccordionContentContainer}>
    <div className={classNames(css.AccordionContentBody, {
      [css['AccordionContentBody---midSizeLeftPadding']]: midSizeLeftPadding,
      [css['AccordionContentBody---fullWidth']]: fullWidth,
    })}
    >
      {children}
    </div>
    {!fullWidth ? <div className={css.AccordionContainerContainer__gutter} /> : null}
  </div>
);

AccordionContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  midSizeLeftPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

AccordionContentContainer.defaultProps = {
  midSizeLeftPadding: false,
  fullWidth: false,
};

export default AccordionContentContainer;
