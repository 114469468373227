import React from 'react';
import css from './styles.scss';
import { IProps } from './types.d';

const AdminText: React.FC<IProps> = ({ label }: IProps): JSX.Element => (
  <span className={css.subtext}>
    {`Contact your admin to change ${label.toLowerCase()}`}
  </span>
);

export default AdminText;
