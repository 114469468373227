import * as React from 'react';
import classNames from 'classnames';
import css from './WorkAndSkillWidget.scss';

export interface CategoryProps {
  name: string;
  highlighted?: boolean;
  compact?: boolean;
  padded?: boolean;
}

export default function Category({ name, highlighted, padded }: CategoryProps): JSX.Element {
  return highlighted ? (
    <div className={classNames(css.SkillCategory, css.highlighted, padded ? css.Padded : '')}>{name}</div>
  ) : (
    <div className={css.SkillCategory}>{name}</div>
  );
}
