/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import PropTypes from 'prop-types';

const CustomOptions = ({
  className,
  option,
  isSelected,
  children,
  onSelect,
  onFocus,
  isFocused,
}) => {
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(option, event);
  };

  const handleMouseEnter = (event) => {
    onFocus(option, event);
  };

  const handleMouseMove = (event) => {
    if (isFocused) return;
    onFocus(option, event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid="custom-option"
      className={className}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
    >
      <input type="checkbox" checked={isSelected} />
      {'  '}
      {children}
    </div>
  );
};

CustomOptions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
};

CustomOptions.defaultProps = {
  children: undefined,
  className: undefined,
  isFocused: false,
  isSelected: false,
  onFocus: () => { /* null */ },
  onSelect: () => { /* null */ },
  option: undefined,
};

export default CustomOptions;
