/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  v2,
} from '@fieldnation/platform-components';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { contentInputState, contentValidationState } from '../../States/AtomFamilies';
import onChangeContentInput from '../../States/Callbacks';
import InputValidationContent from '../InputValidationContent';
import {
  ContentKey, InputSize, InputType, InputTypes,
} from '../Section/types.d';
import css from './styles.scss';
import { IProps } from './types.d';

const { TextInput } = v2;

const InputTextContent: React.FC<IProps> = ({
  sectionKey, config, shouldFocus,
}: IProps): JSX.Element => {
  const onChange = useRecoilCallback(onChangeContentInput);
  const inputInitialValue = useRecoilValue(contentInputState(config.key as ContentKey))?.value || '';
  const validation = useRecoilValue(contentValidationState(config.key as ContentKey));
  const placeHolderReference = config?.inputPlaceHolder
    || `Enter ${config.displayName.toLowerCase()}`;
  const widthStyle = config?.inputSize || InputSize.LARGE;
  return (
    <div className={css[`text--input--${widthStyle}`]}>
      <TextInput
        label={config.displayName}
        labelHidden={config.isInputLabelHidden}
        helpText={config.inputDescription}
        value={inputInitialValue}
        onChange={(event) => onChange(event.target.value || '', sectionKey, config)}
        optional={!!config.isOptional}
        type={(config?.inputType || InputType.TEXT) as InputTypes}
        applyFocus={shouldFocus}
        // @ts-ignore
        placeholder={placeHolderReference}
      />
      <InputValidationContent validation={validation} />
    </div>
  );
};

export default InputTextContent;
