import * as React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { ChartProps } from '../types.d';

const Chart = ({
  data,
  areas,
  legendAlign = 'right',
  tooltTip,
}: ChartProps): JSX.Element => (
  <ResponsiveContainer>
    <AreaChart
      width={580}
      height={240}
      data={data}
    >
      <defs>
        {areas.map(({
          id, colorHash,
        }) => (
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colorHash} stopOpacity={0.08} />
            <stop offset="95%" stopColor={colorHash} stopOpacity={0} />
          </linearGradient>
        ))}
      </defs>
      <XAxis
        dataKey="name"
        tickLine={false}
        axisLine={false}
        tickMargin={3}
        tickSize={8}
      />
      <YAxis
        tickLine={false}
        tickMargin={3}
        axisLine={false}
        allowDecimals={false}
        tickSize={8}
        width={30}
      />
      <Tooltip
        content={tooltTip}
      />
      <Legend
        align={legendAlign}
        verticalAlign="top"
        iconType="circle"
        iconSize={12}
        height={30}
      />
      {areas.map(({
        id, name, colorHash, dataKey,
      }) => (
        <Area
          name={name}
          type="monotone"
          dataKey={dataKey}
          stroke={colorHash}
          strokeWidth={2}
          fillOpacity={1}
          fill={`url(#${id})`}
        />
      ))}
    </AreaChart>
  </ResponsiveContainer>
);

export default Chart;
