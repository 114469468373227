/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { IconButton } from '@fieldnation/platform-components';
import css from './WorkSummaryWidget.scss';
import { ProjectTypeProps } from '../types.d';
import NumberofJobs from './NumberOfJobs';

export default function ProjectType({
  name,
  numJobs = 0,
  children,
  onExpand,
  expanded,
  hourlyRate,
  skillSetNumjobs,
}: ProjectTypeProps): JSX.Element {
  return (
    <>
      <div className={css.ProjectType}>
        <div className={css.ProjectTypeLeftContent}>
          {' '}
          <div className={css.ProjectTypeName}>
            <span>{name}</span>
          </div>
          {hourlyRate && (
          <div>
            <span className={css.HourlyRate}>{hourlyRate}</span>
          </div>
          )}
        </div>
        <div className={css.ProjectTypeRightContent}>
          <div className={numJobs === 0 ? css.ProjectTypeNumJobsDisabled : css.ProjectTypeNumJobs}>
            <NumberofJobs numJobs={numJobs} />
          </div>
          {Number(skillSetNumjobs) > 0 ? (
            <div className={css.AccordionV4}>
              <IconButton size="lg" disabled={numJobs === 0} name={expanded ? 'collapse' : 'expand'} onClick={onExpand} />
            </div>
          ) : null}

        </div>
      </div>
      {expanded ? (
        <div className={css.ExpandedContent}>{children}</div>
      ) : null}
    </>
  );
}
