/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { NotificationFlashAlert } from './Notification';
import { IJsObject } from './types.d';

const API_TIMEOUT_MESSAGE =
  'Your request has timed out. Please try again later.';
const API_UNDEFINED_MESSAGE = 'Something went wrong. Please try again later.';
const TIMEOUT_LIMIT = 10 * 1000;

export function handleApiError<T = IJsObject>(error: any, requestOptions?: AxiosRequestConfig): T {
  let localError: any = {
    message: error?.message || API_UNDEFINED_MESSAGE,
  };

  if (error?.response?.data) {
    localError = error.response.data;
  }

  const isHtmlError = requestOptions?.url?.includes('error_type=html');

  NotificationFlashAlert({
    level: 'error',
    message: localError.message,
    children: isHtmlError ? (localError.message) : '',
    dismissible: isHtmlError ? 'button' : 'both',
  });

  return localError;
}

export function getApiInstance(): AxiosInstance {
  return axios.create({
    baseURL: '/v2',
    timeout: TIMEOUT_LIMIT,
    timeoutErrorMessage: API_TIMEOUT_MESSAGE,
  });
}

export async function makeApiRequest<T = IJsObject>(
  options: AxiosRequestConfig,
): Promise<T> {
  try {
    const apiResponse = await getApiInstance().request(options);
    if (!apiResponse.data) {
      throw Error('No data found on response');
    }
    return apiResponse?.data;
  } catch (error) {
    return handleApiError<T>(error, options);
  }
}
