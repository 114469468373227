import { MonolithProfile } from '../types.d';

export default function isManagedOrSelf(
  user: MonolithProfile,
  context: MonolithProfile,
): boolean {
  if (!context || !context.id) {
    return false;
  }

  const myUserId = context.id;
  const myGroupId = context?.group?.id || 0;
  const myCompanyId = context?.company?.id || 0;
  const userId = user.id;
  const userCompanyId = user?.company?.id || 0;
  const marketplaceManager = context?.company?.role?.marketplace_manager || false;
  const parentId = user?.company?.parent?.id || 0;
  const isClientOnly = user?.company?.is_only_client || false;

  if (myUserId === userId) {
    return true;
  }

  if (marketplaceManager && myCompanyId && myCompanyId === userCompanyId) {
    return true;
  }

  // end client user this company manages
  if (myCompanyId && parentId === myCompanyId && isClientOnly) {
    return true;
  }

  // admins "manage" their staff
  if (myGroupId === 2 && myCompanyId && myCompanyId === userCompanyId) {
    return true;
  }

  if ([3, 9].includes(myGroupId)) {
    return true;
  }

  return false;
}
