import * as React from 'react';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { v2 } from '@fieldnation/platform-components';
import css from './WorkAndSkillWidget.scss';
import { userIdQuery } from '../queries/user.query';
import trackAmplitudeEvent, { VIEWED_SKILL_JOB_DETAIL } from '../amplitude';
import { useFeedback } from './Feedback';
import JobSummary from './JobSummary';
import TechnicianInfo from './TechnicianInfo';
import useWorkOrderId from './use-work-order-id';

const { Modal } = v2;

export interface WorkAndSkillWidgetProps {
  onClose: () => void;
  passedInUserId?: number;
  passedUserData?: any;
  skill?: { id: number; name: string };
  typeOfWork?: { id: number; name: string };
  network?: { id: number; name: string };
}

export interface SkillModalFilters
  extends Pick<WorkAndSkillWidgetProps, 'skill' | 'typeOfWork' | 'network'> {
  userId: number;
}

export function useSkillsModal(
  source: string,
): [(args?: SkillModalFilters) => void, JSX.Element | null] {
  const [show, setShow] = useState<SkillModalFilters | null>(null);
  const shown = Boolean(show);

  const toggle = useCallback(
    (args?: SkillModalFilters) => {
      if (!args) {
        setShow(null);
        return;
      }

      trackAmplitudeEvent({
        category: VIEWED_SKILL_JOB_DETAIL,
        ampProps: {
          source,
          skill: args?.skill?.name,
          network: args?.network?.name,
          typeOfWorkId: args?.typeOfWork?.name,
        },
      });

      setShow(args);
    },
    [shown],
  );

  return [
    toggle,
    shown ? (
      <WorkAndSkillWidget
        onClose={() => toggle()}
        key={show?.userId || 0}
        passedInUserId={show?.userId || 0}
        skill={show?.skill}
        network={show?.network}
        typeOfWork={show?.typeOfWork}
      />
    ) : null,
  ];
}

export default function WorkAndSkillWidget({
  onClose,
  passedInUserId = 0,
  skill,
  typeOfWork,
  network,
}: WorkAndSkillWidgetProps) {
  const userId = passedInUserId || useRecoilValue(userIdQuery);
  const [toggleFeedback, feedback] = useFeedback(userId || 0);
  const workOrderId = useWorkOrderId();

  return (
    <Modal
      isOpen
      header="Job history and ratings"
      onClose={onClose}
      size="large"
      footerActions={
        feedback
          ? [
            {
              label: 'Back',
              type: 'secondary',
              onClick: toggleFeedback,
            },
          ]
          : [
            {
              label: 'Give Feedback',
              type: 'text',
              onClick: toggleFeedback,
            },
            {
              label: 'Close',
              type: 'secondary',
              onClick: onClose,
            },
          ]
      }
    >
      {feedback || (
        <>
          <TechnicianInfo
            key={userId || undefined}
            userId={userId || undefined}
            workOrderId={workOrderId}
          />

          <div className={css.NegativeBorderMargins}>
            <JobSummary
              key={userId || undefined}
              userId={userId || undefined}
              skill={skill}
              typeOfWork={typeOfWork}
              network={network}
            />
          </div>
        </>
      )}
    </Modal>
  );
}
