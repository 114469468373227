/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Icon } from '@fieldnation/platform-components';
import css from './WorkSummaryWidget.scss';
import { SkillSetProps } from '../types.d';
import NumberofJobs from './NumberOfJobs';

export default function SkillSet({
  name,
  numJobs = 0,
  lastCompletion,
  marketplaceRating,
  myCompanyRating,
}: SkillSetProps): JSX.Element {
  return (
    <>
      <div className={css.SkillSet}>
        <div className={css.SkillSetLeftContent}>
          {' '}
          <div className={css.SkillSetName}>
            <span>{name}</span>
          </div>
          <div>
            <span className={marketplaceRating ? css.SKMarketplace : css.SKNoRating}>
              <Icon name="starSolid" size={16} />
              <div className={marketplaceRating ? css.SkillSetRatingText : css.SkillSetNonRatingText}>
                <span>{marketplaceRating ? marketplaceRating?.toFixed(1) : 'N/A'}</span>
              </div>
            </span>
            <span className={myCompanyRating ? css.SKMyCompany : css.SKNoRating}>
              <Icon name="starSolid" size={16} />
              <div className={myCompanyRating ? css.SkillSetRatingText : css.SkillSetNonRatingText}>
                <span>{myCompanyRating ? myCompanyRating?.toFixed(1) : 'N/A'}</span>
              </div>
            </span>
          </div>
          {lastCompletion && (
          <div>
            <span className={css.SkillSetLastCompletion}>{`Last completed ${lastCompletion}`}</span>
          </div>
          )}
        </div>
        <div className={css.SkillSetRightContent}>
          {numJobs && (
          <div className={css.SkillSetNumJobs}>
            <NumberofJobs numJobs={numJobs} />
          </div>
          )}
        </div>
      </div>
    </>
  );
}
