/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext } from 'react';
import {
  Loader,
  v2,
} from '@fieldnation/platform-components';
import ScreeningModalWidget from '../../../Screening/index';
import { store } from '../Store';
import EditModal from './EditModal';
import { ctx as registryCtx } from '../../Registry';
import ContextTable from './ContextTable';
import trackAmplitudeEvent, {
  WORK_ORDER_DETAILS,
  TEMPLATE_DETAILS,
  SCREENING_SUCCESS,
  SCREENING_FAILED,
  SCREENING_MODAL_CLOSED,
} from '../amplitude';
import '../Styles.scss';
import { defaultSelectedScreening } from '../utils';

const { Tile, TileDivision } = v2;

const ListRequirements = (): JSX.Element => {
  const ctx: any = useContext(store);
  const registry: any = useContext(registryCtx);
  const {
    dispatch,
    state: {
      requirements,
      isEditting,
      isManager,
      canDelete = false,
      loading,
      selectedScreening,
    },
  } = ctx;

  useEffect(() => {
    registry.set('refresh', (reqs) => dispatch({
      type: 'refresh',
      requirements: reqs,
    }));

    registry.set('reload', (userId = 0) => dispatch({
      type: 'reload',
      userId,
    }));

    return () => {
      registry.clear('refresh');
      registry.clear('reload');
    };
  }, [isManager]);

  const trackEvent = (
    category,
    screeningData,
  ) => {
    const {
      state, postal_code: zip, country, fee,
    } = screeningData;

    trackAmplitudeEvent({
      category,
      ampProps: {
        userId: selectedScreening?.userId || 0,
        screeningType: selectedScreening?.screening || '',
        Source: WORK_ORDER_DETAILS,
        'Package Cost': fee,
        state,
        zip,
        country,
      },
    });
  };

  const handleScreeningModalClose = () => {
    trackAmplitudeEvent({
      category: SCREENING_MODAL_CLOSED,
      ampProps: {
        userId: selectedScreening.userId,
        screeningType: selectedScreening.screening,
        Source: WORK_ORDER_DETAILS,
      },
    });
    dispatch({
      type: 'screening_popup',
      selectedScreening: defaultSelectedScreening,
    });
  };
  const handleScreeningOnSubmit = (val, res) => {
    const userId = selectedScreening?.userId || 0;
    if (res) {
      trackEvent(SCREENING_SUCCESS, val);
      dispatch({
        type: 'reload',
        userId,
      });
    } else {
      trackEvent(SCREENING_FAILED, val);
    }
  };

  const jsx = (
    <Tile>
      <TileDivision
        heading="Work Order Qualifications"
        actions={[
          canDelete && {
            icon: 'edit',
            onClick: () => {
              trackAmplitudeEvent({
                category: 'Edited Required Qualifications',
                ampProps: {
                  // eslint-disable-next-line lodash/prefer-includes
                  Source: (window.location.href.indexOf('templates') > -1)
                    ? TEMPLATE_DETAILS : WORK_ORDER_DETAILS,
                },
              });
              dispatch({ type: 'edit' });
            },
          },
        ]}
      >
        {isEditting && <EditModal />}
        {selectedScreening?.isScreeningOpen && (
          <ScreeningModalWidget
            onSubmitCallback={handleScreeningOnSubmit}
            onClose={handleScreeningModalClose}
            screeningType={selectedScreening.screening}
            source={WORK_ORDER_DETAILS}
            userId={selectedScreening.userId}
          />
        )}

        {!isManager && requirements.length > 0 && (
          <div className="u-margin-bottom--sm" data-testid="wod-qs-list">
            The following requirements have been marked as necessary in order to successfully
            complete this work order. Make sure that you have updated your profile to accurately
            reflect your qualifications before requesting.
          </div>
        )}

        {isManager && requirements.length > 0 && (
          <div className="u-margin-bottom--sm" data-testid="wod-qs-list">
            Qualifications can include items like licenses, certifications, insurance, equipment,
            screenings, and some required selection rule criteria.
            If any qualifications are missing from a requesting provider&#39;s profile,
            you will be prompted to confirm assignment.
          </div>
        )}

        {!requirements.length && (
          <div className="u-margin-bottom--sm">
            There are no requirements for requesting or being assigned to this
            work order.
          </div>
        )}

        {!loading && requirements.length > 0 && (
          <div className="RequirementsTableWrapper">
            <ContextTable />
          </div>
        )}
      </TileDivision>
    </Tile>
  );

  return loading ? <Loader>{jsx}</Loader> : jsx;
};

export default ListRequirements;
