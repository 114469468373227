import React from 'react';

interface Props {}

const JobHistory = ({
}: Props): JSX.Element => (
  <div>
    Job History section will go here
  </div>
);

JobHistory.defaultProps = {};

export default JobHistory;
