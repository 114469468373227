import * as React from 'react';

const NoMap = (): JSX.Element => (
  <div
    style={{
      display: 'block',
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <img
      alt=""
      style={{ width: '100%' }}
      className="img-responsive"
      src="https://assets.fieldnation.com/images/generic-map.png"
    />
    <div
      className="text-xs-center"
      style={{
        color: '#fff',
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
      }}
    >
      <div
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <span style={{ fontSize: '2.8rem' }} className="icon-nomap" />
        <div>
          <strong>Cannot Find Location</strong>
        </div>
        <div>Verify the provided address is correct.</div>
      </div>
    </div>
  </div>
);

export default NoMap;
