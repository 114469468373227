import React, { Suspense, useEffect } from 'react';
import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';
import { Loader, v2 } from '@fieldnation/platform-components';
import get from 'lodash/get';
import { userInfoAtom, isLoadingAtom } from './state';
import { ProfileInfoItemWrapper } from '../../components';
import { PasswordProtectedRequest } from '..';
import {
  Username,
  FullName,
  Address,
  Email,
  CellNumber,
  PhoneNumber,
  ConnectedAccounts,
} from './items';

import css from './styles.scss';
import { AddressConfirmation } from '../AddressConfirmation/address-confirmation';

const { Tile, TileDivision } = v2;

const PersonalInfoComponent = () => {
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const isLoading = useRecoilValue(isLoadingAtom);
  const SSOEnabled = get(window, 'SSOEnabled', true);

  useEffect(() => {
    setUserInfo(window?.user || {});

    return () => setUserInfo({});
  }, []);

  return (
    <>
      <div className={css['personal-info-component']}>
        <Tile>
          <TileDivision heading="Personal Information">
            <Loader isLoading={isLoading} size="md">
              <ProfileInfoItemWrapper>
                <Username />
                <FullName />
                <Address />
                <Email />
                <CellNumber />
                <PhoneNumber />
              </ProfileInfoItemWrapper>
              <PasswordProtectedRequest />
              <AddressConfirmation />
            </Loader>
          </TileDivision>
        </Tile>
      </div>
      {SSOEnabled && (
        <div className={css['connected-account-component']}>
          <Tile>
            <TileDivision heading="Connected Accounts">
              <Loader isLoading={isLoading} size="md">
                <ProfileInfoItemWrapper>
                  <ConnectedAccounts method="Google" />
                  <ConnectedAccounts method="Apple" />
                </ProfileInfoItemWrapper>
                <PasswordProtectedRequest />
                <AddressConfirmation />
              </Loader>
            </TileDivision>
          </Tile>
        </div>
      )}
    </>
  );
};

const PersonalInfoWidget = (): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader fixed isLoading />}>
      <PersonalInfoComponent />
    </Suspense>
  </RecoilRoot>
);

export default PersonalInfoWidget;
