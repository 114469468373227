/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  v2, Icon, Switch, Sortable,
} from '@fieldnation/platform-components';
import css from './Components/style.scss';
import {
  getSidebarConfigEnabledCount,
  getSidebarConfigQuery,
  resetSidebarConfigToDefaults,
  toggleSidebarConfigToggle,
  updateSidebarConfigOrdering,
  uptickSidebarConfigVersion,
} from './queries/snapshot.query';
import skillsQuery from './queries/SkillsQuery';

import { SETTING_OPTIONS, Setting } from './queries/snapshot.atom';

const { Modal } = v2;

interface Props {
  onClose: () => void;
}

const DrawerSettingsModal: React.FC<Props> = ({ onClose }) => {
  const drawerConfigValue = useRecoilValue(getSidebarConfigQuery);
  const drawerConfigAppliedCount = useRecoilValue(getSidebarConfigEnabledCount);
  const resetDrawerConfigToDefaults = useSetRecoilState(resetSidebarConfigToDefaults);
  const updateDrawerConfigOrdering = useSetRecoilState(updateSidebarConfigOrdering);
  const uptickDrawerConfigVersion = useSetRecoilState(uptickSidebarConfigVersion);
  const updateDrawerConfigToggle = useSetRecoilState(toggleSidebarConfigToggle);
  const hasSkillDiscoveryExperiment = useRecoilValue(skillsQuery);

  if (drawerConfigValue.newColumnsFound) {
    // @ts-ignore
    uptickDrawerConfigVersion(drawerConfigValue);
  }

  const getRowContent = (
    section: Setting,
    enabled: boolean,
  ) => {
    if (section.enabled !== enabled) return null;
    // hide ability to toggle on
    if (section.setting === SETTING_OPTIONS.SKILLS && !hasSkillDiscoveryExperiment) return null;
    if (section.setting === SETTING_OPTIONS.SUMMARY) return null;
    return (
      <tr key={section.setting} data-id={section.setting}>
        <td>
          <span className="table--sortIcon">
            <Icon name="drag" size={24} />
          </span>
        </td>
        <td>
          <div>{section.setting}</div>
          {}
        </td>
        <td className="table--enableColumn">
          <Switch
            checked={section.enabled}
            onChange={() => {
            // @ts-ignore
              updateDrawerConfigToggle(section.setting);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <Modal
      roundedCorners
      bodyColor="gray10"
      isOpen
      onClose={onClose}
      header="Configure Preview"
      size="small"
      tertiaryText={`${drawerConfigAppliedCount} applied`}
      footerActions={[
        {
          label: 'Reset to defaults',
          type: 'secondary',
          onClick: () => {
            resetDrawerConfigToDefaults();
          },
        },
        {
          label: 'Done',
          type: 'primary',
          onClick: () => {
            onClose();
          },
        },
      ]}
    >
      <div>
        <p>Select and organize content that displays in side panel.</p>
        <div className={css.tableContainer}>
          <table className="table table-header-bordered">
            <thead>
              <tr>
                <th>Order</th>
                <th>Section</th>
                <th>Enable</th>
              </tr>
            </thead>
            <Sortable
              options={{}}
              tag="tbody"
              onChange={(newOrdered) => {
                // @ts-ignore
                updateDrawerConfigOrdering(newOrdered);
              }}
            >
              {drawerConfigValue.columns.map((n: any) => getRowContent(n, true))}
              {drawerConfigValue.columns.map((n: any) => getRowContent(n, false))}
            </Sortable>
          </table>
        </div>
      </div>
    </Modal>
  );
};

DrawerSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default DrawerSettingsModal;
