import { selectorFamily } from 'recoil';
import {
  ContentKey, ContentType, SectionCardKey, SectionKey,
} from '../Components/Section/types.d';
import {
  contentInputState,
  contentValidationState,
  sectionCardSections,
  sectionContentsState,
  sectionFilesState,
  sectionHasErrorState,
} from './AtomFamilies';

const isSectionOnSaveDisabled = selectorFamily<boolean, SectionKey>({
  key: 'isSectionOnSaveDisabled',
  get: (sectionKey: SectionKey) => ({ get }): boolean => {
    let isDisabled = false;
    const contents = get(sectionContentsState(sectionKey));
    const sectionHasError = get(sectionHasErrorState(sectionKey));
    contents.forEach((content) => {
      const inputState = get(contentInputState(content.key as ContentKey));
      const isRequiredEmpty = !inputState.contentConfig?.isOptional && !inputState.value;
      if (isRequiredEmpty) {
        isDisabled = true;
      }
      if (inputState.contentConfig?.contentType === ContentType.IMAGE) {
        const files = get(sectionFilesState(sectionKey));
        if (!files.length) {
          isDisabled = true;
        }
      }
      const contentValidation = get(contentValidationState(content?.key));
      if (!contentValidation?.isValid) {
        isDisabled = true;
      }
    });
    if (sectionHasError) {
      isDisabled = true;
    }
    return isDisabled;
  },
});

export const isSectionCardOnSaveDisabled = selectorFamily<boolean, SectionCardKey>({
  key: 'isSectionCardOnSaveDisabled',
  get: (cardKey: string) => ({ get }): boolean => {
    const sections = get(sectionCardSections(cardKey as SectionCardKey));
    let isDisabled = false;
    sections.forEach((section) => {
      const sectionKey = section.key;
      const sectionHasError = get(sectionHasErrorState(sectionKey));
      const contents = get(sectionContentsState(sectionKey));
      contents.forEach((content) => {
        const inputState = get(contentInputState(content.key as ContentKey));
        const isRequiredEmpty = !inputState.contentConfig?.isOptional && !inputState.value;
        if (isRequiredEmpty) {
          isDisabled = true;
        }

        const contentValidation = get(contentValidationState(content?.key));
        if (!contentValidation?.isValid) {
          isDisabled = true;
        }
      });
      if (sectionHasError) {
        isDisabled = true;
      }
    });
    return isDisabled;
  },
});

export default isSectionOnSaveDisabled;
