/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import { useRecoilValue } from 'recoil';
import { ProfileInfoItems } from '../constants';
import { canEditPermissionSelector } from '../widgets/PersonalInfo/state';

/**
 * Custom hook to decide if the user can edit an info item or not
 * Its like a macro. Otherwise we end up importing a bunch of things every time
 *
 * @param itemName string
 * @returns boolean
 */
export function useEditPermission(
  itemName: ProfileInfoItems | string,
): boolean {
  return useRecoilValue(canEditPermissionSelector(itemName));
}
