import React, {
  useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@fieldnation/platform-components';
import Registry, { ctx } from '../../Registry';
import {
  getRequirementsByTemplate,
  getRequirementsByCompany,
  getRequirementsByWorkOrder,
  getRequirementsByUserAndWorkOrder,
} from '../../api';
import RequirementsWidget from './RequirementsWidget';
import StateProvider from '../Store';
import {
  defaultSelectedScreening, mapRequirements,
} from '../utils';
import { QualificationRequirementsWidgetWrapperProps } from '../../types.d';

const ContextWrapper = ({
  companyId, templateId, userId, workOrderId: woId, isWoDetails = false,
}: QualificationRequirementsWidgetWrapperProps): JSX.Element => {
  const registry = useContext(ctx);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState(null);

  async function loadData(selectedTemplateId = 0) {
    const newTemplateId = selectedTemplateId || templateId;

    try {
      setLoading(true);
      let method: () => Promise<unknown>;

      const m = window.location.search.match(/&workorder_id=(\d+)/);
      const m1 = window.location.search.match(/workorder_id=(\d+)/);
      const t = window.location.search.match(/&template_id=(\d+)/);
      const workOrderId = Number(m?.[1] || m1?.[1] || woId || 0);
      const tempId = Number(newTemplateId || t?.[1] || 0);

      // Calls will be forwarded to qualificationsV2 from the backend if v2 data is available
      if (workOrderId) {
        method = getRequirementsByWorkOrder(workOrderId);
        if (userId && isWoDetails) {
          method = getRequirementsByUserAndWorkOrder(
            userId,
            workOrderId,
          );
        }
      } else if (tempId) {
        // create from template
        method = getRequirementsByTemplate(tempId);
      } else {
        method = getRequirementsByCompany(companyId);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const actionData: any = await method();

      if (actionData.error) {
        throw new Error();
      }

      const [formattedQualifications, formattedRequirements] = mapRequirements(actionData);

      setData({
        isEditting: false,
        ...actionData,
        requirements: formattedRequirements,
        qualifications: formattedQualifications,
        users: actionData?.users || [],
        workOrderId,
        templateId,
        newTemplateId,
        userId,
        companyId,
        selectedScreening: defaultSelectedScreening,
        v2: actionData.version === 3,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError('Qualifications temporarily unavailable');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData();
  }, [userId, companyId, templateId, woId]);

  registry.set('reloadQualifications', async (selectedTemplateId = 0) => {
    loadData(selectedTemplateId).catch((e) => setError(e));
  });

  if (loading) {
    return <span data-testid="loader"><Loader /></span>;
  }

  if (error) {
    return <span className="text-danger">{error}</span>;
  }

  return (
    <Registry>
      <StateProvider initialState={data}>
        <div data-testid="qsWrapper">
          <RequirementsWidget isWoDetails={isWoDetails} />
        </div>
      </StateProvider>
    </Registry>
  );
};

ContextWrapper.propTypes = {
  companyId: PropTypes.number,
  templateId: PropTypes.number,
  userId: PropTypes.number,
  workOrderId: PropTypes.number,
  isWoDetails: PropTypes.bool,

};

ContextWrapper.defaultProps = {
  companyId: 0,
  templateId: 0,
  userId: 0,
  workOrderId: 0,
  isWoDetails: false,
};

export default ContextWrapper;
