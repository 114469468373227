import { IJsObject } from '../api/types.d';
import { getAnsweredByUser, getOnBehalfUser } from './trackerUtils';
import {
  WaWorkerCompEventAnswerValue,
  WaWorkerCompEventProps,
  WaWorkerCompEventSaveStatus,
  WaWorkerCompEventSource,
  WaWorkerCompEvents,
} from './types.d';

const trackEvent = (event: WaWorkerCompEvents, props: IJsObject) => {
  if (window?._amplitudeClient?.logEvent) {
    // eslint-disable-next-line no-unused-expressions
    window?._amplitudeClient?.logEvent(event, props);
  }
};

export const trackAnsweredQuestionEvent = async (
  event: WaWorkerCompEvents,
  value: WaWorkerCompEventAnswerValue | string,
  source: WaWorkerCompEventSource,
): Promise<void> => {
  const eventProps = {
    [WaWorkerCompEventProps.ANSWERED_BY]: getAnsweredByUser(),
    [WaWorkerCompEventProps.ON_BEHALF_OF]: getOnBehalfUser(),
    [WaWorkerCompEventProps.SOURCE]: source,
  };
  if (event !== WaWorkerCompEvents.ENTERED_UBI_NUMBER) {
    eventProps[WaWorkerCompEventProps.VALUE] = value;
  }

  trackEvent(event, eventProps);
};

export const trackSaveEvent = async (
  values: string[],
  status: WaWorkerCompEventSaveStatus,
  source: WaWorkerCompEventSource,
): Promise<void> => {
  const eventProps = {
    [WaWorkerCompEventProps.SAVED_BY]: getAnsweredByUser(),
    [WaWorkerCompEventProps.VALUES]: values,
    [WaWorkerCompEventProps.ON_BEHALF_OF]: getOnBehalfUser(),
    [WaWorkerCompEventProps.SOURCE]: source,
    [WaWorkerCompEventProps.STATUS]: status,
  };
  trackEvent(WaWorkerCompEvents.SAVED_WA_L_AND_I_INFO, eventProps);
};

export const trackOpenAndClosedEvent = async (
  event: WaWorkerCompEvents,
  source: WaWorkerCompEventSource,
): Promise<void> => {
  const eventProps = {
    [event === WaWorkerCompEvents.OPENED_WA_L_AND_I_FORM ?
      WaWorkerCompEventProps.OPENED_BY : WaWorkerCompEventProps.CLOSED_BY]: getAnsweredByUser(),
    [WaWorkerCompEventProps.ON_BEHALF_OF]: getOnBehalfUser(),
    [WaWorkerCompEventProps.SOURCE]: source,
  };
  trackEvent(event, eventProps);
};
