import * as React from 'react';
import { Link } from '@fieldnation/platform-components';
import { BackoutsLists } from '../../types.d';

const BackoutsAssessmentID = ({ workOrderId }: BackoutsLists): JSX.Element => (
  <Link target="_blank" href={`/workorders/${workOrderId}`}>
    <span className="u-textLink">
      {workOrderId}
    </span>
  </Link>
);

export default BackoutsAssessmentID;
