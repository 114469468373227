import * as React from 'react';
import { useEffect } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { v2 } from '@fieldnation/platform-components';
import JobHistoryFilters from './JobHistoryFilters';
import JobHistory from './JobHistory';
import css from './JobHistoryModal.scss';
import jobHistoryQuery, { jobFilter } from '../queries/job-history.query';
import userQuery from '../queries/user.query';
import trackAmplitudeEvent, { PROFILE_FILTERED_RATINGS } from '../amplitude';

const { Modal } = v2;

interface Props {
  dayHistory: string;
  woHistory?: string;
  withMyCompany?: boolean;
  withCompanyId?: string;
  onClose: () => void;
  isFromPerformance?: boolean;
  defaultTypeOfWork?: number;
}

const JobHistoryModal = ({
  dayHistory,
  woHistory,
  onClose,
  withCompanyId = '0',
  withMyCompany = false,
  isFromPerformance = false,
  defaultTypeOfWork,
}: Props): JSX.Element | null => {
  const setFilter = useSetRecoilState(jobFilter);
  const jobHistory = useRecoilValue(jobHistoryQuery);
  const userData = useRecoilValue(userQuery);
  const hasContract = jobHistory?.filters?.hasContract
    ? ' has-filters-contract'
    : '';
  if (!jobHistory || !userData) {
    return null;
  }

  useEffect(() => {
    setFilter({
      dayHistory: Number(dayHistory) || 0,
      woHistory: Number(woHistory) || 0,
      withCompanyId: Number(withCompanyId) || 0,
      withMyCompany,
      typeOfWork: defaultTypeOfWork,
    });
    // track that the ratings were filtered on load
    if (defaultTypeOfWork) {
      trackAmplitudeEvent({
        category: PROFILE_FILTERED_RATINGS,
        ampProps: {
          Days: 'All time',
          'Type of Work': defaultTypeOfWork,
        },
      });
    }
  }, []);

  return (
    <Modal
      isOpen
      header={`Ratings for ${userData.first_name} ${userData.last_name}`}
      onClose={onClose}
      size="large"
      footerActions={[
        {
          label: 'Close',
          type: 'secondary',
          onClick: onClose,
        },
      ]}
    >
      <div className={classNames(css['job-history-modal-wrapper'], css[`job-history-modal-wrapper${hasContract}`])}>
        {jobHistory?.filters?.hasContract && (
          <div className={css['job-history-modal-left']}>
            <JobHistoryFilters isFromPerformance={isFromPerformance} />
          </div>
        )}
        <div className={css['job-history-modal-right']}>
          <JobHistory />
        </div>
      </div>
    </Modal>
  );
};

JobHistoryModal.defaultProps = {
  woHistory: '0',
  withCompanyId: '0',
  withMyCompany: false,
  isFromPerformance: false,
  defaultTypeOfWork: 0,
};

export default JobHistoryModal;
