import * as React from 'react';
import classNames from 'classnames';
import ReliabilityFallback from '../ProviderSnapshot/Components/ReliabilityFallback';
import CancellationRate from './CancellationRate';
import TimelinessRate from './TimelinessRate';
import { IReliabilityScore } from './types.d';

interface Props {
  providerId: number;
  reliabilityScore: IReliabilityScore;
  wrapperClasses?: {
    [key: string]: boolean;
  };
}

export default function ProviderReliability(props: Props): JSX.Element {
  const { providerId, reliabilityScore, wrapperClasses } = props;
  return (
    <React.Suspense fallback={<ReliabilityFallback />}>
      <div className={classNames({ ...wrapperClasses })}>
        <CancellationRate
          providerId={providerId}
          reliabilityScore={reliabilityScore}
        />
        <TimelinessRate
          providerId={providerId}
          reliabilityScore={reliabilityScore}
        />
      </div>
    </React.Suspense>
  );
}

ProviderReliability.defaultProps = {
  wrapperClasses: {},
};
