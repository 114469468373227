/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import css from './Styles.scss';
import { CustomSelectOptionsProps } from './types.d';

export const CustomSelectOptions: React.FC<CustomSelectOptionsProps> =
({
  onSelect,
  onFocus,
  isFocused,
  option,
  className,
}: CustomSelectOptionsProps) => {
  const optionItems = {
    label: option?.label || '',
    value: option?.value || '',
    type: option?.type || '',
  };

  const handleMouseDown = (event: React.FocusEvent<HTMLSelectElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(optionItems, event);
  };

  const handleMouseEnter = (event: React.FocusEvent<HTMLSelectElement>): void => {
    onFocus(optionItems, event);
  };

  const handleMouseMove = (event: React.FocusEvent<HTMLSelectElement>): void => {
    if (isFocused) return;
    onFocus(optionItems, event);
  };

  return (
    <div
      role="presentation"
      className={className}
      // @ts-ignore
      onMouseDown={handleMouseDown}
      // @ts-ignore
      onMouseEnter={handleMouseEnter}
      // @ts-ignore
      onMouseMove={handleMouseMove}
    >
      <h1 className={css.customSelectTitle}>{option?.label}</h1>
      <h2 className={css.customSelectCategory}>{option?.type}</h2>
    </div>
  );
};
