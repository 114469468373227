/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GroupCardListItem } from '@fieldnation/platform-components';
import * as React from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import * as PropTypes from 'prop-types';
import providerCommitmentQuery from '../queries/provider-commitment.query';
import css from './Rating.scss';
import TimelinessModal from './TimelinessModal';
import performance, {
  hasShowPerformanceTile,
  performanceFilter,
} from '../queries/performance.query';
import { userIdQuery } from '../queries/user.query';
import trackAmplitudeEvent, { VIEWED_RELIABILITY_DETAILS } from '../amplitude';

interface TimelinessProps {
  useAlternateNaming?: boolean;
}

const Timeliness: React.FC<TimelinessProps> = ({
  useAlternateNaming = false,
}) => {
  const providerCommitment = useRecoilValue(providerCommitmentQuery);
  const performanceFilters = useRecoilValue(performance);
  const performanceFilterValue = useRecoilValue(performanceFilter);
  const userId = useRecoilValue(userIdQuery);
  const [showModal, setShowModal] = useState(false);
  const { numeratorCount = 0, denominatorCount = 0 } = providerCommitment?.timelinessStats || {};

  const showPerformanceTile = hasShowPerformanceTile();
  const commitmentDays = Number(performanceFilterValue?.dayHistory);

  const getPerformanceFilters = () => ({
    dayHistory: !showPerformanceTile
      ? commitmentDays
      : Number(performanceFilters?.filters?.dayHistory) || 0,
    pastHistory: Number(performanceFilters?.filters?.woHistory) || 0,
    companyId: performanceFilters?.filters?.withCompanyId || 0,
    graphType: 'monthly',
  });

  const value = providerCommitment?.showTimelinessPercent ? (
    `${providerCommitment?.timelinessPercent?.toFixed(1)}%`
  ) : (
    <span className={classNames('text-muted', css['timeliness-sub-text'])}>not enough data</span>
  );

  return (
    <div className="u-padding-bottom--md">
      {!!showModal && (
        <TimelinessModal
          backoutsFilters={{ ...getPerformanceFilters() }}
          userId={userId || 0}
          onClose={() => setShowModal(false)}
          useAlternateNaming
        />
      )}
      <div className={css['timeliness-wrapper']}>
        <GroupCardListItem
          hideExpander
          dottedLine
          middleContent=""
          label={useAlternateNaming ? 'Timeliness' : 'Check-in Accuracy'}
          // @ts-ignore
          data={<span className={css['semi-bold']}>{value}</span>}
          type="default"
          border="none"
        />
      </div>
      <div className={classNames('text-muted', css['timeliness-sub-text'])}>
        {useAlternateNaming ? (
          <>
            {denominatorCount > 0 && (
              <span>
                {numeratorCount}
                &nbsp;of&nbsp;
                {denominatorCount}
                &nbsp;
              </span>
            )}
            <span>Hard-start work orders checked in on-time</span>
          </>
        ) : (
          'On-site check-ins at designated start time.'
        )}
      </div>
      <div>
        <button
          className={css['see-commitment-link']}
          type="button"
          onClick={() => {
            const match = window.location.pathname.match(/workorders\/(\d)+\/providers/g);
            setShowModal(true);
            trackAmplitudeEvent({
              category: VIEWED_RELIABILITY_DETAILS,
              ampProps: {
                Source: match?.length ? 'Provider Drawer' : 'Profile',
                Type: 'Check In Accuracy',
                Score: providerCommitment?.showTimelinessPercent
                  ? providerCommitment?.timelinessPercent?.toFixed(1)
                  : 'not enough data',
              },
            });
          }}
        >
          <span className={css['timeliness-sub-text']}>See check-in details</span>
        </button>
      </div>
    </div>
  );
};

Timeliness.propTypes = {
  // For the timeliness metric we are showing two different sets of labels
  // use false for buyers to see this as 'Check-in Accuracy'
  // use true for providers to see this as 'Timeliness'
  useAlternateNaming: PropTypes.bool,
};

Timeliness.defaultProps = {
  useAlternateNaming: false,
};

export default Timeliness;
