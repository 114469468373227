import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { v2, Loader } from '@fieldnation/platform-components';
import { deleteUserLicensesCertifications } from '../api';
import Padded from './Padded';
import licenseCertificationQuery from '../queries/licenses-certifications.query';
import { versionState } from '../queries/user.query';

const { Modal } = v2;

interface Props {
  onClose: () => void;
  deleteId: number;
}

const DeleteLicensesAndCertifications: React.FC<Props> = ({
  deleteId,
  onClose,
}) => {
  const licensesCertifications = useRecoilValue(licenseCertificationQuery);
  if (!licensesCertifications) {
    return null;
  }
  const { id: userId, data } = licensesCertifications;
  const [loading, setLoading] = useState(false);
  const lcData = data.filter((f) => f.id === deleteId)[0];
  const [version, setVersion] = useRecoilState(versionState);

  // save or store the form data
  const onSave = async () => {
    if (!deleteId) {
      return;
    }
    setLoading(true);

    await deleteUserLicensesCertifications(userId, lcData);
    setVersion(version + 1);
    onClose();
  };

  return (
    <Modal
      header={`Delete ${lcData.name}`}
      onClose={onClose}
      isOpen
      footerActions={[
        {
          label: 'Cancel',
          type: 'secondary',
          onClick: onClose,
        },
        {
          label: 'Yes, Delete',
          type: 'primary',
          onClick: onSave,
        },
      ]}
    >
      {loading && <Loader fixed isLoading />}
      <Padded>
        Are you sure you want to delete
        {' '}
        <strong>{lcData.name}</strong>
        ?
      </Padded>
    </Modal>
  );
};

DeleteLicensesAndCertifications.propTypes = {
  onClose: PropTypes.func.isRequired,
  deleteId: PropTypes.number.isRequired,
};

export default DeleteLicensesAndCertifications;
