import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  v2,
  Loader,
  Grid,
  GridColumn,
} from '@fieldnation/platform-components';
import { FooterAction } from '@fieldnation/platform-components/src/v2/Modal/types.d';
import {
  useRecoilValue,
} from 'recoil';
import css from './Styles.scss';
import {
  credentialDefaultValue,
  lcDefaultInputs,
  lcSelectDataQuery,
  orgDefaultValue,
} from './Store/query';
import { validateLCInputs } from './validation';
import {
  getUserCredentialDetailsById,
  getCredentialDetailsById,
  saveCredentials,
  updateCredentialsByManualVerification,
} from './api';
import { CustomSelectOptions } from './CustomSelectOptions';
import { success, error } from '../legacy/flash-messenger';
import SelectDropdown from './Components/SelectDropdown';
import InputField from './Components/InputField';
import FileUploadField from './Components/FileUploadField';
import {
  CredentialInputData,
  Credential,
  LicenseCertidicationProps,
  VerificationOrg,
  UserCredentialsDetails,
  CredentialSelect,
} from './types';

const {
  Modal,
  DatePicker,
  TextArea,
} = v2;
const ConfigurableCredential = ({
  id,
  userId,
  isEditMode,
  onSubmitCallback,
  onClose,
}: LicenseCertidicationProps): JSX.Element => {
  const [isOpen] = useState(true);
  const [isEditModeActive] = useState<boolean>(id !== undefined && isEditMode);
  const [loading, setLoading] = useState<boolean>(false);
  const lcSelectOptions = useRecoilValue(lcSelectDataQuery);
  const [selectedCredential, setSelectedCredential] = useState<Credential>(credentialDefaultValue);
  const [lcInputs, setLCInputs] = useState<CredentialInputData>(lcDefaultInputs);
  const [selectedOrganization, setSelectedOrganization] = useState<VerificationOrg>(orgDefaultValue);
  const [lcFile, setLcFile] = useState<any>([]);
  const numberFieldLabelText = `${selectedCredential?.type} ID Number`;
  const [lcValidation, setLCValidation] = useState<Partial<CredentialInputData>>({});
  const [isSelectedModeActive] = useState<boolean>(
    id !== undefined && isEditMode === false && selectedCredential?.id <= 0,
  );
  const headingText = isEditModeActive ? `Edit ${selectedCredential?.name} ` : 'Add Credential';
  const lcStatusOptions = [
    { label: 'In Review', value: 0 },
    { label: 'Verified', value: 1 },
    { label: 'Denied', value: 4 },
  ] as CredentialSelect[];

  const getDynamicInputRecord = () => selectedOrganization?.fields?.map((field) => field?.name);

  const validateInputs = () => validateLCInputs(
    lcInputs,
    setLCValidation,
    [],
    false,
      selectedCredential?.verifiedByName,
      getDynamicInputRecord(),
  );

  const handleOnClose = () => {
    onClose();
  };

  const handleLcChange = async (v) => {
    setLoading(true);
    const detailsCredential: Credential = await getCredentialDetailsById(v?.value || 0);
    setSelectedCredential(detailsCredential);
    setLCInputs({
      ...lcInputs,
      credentialId: detailsCredential.id,
      credentialIdNumber: '',
    });
    setLoading(false);
    setLcFile([]);
  };

  const handleLCSave = async (): Promise<void> => {
    const isLCFormValid = validateInputs();
    if (!isLCFormValid) {
      return;
    }
    setLoading(true);
    const insertData: CredentialInputData = {
      ...lcInputs,
      companyId: window?.context?.company?.id || 0,
      companyName: window?.context?.company?.name || '',
      firstName: window?.context?.first_name || '',
      lastName: window?.context?.last_name || '',
    };
    delete (insertData.id);
    const resp = await saveCredentials(insertData, userId, {
      headers: {
        'x-user-id': userId,
      },
      onError: async (_res: Response, msg: any) => {
        error(msg?.message || 'An error occurred while saving credentials', 'Error');
      },
    });
    if (resp?.id && resp?.id > 0) {
      success('Credential added to the provider profile successfully', 'Success');
      setSelectedOrganization(orgDefaultValue);
      if (onSubmitCallback) {
        onSubmitCallback({ ...lcInputs }, resp);
      }

      handleOnClose();
    }
    setLoading(false);
  };

  const handleLCUpdate = async (): Promise<void> => {
    const isLCFormValid = validateInputs();
    if (!isLCFormValid) {
      return;
    }
    setLoading(true);
    const updatedData: CredentialInputData = {
      ...lcInputs,
      companyId: window?.context?.company?.id || 0,
      companyName: window?.context?.company?.name || '',
      firstName: window?.context?.first_name || '',
      lastName: window?.context?.last_name || '',
      manualVerificationBy: window?.context?.id || 0,
    };

    const resp = await updateCredentialsByManualVerification(updatedData, userId, {
      headers: {
        'x-user-id': userId,
      },
    });

    if (resp.error) {
      error('Error saving license/certificate', 'Error');
      setLoading(false);
    }
    if (onSubmitCallback) {
      onSubmitCallback({ ...updatedData }, resp);
    }
    setLoading(false);
    handleOnClose();
  };

  const handleLcFileUpload = async (file): Promise<void> => {
    setLcFile(file);
    setLoading(true);
    const sizeLimit = 15728640; // 15mb
    if (file[0]?.size > sizeLimit) {
      error('File size should be less than 15mb', 'Error');
      setLoading(false);
      setLcFile([]);
      return;
    }
    const fromData = new FormData();
    fromData.append('file', file[0]);
    const url = '/credentials/file/file-upload';
    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-user-id': userId.toString(),
      },
      body: fromData,
      credentials: 'same-origin',
      redirect: 'follow',
    }).then(async (response: Response) => {
      if (response.ok) {
        const data = await response.json();
        setLCInputs({
          ...lcInputs,
          uploadImageUrl: data?.fileKey || '',
        });
      } else {
        error('There was an unknown error uploading the file', 'Error');
        setLcFile([]);
      }
      setLoading(false);
    }).catch(() => {
      error('There was an unknown error uploading the file', 'Error');
      setLoading(false);
      setLcFile([]);
    });
  };

  const handleLcFileRemove = (): void => {
    setLcFile([]);
    setLCInputs({
      ...lcInputs,
      uploadImageUrl: '',
    });
  };

  const footerActions: FooterAction[] = [
    {
      label: isSelectedModeActive ? 'Add' : 'Update',
      type: 'primary',
      disabled: loading,
      onClick: isEditModeActive ? handleLCUpdate : handleLCSave,
    },
  ];

  const fetchCredentialDetails = (
    credentialId: number,
    states: Partial<CredentialInputData>,
  ) => {
    setLoading(true);
    getCredentialDetailsById(Number(credentialId))
      .then((resp: Credential) => {
        setSelectedCredential(resp);
        setLCInputs({
          ...lcInputs,
          ...states,
          credentialId: resp?.id,
        });
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        error('Something Went Wrong', 'Error');
        onClose();
      });
  };

  useEffect(() => {
    if (isSelectedModeActive) {
      fetchCredentialDetails(Number(id), {});
    }
  }, [isSelectedModeActive]);

  useEffect(() => {
    if (isEditModeActive) {
      setLoading(true);
      getUserCredentialDetailsById(Number(id), Number(userId))
        .then((resp: UserCredentialsDetails) => {
          fetchCredentialDetails(Number(resp?.credential?.id || 0), {
            id: Number(id),
            credentialId: Number(resp?.credential?.id || 0),
            credentialIdNumber: String(resp?.credentialIdNumber || 0),
            issuingOrgId: resp?.issuingOrg?.verificationId || 0,
            issuedAt: new Date(
              (resp?.issuedAt || 0) * 1000,
            ).toLocaleDateString('en-US'),
            expiresAt: new Date(
              (resp?.expiresAt || 0) * 1000,
            ).toLocaleDateString('en-US'),
            uploadImageUrl: resp?.uploadImageUrl || '',
            verified: resp?.verified || 0,
            notes: resp?.notes || '',
          });

          setLoading(false);
        }).catch(() => {
          setLoading(false);
          error('Something Went Wrong', 'Error');
          onClose();
        });
    }
  }, [isEditModeActive]);

  return (
    <Modal
      size="medium"
      header={headingText}
      footerActions={footerActions}
      onClose={handleOnClose}
      isOpen={isOpen}
    >
      <Loader isLoading={loading}>
        <div className={css.lcFormWrapper}>
          <Grid>
            <GridColumn xs="12">
              <SelectDropdown
                label="Credential"
                value={lcSelectOptions.find(
                  (data) => Number(data.value) === Number(selectedCredential?.id || 0),
                ) || ''}
                options={lcSelectOptions}
                disabled={isEditModeActive || isSelectedModeActive}
                onChange={handleLcChange}
                optionComponent={CustomSelectOptions}
                hasValidationError={!!lcValidation?.credentialId}
                errorMessage={`${selectedCredential?.type || 'Credential'} Is Required!`}
              />
            </GridColumn>

            {selectedCredential?.id > 0 && (
              <>
                <GridColumn xs="12">
                  <InputField
                    id="number"
                    label={numberFieldLabelText}
                    onChange={(e) => {
                      setLCInputs({
                        ...lcInputs,
                        credentialIdNumber: e.target.value.trim(),
                      });
                    }}
                    value={lcInputs.credentialIdNumber || ''}
                    hasValidationError={!!lcValidation?.credentialIdNumber}
                    errorMessage={`${selectedCredential?.type} ID number is required`}
                  />
                </GridColumn>
                <GridColumn xs="12" md="6">
                  <div className={css.inputFields}>
                    <DatePicker
                      label="Issue date"
                      value={lcInputs.issuedAt || ''}
                      validationMessage={lcValidation?.issuedAt || ''}
                      onChange={(value) => {
                        setLCInputs({
                          ...lcInputs,
                          issuedAt: value.trim(),
                        });
                      }}
                    />
                  </div>
                </GridColumn>
                <GridColumn xs="12" md="6">
                  <div className={css.inputFields}>
                    <DatePicker
                      label="Expiration date"
                      value={lcInputs.expiresAt || ''}
                      validationMessage={lcValidation.expiresAt || ''}
                      onChange={(value) => {
                        setLCInputs({
                          ...lcInputs,
                          expiresAt: value.trim(),
                        });
                      }}
                    />
                  </div>
                </GridColumn>

                <GridColumn xs="12">
                  <FileUploadField
                    label="Upload"
                    accept="image/jpeg, image/png, application/pdf"
                    helpText="Supports jpeg, png and pdf file upload"
                    multiple={false}
                    onDrop={handleLcFileUpload}
                    files={lcFile}
                    onDeleteFile={handleLcFileRemove}
                    hasValidationError={!!lcValidation?.uploadImageUrl}
                    errorMessage={lcValidation?.uploadImageUrl || ''}
                  />
                </GridColumn>

                <GridColumn xs="12">
                  <div className={css.inputFields}>
                    <TextArea
                      optional
                      hideOptionalLabel
                      id="notes"
                      label="Notes"
                      helpText="Notes are only visible for internal uses"
                      onChange={(e) => {
                        setLCInputs({
                          ...lcInputs,
                          notes: e.target.value,
                        });
                      }}
                      value={lcInputs.notes || ''}
                    />
                  </div>
                </GridColumn>

                <GridColumn xs="12">
                  <SelectDropdown
                    label="Status"
                    value={lcStatusOptions.find((data) => Number(data.value) === Number(lcInputs?.verified || 0)) || ''}
                    options={lcStatusOptions}
                    onChange={(e: any) => setLCInputs({ ...lcInputs, verified: e?.value || 0 })}
                  />
                </GridColumn>
              </>
            )}
          </Grid>
        </div>
      </Loader>
    </Modal>
  );
};
ConfigurableCredential.propTypes = {
  onSubmitCallback: PropTypes.func,
  id: PropTypes.number,
  userId: PropTypes.number.isRequired,
  isEditMode: PropTypes.bool,
};

ConfigurableCredential.defaultProps = {
  id: 0,
  isEditMode: false,
  onSubmitCallback: () => { },
};
export default ConfigurableCredential;
