import * as React from 'react';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v2 } from '@fieldnation/platform-components';
import jobHistoryQuery from '../queries/job-history.query';
import Padded from './Padded';
import { getPrimaryFilterOptions, isProvider, getSecondaryFilterOptions } from './utils';
import typesOfWorkQuery from '../queries/types-of-work.query';
import { hasShowPerformanceTile, performanceFilter } from '../queries/performance.query';

import { BackoutsDetailsFilters, LabelValue } from '../types.d';

const { Select } = v2;

type ModalFilter = {
  filters: BackoutsDetailsFilters;
  setFilters: (arg0: Record<string, unknown>) => void;
};

const ModalFilters: React.FC<ModalFilter> = ({
  filters,
  setFilters,
}: ModalFilter) => {
  const jobHistory = useRecoilValue(jobHistoryQuery);
  const typesOfWork = useRecoilValue(typesOfWorkQuery);
  const [filter, setFilter] = useRecoilState(performanceFilter);
  const showPerformanceTile = hasShowPerformanceTile();

  let companyList:LabelValue[] = [];
  if (jobHistory?.companyList) {
    companyList = jobHistory?.companyList;
  }

  let typeOfWorkOptions:LabelValue[] = [
    { label: 'All types of work', value: '0' },
  ];
  if (jobHistory?.companyList) {
    typeOfWorkOptions = [
      ...typeOfWorkOptions,
      ...(typesOfWork?.data.map(({ name }) => ({ label: name, value: name })) || []),
    ];
  }

  let defaultDF = '0';
  if (filters?.dayHistory) {
    defaultDF = `${filters?.dayHistory}days`;
  } else if (filters?.pastHistory) {
    defaultDF = `${filters?.pastHistory}workorders`;
  }
  const [dataFilter, setDataFilter] = useState<string>(defaultDF);

  const applyFilter = async (type: BackoutsDetailsFilters) => {
    setFilters({ ...filters, ...type });
    // sets the filter value back in the reliability tile
    setFilter({
      dayHistory: type.dayHistory,
      woHistory: type?.pastHistory,
      version: (filter?.version || 0) + 1,
    });
  };

  return (
    <div className="available-filters">
      <Padded>
        <Select
          label=""
          value={`${dataFilter}`}
          options={showPerformanceTile ? getPrimaryFilterOptions() : getSecondaryFilterOptions()}
          onChange={async ({ value: v }) => {
            const value = String(v);
            if (value) {
              setDataFilter(value);
            }

            let dayHistory = '';
            let pastHistory = '';
            if (value.includes('days')) {
              dayHistory = value.replace(/\D/g, '');
            }
            if (value.includes('workorders')) {
              pastHistory = value.replace(/\D/g, '');
            }
            const dayHistoryFilter = Number(dayHistory) || 0;

            await applyFilter({
              dayHistory: dayHistoryFilter,
              pastHistory: Number(pastHistory) || 0,
              graphType: 'monthly',
            });
          }}
        />
      </Padded>
      <Padded>
        <Select
          label=""
          value={`${filters?.typeOfWork || 0}`}
          options={typeOfWorkOptions}
          onChange={async ({ value }) => {
            await applyFilter({ typeOfWork: value?.toString() });
          }}
        />
      </Padded>
      {!isProvider()
        && (
        <Padded>
          <Select
            label=""
            value={`${filters?.companyId || '0'}`}
            options={companyList}
            onChange={async ({ value }) => {
              await applyFilter({ companyId: Number(value) });
            }}
          />
        </Padded>
        )}
    </div>
  );
};
export default ModalFilters;
