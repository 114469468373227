/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import {
  Icon,
  IconAside,
  IconAsideIcon,
} from '@fieldnation/platform-components';

import { IInfoBox } from './types.d';

import css from './styles.scss';

export const InfoBox = ({ message, icon = { name: 'infoHollow' } }: IInfoBox): JSX.Element => (
  <div className={css[`info-box-content${icon.name.includes('warning') ? '--warning' : ''}`]}>
    <IconAside iconPosition="left" iconColor="grey80">
      <IconAsideIcon verticalAlign="top">
        <Icon name={icon.name} size={24} block />
      </IconAsideIcon>
      <div className={css['info-box-message']}>{message}</div>
    </IconAside>
  </div>
);
