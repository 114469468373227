import React, { createElement, isValidElement } from 'react';
import classNames from 'classnames';
import {
  Button,
  ButtonGroup,
  InfoIcon,
} from '@fieldnation/platform-components';
import { CardTooltipProps } from './types.d';
import css from './CardTooltip.scss';

const CardTooltip = (props: CardTooltipProps): JSX.Element => {
  const { heading, content, footerActions } = props;
  let { children } = props;

  if (children && !Array.isArray(children)) {
    children = isValidElement(children)
      ? [children]
      : [createElement(children as string)];
  }

  return (
    <span
      className={classNames(css['card-tooltip-main'])}
      data-testid="custom-element"
    >
      <InfoIcon position="right" showOnEvent="click">
        <div className={classNames(css['card-tooltip-container'])}>
          <div className={classNames(css['tooltip-heading'])}>
            {heading || ''}
          </div>
          <div className={classNames(css['tooltip-content'])}>
            <div>{content || ''}</div>
            {Array.isArray(children) &&
              children.map((child, index) => (
                <span key={`tooltip-child-${index + 1}`}>{child}</span>
              ))}
          </div>
          <div className={classNames(css['tooltip-footer'])}>
            <ButtonGroup textAlign="right">
              {footerActions?.map((buttons, i) => (
                <Button key={`toolt-buttons-${i + 1}`} {...buttons} />
              ))}
            </ButtonGroup>
          </div>
        </div>
      </InfoIcon>
    </span>
  );
};

export default CardTooltip;
