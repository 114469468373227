import React from 'react';
import chunk from 'lodash/chunk';
import classNames from 'classnames';
import { Grid, GridColumn } from '@fieldnation/platform-components';
import { ColumnUnit } from '@fieldnation/platform-components/src/GridColumn/types.d';
import { TileManagerProps } from './types.d';

import css from './TileManager.scss';

const TileManager = (props: TileManagerProps): JSX.Element => {
  let { children = [] } = props;
  const { itemsPerRow = '2' } = props;

  if (!Array.isArray(children)) children = [children];

  children = children.filter(Boolean);

  const divided = false;
  const vSpace = true;

  const renderableItems = chunk(
    children,
    Number(itemsPerRow) > 12 ? 12 : Number(itemsPerRow),
  );

  const renderRow = (items: JSX.Element[], breakPoint: ColumnUnit) => (
    <Grid divided={divided} vSpace={vSpace} fillParent>
      {items.map((item, index) => (
        <GridColumn
          xs="12"
          sm="12"
          md={Number(breakPoint) < 6 ? '6' : breakPoint}
          lg={Number(breakPoint) < 6 ? '6' : breakPoint}
          xl={breakPoint}
          key={`tile-row-${index + 1}`}
        >
          <div
            className={classNames(css['card-cell'], `card-cell-${index + 1}`)}
          >
            {item}
          </div>
        </GridColumn>
      ))}
    </Grid>
  );

  return (
    <div className={classNames(css['tilemanager-main'])}>
      <Grid divided={divided} vSpace={vSpace} fillParent>
        {renderableItems.map((item, index) => (
          <GridColumn xs="12" md="12" key={`tile-row-grp-${index + 1}`}>
            {renderRow(item, String(12 / (item?.length || 1)) as ColumnUnit)}
          </GridColumn>
        ))}
      </Grid>
    </div>
  );
};

export default TileManager;
