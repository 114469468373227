import { FileInput, Grid, GridColumn } from '@fieldnation/platform-components';
import css from '../Styles.scss';
import React, { useState } from 'react';
import ErrorBlock from './ErrorBlock';
import { error } from '../../legacy/flash-messenger';

const FileUpload = ({
  validation,
  onSetLoading,
  onChange,
  userId,
}) => {
  const [lcFile, setLcFile] = useState<any>([]);

  const handleLcFileUpload = async (file): Promise<void> => {
    setLcFile(file);
    onSetLoading(true);
    const sizeLimit = 15728640; // 15mb
    if (file[0]?.size > sizeLimit) {
      error('File size should be less than 15mb', 'Error');
      onSetLoading(false);
      setLcFile([]);
      return;
    }
    const fromData = new FormData();
    fromData.append('file', file[0]);
    const url = '/credentials/file/file-upload';
    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-user-id': userId.toString(),
      },
      body: fromData,
      credentials: 'same-origin',
      redirect: 'follow',
    }).then(async (response: Response) => {
      if (response.ok) {
        const data = await response.json();
        onChange(data?.fileKey || '');
      } else {
        error('There was an unknown error uploading the file', 'Error');
        setLcFile([]);
      }
      onSetLoading(false);
    }).catch(() => {
      error('There was an unknown error uploading the file', 'Error');
      onSetLoading(false);
      setLcFile([]);
    });
  };

  const handleLcFileRemove = (): void => {
    setLcFile([]);
    onChange('');
  };

  return <Grid>
    <GridColumn xs="12">
      <div className={css.inputFields}>
        <FileInput
          buttonLabel="Upload file"
          accept="image/jpeg, image/png, application/pdf"
          helpText="JPG, PNG or PDF files accepted"
          multiple={false}
          onDrop={(file) => {
            handleLcFileUpload(file);
          }}
          files={lcFile}
          onDeleteFile={() => {
            handleLcFileRemove();
          }}
        />
        <ErrorBlock msg={validation} />
      </div>
    </GridColumn>
  </Grid>
};

export default FileUpload;

