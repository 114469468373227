/* eslint-disable no-console */
/* eslint-disable function-paren-newline */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import get from 'lodash/get';
import { useDebouncedCallback } from 'use-debounce';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';
import { LoadOptionsSignature } from '@fieldnation/platform-components/src/v2/Select/types.d';
import { DEFAULT_DEBOUNCE_DELAY, MINIMUM_CHARACTER_COUNT } from './constants';
import {
  autocompleteRequestBuilder,
  autocompleteResponseBuilder,
  getPlaceDetailsFromPlaceId,
} from './helpers';
import {
  ICountry,
  IUseCountryList,
  ISimpleAddressInputValue,
  IFullAddressInputValues,
} from './types.d';

/**
 * This hook just modifies the v2 Select input
 *
 * @param {array} parentDivList - Array of div classnames
 * @param {array} fix - Array of things to fix
 */
export const useSelectInputFormatter = (
  parentDivList: string[],
  fix: ('arrow' | 'height')[],
): void => {
  useEffect(() => {
    parentDivList.forEach((item) => {
      if (fix.includes('arrow')) {
        const selectArrowStyle = document.querySelector<HTMLElement>(
          `.${item} .Select-arrow-zone`,
        )?.style;
        if (selectArrowStyle) {
          selectArrowStyle.display = 'none';
        }
      }
      if (fix.includes('height')) {
        const selectValueStyle = document.querySelector<HTMLElement>(
          `.${item} .Select-value`,
        )?.style;
        if (selectValueStyle) {
          selectValueStyle.lineHeight = '40px';
        }
        const selectControlStyle = document.querySelector<HTMLElement>(
          `.${item} .Select-control`,
        )?.style;
        if (selectValueStyle && selectControlStyle) {
          selectControlStyle.height = '40px';
        }
      }
    });
  }, []);
};

/**
 * It returns the country list with states and if current country is give,
 * It will return the country object.
 *
 * @param {string} currentCountry - Current Selected country
 * @param {string} countryListValuePath - Object path to get the country list from. Starts from window
 * @return {Object} { allCountries, currentCountryValue }
 */
export const useCountryList = (
  currentCountry: string,
  countryListValuePath: string,
): IUseCountryList => {
  const allCountries = useMemo<ICountry[]>(
    () =>
      get(window, countryListValuePath, []).map((country) => ({
        ...(country as ICountry),
        label: country?.name,
        value: country?.iso,
      })).sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      }),
    [],
  );
  const currentCountryValue = useMemo<ICountry>(() => {
    if (typeof currentCountry === 'string') {
      return (
        allCountries.find(({ value }) => value === currentCountry) ||
        ({} as ICountry)
      );
    }
    return currentCountry;
  }, [currentCountry]);

  return { allCountries, currentCountryValue };
};

/**
 * Hook that can suggest places from google maps
 * It returns LoadOptionsSignature callback that can be provided to a typeahead enabled select
 *
 * @param {string} sessionToken - Places Autocomplete Session Token
 * @param {ISimpleAddressInputValue} currentValue - Current Selected Address Value
 * @param {Function} onChangeCallback - callback that will be invoked
 *
 * @returns {Function} Debounced LoadOptionsSignature callback
 */
export const usePlacesLoadOptions = (
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  currentValue: ISimpleAddressInputValue,
  onChangeCallback: (v: ISimpleAddressInputValue) => void,
  autocompletionRequest?: google.maps.places.AutocompletionRequest | undefined,
  targetCountry?: string,
): DebouncedState<LoadOptionsSignature> => {
  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  /**
   * This useEffect pushes the user selected option/change to upstream component
   */
  useEffect(() => {
    onChangeCallback(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (window?.google?.maps?.places) {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService(),
      );
    }
  }, []);

  return useDebouncedCallback((input: string, cb: Function): void => {
    if (!autocompleteService) return cb([]);
    if (input.length < MINIMUM_CHARACTER_COUNT) return cb([]);
    try {
      autocompleteService.getPlacePredictions(
        autocompleteRequestBuilder(
          input,
          sessionToken,
          autocompletionRequest,
          targetCountry,
        ),
        (suggestions) => {
          cb(null, {
            complete: true,
            options: autocompleteResponseBuilder(input, suggestions),
          });
        },
      );
    } catch (error) {
      console.error(error);
      cb(null, {
        complete: true,
        options: autocompleteResponseBuilder(input, null),
      });
    }
  }, DEFAULT_DEBOUNCE_DELAY);
};

/**
 * handles when user clicks on an option
 *
 * @param stateUpdater
 * @param token
 * @param tokenUpdater
 * @returns
 */
export const useSelectOnChange = (
  stateUpdater: Dispatch<SetStateAction<ISimpleAddressInputValue>>,
  token: google.maps.places.AutocompleteSessionToken | undefined,
  tokenUpdater: Dispatch<
    SetStateAction<google.maps.places.AutocompleteSessionToken | undefined>
  >,
  placeDetailsRequest?: google.maps.places.PlaceDetailsRequest | undefined,
  addressComponentMapping?: (
    placeDetails: google.maps.places.PlaceResult | null,
  ) => IFullAddressInputValues,
): ((v: ISimpleAddressInputValue) => void) => {
  const placeService = useMemo(() => {
    if (window?.google?.maps?.places) {
      return new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    return undefined;
  }, []);

  const callBack = useCallback(async (v: ISimpleAddressInputValue) => {
    if (v?.place_id) {
      try {
        const details = await getPlaceDetailsFromPlaceId(
          v.place_id,
          token,
          placeService,
          placeDetailsRequest,
          addressComponentMapping,
        );
        if (window?.google?.maps?.places) {
          tokenUpdater(
            new window.google.maps.places.AutocompleteSessionToken(),
          );
        }
        if (details) {
          stateUpdater(details);
        }
      } catch (error) {
        console.error(error);
        stateUpdater(v);
      }
    } else {
      stateUpdater({ value: v?.value, label: v?.label });
    }
  }, []);
  return callBack;
};
