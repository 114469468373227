import {
  CARRIERS, DIRECTIONS, DIRECTIONS_FOR_PROVIDERS, DIRECTIONS_WITHOUT_OTHER_LOCATION,
} from './constants';
import { DirectionOption } from './types.d';

// eslint-disable-next-line import/prefer-default-export
export const getCarrierFromTrackingNumber = (trackingNumber: string): {
  carrier: string,
  trackingLink: string,
} => {
  const { carrier, trackingLink } = CARRIERS.reduce((acc,
    { label, regs, url }) => (regs && regs.find((reg) => trackingNumber.match(reg))
    ? { carrier: label, trackingLink: (url || '').replace('{trackingNumber}', trackingNumber) }
    : acc),
  { carrier: 'other', trackingLink: '' });

  return {
    carrier, trackingLink,
  };
};

export const getDirections = (
  isProvider: boolean,
  disableOtherLocation: boolean,
): Array<DirectionOption> => {
  if (isProvider) return DIRECTIONS_FOR_PROVIDERS;
  if (disableOtherLocation) return DIRECTIONS_WITHOUT_OTHER_LOCATION;
  return DIRECTIONS;
};
