export function truncate(str, threshold) {
  return `${str.substring(0, threshold)}...`;
}

export function shouldTruncate(str, threshold) {
  return str.length > threshold;
}

/*
  input can be number, string or array. For number, we're converting it to
  string while feeding to the function.
*/
export default function applyTruncation(value, truncateText, threshold = 20) {
  if (!truncateText) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((val) => (shouldTruncate(val, threshold)
      ? truncate(val, threshold)
      : val
    ));
  }

  return shouldTruncate(value, threshold) ? truncate(value, threshold) : value;
}
