import React, { memo } from 'react';
import { Button } from '@fieldnation/platform-components';
import classes from './styles.scss';

interface ActionButtonProps {
  onSave(): void;
  isPostingData: boolean;
}

const ActionButton = ({
  onSave,
  isPostingData,
}: ActionButtonProps): JSX.Element => (
  <Button
    className={classes.ActionButton}
    label="Save"
    type="primary"
    onClick={onSave}
    disabled={isPostingData}
    size="lg"
  />
);

export default memo(ActionButton);
