/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
import React, { useState, useCallback, useRef } from 'react';
import escapeHtml from 'escape-html';
import { createEditor, BaseEditor, Descendant, Text } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import isEqual from 'lodash/isEqual';
import { Button, ButtonGroup } from '@fieldnation/platform-components';
import EditorToolbar, { Element, Leaf } from './EditorToolbar';

import css from './SlateEditor.scss';

type CustomElement = {
  type: string;
  children: CustomText[] | CustomElement[];
  [key: string]: unknown;
};
type CustomText = { text: string; [key: string]: unknown };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface Props {
  initialValue: Descendant[];
  children?: React.ReactNode;
  onSave: (val: string) => void;
  onCancel: () => void;
}

const SlateEditor = ({
  initialValue,
  children,
  onSave,
  onCancel,
}: Props): JSX.Element => {
  const [editor] = useState(() => withReact(createEditor()));
  const textObj = useRef<Descendant[]>(initialValue);
  const [disabled, setDisabled] = useState(true);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const serializeToHTML = (textObjArr: Descendant[]): string => {
    const serialize = (node: Descendant) => {
      if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        if (node.bold) {
          string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
          string = `<em>${string}</em>`;
        }
        if (node.underline) {
          string = `<u>${string}</u>`;
        }
        return string;
      }

      // eslint-disable-next-line no-shadow
      const children = node.children.map((n) => serialize(n)).join('');

      switch (node.type) {
        case 'paragraph':
          return `<p>${children}</p>`;
        case 'bulleted-list':
          return `<ul">${children}</ul>`;
        case 'list-item':
          return `<li>${children}</li>`;
        default:
          return children;
      }
    };
    return serialize({
      children: textObjArr,
    } as Descendant);
  };

  return (
    <Slate
      editor={editor}
      value={initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some(
          (op) => op.type !== 'set_selection',
        );
        if (isAstChange) {
          textObj.current = value;
          if (isEqual(initialValue, textObj.current) && !disabled) {
            setDisabled(true);
          }
          if (!isEqual(initialValue, textObj.current) && disabled) {
            setDisabled(false);
          }
        }
      }}
    >
      <div className={css['editor-wrapper']}>
        <EditorToolbar />
        <div className={css['slate-editor']}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter a note…"
            spellCheck
            autoFocus
          />
        </div>
      </div>
      {children}
      <ButtonGroup textAlign="right">
        <Button
          label="Cancel"
          type="secondary"
          onClick={() => onCancel && onCancel()}
        />
        <Button
          label="Save note"
          type="primary"
          onClick={() => onSave && onSave(serializeToHTML(textObj.current))}
          disabled={disabled}
        />
      </ButtonGroup>
    </Slate>
  );
};

export default SlateEditor;
