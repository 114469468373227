/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  v2,
  Grid,
  GridColumn,
} from '@fieldnation/platform-components';
import {
  capitalizeFirstChar,
} from './licenses-certifications-utils';
import css from './LicensesAndCertifications.scss';
import Padded from './Padded';

import { UserQualifications } from '../types.d';

const {
  TextInput, DatePicker,
} = v2;

interface LCForm {
  values: UserQualifications;
  validation: UserQualifications;
  onChange: (x: any) => void;
  onFile: (x: any, y: any) => void;
}

const LicensesAndCertificationsForm: React.SFC<LCForm> = ({
  values,
  validation,
  onChange,
  onFile,
}: LCForm) => {
  const {
    id,
    name = '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    issue_date = { utc: '' },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expire_date = { utc: '' },
    category = '',
    number = '',
    geos = [],
  } = values;
  // handle the edit
  let region:string = values?.region || '';
  if (!region) {
    // note: stored the region data to QS in the geo table of state column
    // we should trust 1st index of the geos array since geos is an array
    region = geos[0]?.state || '';
  }
  if (!id) {
    return null;
  }
  const labelType = category === 1 ? 'Certification' : 'License';
  return (
    <div className={css.lcForm} key={`${id}${category}`}>
      <Padded>
        <div className="o-hdg o-hdg--h2">{name}</div>
        <small className="text-muted">
          These can be viewed by buyers. Hide sensitive information such as
          social and tax id.
        </small>
      </Padded>
      <Padded>
        <TextInput
          label={`${capitalizeFirstChar(labelType)} number`}
          onChange={(e) => onChange({ id, number: e?.target?.value || '' })}
          value={number}
        />
        {validation?.number && (
          <span className="text-danger">{validation?.number}</span>
          )}
      </Padded>
      <Grid>
        <GridColumn xs="6">
          <DatePicker
            label="Date issued"
            onChange={(val: string) => onChange({ id, issue_date: { utc: val } })}
            value={issue_date.utc}
          />
          {validation?.issue_date && (
            <span className="text-danger">{validation?.issue_date}</span>
            )}
        </GridColumn>
        <GridColumn xs="6">
          <DatePicker
            label="Expiration date"
            onChange={(val: string) => onChange({ id, expire_date: { utc: val } })}
            value={expire_date.utc}
          />
          {validation?.expire_date && (
            <span className="text-danger">{validation?.expire_date}</span>
            )}
        </GridColumn>
      </Grid>
      <Padded>
        {labelType === 'License'
            && (
            <TextInput
              optional
              label="Is the license limited to a specific geographic area? Explain"
              onChange={(e) => onChange({ id, region: e?.target?.value || '' })}
              value={region}
            />
            )}
      </Padded>
      <Padded>
        <div className={css.fileUpload}>
          Upload the scan or PDF for verification
        </div>
        <div>
          <input
            type="file"
            name={`lc_${id}`}
            onChange={(e) => onFile(e, id || 0)}
          />
        </div>
        {validation?.file && (
          <span className="text-danger">{validation.file}</span>
          )}
      </Padded>
    </div>
  );
};

export default LicensesAndCertificationsForm;
