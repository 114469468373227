import React from 'react';
import { RecoilRoot } from 'recoil';
import SectionCard from '../SectionCard';
import CompanyOverviewConfig from './config';

const CompanyOverview: React.FC = (): JSX.Element => (
  <RecoilRoot>
    <SectionCard sectionCardConfig={CompanyOverviewConfig} />
  </RecoilRoot>
);

export default CompanyOverview;
