/* eslint-disable @typescript-eslint/no-explicit-any */
import requestWrapper from '../requestWrapper';
import { ScreeningInputValues } from './types.d';

export async function getUserById(
  userId: number,
  options: any = {},
): Promise<any> {
  return requestWrapper(`/v2/users/${userId}`, {
    ...options,
    method: 'GET',
  });
}

export async function updateUserInfo(
  data: ScreeningInputValues,
  options: any = {},
): Promise<any> {
  const sendData = {
    email: data.email,
    phone: data.phone,
  };

  return requestWrapper(`/v2/users/${data?.id}`, {
    ...options,
    method: 'POST',
    body: sendData,
  });
}

export async function getScreeningFeeByType(
  state: string,
  country: string,
  options: any = {},
): Promise<any> {
  return requestWrapper(`/micro/background-checks/packages?state=${state}&country=${country}`, {
    ...options,
    method: 'GET',
  });
}

export async function fetchPackage(
  packageId: string,
): Promise<any> {
  return requestWrapper(`/micro/background-checks/packages/${packageId}`);
}

export async function fetchCandidate(
  userId: number,
): Promise<any> {
  return requestWrapper(`/micro/background-checks/candidates/${userId}`);
}

export async function createCandidate(
  userInfo: ScreeningInputValues,
  candidate: any,
): Promise<any | null> {
  try {
    const {
      id,
      first_name,
      last_name,
      email,
      phone,
      postal_code,
      city,
      state,
      country,
    } = userInfo;

    let data = {
      id,
      first_name,
      last_name,
      email,
      phone,
      postal_code,
      city,
      state,
      country,
      ...candidate,
      billing_address: {
        address1: candidate.billing_address1,
        address2: candidate.billing_address2,
        city: candidate.billing_city,
        state: candidate.billing_state,
        zip: candidate.billing_zip,
        country: candidate.billing_country,
      },
    };
    if (candidate.driver_license_number) {
      data = {
        ...data,
        driver_license: {
          number: candidate.driver_license_number,
          state,
        },
      };
    }
    return requestWrapper('/micro/background-checks/candidates', { method: 'POST', body: data });
  } catch (e) {
    return null;
  }
}

export async function updateCandidate(
  user: ScreeningInputValues,
  candidate: any,
): Promise<any> {
  const data = { ...candidate, ...{ city: user.city, state: user.state, country: user.country } };
  return requestWrapper(`/micro/background-checks/candidates/${candidate.id}`, { method: 'PUT', body: data });
}

export async function createReport(
  candidate: any,
  formData: ScreeningInputValues,
): Promise<any> {
  const payload = {
    ...candidate,
    payment: {
      billing_address: {
        address1: formData.billing_address1,
        address2: formData.billing_address2,
        city: formData.billing_city,
        state: formData.billing_state,
        zip: formData.billing_zip,
        country: formData.billing_country,
      },
      credit_card: {
        card_number: formData.card_number.replaceAll(' ', ''),
        exp_date: formData.card_exp.replaceAll(' ', ''),
        cardholder_name: formData.cardholder_name,
        cvv: formData.card_cvv,
        type: formData.card_type,
      },
    },
  };
  return requestWrapper(`/micro/background-checks/candidates/${candidate.candidate_id}/reports`, { method: 'POST', body: payload });
}

export async function submitScreeningOrder(
  user: ScreeningInputValues,
  packageId: string,
  formData: ScreeningInputValues,
): Promise<any | null> {
  if (!user || !user?.id || !packageId) {
    return null;
  }
  const pkg = await fetchPackage(packageId);
  let candidate = await fetchCandidate(user?.id);
  if (candidate && candidate?.id) {
    await updateCandidate(user, candidate);
  } else {
    candidate = await createCandidate(
      user,
      {
        ...formData,
        vendor_registrations: [{ vendor: { name: pkg?.vendor?.name } }],
      },
    );
  }

  if (candidate?.id) {
    return createReport({ candidate_id: candidate.id, package: pkg }, formData);
  }

  return null;
}
