/* eslint-disable object-curly-newline */
/* eslint-disable dot-notation */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Heading, TitleBar } from '@fieldnation/platform-components';
import { PageHeaderProps } from './types.d';
import css from './PageHeader.scss';

const PageHeader = (props: PageHeaderProps): JSX.Element => {
  const { headline, isFlash, actions, headlineStyle } = props;
  const heading: any = (
    <Heading styleLevel={headlineStyle} tag={headlineStyle}>
      {headline}
    </Heading>
  );
  return (
    <div
      className={classNames(css[`headline-${isFlash ? 'flash' : 'padded'}`])}
    >
      <TitleBar title={heading} actions={actions} />
    </div>
  );
};

PageHeader.propTypes = {
  headline: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
  headlineStyle: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  isFlash: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
      onClick: PropTypes.func,
      label: PropTypes.string,
      type: PropTypes.oneOf(['primary', 'secondary', 'link']),
      disabled: PropTypes.bool,
      menuType: PropTypes.oneOf(['button', 'list']),
      menuItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          icon: PropTypes.string,
          disabled: PropTypes.bool,
          onClick: PropTypes.func,
          href: PropTypes.string,
          target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
        }),
      ),
    }),
  ),
};

PageHeader.defaultProps = {
  headlineStyle: 'h4',
  isFlash: true,
  actions: [],
};

export default PageHeader;
