import { BodyText, Icon } from '@fieldnation/platform-components';
import React, { useMemo, memo } from 'react';
import { VerificationStatusEnum } from './types.d';
import classes from './styles.scss';

const verificationText = {
  [VerificationStatusEnum.NEW]: 'UBI Number Pending Verification',
  [VerificationStatusEnum.VERIFIED]: (
    <span className={classes.VerifiedUbi}>
      <Icon name="completeSolid" size={14} />
      <span className={classes.VerificationText}>Verified UBI Number</span>
    </span>
  ),
  [VerificationStatusEnum.INVALID]: (
    <span className={classes.InvalidUbi}>
      <Icon name="warningSolid" size={14} />
      <span className={classes.VerificationText}>Invalid UBI Number</span>
    </span>
  ),
  none: '',
};

interface VerificationStatusProps {
  verificationStatus: VerificationStatusEnum;
}

const VerificationStatus = ({
  verificationStatus,
}: VerificationStatusProps): JSX.Element => {
  const statusBody = useMemo(() => verificationText[verificationStatus], [verificationStatus]);

  return (
    <BodyText styleLevel="xs" tag="div">
      {statusBody}
    </BodyText>
  );
};

export default memo(VerificationStatus);
