import * as React from 'react';

interface WithChildProps {
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const Padded: React.SFC<WithChildProps> = ({
  children,
  position,
  size,
}: WithChildProps) => (
  <div className={`u-margin-${position}--${size}`}>{children}</div>
);

Padded.defaultProps = {
  position: 'bottom',
  size: 'md',
};

export default Padded;
