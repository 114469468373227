import React from 'react';
import { v2, Icon } from '@fieldnation/platform-components';
import styles from './TrackingField.scss';
import { getCarrierFromTrackingNumber } from '../utils';
import FormError from './FormError';

const { TextInput } = v2;

interface CarrierInfo {
  name: string;
  tracking: string;
  tracking_link?: string;
}

interface TrackingFieldProps {
  value: CarrierInfo;
  onChange: (value: CarrierInfo) => void;
  disabled: boolean;
  errors: {
    name: boolean;
    tracking: boolean;
  }
}

const TrackingField: React.FC<TrackingFieldProps> = ({
  value,
  onChange,
  disabled = false,
  errors,
}: TrackingFieldProps): JSX.Element => {
  const handleTrackingChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const trackingNumber = e.target.value;
    const { carrier, trackingLink } = getCarrierFromTrackingNumber(trackingNumber);
    onChange({
      ...value,
      name: carrier,
      tracking: trackingNumber,
      tracking_link: trackingLink,
    });
  };

  const handleCarrierNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...value,
      name: e.target.value,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.trackingFields}>
        <div className={styles.trackingInput}>
          <TextInput
            label="Tracking number"
            value={value.tracking || ''}
            onChange={handleTrackingChange}
            disabled={disabled}
          />
          {errors?.tracking === true ? <FormError message="Tracking number is required" /> : null}
        </div>
        {value.tracking && !value.tracking_link && (
          <div className={styles.carrierInput}>
            <TextInput
              label="Carrier name"
              value={value.name === 'other' ? '' : value.name}
              onChange={handleCarrierNameChange}
              disabled={disabled}
            />
            {errors?.name === true ? <FormError message="Carrier name is required" /> : null}
          </div>
        )}
      </div>
      {value.tracking && value.name !== 'other' && value?.tracking_link && (
        <div className={styles.carrierInfo}>
          Carrier:
          {' '}
          <span className={styles.carrierName}>{value.name}</span>
          {' '}
          |
          {' '}
          <a
            href={value.tracking_link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.trackLink}
          >
            Track shipment
            <Icon name="openInNew" size={16} />
          </a>
        </div>
      )}
    </div>
  );
};

export default TrackingField;
