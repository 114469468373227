import React from 'react';
import {
  Icon,
  IconAside,
  IconAsideIcon,
  BodyText,
  Tooltip,
  TooltipComponent,
} from '@fieldnation/platform-components';
import css from './styles.scss';

const AddressVerifiedChip: React.FC = () => (
  <Tooltip position="left" showOnEvent="hover">
    <div className={css['chip--container']}>
      <IconAside iconPosition="left">
        <IconAsideIcon verticalAlign="middle">
          <span className={css['icon--style']}>
            <Icon name="completeHollow" size={18} block />
          </span>
        </IconAsideIcon>
        <BodyText tag="span" styleLevel="md">
          <span className={css['chip--label']}>Verified</span>
        </BodyText>
      </IconAside>
    </div>
    <TooltipComponent color="white">
      This address has been verified by our tax compliance partner.
    </TooltipComponent>
  </Tooltip>
);

export default AddressVerifiedChip;
