import * as AmplitudeClient from 'amplitude-js';

export const WORK_ORDER_CREATE = 'Work Order Create';
export const WORK_ORDER_DETAILS = 'Work Order Details';
export const TEMPLATE_DETAILS = 'Template Details';
export const SCREENING_SUCCESS = 'Screening Order Success';
export const SCREENING_FAILED = 'Screening Order Failed';
export const SCREENING_MODAL_CLOSED = 'Abandoned Order';

interface Payload {
  category: string;
  ampProps?: Record<string, unknown>;
  link?: string;
  linkEvent?: React.ChangeEvent<HTMLButtonElement | HTMLAnchorElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback?: Function;
}

declare global {
  interface Window {
    _AMPLITUDE_KEY: string;
    _amplitudeClient: AmplitudeClient.AmplitudeClient;
  }
}

export function getAmplitudeClient(): AmplitudeClient.AmplitudeClient | null {
  // eslint-disable-next-line no-underscore-dangle
  if (window._amplitudeClient) {
    // eslint-disable-next-line no-underscore-dangle
    return window._amplitudeClient;
  }

  const amplitudeClient = AmplitudeClient.getInstance();
  const user = window.context;
  // eslint-disable-next-line no-underscore-dangle
  const amplitudeKey = window._AMPLITUDE_KEY || '';
  if (!user || !user?.id || !amplitudeKey) {
    return null;
  }
  amplitudeClient.init(amplitudeKey, user.id.toString());
  amplitudeClient.setUserProperties({
    'Group ID': user?.group?.id || 0,
    'Company ID': user?.company?.id || 0,
    Email: user?.email || '',
    Name: `${user?.first_name} ${user?.first_name}`,
  });

  return amplitudeClient;
}

export default function trackAmplitudeEvent(payload: Payload): void {
  const {
    category, ampProps = {}, link, linkEvent, callback,
  } = payload;

  if (!category) {
    return;
  }

  const client = getAmplitudeClient();
  if (!client) {
    return;
  }

  if (link && linkEvent) {
    linkEvent.preventDefault();
  }

  client.logEvent(category, ampProps, () => {
    if (callback && typeof callback === 'function') {
      callback();
    }
    if (link) {
      document.location.href = link;
    }
  });
}
