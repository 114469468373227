import { Loader } from '@fieldnation/platform-components';
import React, { useState } from 'react';
import css from './styles.scss';
import { IProps } from './types.d';

const ImageContent: React.FC<IProps> = ({
  href, alt, version,
}: IProps): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const versioning = `?v${(version || 0) + 1}`;
  const isMissing = href === '/images/missing-profile.png' ? 'invalid' : 'valid';
  return (
    <div>
      {href && (
      <Loader isLoading={!loaded} size="md">
        <img
          className={css[`image--content--${isMissing}`]}
          src={href + versioning}
          alt={alt || 'Image could not be loaded'}
          onLoad={() => setLoaded(true)}
        />
      </Loader>
      )}
    </div>
  );
};

export default ImageContent;
