import * as React from 'react';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  v2,
  Switch,
  Stars,
  ProgressBar,
} from '@fieldnation/platform-components';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import Padded from './Padded';
import jobHistoryQuery, { jobFilter } from '../queries/job-history.query';
import trackAmplitudeEvent, {
  PROFILE_FILTERED_RATINGS,
} from '../amplitude';
import {
  daysWOsOptionsRating, myCompanyOption, dayOptions, Group,
} from './utils';
import css from './JobHistoryModal.scss';
import { JobHistoryFilters as IJobHistoryFilters } from '../types.d';

const { Select } = v2;

const JobHistoryFilters = (
  { isFromPerformance = false }: { isFromPerformance: boolean },
): JSX.Element | null => {
  const jobHistory = useRecoilValue(jobHistoryQuery);
  const setFilter = useSetRecoilState(jobFilter);

  if (!jobHistory) {
    return null;
  }

  const {
    results,
    total,
    typeOfWorkData,
    filters,
    companyList,
  } = jobHistory;

  let defaultDF = 'all';
  if (filters?.dayHistory) {
    defaultDF = `${filters?.dayHistory}days`;
  }
  if (filters?.woHistory) {
    defaultDF = `${filters?.woHistory}workorders`;
  }
  const [dataFilter, setDataFilter] = useState<string>(defaultDF);
  const [companyId, setCompanyId] = useState<string>(
    `${filters?.withCompanyId}` || '0',
  );

  const ratingHash = (filters?.ratingHash || 0).toString();

  let typeOfWorkOptions = [
    { label: 'All types of work', value: 0 },
  ];
  if (typeOfWorkData && typeOfWorkData?.length) {
    typeOfWorkOptions = [...typeOfWorkData];
  }

  const stars = useMemo(() => {
    const x = (results || []).reduce(
      (acc, i) => {
        const y = Math.ceil(i.rating || 0);
        const z = acc.find(({ rating: s }) => s === y);
        if (z) {
          z.value += 1;
        }
        return acc;
      },
      [1, 2, 3, 4, 5].map((i) => ({ rating: i, value: 0 })),
    );

    const f = (q: number) => x.find(({ rating: s }) => s === q)?.value || 0;

    const valueFor = (value: number) => {
      switch (value) {
        case 1:
          return f(1);
        case 2:
          return f(2);
        case 3:
          return f(3);
        case 4:
          return f(4);
        default:
          return f(5);
      }
    };

    return {
      valueFor,
      progressValue: (value: number) => {
        const p = valueFor(value);

        if (!total) {
          return 0;
        }

        return Math.round((p / total) * 10);
      },
    };
  }, [ratingHash]);

  const applyFilter = async (value: IJobHistoryFilters) => {
    // make toggle the rating filter
    let starRatingSelected = true;
    if (value?.withStarRating
      && filters?.withStarRating
      && value?.withStarRating === filters?.withStarRating
    ) {
      // eslint-disable-next-line no-param-reassign
      value = { withStarRating: 0 };
      starRatingSelected = false;
    }

    const ratingValue = value?.withStarRating || 0;
    const appliedRatingFilter = filters?.withStarRating || 0;
    const filterRatingHash = starRatingSelected && (ratingValue || appliedRatingFilter)
      ? filters?.ratingHash : Math.floor(Date.now());

    const f = {
      ...filters,
      ...value,
      ratingHash: filterRatingHash,
      version: (filters?.version || 0) + 1,
    };
    await setFilter(f);
  };

  return (
    <div>
      <Padded>
        <Select
          label=""
          value={`${dataFilter || 'all'}`}
          options={isFromPerformance ? daysWOsOptionsRating : dayOptions}
          onChange={async ({ value: v }) => {
            const value = String(v);
            setDataFilter(value);

            let dayHistory = '';
            let woHistory = '';
            if (value.includes('days')) {
              dayHistory = value.replace(/\D/g, '');
            }
            if (value.includes('workorders')) {
              woHistory = value.replace(/\D/g, '');
            }
            await applyFilter({
              dayHistory: Number(dayHistory) || 0,
              woHistory: Number(woHistory) || 0,
            });
            trackAmplitudeEvent({
              category: PROFILE_FILTERED_RATINGS,
              ampProps: {
                Days: Number(value) === 0 ? 'All time' : Number(value),
                'Type of Work': filters?.typeOfWork,
                Comments: Boolean(filters?.withComments),
                Stars: filters?.withStarRating,
              },
            });
          }}
        />
      </Padded>

      <Padded>
        <Select
          label=""
          value={`${filters?.typeOfWork || '0'}`}
          options={typeOfWorkOptions}
          onChange={async ({ value }) => {
            await applyFilter({ typeOfWork: Number(value) });
            trackAmplitudeEvent({
              category: PROFILE_FILTERED_RATINGS,
              ampProps: {
                Days: filters?.dayHistory,
                'Type of Work': Number(value),
                Comments: Boolean(filters?.withComments),
                Stars: filters?.withStarRating,
              },
            });
          }}
        />
      </Padded>

      <Padded>
        {(isFromPerformance && filters?.contextGroupId === Group?.Staff)
          ? (
            <Select
              label=""
              options={companyList}
              value={companyId}
              onChange={async ({ value: v }) => {
                const value = String(v);
                setCompanyId(value);
                await applyFilter({
                  withCompanyId: Number(value),
                  withMyCompany: Number(value) > 0,
                });
              }}
            />
          ) : (
            <Select
              label=""
              value={filters?.withMyCompany ? '1' : '0'}
              options={myCompanyOption}
              onChange={async ({ value }) => {
                await applyFilter({ withMyCompany: Boolean(Number(value)) });
              }}
            />
          )}
      </Padded>

      <Padded>
        <Switch
          sideLabel="Show only ratings with comments"
          checked={Boolean(filters?.withComments)}
          onChange={async (e) => {
            await applyFilter({ withComments: e?.target?.checked });
            trackAmplitudeEvent({
              category: PROFILE_FILTERED_RATINGS,
              ampProps: {
                Days: filters?.dayHistory,
                'Type of Work': filters?.typeOfWork,
                Comments: Boolean(filters?.withComments),
                Stars: filters?.withStarRating,
              },
            });
          }}
        />
      </Padded>

      {[5, 4, 3, 2, 1].map((num) => (
        <Padded key={num} size="sm">
          {/* eslint-disable max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className={classNames(css['rating-filter'], css[`${filters?.withStarRating === num
              ? 'rating-filter-active' : ''}`])}
            onClick={async () => {
              await applyFilter({ withStarRating: num });
              trackAmplitudeEvent({
                category: PROFILE_FILTERED_RATINGS,
                ampProps: {
                  Days: filters?.dayHistory,
                  'Type of Work': filters?.typeOfWork,
                  Comments: Boolean(filters?.withComments),
                  Stars: num,
                },
              });
            }}
          >
            <Stars
              rating={num}
              color="yellow"
            />
            <ProgressBar
              fillWithGray
              currentProgress={
                stars.progressValue(num) as
                  | 0
                  | 1
                  | 2
                  | 3
                  | 4
                  | 5
                  | 6
                  | 7
                  | 8
                  | 9
                  | 10
              }
            />
            <div className="count">
              <button
                className="u-resetButton u-textLink"
                type="button"
              >
                {stars.valueFor(num)}
              </button>
            </div>
          </div>
          {/* eslint-enable max-len */}
        </Padded>
      ))}
    </div>
  );
};

export default JobHistoryFilters;
