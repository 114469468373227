import * as React from 'react';
import { Icon } from '@fieldnation/platform-components';
import { useRecoilValue } from 'recoil';
import providerCommitmentQuery from '../queries/provider-commitment.query';

import css from './Rating.scss';

const TimelinessChip = (): JSX.Element => {
  const providerCommitment = useRecoilValue(providerCommitmentQuery);
  const { denominatorCount = 0 } = providerCommitment?.timelinessStats || {};

  const timelinessValue = providerCommitment?.timelinessPercent
    ? Number(providerCommitment.timelinessPercent.toFixed(1))
    : 0;

  const showChip = timelinessValue >= 80 && denominatorCount >= 5;

  return showChip ? (
    <div className={css['timeliness-chip']}>
      <Icon name="check" size={16} />
      Timely
    </div>
  ) : (
    <></>
  );
};

export default TimelinessChip;
