import * as React from 'react';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { Section, v2, Icon } from '@fieldnation/platform-components';
import WrapSection from './WrapSection';
import Padded from '../Components/Padded';
import AssignmentCommitment from '../Components/AssignmentCommitment';
import Timeliness from '../Components/Timeliness';
import ReliabilityChip from '../Components/ReliabilityChip';
import TimelinessChip from '../Components/TimelinessChip';
import ReliabilityFallback from '../Components/ReliabilityFallback';
import { getSecondaryFilterOptions } from '../Components/utils';

import performance, {
  performanceFilter,
  showProviderReliabilityTile,
} from '../queries/performance.query';
import providerCommitmentQuery from '../queries/provider-commitment.query';
import contextQuery from '../queries/context.query';
import userQuery, { userIdQuery } from '../queries/user.query';
import ReliabilityCache from '../Components/ReliabilityCache';

import { ProviderCommitmentResponse } from '../types.d';

import css from './Rating.scss';

const { Select } = v2;

interface Props {
  assignmentCommitment: ProviderCommitmentResponse | undefined;
  collapse?: boolean;
}

const Reliability = ({
  assignmentCommitment = {},
  collapse,
}: Props): JSX.Element | null => {
  // const assignmentCommitment = sidebarV2Data?.assignmentCommitment || {};
  const hasAssignmentCommitment = !!Object.keys(assignmentCommitment)?.length;
  const providerCommitment = hasAssignmentCommitment
    ? assignmentCommitment : useRecoilValue(providerCommitmentQuery);
  const showProviderReliability = useRecoilValue(showProviderReliabilityTile);
  const data = useRecoilValue(performance);
  const context = useRecoilValue(contextQuery);

  const filters = data?.filters;
  const [filter, setFilter] = useRecoilState(performanceFilter);
  const [days, setDays] = useState<string>('0');
  const [dataFilter, setDataFilter] = useState<string>('0');
  const [invalidateCache, setInvalidateCache] = useState<boolean>(false);
  const isStaff = [3, 9].includes(context?.group?.id || 0);
  const userId = useRecoilValue(userIdQuery);
  const userData = useRecoilValue(userQuery);
  const { denominatorCount = 0 } = providerCommitment?.timelinessStats || {};

  const assignmentCompletionValue = providerCommitment?.showCommitmentPercent
    ? Number(providerCommitment?.commitmentPercent?.toFixed(1))
    : 0;
  const showReliabilityChip = assignmentCompletionValue >= 90;
  const timelinessValue = providerCommitment?.showTimelinessPercent
    ? Number(providerCommitment?.timelinessPercent?.toFixed(1))
    : 0;
  let shouldShowTimeliness = denominatorCount >= 5;

  if (isStaff) {
    shouldShowTimeliness = true;
  }

  // check if user is SC admin
  if (context?.company?.role?.marketplace_manager === true) {
    // check if profile user is in same SC
    if (userData?.company?.id === context?.company?.id) {
      shouldShowTimeliness = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const applyFilter = async (obj: any) => {
    let value = obj;
    if (obj?.dataFilter) {
      value = {
        dayHistory: obj?.dataFilter.includes('days') ? obj?.dataFilter.replace(/\D/g, '') : Number(obj?.dataFilter),
        woHistory: 0,
      };
    }

    setFilter({
      ...filters,
      ...value,
      version: (filters?.version || 0) + 1,
    });
  };

  React.useEffect(() => {
    if (Number(filter?.dayHistory) !== Number(days)) {
      setDataFilter(`${filter.dayHistory === 0 ? filter.dayHistory : `${filter.dayHistory}days`}`);
      setDays(`${filter.dayHistory}` || '0');
    }
  }, [filter.dayHistory]);

  const chip = (
    <div>
      {showReliabilityChip && (
        <span className={css['reliability-chip-drawer']}>
          <Icon name="check" size={16} />
        </span>
      )}
      {shouldShowTimeliness && timelinessValue >= 80 && (
        <span className={classNames(css['reliability-chip-drawer'], 'u-margin-left--sm')}>
          <Icon name="accessTime" size={16} />
        </span>
      )}
    </div>
  );

  return (
    <WrapSection
      label="Reliability"
      collapse={collapse}
      customHeaderContent={chip}
      fullWidth
    >
      <div className={classNames('u-margin-left--md', 'u-margin-right--md')}>
        <Section>
          {showProviderReliability && (
            <React.Suspense fallback={<ReliabilityFallback />}>
              <div className="u-padding-bottom--md">
                <Padded size="lg">
                  <Select
                    label=""
                    options={getSecondaryFilterOptions()}
                    value={dataFilter}
                    onChange={async ({ value: v }) => {
                      const value = String(v);
                      setDataFilter(value);
                      setDays(value.replace('days', ''));
                      setInvalidateCache(true);
                      await applyFilter({ dataFilter: value });
                    }}
                  />
                </Padded>
                {(hasAssignmentCommitment && !invalidateCache)
                  ? (
                    <ReliabilityCache
                      providerCommitment={providerCommitment || {}}
                      userId={userId || 0}
                    />
                  ) : (
                    <div>
                      <ReliabilityChip />
                      <AssignmentCommitment />
                      {(context?.id === userId || (shouldShowTimeliness && timelinessValue >= 80))
                        && <TimelinessChip />}
                      {(context?.id === userId || shouldShowTimeliness)
                        && <Timeliness useAlternateNaming />}
                    </div>
                  )}
              </div>
            </React.Suspense>
          )}
        </Section>
      </div>
    </WrapSection>
  );
};

Reliability.defaultProps = {
  collapse: false,
};

export default Reliability;
