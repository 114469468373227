import { selector } from 'recoil';
import { getCompanyList } from '../api';
import { LabelValue } from '../types.d';

const companyListQuery = selector<LabelValue[] | null>({
  key: 'companyList',
  get: async () => {
    const companyList = await getCompanyList();

    return companyList || null;
  },
});

export default companyListQuery;
