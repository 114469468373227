import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  v2,
  Loader,
  Grid,
  GridColumn,
  Link,
  Icon,
  IconAside,
  IconAsideIcon,
  FileInput,
  BodyText,
} from '@fieldnation/platform-components';
import { FooterAction } from '@fieldnation/platform-components/src/v2/Modal/types.d';
import {
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { Options } from '@fieldnation/platform-components/src/v2/Select/types';
import css from './Styles.scss';
import {
  CredentialInputData,
  Credential,
  LicenseCertidicationProps,
  CredentialCustomInput,
  VerificationOrg,
  UserCredentialsDetails,
  VefrificationOptions,
} from './types';
import {
  credentialDefaultValue,
  lcDefaultInputs,
  lcSelectDataQuery,
  LicenseCertificationAtom,
  orgDefaultValue,
} from './Store/query';
import ClearLogo from './Svg/ClearLogo';
import { validateLCInputs } from './validation';
import {
  getUserCredentialDetailsById,
  getCredentialDetailsById,
  getVerificationByOrgId,
  saveCredentials,
  updateCredentials,
} from './api';
import { CustomSelectOptions } from './CustomSelectOptions';
import { success, error } from '../legacy/flash-messenger';
import { EVENTS, useAmplitudeTracker } from '../Amplitude/useAmplitudeTracker';
import getUserCredentialStatus from './Helpers';
import { userDataQuery } from '../Screening/Store/user.query';
import screeningAtom from '../Screening/Store/screening.atom';

const {
  Modal,
  DatePicker,
  Select,
  TextInput,
  Checkbox,
} = v2;
const LicenseCertification = ({
  id,
  userId,
  source,
  isEditMode,
  onSubmitCallback,
  onClose,
  customContent,
  allowEditOrg = false,
  showSuccessAlert = true,
  customFooterActions,
}: LicenseCertidicationProps): JSX.Element => {
  const [isOpen] = useState(true);
  const [isEditModeActive, setIsEditModeActive] = useState<boolean>(id !== undefined && isEditMode);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadAnother, setUploadAnother] = useState<string[]>([]);
  const [lastAddedName, setLastAddedName] = useState<string>('');
  const setLCAtom = useSetRecoilState(LicenseCertificationAtom);
  const lcSelectOptions = useRecoilValue(lcSelectDataQuery);
  const userInfo = useRecoilValue(userDataQuery);
  const setScreeningData = useSetRecoilState(screeningAtom);
  const [selectedCredential, setSelectedCredential] = useState<Credential>(credentialDefaultValue);
  const [lcInputs, setLCInputs] = useState<CredentialInputData>(lcDefaultInputs);
  const [selectedOrganization, setSelectedOrganization] = useState<VerificationOrg>(orgDefaultValue);
  const [customInputs, setCustomInputs] = useState<CredentialCustomInput[]>([]);
  const [lcFile, setLcFile] = useState<any>([]);
  const numberFieldLabelText = `${selectedCredential?.type} ID Number`;
  const [lcValidation, setLCValidation] = useState<Partial<CredentialInputData>>({});
  const [isSelectedModeActive] = useState<boolean>(
    id !== undefined && isEditMode === false && selectedCredential?.id <= 0,
  );
  const headingText = isEditModeActive ? `Edit ${selectedCredential?.name} ` :
    isSelectedModeActive ? 'Add Credential' : 'Add Credential';
  const trackOnAmplitude = useAmplitudeTracker();
  const validateInputs = () => validateLCInputs(
    lcInputs,
    setLCValidation,
    customInputs,
        selectedCredential?.verifiedOn !== null,
        selectedCredential?.verifiedByName,
        getDynamicInputRecord(),
  );

  const getDynamicInputRecord = () => selectedOrganization?.fields?.map((field) => field?.name);
  const handleOnClose = () => {
    onClose();
  };

  const handleAfterUploadAnother = () => {
    setLCInputs(lcDefaultInputs);
    setSelectedCredential(credentialDefaultValue);
    setUploadAnother([]);
    setLcFile([]);
    setLCAtom({
      id: undefined,
      userId,
      source,
      isEditMode: false,
    });
    if (isEditModeActive) {
      setIsEditModeActive(false);
    }
  };

  const handleLcChange = async (v) => {
    setLoading(true);
    const detailsCredential: Credential = await getCredentialDetailsById(v?.value || 0);
    setSelectedCredential(detailsCredential);
    setLCInputs({
      ...lcInputs,
      credentialId: detailsCredential.id,
      credentialIdNumber: '',
    });
    setLoading(false);
    if (!isEditModeActive) {
      trackOnAmplitude(EVENTS.STARTED_ADDING_LICENSE_OR_CERTIFICATION, {
        Type: detailsCredential?.type || '',
        CredentialName: detailsCredential?.name || '',
        Source: source,
      });
    }
    setLastAddedName('');
    setLcFile([]);
  };

  const handleOrgChange = async (v) => {
    setLoading(true);
    const organizationDetails: VerificationOrg = await getVerificationByOrgId(v?.value || 0);
    setSelectedOrganization(organizationDetails);
    setLCInputs({
      ...lcInputs,
      issuingOrgId: organizationDetails.id,
    });
    setLoading(false);
  };

  const handleCustominputChange = (value, key) => {
    setCustomInputs((current) => current.filter((input) => input.fieldName !== key));
    setCustomInputs((current) => [...current, {
      fieldName: key,
      fieldValue: value,
    }]);
  };

  const getCustomInputValueByKey = (key) => customInputs.find((input) => input.fieldName === key)?.fieldValue || '';

  const getCustomInputSelectvalueByKey = (key, options) => {
    const selectedOption = customInputs.find((opt) => opt.fieldName === key);
    return options.find((option) => option.label === selectedOption?.fieldValue) as Options;
  };

  const getOrgOptions = () => {
    if (selectedCredential?.organizations) {
      return selectedCredential?.organizations.map((org) => ({
        label: org.name,
        value: org.id,
      })) as Options[];
    }
    return [] as Options[];
  };

  const getSelectedOrgOption = () => {
    const orgOptions = getOrgOptions();
    return orgOptions.find((o) => Number(o?.value || 0) === Number(lcInputs?.issuingOrgId || 0));
  };

  const handleLCSave = async (): Promise<void> => {
    const isLCFormValid = validateInputs();
    if (!isLCFormValid) {
      return;
    }
    trackOnAmplitude(EVENTS.ADDED_LICENSE_OR_CERTIFICATION, {
      Type: selectedCredential?.type || '',
      IssueDate: lcInputs?.issuedAt || '',
      ExpirationDate: lcInputs?.expiresAt || '',
      CredentialIdNumber: lcInputs?.credentialIdNumber || '',
      CustomFields: customInputs || [],
      MassUpload: uploadAnother?.length > 0 ?? false,
      CredentialName: selectedCredential?.name || '',
      ...(source ? { Source: source } : {}),
    });
    setLoading(true);
    const insertData: CredentialInputData = {
      ...lcInputs,
      companyId: window?.context?.company?.id || 0,
      companyName: window?.context?.company?.name || '',
      firstName: window?.context?.first_name || '',
      lastName: window?.context?.last_name || '',
      customInputs,
    };
    delete (insertData.id);
    const resp = await saveCredentials(insertData, userId, {
      headers: {
        'x-user-id': userId,
      },
      onError: async (_res: Response, msg: any) => {
        error(msg?.message || 'An error occurred while saving credentials', 'Error');
      },
    });
    if (resp?.id && resp?.id > 0) {
      if (showSuccessAlert) {
        success('Credential added to the profile successfully', 'Success');
      }
      setSelectedOrganization(orgDefaultValue);
      setCustomInputs([]);
      if (onSubmitCallback) {
        onSubmitCallback({ ...lcInputs, uploadAnother }, resp);
      }
      if (uploadAnother.length > 0) {
        setLastAddedName(selectedCredential.name);
        handleAfterUploadAnother();
      } else {
        handleOnClose();
      }
    }
    setLoading(false);
  };

  const handleLCUpdate = async (): Promise<void> => {
    const isLCFormValid = validateInputs();
    if (!isLCFormValid) {
      return;
    }
    trackOnAmplitude(EVENTS.EDITED_LICENSE_OR_CERTIFICATION, {
      Type: selectedCredential?.type || '',
      IssueDate: lcInputs?.issuedAt || '',
      ExpirationDate: lcInputs?.expiresAt || '',
      CredentialIdNumber: lcInputs?.credentialIdNumber || '',
      CustomFields: customInputs || [],
      VerificationStatus: getUserCredentialStatus(lcInputs?.verified || 0),
      CredentialName: selectedCredential?.name || '',
      ...(source ? { Source: source } : {}),
    });
    setLoading(true);
    const updatedData: CredentialInputData = {
      ...lcInputs,
      certOrgId: selectedOrganization?.certOrgId || '',
      companyId: window?.context?.company?.id || 0,
      companyName: window?.context?.company?.name || '',
      firstName: window?.context?.first_name || '',
      lastName: window?.context?.last_name || '',
      customInputs,
    };

    const resp = await updateCredentials(updatedData, userId, lcInputs?.credentialId || 0, {
      headers: {
        'x-user-id': userId,
      },
    });

    if (resp.error) {
      error('Error saving license/certificate', 'Error');
      setLoading(false);
    }
    if (onSubmitCallback) {
      onSubmitCallback({ ...updatedData, uploadAnother }, resp);
    }
    setLoading(false);
    handleOnClose();
  };

  const handleUploadAnother = (value): void => {
    setUploadAnother(value);
  };

  const handleLcFileUpload = async (file): Promise<void> => {
    setLcFile(file);
    setLoading(true);
    const sizeLimit = 15728640; // 15mb
    if (file[0]?.size > sizeLimit) {
      error('File size should be less than 15mb', 'Error');
      setLoading(false);
      setLcFile([]);
      return;
    }
    const fromData = new FormData();
    fromData.append('file', file[0]);
    const url = '/credentials/file/file-upload';
    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'x-user-id': userId.toString(),
      },
      body: fromData,
      credentials: 'same-origin',
      redirect: 'follow',
    }).then(async (response: Response) => {
      if (response.ok) {
        const data = await response.json();
        setLCInputs({
          ...lcInputs,
          uploadImageUrl: data?.fileKey || '',
        });
      } else {
        error('There was an unknown error uploading the file', 'Error');
        setLcFile([]);
      }
      setLoading(false);
    }).catch(() => {
      error('There was an unknown error uploading the file', 'Error');
      setLoading(false);
      setLcFile([]);
    });
  };

  const handleLcFileRemove = (): void => {
    setLcFile([]);
    setLCInputs({
      ...lcInputs,
      uploadImageUrl: '',
    });
  };

  const renderCustomInputs = () => selectedOrganization?.fields?.map((credentialCustomInput) => {
    const customLabel = credentialCustomInput?.name.replace(/([A-Z])/g, ' $1').trim();
    const isValidField = lcValidation?.customInputs?.find((input) => input?.fieldName === credentialCustomInput?.name);
    const isValid = Boolean(isValidField && isValidField?.fieldValue?.length || 0 > 0);
    if (credentialCustomInput?.type === 'select') {
      const mappedOptions = credentialCustomInput.options?.map((option) => ({
        value: option.value,
        label: option.name,
      }));
      return (
        <GridColumn xs="12" md="6">
          <div key={`custom-input-${credentialCustomInput.name}`} className={css.inputFields}>
            <Select
              label={customLabel}
              value={getCustomInputSelectvalueByKey(credentialCustomInput.name, mappedOptions) || ''}
              options={mappedOptions}
              onChange={(v) => {
								  handleCustominputChange(v.label, credentialCustomInput.name);
              }}
            />
            {isValid && (
            <IconAside iconPosition="left" iconColor="red">
              <IconAsideIcon verticalAlign="middle">
                <Icon name="warningSolid" size={22} block />
              </IconAsideIcon>
              <div className={css.validationErrorText}>
                {`${customLabel} Is Required!`}
              </div>
            </IconAside>
            )}
          </div>
        </GridColumn>
      );
    }
    return (
      <GridColumn xs="12" md="6">
        <div key={`custom-input-${credentialCustomInput.name}`} className={css.inputFields}>
          <TextInput
            label={customLabel}
            onChange={(e) => {
								  handleCustominputChange(e.target.value, credentialCustomInput.name);
            }}
            value={getCustomInputValueByKey(credentialCustomInput.name) || ''}
          />
          {isValid && (
          <IconAside iconPosition="left" iconColor="red">
            <IconAsideIcon verticalAlign="middle">
              <Icon name="warningSolid" size={22} block />
            </IconAsideIcon>
            <div className={css.validationErrorText}>
              {`${customLabel} Is Required!`}
            </div>
          </IconAside>
          )}
        </div>
      </GridColumn>
    );
  });

  const checkboxComponent = (
    <div key="customComponent" className={css.customFooterCheckbox}>
      <Checkbox
        onChange={handleUploadAnother}
        options={[{ label: 'Add another', value: '1' }]}
        value={uploadAnother}
      />
    </div>
  );

  const footerActions: FooterAction[] = [
    ...customFooterActions || [],
    {
      label: isSelectedModeActive ? 'Add' : 'Save',
      type: 'primary',
      disabled: loading,
      onClick: isEditModeActive ? handleLCUpdate : handleLCSave,
    },
  ];

  if (!isEditModeActive && !isSelectedModeActive) {
    footerActions.unshift(
      {
        label: 'Add Another',
        type: 'custom',
        customComponent: checkboxComponent,
      },
    );
  }

  const fetchCredentialDetails = (
    credentialId: number,
    states: Partial<CredentialInputData>,
  ) => {
    setLoading(true);
    getCredentialDetailsById(Number(credentialId))
      .then((resp: Credential) => {
        setSelectedCredential(resp);
        setLCInputs({
          ...lcInputs,
          ...states,
          credentialId: resp?.id,
        });
        setLoading(false);
        setLastAddedName('');
      }).catch(() => {
        setLoading(false);
        error('Something Went Wrong', 'Error');
        onClose();
      });
  };

  const getCustomFields = (fields: Partial<VefrificationOptions[]>) => (fields || []).map(
    (option: VefrificationOptions | undefined) => (
      { fieldName: option?.name || '', fieldValue: option?.value || '' }
    ),
  ) as CredentialCustomInput[];

  const loadCustomFields = async (verificationId: number) => {
    const orgDetails: VerificationOrg = await getVerificationByOrgId(
      String(verificationId),
    );
    setSelectedOrganization(orgDetails);
  };

  useEffect(() => {
    setScreeningData({
      userId,
      screeningType: '',
      source,
    });
  }, [userId]);

  useEffect(() => {
    if (isSelectedModeActive) {
      fetchCredentialDetails(Number(id), {});
    }
  }, [isSelectedModeActive]);

  useEffect(() => {
    if (isEditModeActive) {
      setLoading(true);
      getUserCredentialDetailsById(Number(id), Number(userId))
        .then((resp: UserCredentialsDetails) => {
          if (Number(resp?.issuingOrg?.verificationId)) {
            loadCustomFields(resp?.issuingOrg?.verificationId || 0);
          }
          fetchCredentialDetails(Number(resp?.credential?.id || 0), {
            id: Number(id),
            credentialId: Number(resp?.credential?.id || 0),
            credentialIdNumber: String(resp?.credentialIdNumber || 0),
            issuingOrgId: resp?.issuingOrg?.verificationId || 0,
            issuedAt: new Date(
              (resp?.issuedAt || 0) * 1000,
            ).toLocaleDateString('en-US'),
            expiresAt: new Date(
              (resp?.expiresAt || 0) * 1000,
            ).toLocaleDateString('en-US'),
            uploadImageUrl: resp?.uploadImageUrl || '',
          });
          setCustomInputs(
            getCustomFields(resp?.customInputs || []),
          );

          setLoading(false);
          setLastAddedName('');
        }).catch(() => {
          setLoading(false);
          error('Something Went Wrong', 'Error');
          onClose();
        });
    }
  }, [isEditModeActive]);

  const correctNameWarningBanner = () => (
    <GridColumn xs="12">
      <div className="u-padding-bottom--md">
        <div style={{ border: '0.1rem solid #ffb838', borderRadius: '0.4rem' }} className="u-background-color--yellow-20 u-padding--md">
          <IconAside iconPosition="left" iconSize="lg">
            <IconAsideIcon verticalAlign="top">
              <div className="u-color--yellow-60">
                <Icon size={24} name="warningRectangle" />
              </div>
            </IconAsideIcon>
            <div className="u-padding-top--xs">
              <BodyText styleLevel="md">
                To ensure successful verification, the name associated with this license must match
                the name on your profile:
                {' '}
                <BodyText styleLevel="mdSemiBold">
                  {userInfo?.first_name}
                  {' '}
                  {userInfo?.last_name}
                </BodyText>
                .
                Please
                {' '}
                <Link onClick={() => setTimeout(() => (window as any)?.Intercom?.(
                                'trackEvent',
                                'Clicked for credential assistance',
                                {},
                            ), 1000)}
                >
                  contact support
                </Link>
                {' '}
                if you need assistance with updating this information.
              </BodyText>
            </div>
          </IconAside>
        </div>
      </div>
    </GridColumn>
  );

  return (
    <Modal
      size="medium"
      header={headingText}
      footerActions={footerActions}
      onClose={() => {
        trackOnAmplitude(EVENTS.ADDED_LICENSE_OR_CERTIFICATION, {
          Action: 'close',
          Source: source,
        });
        onClose();
      }}
      isOpen={isOpen}
    >
      <Loader isLoading={loading}>
        {customContent && <div>{customContent}</div>}
        <div className={css.lcFormWrapper}>
          <Grid>
            <GridColumn xs="12">
              <div className={css.inputFields}>
                <Select
                  label="Credential"
                  value={lcSelectOptions.find((data) => Number(data.value) === Number(selectedCredential?.id || 0)) || ''}
                  options={lcSelectOptions}
                  disabled={isEditModeActive || isSelectedModeActive}
                  onChange={handleLcChange}
									// @ts-ignore
                  optionComponent={CustomSelectOptions}
                />
                {lcValidation?.credentialId && (
                <IconAside iconPosition="left" iconColor="red">
                  <IconAsideIcon verticalAlign="middle">
                    <Icon name="warningSolid" size={22} block />
                  </IconAsideIcon>
                  <div className={css.validationErrorText}>
                    {`${selectedCredential?.type || 'Credential'} Is Required!`}
                  </div>
                </IconAside>
								)}
              </div>
            </GridColumn>
            {!!lastAddedName && (
            <GridColumn xs="12">
              <div className={css.successAlert}>
                <span className={css.icon}>
                  <Icon
                    size={24}
                    name="completeHollow"
                    disablePointerEvent
                  />
                </span>
                <span className={css.textInfo}>
                  {lastAddedName}
                  {' '}
                  succcessfully added to your profile
                </span>
              </div>
            </GridColumn>
            )}
            {selectedCredential?.id > 0 && (
            <>
              {selectedCredential?.verifiedOn === null ? (
                <GridColumn xs="12">
                  <div className={css.inputFields}>
                  <TextInput
                  id="number"
                  label={numberFieldLabelText}
                  onChange={(e) => {
												  setLCInputs({
												    ...lcInputs,
												    credentialIdNumber: e.target.value.trim(),
												  });
                }}
                  value={lcInputs.credentialIdNumber || ''}
                />
                  {lcValidation?.credentialIdNumber && (
                <IconAside iconPosition="left" iconColor="red">
                <IconAsideIcon verticalAlign="middle">
                <Icon name="warningSolid" size={22} block />
              </IconAsideIcon>
                <div className={css.validationErrorText}>
                {`${selectedCredential?.type} ID number is required`}
              </div>
              </IconAside>
											)}
                </div>
                </GridColumn>
)
								  : (
  <GridColumn xs="12" md="6">
    <div className={css.inputFields}>
      <Select
        label="Issuing Organization"
        value={getSelectedOrgOption() || ''}
        options={getOrgOptions()}
        disabled={isEditModeActive && !allowEditOrg}
        onChange={handleOrgChange}
      />
      {lcValidation?.issuingOrgId && (
      <IconAside iconPosition="left" iconColor="red">
        <IconAsideIcon verticalAlign="middle">
          <Icon name="warningSolid" size={22} block />
        </IconAsideIcon>
        <div className={css.validationErrorText}>
          Issuing organization is required
        </div>
      </IconAside>
												)}
    </div>
  </GridColumn>
									)}
              {selectedOrganization?.fields?.length > 0 &&
									renderCustomInputs()}
              {selectedCredential?.verifiedByName?.toLowerCase() !== 'atlas' && (
              <GridColumn xs="12" md="6">
                <div className={css.inputFields}>
                  <DatePicker
                  label="Issue date"
                  value={lcInputs.issuedAt || ''}
                  validationMessage={lcValidation?.issuedAt || ''}
                  onChange={(value) => {
                  setLCInputs({
                    ...lcInputs,
                    issuedAt: value.trim(),
                  });
                }}
                />
                </div>
              </GridColumn>
                )}
              {selectedCredential?.verifiedByName?.toLowerCase() !== 'atlas' && (
              <GridColumn xs="12" md="6">
                <div className={css.inputFields}>
                  <DatePicker
                  label="Expiration date"
                  value={lcInputs.expiresAt || ''}
                  validationMessage={lcValidation.expiresAt || ''}
                  onChange={(value) => {
                  setLCInputs({
                    ...lcInputs,
                    expiresAt: value.trim(),
                  });
                }}
                />
                </div>
              </GridColumn>
                )}

              {selectedCredential?.verifiedByName?.toLowerCase() === 'atlas' && correctNameWarningBanner()}

              {selectedCredential?.verifiedOn !== null ?
								  (
  <div className={css.clearWrapper}>
    <GridColumn xs="12" md="9">
      <p className={css.clearInfoText}>
        Field Nation partners with CLEAR as part of our professional credential verification process. By saving this license or certification information,
        you consent to CLEAR obtaining information about your relevant professional credentials.
        See CLEAR
        {' '}
        <Link href="https://www.clearme.com/privacy-policy" target="_blank">Privacy Policy</Link>
        .
      </p>
    </GridColumn>
    <GridColumn xs="12" md="3">
      <div className={css.clearLogoWrapper}>
        <ClearLogo width={130} height={52} />
      </div>
    </GridColumn>
  </div>
) :
								  (
  <GridColumn xs="12">
    <div className={css.helpText}>
      Upload the scan or PDF for verification
    </div>
    <div className={css.inputFields}>
      <FileInput
        buttonLabel="Upload"
        accept="image/jpeg, image/png, application/pdf"
        helpText="Supports jpeg, png and pdf file upload"
        multiple={false}
        onDrop={(file) => {
													  handleLcFileUpload(file);
        }}
        files={lcFile}
        onDeleteFile={() => {
													  handleLcFileRemove();
        }}
      />
    </div>
    {lcValidation?.uploadImageUrl && (
    <IconAside iconPosition="left" iconColor="red">
      <IconAsideIcon verticalAlign="middle">
        <Icon name="warningSolid" size={22} block />
      </IconAsideIcon>
      <div className={css.validationErrorText}>
        {lcValidation?.uploadImageUrl}
      </div>
    </IconAside>
											)}
  </GridColumn>
									)}
            </>
     )}
          </Grid>
        </div>
      </Loader>
    </Modal>
  );
};
LicenseCertification.propTypes = {
  onSubmitCallback: PropTypes.func,
  id: PropTypes.number,
  userId: PropTypes.number.isRequired,
  source: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool,
  customContent: PropTypes.node,
};

LicenseCertification.defaultProps = {
  onSubmitCallback: () => { },
  customContent: null,
  customFooterActions: [],
};
export default LicenseCertification;
