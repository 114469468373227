/* eslint-disable import/prefer-default-export, @typescript-eslint/explicit-module-boundary-types */
export const SEGMENTATION_V4_EXPERIEMNT_ID = 126;
export const ACCORDION_VALUE_MODIFIER = 10;
export const BUYER_GROUP_IDS = [2, 7, 8];

export const isV4Segmentation = () => Boolean(
  window?.context?.experiments?.results?.find(
    ({ id, value }) => (id === SEGMENTATION_V4_EXPERIEMNT_ID && value && value !== 'old' ? Boolean(value) : false)),
);

export function getReputationServiceURL(): string {
  return window?.location?.host?.includes('localhost')
    ? 'http://localhost:8989'
    : '/s/reputation';
}
