/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Loader, v2 } from '@fieldnation/platform-components';

import css from './styles.scss';
import { makePasswordRequest } from '../../api';
import { PASSWORD_RETRY_DELAY, KeyCode } from '../../constants';
import { PasswordContext } from '../../widgets';

const { TextInput, Tile, TileDivision } = v2;

export const PasswordConfirmation = (): JSX.Element => {
  const { setIsValid } = useContext(PasswordContext);
  const [password, setPassword] = useState('');
  const [helpText, setHelpText] = useState('');
  const [waitTimer, setWaitTimer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const prevPasswordRef = useRef('');

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const passwordConfirmation = await makePasswordRequest(
        window?.context?.id || 0,
        password,
      );

      setIsLoading(false);
      prevPasswordRef.current = '';

      if (passwordConfirmation?.is_valid === true) {
        setIsValid(true);
      }

      if (!passwordConfirmation?.is_valid) {
        setHelpText('Invalid password. Try again');
        setWaitTimer(PASSWORD_RETRY_DELAY);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => setWaitTimer((waitTimer || 1) - 1), 1000);
    return () => clearInterval(timer);
  }, [waitTimer]);

  return (
    <div className={css['password-confirmation-container']}>
      <Tile>
        <TileDivision heading="Confirm Password">
          <Loader isLoading={isLoading} size="sm">
            <div className={css['confirmation-text']}>
              Enter your password to edit this section of your profile.
            </div>
            <TextInput
              label="Password"
              type="password"
              disabled={Boolean(waitTimer || isLoading)}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyUp={(e) => {
                if (
                  e.keyCode === KeyCode.ENTER &&
                  password &&
                  password !== prevPasswordRef.current
                ) {
                  prevPasswordRef.current = password;
                  handleSubmit();
                }
              }}
            />
            <span className={css['confirmation-help-text']}>
              {helpText}
              {waitTimer > 0 && (
                <span>
                  &nbsp;in
                  {` ${waitTimer} `}
                  sec.
                </span>
              )}
            </span>
            <div className={css['password-submit']}>
              <Button
                disabled={Boolean(waitTimer || !password)}
                block
                onClick={handleSubmit}
                label="Submit"
                type="primary"
              />
            </div>
          </Loader>
        </TileDivision>
      </Tile>
    </div>
  );
};
