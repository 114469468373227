import React from 'react';
import {
  Link,
} from '@fieldnation/platform-components';
import FnLogoWhite from './Svg/FnLogoWhite';

import css from './CuratedChip.scss';
import trackAmplitudeEvent, { LEARNED_MORE } from '../ProviderSnapshot/amplitude';

const SquareIcon = (): JSX.Element => (
  <div className={css.Square__Icon}>
    <FnLogoWhite width={16} height={18} />
  </div>
);

interface IProps {
  label: string;
  networks?: string[];
  providerName: string;
  source?: string;
}

const PopoverContent = ({
  label, networks = [], providerName, source,
}: IProps): JSX.Element => (
  <div className={css.Overlay__Container}>
    <div className={css['Overlay__Top--Border']} />
    <div className={css.Title__Container}>
      <SquareIcon />
      <span className={css.Title}>{`Field Nation ${label}`}</span>
    </div>
    <div className={css.Overlay__Content}>
      <span className={css.Subtitle}>
        {`${providerName} is in ${networks.length > 1 ? 'these' : 'this'} network${networks.length > 1 ? 's' : ''}:`}
      </span>
      <ul>
        {networks?.map((n: string, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${n}-${i}`} className={css.network__Label}>
            <span className={css.Label__bullet} />
            {n}
          </li>
        ))}
      </ul>
    </div>
    <div className={css.Overlay__Footer}>
      <span className={css.Footer__Text}>
        This provider has been designated as a Select Tech based on their quality and expertise.
        <Link
          onClick={() => {
            trackAmplitudeEvent({
              category: LEARNED_MORE,
              ampProps: {
                Source: `Select Tech Network - ${source}`,
              },
            });
          }}
          href="https://support.fieldnation.com/s/article/Select-Networks"
          target="_blank"
        >
          {' Learn More'}
        </Link>
      </span>
    </div>
  </div>
);

PopoverContent.defaultProps = {
  networks: [],
  source: 'Find Providers',
};

export default PopoverContent;
