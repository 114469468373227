import React from 'react';
import {
  v2, IconAside, IconAsideIcon, Icon,
} from '@fieldnation/platform-components';
import css from '../Styles.scss';

const { TextInput } = v2;

type ValueType = string | number;

interface InputFieldProps {
  id?: string;
  label: string;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasValidationError?: boolean;
  errorMessage?: ValueType;
  wrapperStyle?: any;
}

const InputField = (props: InputFieldProps) => {
  const {
    id,
    label,
    value,
    disabled,
    onChange,
    hasValidationError,
    errorMessage,
    wrapperStyle,
  } = props;

  return (
    <div className={css.inputFields} style={wrapperStyle}>
      <TextInput
        id={id}
        label={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      {hasValidationError && (
        <div style={{ marginTop: '0.6rem' }}>
          <IconAside iconPosition="left" iconColor="red">
            <IconAsideIcon verticalAlign="middle">
              <Icon name="warningSolid" size={22} block />
            </IconAsideIcon>
            <div className={css.validationErrorText}>
              {errorMessage}
            </div>
          </IconAside>
        </div>
      )}
    </div>
  );
};

InputField.defaultProps = {
  id: '',
  disabled: false,
  hasValidationError: false,
  errorMessage: '',
  wrapperStyle: {},
};

export default InputField;
