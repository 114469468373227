/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import {
  Grid,
  GridColumn,
  Section,
  Status,
} from '@fieldnation/platform-components';
import { useRecoilValue } from 'recoil';
import { isBefore, format } from 'date-fns';
import WrapSection from './WrapSection';
import {
  openLicenseOrCertification,
  LicenseAndCertStatusType,
  LicenseAndCertqcStatusType,
} from '../Components/LicensesAndCertifications';
import licenseCertificationQuery from '../queries/licenses-certifications.query';

import { MonolithProfile, UserQualifications } from '../types.d';

interface Props {
  user: MonolithProfile;
  collapse?: boolean;
}

const LicensesAndCerts = ({
  user, collapse,
}: Props): JSX.Element | null => {
  const data = useRecoilValue(licenseCertificationQuery);

  if (!user) {
    return null;
  }

  const {
    // @ts-ignore
    showStatus = false,
    // @ts-ignore
    id: userId,
    // @ts-ignore
    data: results = [],
  } = data || {};

  const displayHtml = (resultsLC: UserQualifications[]) => (
    <Grid>
      {resultsLC?.map?.((item) => {
        const statusOfQs = item?.status?.name || '';
        const statusOfQsIndex: number = [
          'PENDING',
          'APPROVED',
          'DENIED',
          'EXPIRED',
        ].indexOf(statusOfQs);
        const isVerifiedByFN: boolean = item?.qualification?.verification?.id === 3;

        return (
          <GridColumn xs="12" key={`${item.id}${item.category}`}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openLicenseOrCertification(item, userId || 0);
                }}
              >
                {item.name}
                {' '}
              </a>
              {' '}
              {isVerifiedByFN && statusOfQs === 'APPROVED' ? (
                <span>
                  <Status
                    inlineFlex
                    size="sm"
                    type={
                      LicenseAndCertqcStatusType[statusOfQsIndex].type as LicenseAndCertStatusType
                    }
                  >
                    {LicenseAndCertqcStatusType[statusOfQsIndex].label}
                  </Status>
                </span>
              ) : (
                <span>
                  {showStatus && isVerifiedByFN && (
                  <Status
                    inlineFlex
                    size="sm"
                    type={
                      item?.status?.id !== -1
                        && isBefore(
                          new Date((item?.expire_date?.utc || '').replace(/-/g, '/')),
                          new Date(),
                        )
                        ? LicenseAndCertqcStatusType[3].type as LicenseAndCertStatusType // Expired
                        : LicenseAndCertqcStatusType[item?.status?.id === -1 ? 2 : 0]
                          .type as LicenseAndCertStatusType // approved or pending
                      }
                  >
                    {
                      item?.status?.id !== -1
                      && isBefore(
                        new Date((item?.expire_date?.utc || '').replace(/-/g, '/')),
                        new Date(),
                      )
                        ? LicenseAndCertqcStatusType[3].label // Expired
                        : LicenseAndCertqcStatusType[
                          item?.status?.id === -1 ? 2 : 0].label // approved or pending
                    }
                  </Status>
                  )}
                </span>
              )}
            </div>
            {item.number && (
              <div>
                ID #:
                {item.number}
              </div>
            )}
            {item?.expire_date?.utc && (
              <div>
                Expires:
                {' '}
                {format(new Date((item?.expire_date?.utc).replace(/-/g, '/')), 'MM/dd/yyyy')}
              </div>
            )}
          </GridColumn>
        );
      })}
    </Grid>
  );

  return (
    <WrapSection
      label="Licenses and certifications"
      value={`${results.length}`}
      collapse={collapse}
    >
      <Section>
        <div style={{ marginBottom: '12px' }}>
          {displayHtml(results)}
        </div>
      </Section>
    </WrapSection>
  );
};

LicensesAndCerts.defaultProps = {
  collapse: false,
};

export default LicensesAndCerts;
