import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Table from './Table';
import TableV2 from './TableV2';
import { store } from '../Store';

import { V2Qualification } from '../../types.d';

const ContextTable = ({ requirements = [] }: { requirements: V2Qualification[]; }): JSX.Element => {
  const ctx = useContext(store);
  const {
    state: {
      requirements: storeRequirements,
      isManager,
      hasAction,
      hasStatus,
      userId,
      v2,
    },
  } = ctx;

  const reqs = requirements.length ? requirements : storeRequirements;

  return v2 ? (
    <TableV2
      store={store}
      userId={userId}
      requirements={reqs}
      isManager={isManager}
      hasAction={hasAction}
    />
  )
    : (
      <Table
        store={store}
        requirements={reqs}
        isManager={isManager}
        hasAction={hasAction}
        hasStatus={hasStatus}
      />
    );
};

ContextTable.propTypes = {
  requirements: PropTypes.array,
};

ContextTable.defaultProps = {
  requirements: [],
};

export default ContextTable;
