/* eslint-disable import/prefer-default-export */
import { atom } from 'recoil';

export const isAddressConfirmationOpenAtom = atom({
  key: 'isAddressConfirmationOpen',
  default: false,
});

export const addressConfirmationDescriptionAtom = atom({
  key: 'addressConfirmationDescription',
  default: 'Something went wrong validating the address.',
});
