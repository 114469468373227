import * as React from 'react';

export enum ScoreType {
  Cancellation = 'cancellation',
  Timeliness = 'timeliness',
}

export interface IReliabilityScore {
  [key: string]: {
    count: number;
    score: number;
    target: number;
    details: {
      [key: string]: number;
    }
  }
}

export type ProgressBarValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface ProviderReliabilityProps {
  reliabilityScore: IReliabilityScore;
  wrapperClasses?: {
    [key: string]: boolean;
  };
}

export class ProviderReliability extends React.Component<ProviderReliabilityProps, any> {}
