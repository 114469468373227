import * as React from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  v2,
  ButtonGroup,
  Button,
  Loader,
} from '@fieldnation/platform-components';
import { addOrUpdateComment } from '../api';
import { jobFilter } from '../queries/job-history.query';

const { TextArea } = v2;

type Invalidation = string | null;

const isValid = (
  comment: string,
  setInvalid: (i: Invalidation) => void,
): boolean => {
  if (!comment.trim().length) {
    setInvalid('Enter a comment.');
    return false;
  }

  if (comment.length > 65535) {
    setInvalid('Your rating is too long.');
    return false;
  }

  return true;
};

interface Props {
  workOrderId: number;
  comment?: string;
  onClose: () => void;
  onSave: (message: string) => void;
}

const CommentForm = ({
  onClose,
  workOrderId,
  comment,
  onSave,
}: Props): JSX.Element => {
  const [value, setValue] = useState(comment || '');
  const [invalid, setInvalid] = useState<Invalidation>(null);
  const [filter, setFilter] = useRecoilState(jobFilter);
  const [loading, setLoading] = useState(false);

  const saveComment = async (msg: string) => {
    setLoading(true);
    await addOrUpdateComment(workOrderId, msg);
    // ES taking bit time for doc, make delay
    setTimeout(() => {
      setFilter({ ...filter, version: (filter?.version || 0) + 1 });
      setLoading(false);
      onClose();
    }, 1000);
    onSave(msg);
  };

  return (
    <div className="comment-message">
      {loading && <Loader fixed isLoading />}
      <TextArea
        onValid={() => ({ type: 'success' })}
        label=""
        value={value}
        onChange={(e) => {
          isValid(e.target.value, setInvalid);
          setValue(e.target.value);
        }}
      />
      {!invalid && <div className="text-danger">{invalid}</div>}
      <div className="u-margin-top--sm">
        <small className="text-muted">
          <em style={{ lineHeight: '1.1em' }}>
            Reply will not be visible to the buyer of the work order, only to
            other buyers viewing your ratings. Use the work order messaging to
            communicate with this client directly.
          </em>
        </small>
      </div>
      <div className="text-xs-right u-margin-top--md">
        <ButtonGroup>
          <Button type="secondary" label="Cancel" onClick={onClose} />
          <Button
            type="primary"
            label="Save Changes"
            disabled={Boolean(invalid)}
            onClick={() => saveComment(value)}
          />
        </ButtonGroup>
      </div>
    </div>
  );
};

CommentForm.defaultProps = {
  comment: undefined,
};

export default CommentForm;
