/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable object-curly-newline */
/* eslint-disable react/require-default-props */
import React from 'react';
// @ts-ignore
import { Button, MessageModal } from '@fieldnation/platform-components';
import classNames from 'classnames';
import { InterceptProps, IErrorPages } from './types.d';

import css from './Intercept.scss';

export const ErrorPages: IErrorPages = {
  404: {
    type: 'danger',
    header: 'Sorry!',
    content: (
      <div className={classNames(css['padding-md'])}>
        The page you are looking for is not here.
      </div>
    ),
    action: (
      <Button
        type="secondary"
        size="lg"
        href="/"
        disableTitleCasing
        label="Go Home"
      />
    ),
  },
  403: {
    type: 'danger',
    header: 'Sorry!',
    content: (
      <div className={classNames(css['padding-md'])}>
        You are unable to access this page!
      </div>
    ),
    action: (
      <Button
        type="secondary"
        size="lg"
        href="/"
        disableTitleCasing
        label="Go Home"
      />
    ),
  },
};

export const Intercept = ({
  code = '404',
  data,
}: InterceptProps): JSX.Element => {
  const { content, action, header, type } = data || ErrorPages?.[code];

  return (
    <MessageModal type={type} header={header} isOpen footerActions={undefined}>
      {content}
      {action && (
        <div className={classNames(css['message-modal-action'])}>{action}</div>
      )}
    </MessageModal>
  );
};
