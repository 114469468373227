import * as React from 'react';
import { Icon } from '@fieldnation/platform-components';
import { useRecoilValue } from 'recoil';
import providerCommitmentQuery from '../queries/provider-commitment.query';

import css from './Rating.scss';

const ReliabilityChip: React.FC = () => {
  const providerCommitment = useRecoilValue(providerCommitmentQuery);
  const assignmentCompletionValue = providerCommitment?.showCommitmentPercent
    ? Number(providerCommitment?.commitmentPercent?.toFixed(1))
    : 0;

  const showChip = assignmentCompletionValue >= 90;

  return showChip ? (
    <div className={css['reliability-chip']}>
      <Icon name="check" size={16} />
      High Commitment
    </div>
  ) : (
    <></>
  );
};

export default ReliabilityChip;
