import * as React from 'react';
import { WaWorkerCompEventSource } from './tracker/amplitude';

export enum VerificationStatusEnum {
  NEW = 'new',
  VERIFIED = 'verified',
  INVALID = 'invalid',
}
export interface UbiFormProps {
    isOnboarding?: boolean,
    source?: WaWorkerCompEventSource;
}

export class UbiForm extends React.Component<UbiFormProps, any> {}
