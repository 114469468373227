/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import {
  Icon,
  Truncate,
  Link,
  Thumbnail,
  Loader,
} from '@fieldnation/platform-components';
import { format, parseISO, fromUnixTime } from 'date-fns';
import { flagComment, approveComment } from '../api';
import CommentForm from './CommentForm';
import contextQuery from '../queries/context.query';

import { JobRatingComment } from '../types.d';

import css from './JobHistoryModal.scss';

// Truncate does not tell us when it will compact or expose a prop, so have
// to guess for now
const ARBITRARY_LENGTH = 150;

interface Props extends JobRatingComment {
  workOrderId: number;
  onApprovedOrFlaged: () => void;
}

const RatingComment = ({
  name,
  photo,
  message,
  createdAt,
  role,
  canEdit,
  canApprove,
  canReport,
  visible,
  status,
  workOrderId,
  onApprovedOrFlaged,
}: Props): JSX.Element | null => {
  const context = useRecoilValue(contextQuery);
  const [expanded, expand] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [comment, setComment] = useState(message);
  const [loading, setLoading] = useState(false);

  const isStaff = [3, 9].includes(context?.group?.id || 0);

  // don't show comment section if the condition true
  if (!visible && !isStaff) {
    return null;
  }

  const approvedOrFlagedComment = async (isApproved: boolean) => {
    setLoading(true);
    if (isApproved) {
      await approveComment(workOrderId);
    } else {
      await flagComment(workOrderId);
    }

    setTimeout(async () => {
      await onApprovedOrFlaged();
      setLoading(false);
    }, 1000);
  };

  let dateToFormat: Date | false = false;

  if (createdAt) {
    if (typeof createdAt === 'number') {
      dateToFormat = fromUnixTime(createdAt);
    }
    if (typeof createdAt === 'string') {
      dateToFormat = parseISO(createdAt);
    }
  }

  return (
    <div
      className={classNames(
        css['comment-container'],
        'u-padding--md',
        'u-margin-top--sm',
      )}
    >
      {loading && <Loader fixed isLoading />}
      <div className={css['comment-thumbnail']}>
        <Thumbnail src={photo} title={name} />
      </div>
      <div className="u-margin-left--sm">
        <div className={css['comment-content']}>
          <small className="text-muted">
            {role === 'buyer' && 'BUYER: '}
            {role === 'provider' && 'PROVIDER: '}
            {dateToFormat
              ? `${name} commented ${format(dateToFormat, 'MMM d, yyyy')}`
              : ''}
          </small>
          {isEditting ? (
            <CommentForm
              workOrderId={workOrderId}
              comment={comment}
              onClose={() => setIsEditting(false)}
              onSave={(msg) => {
                setComment(msg);
              }}
            />
          ) : (
            <div className="comment-message">
              {expanded ? (
                comment
              ) : (
                <Truncate
                  wordBreak="break-word"
                  maxLines={2}
                  text={comment}
                  lineHeight="1.3em"
                />
              )}
              {!expanded && (comment || []).length > ARBITRARY_LENGTH ? (
                <Link onClick={() => expand(true)}>See more</Link>
              ) : null}
              <div className={css['comment-info']}>
                {isStaff && status === 'pending' && (
                  <div className={css['comment-action']}>
                    <Icon name="time" size={18} />
                    <small>
                      <em className="text-muted">Reply pending review</em>
                    </small>
                  </div>
                )}
                {isStaff && visible === true && (
                  <div className={css['comment-action']}>
                    <Icon name="visibilityOn" size={18} />
                    <small>
                      <em className="text-muted">Comment publicly visible</em>
                    </small>
                  </div>
                )}
                {isStaff && visible === false && (
                  <div className={css['comment-action']}>
                    <Icon name="visibilityOff" size={18} />
                    <small>
                      <em className="text-muted">
                        Comment visible to staff only
                      </em>
                    </small>
                  </div>
                )}
                {canApprove && status !== 'approved' && (
                  <div className={css['comment-action']}>
                    <small>
                      <Link onClick={() => approvedOrFlagedComment(true)}>
                        Approve
                      </Link>
                    </small>
                  </div>
                )}
                {canEdit && (
                  <div className={css['comment-action']}>
                    <small>
                      <Link
                        onClick={() => {
                          setIsEditting(true);
                        }}
                      >
                        Edit Reply
                      </Link>
                    </small>
                  </div>
                )}
                {canReport && status !== 'pending' && (
                  <div className={css['comment-action']}>
                    <small>
                      <Link onClick={() => approvedOrFlagedComment(false)}>
                        Flag comment
                      </Link>
                    </small>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingComment;
