import * as React from 'react';
import { useState, useCallback } from 'react';
// @ts-ignore
import NativeForms from 'native-forms-react';
import { useRecoilValue } from 'recoil';
import contextQuery from '../queries/context.query';

export interface FeedbackProps {
  userId: number;
  onClose: () => void;
}

export function useFeedback(userId: number): [() => void, JSX.Element | null] {
  const [open, setOpen] = useState(false);

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return [toggle, open ? <Feedback userId={userId} onClose={toggle} /> : null];
}

export default function Feedback({ userId, onClose }: FeedbackProps) {
  const context = useRecoilValue(contextQuery);
  if (!context) {
    return null;
  }

  return (
    <NativeForms
      onSend={() => {
        setTimeout(() => {
          onClose();
        }, 2000);
      }}
      email={context?.email}
      name={`${context?.first_name} ${context?.last_name}`}
      extraData={{ userId, role: context?.role_type }}
      form="https://form.nativeforms.com/wR3Q0IWPmZid09WMpdzNx1Db"
    />
  );
}
