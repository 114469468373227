import { Loader } from '@fieldnation/platform-components';
import React, { Suspense, useEffect } from 'react';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import { AddEditCredentialsProps, EditCredentialsProps, LicenseCertidicationProps } from './types.d';
import LicenseCertification from './LicenseCertification';
import ConfigurableCredential from './ConfigurableCredential';
import { LicenseCertificationAtom } from './Store/query';
import EnhancedCredentialsAdd from './EnhancedCredentialsAdd';
import EnhancedCredentialsEdit from './EnhancedCredentialsEdit';

const LicenseCerificationWrapper = (
  props: LicenseCertidicationProps,
): JSX.Element => {
  const {
    id, userId, source, isEditMode,
  } = props;
  const setLCData = useSetRecoilState(LicenseCertificationAtom);

  useEffect(() => {
    setLCData({
      id,
      userId,
      source,
      isEditMode,
    });
  });
  return (
    <>
      <LicenseCertification {...props} />
    </>
  );
};

const ConfigurableCredentialWrapper = (
  props: LicenseCertidicationProps,
): JSX.Element => {
  const {
    id, userId, source, isEditMode,
  } = props;
  const setLCData = useSetRecoilState(LicenseCertificationAtom);

  useEffect(() => {
    setLCData({
      id,
      userId,
      source,
      isEditMode,
    });
  });
  return (
    <>
      <ConfigurableCredential {...props} />
    </>
  );
};

const LicenseCertifcaionWidget = (props: LicenseCertidicationProps): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader isLoading fixed />}>
      <LicenseCerificationWrapper {...props} />
    </Suspense>
  </RecoilRoot>
);

const ConfigurableCredentialWidget = (props: LicenseCertidicationProps): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader isLoading fixed />}>
      <ConfigurableCredentialWrapper {...props} />
    </Suspense>
  </RecoilRoot>
);

const EnhancedCredentialsAddWrapper = (
  props: AddEditCredentialsProps,
): JSX.Element => {
  const {
    userId, source = '',
  } = props;
  const setLCData = useSetRecoilState(LicenseCertificationAtom);

  useEffect(() => {
    setLCData({
      userId,
      source,
    });
  });
  return (
    <EnhancedCredentialsAdd {...props} />
  )
};

const EnhancedCredentialsAddWidget = (props: AddEditCredentialsProps): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader isLoading fixed />}>
      <EnhancedCredentialsAddWrapper {...props} />
    </Suspense>
  </RecoilRoot>
);

const EnhancedCredentialsEditWrapper = (
  props: EditCredentialsProps,
): JSX.Element => {
  const {
    userCredentialId, userId, source = '',
  } = props;
  const setLCData = useSetRecoilState(LicenseCertificationAtom);

  useEffect(() => {
    setLCData({
      userCredentialId,
      userId,
      source,
    });
  });

  return (
    <EnhancedCredentialsEdit {...props} />
  );
};

const EnhancedCredentialsEditWidget = (props: EditCredentialsProps): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader isLoading fixed />}>
      <EnhancedCredentialsEditWrapper {...props} />
    </Suspense>
  </RecoilRoot>
);

export {
  LicenseCertifcaionWidget,
  ConfigurableCredentialWidget,
  EnhancedCredentialsAddWidget,
  EnhancedCredentialsEditWidget,
};
