import { UserQualifications } from '../types.d';

export const capitalizeFirstChar =
  (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

// initial/empty state of the form
export const emptyForm = {
  id: 0,
  name: '',
  category: 0,
  issue_date: { utc: '' },
  expire_date: { utc: '' },
} as UserQualifications;

// license or certification form validation
export const formValidate = ({
  id,
  number,
  issue_date,
  expire_date,
  file,
  category = 0,
  isEdit = false,
}: UserQualifications): Record<string, unknown> => [
  !number && {
    number: `${capitalizeFirstChar(category === 1 ? 'certification' : 'license')} number is required`,
  },
  !issue_date
        || typeof issue_date !== 'object'
        || (!issue_date.utc && { issue_date: 'Select start date' }),
  !expire_date
        || typeof expire_date !== 'object'
        || (!expire_date.utc && { expire_date: 'Select start date' }),
  !isEdit && !file && { file: 'Upload a file' },
// eslint-disable-next-line @typescript-eslint/ban-types
].reduce((acc, item) => (item ? { ...acc, ...(item as {}), id } : acc), {});
