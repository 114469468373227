import * as React from 'react';
import { Section } from '@fieldnation/platform-components';
import WrapListItem from '../Components/WrapListItem';
import WrapSection from './WrapSection';
import { MonolithProfile, MonolithEndorsements } from '../types.d';

const getEndorsements = (endorsements: MonolithEndorsements) => {
  if (!endorsements) {
    return (
      <div className="text-muted">
        <em>Upgrade to view marketplace endorsements.</em>
      </div>
    );
  }

  return (
    <Section>
      <WrapListItem label="Communication" value={`${endorsements?.communication || '0'}`} />
      <WrapListItem label="Problem Solving" value={`${endorsements?.problem_solving || '0'}`} />
      <WrapListItem label="Preparedness" value={`${endorsements?.preparedness || '0'}`} />
      <WrapListItem label="Professionalism" value={`${endorsements?.professionalism || '0'}`} />
    </Section>
  );
};

interface Props {
  user: MonolithProfile;
  collapse?: boolean;
}

const Endorsements = ({
  user, collapse,
}: Props): JSX.Element | null => {
  if (!user) {
    return null;
  }

  const marketplaceEndorsements = user?.rating?.marketplace?.endorsements || {};
  const totalEndorsements = Number(marketplaceEndorsements.communication)
    + Number(marketplaceEndorsements.problem_solving)
    + Number(marketplaceEndorsements.preparedness)
    + Number(marketplaceEndorsements.professionalism);

  return (
    <WrapSection
      label="Endorsements"
      value={`${totalEndorsements || '0'}`}
      collapse={collapse}
    >
      {getEndorsements(marketplaceEndorsements)}
    </WrapSection>
  );
};

Endorsements.defaultProps = {
  collapse: false,
};

export default Endorsements;
