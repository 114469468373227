import { Libraries } from '@googlemaps/js-api-loader';
import { IFullAddressInputValues } from './types.d';

export const DEFAULT_GOOGLE_MAPS_KEY =
  'AIzaSyCTIHMSp9o-RMRMC2ZXt4M_0ALT0EDV_Cs';

export const DEFAULT_PLACE_TYPES: string[] = ['address'];
export const DEFAULT_GOOGLE_MAPS_LIBRARIES: Libraries = ['places'];
export const DEFAULT_DEBOUNCE_DELAY = 300; // in ms;
export const MINIMUM_CHARACTER_COUNT = 3;
export const DEFAULT_COUNTRY_LIST_PATH = '_COUNTRIES';
// use ALPHA-2 code from here https://www.iban.com/country-codes
export const DEFAULT_AUTOCOMPLETE_COUNTRY: string[] = ['US', 'CA'];
export const DEFAULT_PLACE_DETAILS_FIELDS: string[] = [
  'address_components',
  'name',
];

export const DEFAULT_ADDITIONAL_INFO = `In Address Line 1, type the specific address of your residence or business
  (e.g. 733 S. Marquette Ave), and don't include a business name or institution.`;

export const DEFAULT_ADDRESS_COMPONENT_MAPPING: (
  placeDetails: google.maps.places.PlaceResult | null,
) => IFullAddressInputValues = (placeDetails) => {
  const { address_components = [], name = '' } = placeDetails || {};
  const fullAddress: IFullAddressInputValues = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  };
  address_components.forEach(({ short_name = '', types = [] }) => {
    if (types.includes('postal_code')) {
      fullAddress.zip = short_name;
    } else if (types.includes('country')) {
      fullAddress.country = short_name;
    } else if (types.includes('administrative_area_level_1')) {
      fullAddress.state = short_name;
    } else if (
      // prettier-ignore
      types.some((item) => ['locality', 'sublocality', 'sublocality_level_1'].includes(item))
    ) {
      fullAddress.city = short_name;
    }
  });

  fullAddress.address1 = name;
  return fullAddress;
};
