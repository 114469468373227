import * as React from 'react';
import {
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
  Section,
  Stars,
} from '@fieldnation/platform-components';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import WrapSection from './WrapSection';
import workOrderTypeOfWorkQuery from '../queries/work-order-type-of-work.query';
import JobHistoryModal from '../Components/JobHistoryModal';
import Padded from '../Components/Padded';
import { MonolithProfile } from '../types.d';

const NotRated = () => <span className="text-muted">not rated</span>;

interface Props {
  user: MonolithProfile;
  collapse?: boolean;
}

const Ratings = ({
  user, collapse,
}: Props): JSX.Element | null => {
  const tow = useRecoilValue(workOrderTypeOfWorkQuery);
  const [jobHistory, setJobHistory] = useState(false);

  if (!user) {
    return null;
  }

  const { rating = {} } = user;
  const hasRating = Boolean(rating?.marketplace?.total_ratings);
  const plural = (rating?.marketplace?.total_ratings || 0) > 1 ? 's' : '';

  return (
    <WrapSection
      label="Ratings"
      subHeading={tow ? tow?.name : ''}
      value={
        rating.marketplace?.stars
          ? parseFloat(`${rating.marketplace?.stars || 0}`).toFixed(1)
          : 'Not rated'
      }
      collapse={collapse}
    >
      <div>
        {jobHistory && (
          <JobHistoryModal
            dayHistory="all"
            onClose={() => setJobHistory(false)}
          />
        )}
        <Section>
          <Median>
            <MedianAlpha>
              My Company
              {' '}
              <small className="text-muted">
                {`${rating.my_company?.total_ratings || 'no '} reviews`}
              </small>
            </MedianAlpha>
            <MedianOmega alignRight>
              {rating.my_company?.stars ? (
                parseFloat(`${rating.my_company?.stars || 0}`).toFixed(1)
              ) : (
                <NotRated />
              )}
              {' '}
              {(rating?.my_company?.stars || 0) > 0 && (
                <Stars rating={rating.my_company?.stars || 0} color="green" />
              )}
            </MedianOmega>
          </Median>
        </Section>
        <Section>
          <Median>
            <MedianAlpha>
              Marketplace
              {' '}
              <small className="text-muted">
                {`${rating.marketplace?.total_ratings || 'no '} reviews`}
              </small>
            </MedianAlpha>
            <MedianOmega alignRight>
              {(rating.marketplace?.stars || 0) > 0 ? (
                parseFloat(`${rating.marketplace?.stars || 0}`).toFixed(1)
              ) : (
                <NotRated />
              )}
              {' '}
              {(rating.marketplace?.stars || 0) > 0 && (
                <Stars rating={rating.marketplace?.stars || 0} color="yellow" />
              )}
            </MedianOmega>
          </Median>
        </Section>
        <Section>
          <Padded>
            {hasRating && (
              <div className="text-xs-center">
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <Link onClick={() => setJobHistory(true)}>
                  {`See all ${rating?.marketplace?.total_ratings} rating${plural}`}
                </Link>
              </div>
            )}
          </Padded>
        </Section>
      </div>
    </WrapSection>
  );
};

Ratings.defaultProps = {
  collapse: false,
};

export default Ratings;
