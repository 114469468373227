import * as React from 'react';
import { Icon, v2 } from '@fieldnation/platform-components';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { timelinessDetails } from '../queries/timeliness-details.query';
import { backoutsTimelinessColumns, modalColumnComponents, renderColumn } from './utils';

import css from './timeliness-details.scss';

const { Listing } = v2;

const TimelinessList = (): JSX.Element => {
  const { results = [] } = useRecoilValue(timelinessDetails);

  const renderSuccessMessage = () => (
    <div className={classNames('text-xs-center', css['message-container'])}>
      <div className={classNames(css['message-success'], css['message-icon'])}>
        <Icon
          size={48}
          name="completeSolid"
        />
      </div>
      <div>
        <div className="u-padding-top--md">
          No inaccurate check-ins.
        </div>
        <span
          className="text-muted"
          style={{ fontSize: '1.2rem' }}
        >
          Arrived on time to all fixed-schedule work orders
        </span>
      </div>
    </div>
  );

  const columnLists = backoutsTimelinessColumns.filter(({ id }) => id !== 'assignedDatetime');
  const columnComponents = modalColumnComponents.filter(({ id }) => id !== 'assignedDatetime');

  if (results.length === 0) {
    return renderSuccessMessage();
  }

  return (
    <div className={css['timeliness-list-container']}>
      <Listing
        activeView="list"
        tableConfig={{
          data: results,
          columns: columnLists.map((column) => (
            {
              id: column.id,
              header: column.header,
              width: column.width || 150,
              render: (row) => renderColumn(column, row, columnComponents),
            }
          )),
        }}
        views={['list']}
      />
    </div>
  );
};

export default TimelinessList;
