import { useQuery } from 'react-query';
import { getUserData } from '../api';

import { MonolithProfile } from '../types.d';

export default function useProfile(userId?: number): MonolithProfile | null {
  if (!userId) {
    return null;
  }

  const profile = useQuery<MonolithProfile | null>(
    ['users', userId, 'v2_profile'],
    async () => {
      const resp: MonolithProfile = await getUserData(userId, {
        throwExceptions: true,
      });

      return resp;
    },
  );

  return profile.status === 'success' ? profile.data : null;
}
