import { atom } from 'recoil';

interface Screening {
  userId: number;
  screeningType: string;
  source: string;
}

const screeningAtom = atom<Screening>({
  key: 'screeningAtom',
  default: {
    userId: 0,
    screeningType: '',
    source: '',
  },
});

export default screeningAtom;
