import * as React from 'react';
import { Section } from '@fieldnation/platform-components';
import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';
import WrapListItem from '../Components/WrapListItem';
import WrapSection from './WrapSection';
import screeningsQuery from '../queries/screenings.query';

interface Props {
  collapse?: boolean;
}

const Screenings = ({
  collapse,
}: Props): JSX.Element => {
  const data = useRecoilValue(screeningsQuery);
  const screenings = data?.items || [];

  return (
    <WrapSection
      label="Screenings"
      value={`${screenings?.length}`}
      collapse={collapse}
    >
      <Section>
        {!screenings?.length && (
          <div className="text-muted u-padding-bottom--xs">
            <em>No completed Screenings</em>
          </div>
        )}

        {screenings?.map((screening, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`key${i}`} className="u-padding-bottom--xs">
            <WrapListItem
              label={screening?.package?.name || ''}
              value={
                screening?.updated_at
                  ? format(new Date(screening.updated_at), 'MMM yyyy')
                  : ''
              }
            />
          </div>
        ))}
      </Section>
    </WrapSection>
  );
};

Screenings.defaultProps = {
  collapse: false,
};

export default Screenings;
