import React, { useEffect, useState } from 'react';
import {
  Grid, GridColumn, Link, Loader, v2,
} from '@fieldnation/platform-components';
import css from './Styles.scss';
import { error, success } from '../legacy/flash-messenger';
import {
  Credential,
  CredentialFormProps,
  CredentialInputData,
  UserCredentialsDetails,
} from './types';
import { lcDefaultInputs } from './Store/query';
import {
  getCredentialDetailsById,
  getUserCredentialDetailsById,
} from './api';
import ClearLogo from './Svg/ClearLogo';
import TypeOfWorkSelection from './typeOfWorkSelection';
import { validateCredentialInputs } from './validation';
import {
  prepareUserCredentialUpdateData,
  trackAddCredentialsAmplitude,
  trackEditCredentialsAmplitude, upsertCredentials,
} from './util';
import PropTypes from 'prop-types';
import ErrorBlock from './Components/ErrorBlock';
import { useAmplitudeTracker } from '../Amplitude/useAmplitudeTracker';
import FileUpload from './Components/FileUpload';
import CredentialNumber from './Components/CredentialNumber';
import VerifiedCredentialFields from './Components/VerifiedCredentialFields';

const {
  DatePicker,
  Checkbox,
} = v2;

const CredentialForm = ({
  userId,
  triggerSubmit,
  selectedCredentialId,
  userCredentialId,
  onSubmitSuccessCallback,
  onClose,
  setParentLoading,
  source,
}: CredentialFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submit, setSubmit] = useState<any>(triggerSubmit);
  const [selectedCredential, setSelectedCredential] = useState<Credential>();
  const [lcInputs, setLcInputs] = useState<CredentialInputData>(lcDefaultInputs);
  const [lcValidation, setLcValidation] = useState<Partial<CredentialInputData>>({});

  const trackOnAmplitude = useAmplitudeTracker();

  useEffect(() => {
    if (userCredentialId) {
      fetchUserCredentialDetails();
    } else if(selectedCredentialId) {
      fetchCredentialDetails(Number(selectedCredentialId));
    }
  }, [selectedCredentialId, userCredentialId]);

  useEffect(() => {
    if (submit !== triggerSubmit) {
      handleSubmitCredential();
    }
  }, [triggerSubmit, submit])

  useEffect(() => {
    if (setParentLoading) {
      setParentLoading(loading);
    }
  }, [loading, setParentLoading])

  // ********************* API calls *********************
  const handleSubmitCredential = async (): Promise<void> => {
    setSubmit(!submit);
    setLcValidation({});

    const isLCFormInValid = validateCredentialInputs(lcInputs);
    if (Object.keys(isLCFormInValid).length) {
      setLcValidation(isLCFormInValid);
      return;
    }

    const resp = await upsertCredentials(
      lcInputs,
      userId,
      (val) => setLoading(val),
    );

    if (resp?.id && resp?.id > 0) {
      if (lcInputs.isEdit) {
        success('Credential updated successfully', 'Success');
        trackEditCredentialsAmplitude(trackOnAmplitude, selectedCredential, lcInputs, source);
      } else {
        success('Credential added to the profile successfully', 'Success');
        trackAddCredentialsAmplitude(trackOnAmplitude, selectedCredential, lcInputs, source);
      }

      onSubmitSuccessCallback?.({ ...lcInputs }, resp);
      onClose?.();
    } else {
      if (resp !== null) {
        error('An error occurred while saving credentials', 'Error');
      }
    }
  }

  const fetchCredentialDetails = async (
    credentialId: number,
    states: Partial<CredentialInputData> = {},
  ) => {
    setLoading(true);
    const resp: Credential = await getCredentialDetailsById(Number(credentialId));
    if (resp) {
      setLcInputs({
        ...lcInputs,
        ...states,
        credential: resp,
        credentialId: resp?.id,
      });
      setSelectedCredential(resp);
    } else {
      error('Something Went Wrong', 'Error');
      onClose?.();
    }
    setLoading(false);
  };

  const fetchUserCredentialDetails = () => {
    setLoading(true);
    getUserCredentialDetailsById(Number(userCredentialId), Number(userId))
      .then((resp: UserCredentialsDetails) => {
        setLoading(false);
        if (resp?.id) {
          fetchCredentialDetails(
            Number(resp?.credential?.id || 0),
            prepareUserCredentialUpdateData(resp)
          );
        } else {
          error('Can\'t load credential details', 'Error');
          onClose?.();
        }
      }).catch(() => {
      setLoading(false);
      error('Can\'t load credential details', 'Error');
      onClose?.();
    });
  }

  return (
    <Loader isLoading={loading} size="md">
      {!selectedCredential && <div style={{ minHeight: '4vh' }} />}
      <div className={css.lcFormWrapper}>
        {/*state issued, license number and custom fields*/}
        {!!selectedCredential?.verifiedOn && <VerifiedCredentialFields
          lcInputs={lcInputs}
          setLcInputs={setLcInputs}
          selectedCredential={selectedCredential}
          lcValidation={lcValidation}
          setLoading={setLoading}
          isEdit={!!userCredentialId}
        />}

        {/*Credential Number for non in-demand */}
        {selectedCredential && !selectedCredential?.verifiedOn && (<Grid>
          <GridColumn xs="12">
            <CredentialNumber
              credentialType={selectedCredential?.type}
              value={lcInputs.credentialIdNumber}
              validation={lcValidation?.credentialIdNumber}
              onChange={(v) => (setLcInputs({
                ...lcInputs,
                credentialIdNumber: v,
              }))}
            />
          </GridColumn>
        </Grid>)}

        {selectedCredential && !selectedCredential?.verifiedOn && (<Grid>
          {/*issue date*/}
          <GridColumn xs="12" md="6">
            <div className={css.inputFields}>
              <DatePicker
                  label="Issue date"
                  value={lcInputs.issuedAt || ''}
                  onChange={(value) => {
                    setLcInputs({
                      ...lcInputs,
                      issuedAt: value.trim(),
                    });
                  }}
              />
              <ErrorBlock msg={lcValidation?.issuedAt} />
            </div>
          </GridColumn>

          {/*expire date*/}
          <GridColumn xs="12" md="6">
            <div className={css.inputFields}>
              <DatePicker
                  label="Expiration date"
                  disabled={lcInputs.noExpireDate}
                  value={lcInputs.expiresAt || ''}
                  onChange={(value) => {
                    setLcInputs({
                      ...lcInputs,
                      expiresAt: value.trim(),
                    });
                  }}
              />
              <Checkbox
                options={[{ label: `This ${lcInputs?.credential?.type} does not expire`, value: 1 }]}
                value={lcInputs?.noExpireDate ? [1] : [null]}
                onChange={(v) => {
                  setLcInputs({
                    ...lcInputs,
                    noExpireDate: !!v?.[1],
                  });
                }}
              />
              <ErrorBlock msg={lcValidation?.expiresAt} />
            </div>
          </GridColumn>
        </Grid>)}

        {selectedCredential && !selectedCredential?.verifiedOn && <FileUpload
          onSetLoading={(val) => setLoading(val)}
          userId={userId}
          validation={lcValidation?.file}
          onChange={(val) => setLcInputs({
            ...lcInputs,
            file: val || '',
          })}
        />}

        {/*type of work*/}
        {!!selectedCredential?.verifiedOn && !lcInputs?.isEdit && (<Grid>
          <GridColumn xs="12">
            <div className={css.inputFields}>
              <TypeOfWorkSelection
                  title='Applicable types of work'
                  subtitle='Select all types of work associated with this license number.'
                  errorMsg={lcValidation?.typesOfWorks as string}
                  callBack={(item) => {
                    setLcInputs({
                      ...lcInputs,
                      typesOfWorks: item,
                    });
                  }} />
            </div>
          </GridColumn>
        </Grid>)}

        {/*clear verification*/}
        {!!selectedCredential?.verifiedOn && <Grid>
          <div className={css.clearWrapper}>
            <GridColumn xs="12" md="9">
              <p className={css.clearInfoText}>
                Field Nation partners with CLEAR as part of our professional credential verification process. By saving this license or certification information,
                you consent to CLEAR obtaining information about your relevant professional credentials.
                See CLEAR
                {' '}
                <Link href="https://www.clearme.com/privacy-policy" target="_blank">Privacy Policy</Link>
                .
              </p>
            </GridColumn>
            <GridColumn xs="12" md="3">
              <div className={css.clearLogoWrapper}>
                <ClearLogo width={130} height={52} />
              </div>
            </GridColumn>
          </div>
        </Grid>}
      </div>
    </Loader>
  );
};

CredentialForm.propTypes = {
  userId: PropTypes.number.isRequired,
  triggerSubmit: PropTypes.bool.isRequired,
  selectedCredentialId: PropTypes.string,
  source: PropTypes.string,
  userCredentialId: PropTypes.number,
  onSubmitSuccessCallback: PropTypes.func,
  setParentLoading: PropTypes.func,
  onClose: PropTypes.func,
};

CredentialForm.defaultProps = {
  onClose: () => {},
  onSubmitSuccessCallback: () => {},
  userCredentialId: 0,
};
export default CredentialForm;
