import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { SmartAddressWidget } from '../../../SmartAddress';
import ActionBar from '../ActionBar';
import { contentInputState, sectionContentsState } from '../../States/AtomFamilies';
import { onChangeSmartAddress, onCloseSection, onSaveSmartAddress } from '../../States/Callbacks';
import { useAddressInputAttribute, useCaEscrowWarning } from '../../States/Hooks';
import css from './styles.scss';
import { IProps } from './types.d';
import { ContentKey } from '../Section/types.d';
import { useAmplitudeTracker } from '../../../Amplitude/useAmplitudeTracker';

const SmartAddress: React.FC<IProps> = ({
  sectionKey,
  onEditConfig,
  sectionDisplayName,
}: IProps): JSX.Element => {
  const sectionContents = useRecoilValue(sectionContentsState(sectionKey));
  const inputStates = sectionContents.map(
    (content) => useRecoilValue(contentInputState(content.key as ContentKey)),
  );
  const onChange = useRecoilCallback(onChangeSmartAddress);
  const onClose = useRecoilCallback(onCloseSection);
  const onSave = useRecoilCallback(onSaveSmartAddress);
  const trackOnAmplitude = useAmplitudeTracker();

  const address1 = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_ADDRESS1)?.value || '';
  const address2 = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_ADDRESS2)?.value || '';
  const city = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_CITY)?.value || '';
  const state = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_STATE)?.value || '';
  const zip = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_ZIP)?.value || '';
  const country = inputStates.find((content) => content.key === ContentKey.COMPANY_OVERVIEW_COUNTRY)?.value || '';

  const { inputAttribute, canSubmit } = useAddressInputAttribute({
    address1,
    address2,
    city,
    state,
    zip,
    country,
  });

  const isCountryDisabled = inputAttribute?.country?.disabled || false;
  const actionBarStyling = isCountryDisabled ? 'double' : 'simple';
  useCaEscrowWarning(state);

  return (
    <div className={css['smart--address']}>
      <SmartAddressWidget
        values={{
          address1,
          address2,
          city,
          state,
          zip,
          country,
        }}
        onChange={(address) => onChange(sectionKey, address)}
        inputAttribute={inputAttribute}
      />
      <div className={css[`smart--address--actionbar--${actionBarStyling}`]}>
        <ActionBar
          onSave={() => onSave(sectionKey, onEditConfig, sectionDisplayName, trackOnAmplitude)}
          onClose={() => onClose(sectionKey)}
          isOnSaveDisabled={!canSubmit}
        />
      </div>
    </div>
  );
};

export default SmartAddress;
