import React from 'react';
import { Button } from '@fieldnation/platform-components';
import { IProps } from './types.d';
import css from './styles.scss';

const EditButton = ({
  disabledContent,
  onClick,
  isDisabled = false,
}: IProps): JSX.Element => (
  <div className={css['edit--button']}>
    {isDisabled ? (
      <div className={css['edit--disabled--content']}>{disabledContent}</div>
    ) : (
      <Button
        icon="edit"
        onClick={onClick}
        key="edit"
        shouldUseIconComponent
        type="text"
        className={css['edit--button--icon']}
      />
    )}
  </div>
);

export default EditButton;
