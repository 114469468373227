import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  // @ts-ignore
  FieldLabel as Label,
  v2,
  Button,
} from '@fieldnation/platform-components';
import css from './ExampleComponent.scss';

const { TextInput, TextArea } = v2;

const ExampleComponent = ({
  id,
  nameLabel,
  nameOnChange,
  nameValue,
  aboutLabel,
  aboutAdditionalInfo,
  aboutDisabled,
  aboutOnChange,
  aboutValue,
  addressLabel,
  addressAdditionalInfo,
  addressDisabled,
  addressOnChange,
  addressValue,
  submitLabel,
}) => (
  <div id={id}>
    <TextInput
      label={nameLabel}
      value={nameValue}
      onChange={nameOnChange}
    />
    <TextArea
      label={aboutLabel}
      additionalInfo={aboutAdditionalInfo}
      value={aboutValue}
      disabled={aboutDisabled}
      onChange={aboutOnChange}
    />
    <Label
      help={addressAdditionalInfo}
    >
      {addressLabel}
    </Label>
    <textarea
      disabled={addressDisabled}
      onChange={addressOnChange}
      className={classNames(css.TextArea, css['TextArea--customize'])}
      value={addressValue}
    />
    <Button
      label={submitLabel}
      icon="icon-back-arrow"
      type="primary"
      onClick={() => {}}
    />
  </div>
);

ExampleComponent.propTypes = {
  id: PropTypes.string,
  nameLabel: PropTypes.string,
  nameOnChange: PropTypes.func.isRequired,
  nameValue: PropTypes.string,
  aboutLabel: PropTypes.string,
  aboutAdditionalInfo: PropTypes.string,
  aboutDisabled: PropTypes.bool,
  aboutOnChange: PropTypes.func.isRequired,
  aboutValue: PropTypes.string,
  addressLabel: PropTypes.string,
  addressAdditionalInfo: PropTypes.string,
  addressDisabled: PropTypes.bool,
  addressOnChange: PropTypes.func.isRequired,
  addressValue: PropTypes.string,
  submitLabel: PropTypes.string,
};

ExampleComponent.defaultProps = {
  id: undefined,
  nameLabel: 'Name',
  nameValue: '',
  aboutLabel: 'About',
  aboutAdditionalInfo: '',
  aboutDisabled: false,
  aboutValue: '',
  addressLabel: 'Address',
  addressAdditionalInfo: '',
  addressDisabled: false,
  addressValue: '',
  submitLabel: 'Submit',
};

export default ExampleComponent;
