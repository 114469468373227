/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GroupCardListItem } from '@fieldnation/platform-components';
import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import BackoutsDetailsModal from './BackoutsDetailsModal';
import providerCommitmentQuery from '../queries/provider-commitment.query';
import css from './Rating.scss';
import performance, { hasShowPerformanceTile, performanceFilter } from '../queries/performance.query';
import { userIdQuery } from '../queries/user.query';
import trackAmplitudeEvent, { VIEWED_RELIABILITY_DETAILS } from '../amplitude';

const AssignmentCommitment: React.FC = () => {
  const providerCommitment = useRecoilValue(providerCommitmentQuery);
  const performanceFilters = useRecoilValue(performance);
  const performanceFilterValue = useRecoilValue(performanceFilter);
  const [backoutsModal, setBackoutsModal] = useState(false);
  const userId = useRecoilValue(userIdQuery);

  const showPerformanceTile = hasShowPerformanceTile();
  const commitmentDays = Number(performanceFilterValue?.dayHistory);

  const getPerformanceFilters = () => ({
    dayHistory: !showPerformanceTile
      ? commitmentDays
      : Number(performanceFilters?.filters?.dayHistory) || 0,
    pastHistory: Number(performanceFilters?.filters?.woHistory) || 0,
    companyId: performanceFilters?.filters?.withCompanyId || 0,
    graphType: 'monthly',
  });

  const { numeratorCount = 0, denominatorCount = 0 } = providerCommitment?.commitmentStats || {};

  const value = providerCommitment?.showCommitmentPercent && denominatorCount > 4 ? (
    `${providerCommitment?.commitmentPercent?.toFixed(1)}%`
  ) : (
    <span className={classNames('text-muted', css['timeliness-sub-text'])}>not enough data</span>
  );

  return (
    <div className="u-padding-bottom--sm">
      {!!backoutsModal && (
        <BackoutsDetailsModal
          backoutsFilters={{ ...getPerformanceFilters() }}
          userId={userId || 0}
          onClose={() => setBackoutsModal(false)}
        />
      )}
      <div className={css['timeliness-wrapper']}>
        <GroupCardListItem
          hideExpander
          dottedLine
          middleContent=""
          label="Assignment Completion"
          // @ts-ignore
          data={<span className={css['semi-bold']}>{value}</span>}
          type="default"
          border="none"
        />
      </div>
      <div className={classNames('text-muted', css['timeliness-sub-text'])}>
        {denominatorCount > 4 && (
          <span>
            {numeratorCount}
            &nbsp;of&nbsp;
            {denominatorCount}
            &nbsp;
          </span>
        )}
        <span>Assigned work orders completed</span>
      </div>
      <div>
        <button
          className={css['see-commitment-link']}
          type="button"
          onClick={() => {
            const match = window.location.pathname.match(
              /workorders\/(\d)+\/providers/g,
            );
            setBackoutsModal(true);
            trackAmplitudeEvent({
              category: VIEWED_RELIABILITY_DETAILS,
              ampProps: {
                Source: match?.length ? 'Provider Drawer' : 'Profile',
                Type: 'Assignment Completion',
                Score: providerCommitment?.showCommitmentPercent
                  ? providerCommitment?.commitmentPercent?.toFixed(1)
                  : 'not enough data',
              },
            });
          }}
        >
          <span className={css['timeliness-sub-text']}>
            See cancellations / removals
          </span>
        </button>
      </div>
    </div>
  );
};

export default AssignmentCommitment;
