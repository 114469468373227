import { MonoTaxObject, Tax } from "./types";

export const taxEntityOptions = [
  { label: 'Individual / Sole Proprietor / Single Member LLC', value: 'I' },
  { label: 'C Corporation', value: 'C' },
  { label: 'S Corporation', value: 'S' },
  { label: 'Partnership', value: 'P' },
  { label: 'Trust / Estate', value: 'T' },
  { label: 'Limited Liability Company', value: 'LL' },
  { label: 'Other', value: 'O' },
];

export const llcTaxClassOptions = [
  { label: 'C Corporation', value: 'LC' },
  { label: 'S Corporation', value: 'LS' },
  { label: 'Partnership', value: 'LP' },
];

export const taxIDOptions = [
  { label: 'EIN', value: 'ein' },
  { label: 'SSN', value: 'ssn' },
];

export const getCountryStates = (countryCode: string) => {
  // eslint-disable-next-line no-underscore-dangle
  const countries = window?._COUNTRIES || [];
  if (countries) {
    const country = countries.find((ele: any) => ele.iso === countryCode);
    if (country) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      return country.state?.['values'];
    }
    return [];
  }
  return [];
}

export const getMappedTaxData = (tax: MonoTaxObject): Tax => {
  let entityType: any = { label: undefined, value: undefined };
  if (tax.entity_type && tax.entity_type.length === 2) {
    entityType = taxEntityOptions.find((e) => e.value === 'LL');
  } else if (tax.entity_type && tax.entity_type.length === 1) {
    entityType = taxEntityOptions.find((e) => e.value === tax.entity_type);
  }
  const dataTaxId = tax.has_ein ? taxIDOptions[0] : taxIDOptions[1];
  const mappedData: Tax = {
    selectedTaxId: typeof (tax.has_ein) === 'number' || typeof (tax.has_ein) === 'boolean'
      ? dataTaxId?.value
      : '',
    selectedTaxType: tax.entity_type
      ? entityType?.value || ''
      : '',
    taxClassOfLLC: tax.entity_type && tax.entity_type.length === 2
      ? llcTaxClassOptions.find((e) => e.value === tax.entity_type) : '',
    electronic1099: typeof (tax.has_ein) === 'number' || typeof (tax.has_ein) === 'boolean'
      ? tax.electronic_consent
      : false,
    companyName: tax.name || '',
    state: tax?.state || '',
    zip: tax.zip || '',
    addressLine1: tax.address1 || '',
    addressLine2: tax.address2 || '',
    city: tax.city || '',
    country: tax?.country || '',
    tinPlaceHolder: tax.tin,
    ssnName: `${tax.first_name} ${tax.last_name}`,
    name: tax.name || '',
    hasExistingTin: !!tax.tin,
    taxVerified: tax.technician_w9_status_id === 3,
    hasEin: !!tax.has_ein || false,
    doingBuisnessAs: tax?.business_name || '',
    otherEntityReason: tax?.other || '',
  };

  return mappedData;
}