import * as React from 'react';
import classNames from 'classnames';
import ProviderNotes from '../../ProviderNotes';
import WrapSection from './WrapSection';

interface Props {
  workorderId?: number | undefined;
  userId: number | undefined;
  collapse?: boolean;
}

const ProviderNotesWrapper = ({
  workorderId, userId, collapse,
}: Props): JSX.Element | null => {
  if (!userId) {
    return null;
  }

  return (
    <WrapSection
      label="Notes"
      value=""
      collapse={collapse}
      fullWidth
    >
      <div className={classNames('u-margin-left--md', 'u-margin-right--md')}>
        <ProviderNotes workorderId={workorderId} userId={userId} type={workorderId ? 'Workorder' : 'Profile'} />
      </div>
    </WrapSection>
  );
};

ProviderNotesWrapper.defaultProps = {
  collapse: false,
  workorderId: undefined,
};

export default ProviderNotesWrapper;
