/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import {
  AbstractContainer,
  ActionBar,
} from '@fieldnation/platform-components';

interface IProps {
  actions?: any[];
  size?: string;
  tertiary?: any,
}

const Footer = ({
  actions,
  size,
  tertiary,
}: IProps): JSX.Element => (
  <AbstractContainer
    borderTop
    disablePadding
    backgroundColor="grey30"
  >
    <ActionBar
      size={size}
      footerActions={actions}
      tertiaryAction={
        (tertiary && tertiary.action)
          ? {
            icon: tertiary.action.icon,
            onClick: tertiary.action.onClick,
            disabled: tertiary.action.disabled,
          }
          : undefined
    }
      tertiaryText={tertiary ? tertiary.text : undefined}
    />
  </AbstractContainer>
);

Footer.defaultProps = {
  actions: [],
  size: null,
  tertiary: null,
};

export default Footer;
