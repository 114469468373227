import React from 'react';
import { Grid, GridColumn } from '@fieldnation/platform-components';
import { IContainerProps, IProps } from './types.d';
import { GridSize } from '../../Commons/types.d';

const AttributeContainer: React.FC<IContainerProps> =
({ node, index }: IContainerProps): JSX.Element => (
  <div key={`${index + 1}`}>
    {node}
  </div>
);

const getContainer = (content) => (
  [content].flat().map((node, index) => (
    <AttributeContainer
      key={`attribute_container_${index + 1}`}
      index={index}
      node={node}
    />
  ))
);

const Attribute: React.FC<IProps> = ({ label, content }: IProps): JSX.Element => {
  const LabelContainer = getContainer(label);
  const ContentContainer = getContainer(content);

  return (
    <div>
      <Grid>
        <GridColumn
          xs={GridSize.LARGE}
          sm={GridSize.LARGE}
          md={GridSize.SMALL}
          lg={GridSize.SMALL}
          xl={GridSize.SMALL}
        >
          {LabelContainer}
        </GridColumn>
        <GridColumn
          xs={GridSize.LARGE}
          sm={GridSize.LARGE}
          md={GridSize.MEDIUM}
          lg={GridSize.MEDIUM}
          xl={GridSize.MEDIUM}
        >
          {ContentContainer}
        </GridColumn>
      </Grid>
    </div>
  );
};

export default Attribute;
