import { FileInput } from '@fieldnation/platform-components';
import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { FileType } from '../../Commons/types.d';
import { sectionFilesState } from '../../States/AtomFamilies';
import { onDeleteSectionFile, onDropSectionFile } from '../../States/Callbacks';
import css from './styles.scss';
import { IProps } from './types.d';

const getHelpText = (acceptFiles: FileType[]): string => {
  const acceptFilesText = acceptFiles as string[];
  let helpText = 'All document and image file types supported';
  const parsedTexts: string[] = [];
  acceptFilesText.forEach((text) => {
    parsedTexts.push(text.split('/')[1]?.toUpperCase());
  });
  if (acceptFiles.length) {
    helpText = `Supported files types: ${parsedTexts.join(', ')}`;
  }
  return helpText;
};

const FileUpload: React.FC<IProps> = (
  { sectionKey, acceptFiles, multiple }: IProps,
): JSX.Element => {
  const onDrop = useRecoilCallback(onDropSectionFile);
  const onDeleteFile = useRecoilCallback(onDeleteSectionFile);
  return (
    <div className={css['file--upload']}>
      <FileInput
        buttonLabel="Upload"
        accept={acceptFiles}
        helpText={getHelpText(acceptFiles)}
        multiple={multiple}
        onDrop={(files) => onDrop(sectionKey, files)}
        files={useRecoilValue(sectionFilesState(sectionKey))}
        onDeleteFile={(file) => onDeleteFile(sectionKey, file)}
      />
    </div>
  );
};

export default FileUpload;
