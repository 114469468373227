import { selector } from 'recoil';
import { getUserScreeningsData } from '../api';
import contextQuery from './context.query';
import userQuery from './user.query';
import isAdminUser from '../Components/is-admin-user';

import { BackgroundCheckItem, MonolithScreenings } from '../types.d';

interface Screenings {
  items?: BackgroundCheckItem[];
  backgroundChecks: MonolithScreenings;
  providerId?: number;
  isProvider?: boolean;
  isBuyer?: boolean;
  scAdmin?: boolean;
  sendFreeScreening?: boolean;
  isViewingAdminsProfile: boolean;
}

const screeningsQuery = selector<Screenings | null>({
  key: 'screenings',
  get: async ({ get }) => {
    const context = get(contextQuery);
    const user = get(userQuery);

    if (!context || !user) {
      return null;
    }

    const screenings = await getUserScreeningsData(user.id);

    const experiments = context?.experiments?.results || [];
    const hasTestComp = Boolean(
      experiments?.find(({ id, value }) => id === 42 && Boolean(value)),
    );
    const isViewingAdminsProfile = isAdminUser(context, user);

    return {
      items: screenings.results,
      backgroundChecks: user?.background_checks || {},
      providerId: user?.id || 0,
      isProvider: context?.group?.id === 1,
      isBuyer: Boolean([2, 7, 8].includes(context?.group?.id || 0)),
      scAdmin:
        context?.group?.id === 2
        && user?.company?.id === context?.company?.id,
      sendFreeScreening: hasTestComp || false,
      isViewingAdminsProfile,
    };
  },
});

export default screeningsQuery;
