/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import {
  BodyText,
  DialogueModal,
  Link,
  Median,
  MedianAlpha,
  MedianOmega,
} from '@fieldnation/platform-components';
import get from 'lodash/get';
import css from '../styles.scss';
import {
  NotificationFlashAlert,
  StandardGridLayout,
} from '../../../components';
import { ConnectedAccountProps } from './types.d';
import { disConnectSocialAccount } from '../../../api';

const checkIfConnected = (method: string): boolean => {
  const connectedAccounts = get(window, 'connectedAccounts', []);

  return connectedAccounts.some((acc) => acc.method.toLowerCase() === method.toLowerCase());
};

export const ConnectedAccounts = (props: ConnectedAccountProps): JSX.Element => {
  const { method } = props;
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [isConnected, setIsConnected] = useState(checkIfConnected(method));
  const [showErrorModal, setShowErrorModal] = useState(false);

  const itemName = (
    <div className={css['list-item-name-container']}>
      <BodyText styleLevel="mdSemiBold" tag="span">
        <span className={css['list-item-name']}>
          {isConnected ? 'Connected ' : 'Connect '}
          to
          {' '}
          {method}
        </span>
      </BodyText>
    </div>
  );

  const onConfirm = async () => {
    const disconnect = await disConnectSocialAccount(method);

    if (disconnect) {
      setIsConnected(false);
      NotificationFlashAlert({
        level: 'success',
        title: 'Account Disconnected',
        message: `An email has been sent to you at ${get(window, 'context.email')} to reflect this update.`,
      });
      window.location.href = '/logout';
    } else {
      setShowErrorModal(true);
    }
    setShowDisconnectModal(false);
  };
  const onCancel = () => {
    setShowDisconnectModal(false);
  };
  const onCloseDisconnectConfirmation = (confirm: boolean) => (confirm ? onConfirm() : onCancel());
  const action = (
    isConnected ? (
      <>
        <MedianOmega>
          <Link
            onClick={() => { setShowDisconnectModal(true); }}
          >
            (Disconnect)
          </Link>
        </MedianOmega>
        <DialogueModal
          positiveText="Confirm"
          negativeText="Cancel"
          header={`Disconnect your ${method} account?`}
          isOpen={showDisconnectModal}
          onClose={onCloseDisconnectConfirmation}
        >
          You will be logged out and you will only be able to log in with your username and password
          unless you’ve connected to another social account.
        </DialogueModal>
        <DialogueModal
          positiveText="Ok"
          header={`Couldn't disconnect from your ${method} ID`}
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
        >
          There was a problem when disconnecting your account. Please try again.
        </DialogueModal>
      </>
    ) : (
      <BodyText styleLevel="md" tag="span">
        <span className={css['list-item-action']}>
          No connected account
        </span>
      </BodyText>
    )
  );

  const itemContent = (
    <div className={css['list-item-content-container']}>
      <Median verticalAlign="top">
        <MedianAlpha>
          <BodyText styleLevel="md" tag="span">
            <span className={css['list-item-content']}>
              Connect an account to log in to Field Nation with
              {' '}
              {method}
            </span>
          </BodyText>
        </MedianAlpha>
        {action}
      </Median>
    </div>
  );

  return <StandardGridLayout itemName={itemName} itemContent={itemContent} />;
};
