import * as React from 'react';
import { Icon, IconAside, IconAsideIcon, v2 } from '@fieldnation/platform-components';
import css from './Styles.scss';

const {
  Checkbox,
  TextInput,
} = v2;

const attrOptions = [
  'General Low Voltage Cabling',
  'CCTV / Surveillance',
  'Access Control',
  'Burglar Alarm',
  'Fire Alarm',
  'Other',
].map(option => ({ label: option, value: option }));

interface AttributeSelectionProps {
  title: string;
  subtitle: string;
  errorMsg?: string;
  callBack: (items: string[]) => void;
}

const TypeOfWorkSelection = ({
  title,
  subtitle,
  errorMsg,
  callBack,
}: AttributeSelectionProps) => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [showOtherLicense, setShowOtherLicense] = React.useState<boolean>(false);
  const [otherLicense, setOtherLicense] = React.useState<string>('');

  const onChangeItems = (items: string[]) => {
    setShowOtherLicense(items?.includes('Other'));
    setSelectedItems(items);

    let licences:string[] = items.filter((name: string) => name !== 'Other');

    if (items?.includes('Other') && otherLicense !== '') {
      licences = [...licences, otherLicense];
    } else if (items?.includes('Other') && otherLicense === '') {
      licences = [...licences, 'Other'];
    }

    callBack(licences);
  };

  React.useEffect(() => {
    let licences:string[] = selectedItems.filter((name: string) => name !== 'Other');

    if (otherLicense !== '') {
      licences = [...licences, otherLicense];
    } else if (selectedItems?.includes('Other') && otherLicense === '') {
      licences = [...licences, 'Other'];
    }

    callBack(licences);
  }, [otherLicense]);

  return (
    <div className="attribute-selection-container u-margin-top--md u-margin-bottom--md">
      <div className="u-margin-top--md">
        <strong>{title}</strong>
      </div>
      <div className="text-muted">
        <small>{subtitle}</small>
      </div>
      { errorMsg && (
        <IconAside iconPosition="left" iconColor="red">
          <IconAsideIcon verticalAlign="middle">
            <Icon name="alertSolid" size={18} block />
          </IconAsideIcon>
          <div className={css.validationErrorText}>
            {errorMsg}
          </div>
        </IconAside>
      )}
      <div className="u-padding-left--sm">
        <div className="u-margin-top--lg">
          <Checkbox
            options={attrOptions}
            value={selectedItems}
            onChange={onChangeItems}
          />
        </div>
        {showOtherLicense && (
          <div className="selected-input">
            <TextInput
              label="Please specify"
              value={String(otherLicense)}
              onChange={(e) => setOtherLicense(e.target.value)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TypeOfWorkSelection);
