import React, {
  useState,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { v2 } from '@fieldnation/platform-components';
import UbiHeader from '../UbiForm/Header';
import useUbiFormHook from '../UbiForm/hooks/ubiFormHook';
import UbiInputFields from '../UbiForm/InputFields';
import { WaWorkerCompEventSource, WaWorkerCompEvents } from '../UbiForm/tracker/types.d';
import { UbiModalProps } from './types.d';
import { trackOpenAndClosedEvent } from '../UbiForm/tracker/amplitude';
import { VerificationStatusEnum } from '../UbiForm/types.d';
import VerificationStatus from '../UbiForm/VerificationStatus';

const { Modal } = v2;

const UbiModal = ({ source = WaWorkerCompEventSource.REQUEST_WO }: UbiModalProps): JSX.Element => {
  const {
    maintainsBooksAndRecords,
    filesIRSExpense,
    hasUbi,
    ubiNumber,
    postingData,
    saveUbiData,
    verificationStatus,
    currentStatus,
    onChangeMaintainRecords,
    onChangeFilesWithIrs,
    onChangeHasUbi,
    onChangeUbiNumber,
    submissionEnabled,
    onChangeVerificationStatus,
  } = useUbiFormHook(source);

  const [isOpen, setIsOpen] = useState(true);

  const onClose = useCallback(() => {
    setIsOpen(false);
    trackOpenAndClosedEvent(WaWorkerCompEvents.CLOSED_WA_L_AND_I_FORM, source);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    window?._fnRedux?.dispatch?.({
      type: 'APP_TOGGLE_OFF',
      flag: 'show_ubi_modal',
    });
  }, []);

  useEffect(() => {
    trackOpenAndClosedEvent(WaWorkerCompEvents.OPENED_WA_L_AND_I_FORM, source);
  }, []);

  const onContinue = useCallback(() => {
    onClose();
    if (submissionEnabled) {
      saveUbiData();
    }
  }, [onClose, saveUbiData, submissionEnabled]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      header={<UbiHeader isModal />}
      footerActions={[
        {
          label: 'Continue',
          type: 'primary',
          onClick: onContinue,
          hideFooter: false,
          disabled: postingData,
        },
      ]}
      headerDivider
      isOpen={isOpen}
      onClose={onClose}
      type="secondary"
      size="large"
      hideFooter={false}
    >
      <UbiInputFields
        maintainsBooksAndRecords={maintainsBooksAndRecords}
        filesIRSExpense={filesIRSExpense}
        hasUbi={hasUbi}
        ubiNumber={ubiNumber}
        verificationStatus={verificationStatus}
        onChangeHasUbi={onChangeHasUbi}
        onChangeUbiNumber={onChangeUbiNumber}
        onChangeMaintainRecords={onChangeMaintainRecords}
        onChangeFilesWithIrs={onChangeFilesWithIrs}
        onChangeVerificationStatus={onChangeVerificationStatus}
        isModal
      />
      <VerificationStatus
        verificationStatus={currentStatus as VerificationStatusEnum}
      />
    </Modal>
  );
};

export default memo(UbiModal);
