import { Loader } from '@fieldnation/platform-components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { sectionCardIsLoading } from '../../States/AtomFamilies';
import CreateActionBar from '../CreateActionBar';
import CreateSection from '../CreateSection';
import EmptySection from '../EmptySection';
import Section from '../Section/Section';
import { SectionCardMode } from '../Section/types.d';
import SectionDescription from '../SectionDescription';
import Wrapper from '../Wrapper';
import { IProps } from './types.d';

const SectionCardContent: React.FC<IProps> = ({
  description,
  cardMode,
  title,
  cardKey,
  sections,
  windowReference,
}: IProps): JSX.Element => {
  const isLoading = useRecoilValue(sectionCardIsLoading(cardKey));
  return (
    <Loader isLoading={isLoading} size="md">
      <Wrapper useDescription={!!description}>
        {description
        && <SectionDescription description={description} /> }
        {cardMode === SectionCardMode.EMPTY
        && <EmptySection label={title} cardKey={cardKey} /> }
        {cardMode === SectionCardMode.INTERACTIVE
        && sections.map((section) => (
          <Section
            key={section.key}
            section={section}
            cardKey={cardKey}
            windowRef={windowReference}
          />
        ))}
        {cardMode === SectionCardMode.CREATE
        && sections.map((section) => (
          <CreateSection
            key={section.key}
            section={section}
            cardKey={cardKey}
          />
        ))}
        {cardMode === SectionCardMode.CREATE
        && (
        <CreateActionBar
          cardKey={cardKey}
          windowRef={windowReference}
          sectionCardDisplayName={title}
        />
        )}
      </Wrapper>
    </Loader>
  );
};

export default SectionCardContent;
