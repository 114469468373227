import { selector } from 'recoil';
import { IReliabilityScore } from '../../ProviderReliability/types.d';
import userQuery from '../queries/user.query';
import { getProviderReliabilityScores } from '../api';

const reliabilityQuery = selector<IReliabilityScore>({
  key: 'provider-reliability-scores',
  get: async ({ get }) => {
    const user = get(userQuery);
    if (!user) {
      return {};
    }
    const reliabilityScores = await getProviderReliabilityScores(Number(user?.id));
    return reliabilityScores || {};
  },
});

export default reliabilityQuery;
