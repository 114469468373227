import React, { ReactNode, useMemo, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { IFullAddressInputValues, IJsObject, IUseAddressInputAttribute } from '../Commons/types.d';
import InputValidationContent from '../Components/InputValidationContent';
import SupportText from '../Components/SupportText';
import { companyState } from './Atoms';
import userPermissionState from './Selectors';

const getInvalidContent = (message?: string) => {
  if (message) {
    return (
      <InputValidationContent validation={{ isValid: false, message: `Invalid ${message.toLowerCase()}` }} />
    );
  }
  return undefined;
};

export function useCountryAndState(
  countryName: string,
  stateName: string,
): { country: IJsObject; state: IJsObject } {
  return useMemo(() => {
    const country = (window?._COUNTRIES || []).find(
      ({ iso }) => iso === countryName,
    ) || {
      name: countryName,
    };

    const state = (country?.state?.values || []).find(
      ({ value }) => value === stateName,
    ) || {
      label: stateName,
    };

    return { country, state };
  }, [countryName, stateName]);
}

export function useAddressInputAttribute(
  currentInputValue: IFullAddressInputValues,
): IUseAddressInputAttribute {
  const { isStaff } = useRecoilValue(userPermissionState);
  const initialCountry = useRecoilValue(companyState)?.country || '';
  const { country } = useCountryAndState(
    currentInputValue?.country,
    currentInputValue?.state,
  );

  return useMemo<IUseAddressInputAttribute>(() => {
    const isCountryDisabled = initialCountry && !isStaff;
    let canSubmit = true;
    let countryContent: ReactNode;

    let address1Content: string | undefined;
    let address2Content: string | undefined;
    let cityContent: string | undefined;
    let stateContent: string | undefined;
    let zipContent: string | undefined;

    if (isCountryDisabled) {
      countryContent = <SupportText label="country" />;
    }

    if (!currentInputValue?.country) {
      canSubmit = false;
      countryContent = <InputValidationContent validation={{ isValid: true, message: 'hello' }} />;
    }

    if (country?.address1?.required && !currentInputValue?.address1) {
      canSubmit = false;
      address1Content = country?.address1?.label || 'address line 1';
    }

    if (country?.address2?.required && !currentInputValue?.address2) {
      canSubmit = false;
      address2Content = country?.address2?.label || 'address line 2';
    }

    if (country?.city?.required && !currentInputValue?.city) {
      canSubmit = false;
      cityContent = country?.city?.label || 'city';
    }

    if (country?.state?.required && !currentInputValue?.state) {
      canSubmit = false;
      stateContent = country?.state?.label || 'state';
    }

    if (country?.zip?.required && !currentInputValue?.zip) {
      canSubmit = false;
      zipContent = country?.zip?.label || 'zip code';
    }

    return {
      inputAttribute: {
        address1: {
          additionalContent: getInvalidContent(address1Content),
        },
        address2: {
          additionalContent: getInvalidContent(address2Content),
        },
        city: {
          additionalContent: getInvalidContent(cityContent),
        },
        state: {
          additionalContent: getInvalidContent(stateContent),
        },
        zip: {
          additionalContent: getInvalidContent(zipContent),
        },
        country: {
          disabled: isCountryDisabled,
          additionalContent: countryContent,
        },
      },
      canSubmit,
    };
  }, [...Object.values(currentInputValue), 'US']);
}

export const useCaEscrowWarning = (newState: string | undefined): void => {
  const {
    location: { state: currentState = '', country = '' } = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useRecoilValue(companyState) || ({} as any);

  useEffect(() => {
    if (country === 'US') {
      let modalType = '';
      if (currentState === 'CA' && newState && newState !== 'CA') {
        modalType = 'from_california';
      } else if (currentState !== 'CA' && newState && newState === 'CA') {
        modalType = 'to_california';
      }

      if (modalType) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-unused-expressions
        window?._fnRedux?.dispatch?.({
          type: 'APP_TOGGLE_ON',
          flag: 'display_ca_escrow_modal',
          value: modalType,
        });
      }
    }
  }, [newState, currentState, country]);
};
