import { Icon, IconAside, IconAsideIcon } from '@fieldnation/platform-components';
import css from '../Styles.scss';
import React from 'react';

const ErrorBlock = ({msg = ''}) => {
  return (!!msg?.length ? (
    <IconAside iconPosition="left" iconColor="red">
      <IconAsideIcon verticalAlign="middle">
        <Icon name="alertSolid" size={18} block />
      </IconAsideIcon>
      <div className={css.validationErrorText}>
        {msg}
      </div>
    </IconAside>
  ) : null)
};

export default ErrorBlock;

