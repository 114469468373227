import React from 'react';
import classNames from 'classnames';
import {
  Tooltip,
  TooltipComponent,
} from '@fieldnation/platform-components';
import applyTruncation from '../utils/truncate';

import FnLogo from './Svg/FnLogo';
import PopoverContent from './PopoverContent';

import css from './WorkOrderCuratedChip.scss';

const TRUNCATE_THRESHOLD = 50;

interface IProps {
  label?: string;
  labelColor: 'black' | 'gray';
  size: 'sm' | 'lg';
}

const Chip = ({ label, labelColor, size }: IProps) => (
  <div className={css.Chip__Outer}>
    <div role="presentation" className={classNames([css.Chip, labelColor === 'gray' ? css.Label__Gray : ''])} data-testid="curated-chip">
      <div className={css.Chip__Icon}>
        <FnLogo width={size === 'sm' ? 12 : 14} height={size === 'sm' ? 14 : 16} />
      </div>
      <span className={classNames([css.Chip__Label, size === 'sm' ? css['Chip__Label--sm'] : ''])}>
        {`${applyTruncation(label, true, TRUNCATE_THRESHOLD)}`}
      </span>
    </div>
  </div>
);

Chip.defaultProps = {
  label: '',
};

interface Props {
  workOrderId: number;
  networkName: string;
  notes?: string[];
  labelColor?: 'black' | 'gray';
  size?: 'sm' | 'lg';
  source?: string;
  overlayPosition?: 'top' | 'right' | 'left' | 'bottom';
}

const WorkOrderCuratedChip = ({
  networkName = '',
  notes = [],
  source = '',
  labelColor = 'black',
  size = 'lg',
  overlayPosition,
}: Props): JSX.Element => (
  <Tooltip showOnEvent="click" position={overlayPosition || 'top'}>
    <span>
      <Chip label={networkName} labelColor={labelColor} size={size} />
    </span>
    {/* @ts-ignore */}
    <TooltipComponent widthAuto isFlush noArrow>
      <PopoverContent
        networkName={networkName}
        notes={notes}
        source={source}
      />
    </TooltipComponent>
  </Tooltip>
);

WorkOrderCuratedChip.defaultProps = {
  workOrderId: 0,
  networkName: '',
  notes: [],
  labelColor: 'black',
  size: 'lg',
  source: undefined,
  overlayPosition: null,
};

export default WorkOrderCuratedChip;
