import { Loader } from '@fieldnation/platform-components';
import React, { Suspense, useEffect } from 'react';
import { RecoilRoot, useSetRecoilState } from 'recoil';
import ScreeningModal from './screening-modal';
import screeningAtom from './Store/screening.atom';
import { ScreeningModalProps } from './types.d';

const ScreeningModalWrapper = (
  props: ScreeningModalProps,
): JSX.Element => {
  const { userId, screeningType, source } = props;
  const setScreeningData = useSetRecoilState(screeningAtom);

  useEffect(() => {
    setScreeningData({
      userId,
      screeningType,
      source,
    });
  });
  return (
    <>
      <ScreeningModal {...props} />
    </>
  );
};

const ScreeningModalWidget = (props: ScreeningModalProps): JSX.Element => (
  <RecoilRoot>
    <Suspense fallback={<Loader fixed isLoading />}>
      <ScreeningModalWrapper {...props} />
    </Suspense>
  </RecoilRoot>
);

export default ScreeningModalWidget;
