import * as React from 'react';
import { Link } from '@fieldnation/platform-components';
import { BackoutsLists } from '../../types.d';

const BackoutsAssessmentName = ({ title, workOrderId }: BackoutsLists): JSX.Element => (
  <Link target="_blank" href={`/workorders/${workOrderId}`}>
    <span className="u-textLink">
      {title}
    </span>
  </Link>
);

export default BackoutsAssessmentName;
