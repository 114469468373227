import * as React from 'react';
import { Icon } from '@fieldnation/platform-components';
import css from './Rating.scss';

export interface RatingProps {
  rating?: number;
  type: 'marketplace' | 'my_company';
  hideType?: boolean;
}

export default function Rating({
  hideType,
  rating,
  type,
}: RatingProps): JSX.Element {
  const typeFormatted = !hideType ? (
    <small>{type === 'marketplace' ? 'marketplace' : 'my company'}</small>
  ) : null;

  return (
    <div className={css.Rating}>
      <div>
        <span
          className={type === 'marketplace' ? css.Marketplace : css.MyCompany}
        >
          <Icon name="starSolid" size={18} />
        </span>
        <div className={css.RatingText}>
          {!rating ? (
            <strong>
              <em>N/A</em>
            </strong>
          ) : (
            <div>
              <strong>{rating.toFixed(1)}</strong>
              /5
            </div>
          )}
        </div>
      </div>
      {typeFormatted}
    </div>
  );
}
