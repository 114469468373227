import { selector } from 'recoil';
import contextQuery from './context.query';

const skillsQuery = selector({
  key: 'skillsQuery',
  get: ({ get }) => {
    const context = get(contextQuery);

    if (!context) {
      return null;
    }

    const experiments = context?.experiments?.results || [];
    const hasSkillDiscoveryExperiment = Boolean(
      // 88 => Skill Discovery
      experiments?.find(({ id, value }) => id === 88 && (Number(value) === 1 || value === 'new')),
    );
    const isStaff = [3, 9].includes(context?.group?.id || 0);
    // staff or experiment can view
    const canViewSkillDiscovery = hasSkillDiscoveryExperiment || isStaff;

    return Boolean(canViewSkillDiscovery);
  },
});

export default skillsQuery;
