/* eslint-disable */
import { AxiosRequestConfig } from 'axios';
import template from 'lodash/template';
import { CallbackInterface } from 'recoil';
import { EVENTS, ITrackEvent } from '../../Amplitude/useAmplitudeTracker';
import { makeApiRequest } from '../Commons/Api';
import { NotificationFlashAlert } from '../Commons/Notification';
import { ICompany, IFullAddressInputValues } from '../Commons/types.d';
import {
  ContentKey,
  ContentType,
  HttpMethod,
  IContentConfig,
  InputValidation,
  InputValidationType,
  IOnEditConfig,
  ISectionContent,
  SectionCardKey,
  SectionCardMode,
  SectionKey,
  SectionMode,
} from '../Components/Section/types.d';
import {
  contentInputState,
  contentValidationState,
  sectionCardCreateConfig,
  sectionCardIsLoading,
  sectionCardModeState,
  sectionCardResourceId,
  sectionCardSections,
  sectionContentsState,
  sectionFilesState,
  sectionHasErrorState,
  sectionIsLoading,
  sectionModeState,
} from './AtomFamilies';
import {
  addressConfirmationDescriptionState,
  addressConfirmationSectionConfigState,
  addressConfirmationSectionKeyState,
  companyState,
  isAddressConfirmationOpenState,
} from './Atoms';
import userPermissionState from './Selectors';

export const onChangeContentInput = ({
  set,
  reset,
}: CallbackInterface) => (
  value: string,
  sectionKey: SectionKey,
  config: IContentConfig,
): void => {
  const newContentInput = {
    key: config.key,
    value,
    contentConfig: config,
  };
  set(contentInputState(config.key), newContentInput);
  const inputValidationRegExp =
  newContentInput.contentConfig?.inputValidation || InputValidation[InputValidationType.ANY];

  const isValid = inputValidationRegExp.test(value);
  const displayName = newContentInput.contentConfig?.displayName.toLowerCase();
  const displayNameFormatted = displayName.charAt(0).toUpperCase() + displayName.slice(1)
  if (value) {
    if (!isValid) {
      set(sectionHasErrorState(sectionKey), true);
      set(contentValidationState(config.key), {
        isValid,
        message: `${displayNameFormatted} is invalid`,
      });
    } else {
      reset(contentValidationState(config.key));
      reset(sectionHasErrorState(sectionKey));
    }
  } else if (!newContentInput.contentConfig?.isOptional) {
    set(sectionHasErrorState(sectionKey), true);
    set(contentValidationState(config.key), {
      isValid: false,
      message: `${displayNameFormatted} is required`,
    });
  }
};

export const onCloseSection = ({
  snapshot,
  set,
  reset,
}: CallbackInterface) => async (sectionKey: SectionKey): Promise<void> => {
  set(sectionModeState(sectionKey), SectionMode.VIEW);
  const sectionContentsSnapshot = await snapshot.getPromise(sectionContentsState(sectionKey));
  sectionContentsSnapshot.forEach((content) => {
    set(contentInputState(content.key as ContentKey), content);
    reset(contentValidationState(content.key as ContentKey));
  });
  reset(sectionFilesState(sectionKey));
  reset(sectionHasErrorState(sectionKey));
};

export const onCloseSectionCard = ({
  snapshot,
  set,
  reset,
}: CallbackInterface) => async (cardKey: string): Promise<void> => {
  const sectionsSnapshot = await snapshot.getPromise(
    sectionCardSections(cardKey as SectionCardKey),
  );
  sectionsSnapshot.forEach((section) => {
    const sectionContents = section.contentConfigs;
    sectionContents.forEach((content) => {
      set(contentInputState(content.key as ContentKey), content);
      reset(contentValidationState(content.key as ContentKey));
    });
    reset(sectionFilesState(section.key));
    reset(sectionHasErrorState(section.key));
  });
  set(sectionCardModeState(cardKey as SectionCardKey), SectionCardMode.EMPTY);
};

export const onCreateSectionCard = ({
  snapshot,
  set,
}: CallbackInterface) => async (
  cardKey: SectionCardKey,
  windowRef: string,
  sectionCardDisplayName: string,
): Promise<void> => {
  const company = await snapshot.getPromise(companyState);
  const sections = await snapshot.getPromise(sectionCardSections(cardKey));
  const createConfig = await snapshot.getPromise(sectionCardCreateConfig(cardKey));
  let body = { ...createConfig.body };
  for (const section of sections) {
    const contents = await snapshot.getPromise(sectionContentsState(section.key));
    const newContents: ISectionContent[] = [];
    for (const content of contents) {
      const contentRef = content.contentConfig?.contentReference;
      const inputValue = await snapshot.getPromise(contentInputState(content.key as ContentKey));
      const result = {
        [contentRef as string]: inputValue.value,
      };
      body = { ...result, ...body };

      const newContent = {
        key: content.key,
        value: String(inputValue),
        contentConfig: content.contentConfig,
      };
      newContents.push(newContent);
    }
    set(sectionContentsState(section.key), newContents);
  }

  const config: AxiosRequestConfig = {
    method: createConfig.method,
    url: template(createConfig.url)({
      company_id: company?.id,
    }),
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  set(sectionCardIsLoading(cardKey), true);

  const response = await makeApiRequest(config);
  if (response?.id || response?.company_id) {
    const successMessage = `Successfully created ${sectionCardDisplayName.toLowerCase()}`;
    NotificationFlashAlert({
      level: 'success',
      message: successMessage,
    });
    const newResource = { ...response };
    window[windowRef] = newResource;

    set(sectionCardModeState(cardKey), SectionCardMode.INTERACTIVE);
  }
  set(sectionCardIsLoading(cardKey), false);
};

export const onSaveSection = ({
  snapshot,
  set,
  reset,
}: CallbackInterface) => async (
  sectionKey: SectionKey,
  onEditConfig?: IOnEditConfig,
  force = false,
  sectionDisplayName?: string,
  cardKey?: string,
  windowRef?: string,
  trackOnAmplitude?: ITrackEvent,
): Promise<void> => {
  set(sectionIsLoading(sectionKey), true);
  const contents = await snapshot.getPromise(sectionContentsState(sectionKey));
  const company = await snapshot.getPromise(companyState);
  const originalAddress = {
    current_address_1: company?.address1,
    current_city: company?.city,
    current_state: company?.state,
    current_zip: company?.zip,
    current_country: company?.country,
  };
  const resourceId = await snapshot.getPromise(sectionCardResourceId(cardKey as SectionCardKey)) || '';
  const files = await snapshot.getPromise(sectionFilesState(sectionKey));
  let isUpload = false;
  let body = {};
  await contents.forEach(async (content) => {
    const contentType = content.contentConfig?.contentType;
    const contentRef = content.contentConfig?.contentReference;
    isUpload = contentType === ContentType.IMAGE;
    const inputValue = await snapshot.getPromise(contentInputState(content.key as ContentKey));
    const result = {
      [contentRef as string]: inputValue.value,
    };
    body = { ...result, ...body };
    if (isUpload) {
      const formData = new FormData();
      const file = files[0];
      formData.append('image', file);
      body = formData;
    }
  });
  const config: AxiosRequestConfig = {
    method: onEditConfig?.method,
    url: template(onEditConfig?.url)({
      company_id: company?.id,
      contact_id: resourceId,
    }),
    data: body,
    headers: {
      'Content-Type': (isUpload) ? 'multipart/form-data' : 'application/json',
    },
  };
  config.params = (force) ? { skip_address_validation: true } : {};
  const response = await makeApiRequest(config);
  if (response?.id || response?.company_id) {
    const successMessage = `Successfully updated ${sectionDisplayName?.toLowerCase()}`;
    NotificationFlashAlert({
      level: 'success',
      message: successMessage,
    });
    const isCompany = response?.id;
    const newResource = { ...response, ...response?.location };
    window[windowRef || 'company'] = newResource;
    if (isCompany) {
      set(companyState, newResource);
    }
    const newContents: ISectionContent[] = [];
    contents.forEach((content) => {
      const key = content?.key || '';
      const refKey = content?.contentConfig?.contentReference || '';
      const newContent = {
        key,
        value: newResource[refKey],
        contentConfig: content.contentConfig,
        version: (content?.version || 1) + 1,
      };
      newContents.push(newContent);
      reset(contentValidationState(content.key as ContentKey));
    });
    set(sectionContentsState(sectionKey), newContents);
    reset(sectionModeState(sectionKey));
    reset(sectionFilesState(sectionKey));
    reset(sectionHasErrorState(sectionKey));
  }
  if (trackOnAmplitude) {
    let validated = false;
    if (config.params?.skip_address_validation === undefined) {
      validated = response?.id || response?.company_id
    } else {
      validated = !config.params?.skip_address_validation
    }

    trackOnAmplitude(EVENTS.UPDATED_ADDRESS,{
      type: 'Company',
      validated: Boolean(validated),
      ...originalAddress,
      new_address_1: config.data?.address1,
      new_city: config.data?.city,
      new_state: config.data?.state,
      new_zip: config.data?.zip,
      new_country: config.data?.country
    })
  }
  set(sectionIsLoading(sectionKey), false);
};

export const onSaveSmartAddress = ({
  snapshot,
  set,
  reset,
}: CallbackInterface) => async (
  sectionKey: SectionKey,
  onEditConfig?: IOnEditConfig,
  sectionDisplayName?: string,
  trackOnAmplitude?: ITrackEvent,
): Promise<void> => {
  set(sectionIsLoading(sectionKey), true);
  const { isStaff } = await snapshot.getPromise(userPermissionState);
  const company = await snapshot.getPromise(companyState);
  const contents = await snapshot.getPromise(sectionContentsState(sectionKey));
  const originalAddress = {
    current_address_1: company?.address1,
    current_city: company?.city,
    current_state: company?.state,
    current_zip: company?.zip,
    current_country: company?.country
  };
  let body = {};
  await contents.forEach(async (content) => {
    const inputValue = await snapshot.getPromise(contentInputState(content.key as ContentKey));
    const result = {
      [content.contentConfig?.contentReference as string]: inputValue.value,
    };
    body = { ...result, ...body };
  });
  const preConfig: AxiosRequestConfig = {
    method: HttpMethod.POST,
    url: 'locations/places/resolve',
    data: body,
  };
  const config: AxiosRequestConfig = {
    method: onEditConfig?.method,
    url: template(onEditConfig?.url)({ company_id: company?.id }),
    data: body,
  };

  if (isStaff) {
    const validationResponse = await makeApiRequest(preConfig);
    if (validationResponse?.address) {
      if (validationResponse.resolution_status === 'INVALID') {
        set(addressConfirmationDescriptionState, validationResponse.resolution_message);
        set(isAddressConfirmationOpenState, true);
        set(addressConfirmationSectionKeyState, sectionKey);
        set(addressConfirmationSectionConfigState, onEditConfig);
        set(sectionIsLoading(sectionKey), false);
        return;
      }
    }
  }
  const response = await makeApiRequest(config);
  if (response?.id) {
    const successMessage = `Successfully updated ${sectionDisplayName?.toLowerCase()}`;
    NotificationFlashAlert({
      level: 'success',
      message: successMessage,
    });
    const newCompany = { ...response, ...response?.location } as ICompany;
    window.company = newCompany;
    set(companyState, newCompany);
    const newContents: ISectionContent[] = [];
    contents.forEach((content) => {
      const key = content?.key || '';
      const refKey = content?.contentConfig?.contentReference || '';
      const newContent = {
        key,
        value: newCompany[refKey],
        contentConfig: content.contentConfig,
      };
      newContents.push(newContent);
    });
    set(sectionContentsState(sectionKey), newContents);
    reset(sectionModeState(sectionKey));
  }

  if (trackOnAmplitude) {
    trackOnAmplitude(EVENTS.UPDATED_ADDRESS, {
      type: 'Company',
      validated: Boolean(response?.id),
      ...originalAddress,
      new_address_1: config.data?.address1,
      new_city: config.data?.city,
      new_state: config.data?.state,
      new_zip: config.data?.zip,
      new_country: config.data?.country
    });
  }

  set(sectionIsLoading(sectionKey), false);
};

export const onDropSectionFile = ({
  snapshot,
  set,
}: CallbackInterface) => async (sectionKey: SectionKey, files: File[]): Promise<void> => {
  const sectionFilesSnapshot = await snapshot.getPromise(sectionFilesState(sectionKey));
  set(sectionFilesState(sectionKey), [...sectionFilesSnapshot, ...files]);
};

export const onDeleteSectionFile = ({
  snapshot,
  set,
}: CallbackInterface) => async (sectionKey: SectionKey, file: File): Promise<void> => {
  const sectionFilesSnapshot = await snapshot.getPromise(sectionFilesState(sectionKey));
  const newState = sectionFilesSnapshot.filter((fileSnapshot) => fileSnapshot !== file);
  set(sectionFilesState(sectionKey), newState);
};

export const onChangeMode = ({
  snapshot,
  set,
  reset,
}: CallbackInterface) => async (sectionKey: SectionKey, cardKey: string): Promise<void> => {
  const sections = await snapshot.getPromise(sectionCardSections(cardKey as SectionCardKey));
  sections.forEach(async (section) => {
    set(sectionModeState(section.key as SectionKey), SectionMode.VIEW);
    const sectionContentsSnapshot = await snapshot.getPromise(
      sectionContentsState(
        section.key as SectionKey,
      ),
    );
    sectionContentsSnapshot.forEach((content) => {
      set(contentInputState(content.key as ContentKey), content);
      reset(contentValidationState(content.key as ContentKey));
    });
    reset(sectionFilesState(section.key as SectionKey));
    reset(sectionHasErrorState(section.key as SectionKey));
  });
  set(sectionModeState(sectionKey), SectionMode.EDIT);
};

export const onChangeSmartAddress = ({
  snapshot,
  set,
}: CallbackInterface) => async (
  sectionKey: SectionKey,
  address: IFullAddressInputValues,
): Promise<void> => {
  const {
    address1, address2, city, state, zip, country,
  } = address;
  const contentsSnapshot = await snapshot.getPromise(sectionContentsState(sectionKey));
  contentsSnapshot.forEach((content) => {
    switch (content.key) {
      case ContentKey.COMPANY_OVERVIEW_ADDRESS1: {
        set(contentInputState(content.key), {
          key: content.key,
          value: address1,
          contentConfig: content.contentConfig,
        });
        break;
      }
      case ContentKey.COMPANY_OVERVIEW_ADDRESS2: {
        set(contentInputState(content.key), {
          key: content.key,
          value: address2,
          contentConfig: content.contentConfig,
        });
        break;
      }
      case ContentKey.COMPANY_OVERVIEW_CITY: {
        set(contentInputState(content.key), {
          key: content.key,
          contentConfig: content.contentConfig,
          value: city,
        });
        break;
      }
      case ContentKey.COMPANY_OVERVIEW_STATE: {
        set(contentInputState(content.key), {
          key: content.key,
          contentConfig: content.contentConfig,
          value: state,
        });
        break;
      }
      case ContentKey.COMPANY_OVERVIEW_ZIP: {
        set(contentInputState(content.key), {
          key: content.key,
          contentConfig: content.contentConfig,
          value: zip,
        });
        break;
      }
      case ContentKey.COMPANY_OVERVIEW_COUNTRY: {
        set(contentInputState(content.key), {
          key: content.key,
          contentConfig: content.contentConfig,
          value: country,
        });
        break;
      }
      default: {
        break;
      }
    }
  });
};

export default onChangeContentInput;
