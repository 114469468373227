import { selector } from 'recoil';
import { getUserContext } from '../api';
import { MonolithProfile } from '../types.d';

const contextQuery = selector<MonolithProfile | null>({
  key: 'context',
  get: async () => {
    const context = await getUserContext();

    return context || null;
  },
});

export default contextQuery;
