import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  differenceInMinutes,
} from 'date-fns';

// unfortunately formatRelative from date-fns is terrible
export default function formatRelative(date1: Date): string {
  const pluralize = (x: number, s: string, p: string) => `${x} ${x !== 1 ? p : s} ago`;
  const date2 = new Date();

  const y = differenceInYears(date2, date1);
  if (y > 1) {
    return pluralize(y, 'year', 'years');
  }

  const m = differenceInMonths(date2, date1);
  if (m > 1) {
    return pluralize(m, 'month', 'months');
  }

  const w = differenceInWeeks(date2, date1);
  if (w > 1) {
    return pluralize(w, 'week', 'weeks');
  }

  const d = differenceInCalendarDays(date2, date1);
  if (d > 1) {
    return pluralize(d, 'day', 'days');
  }

  const h = differenceInHours(date2, date1);
  if (h > 1) {
    return pluralize(h, 'hour', 'hours');
  }

  const n = differenceInMinutes(date2, date1);
  if (n > 1) {
    return pluralize(n, 'minute', 'minutes');
  }

  return 'today';
}
