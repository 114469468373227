/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable lodash/prefer-includes */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  v2,
  Grid,
  GridColumn,
  IconAside,
  IconAsideIcon,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  FieldGroup as Group,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Box,
  Link,
  BodyText,
  Loader,
} from '@fieldnation/platform-components';
import CustomFooter from './CustomFooter';
import CustomOptions from './CustomOptions';
import { store } from '../Store';
import {
  getQualificationsByType,
  getCredentialsByType,
  getv2RequirementOptions,
} from '../../api';
import trackAmplitudeEvent, {
  WORK_ORDER_DETAILS,
  TEMPLATE_DETAILS,
} from '../amplitude';
import {
  setLocalStorage,
  getLocalStorage,
} from '../utils';
// eslint-disable-next-line import/extensions,import/no-unresolved
import {
  QsOptionsInterface, QualificationInterface, QDropdownOptionsInterface, CategoryType,
} from '../../types.d';

const { Select } = v2;

let uniqId = -1000000;
const getUniqId = () => {
  uniqId -= 1;
  return uniqId;
};

const getCategory = () => ([
  { label: 'Certifications', value: CategoryType.CERTIFICATION, disabled: false },
  { label: 'Licenses', value: CategoryType.LICENSE, disabled: false },
  { label: 'Equipment', value: CategoryType.EQUIPMENT, disabled: false },
  { label: 'Insurance', value: CategoryType.INSURANCE, disabled: false },
  { label: 'Background Check', value: CategoryType.BACKGROUND_CHECK, disabled: false },
  { label: 'Drug Test', value: CategoryType.DRUG_TEST, disabled: false },
  { label: 'COVID-19 Vaccination Proof', value: CategoryType.REQUIRE_C19_VACCINE, disabled: false },
]);

interface IProps {
  onChangeData: (qs: QualificationInterface[]) => void;
  useV2Data: boolean;
}

const Form = ({
  onChangeData,
  useV2Data,
}: IProps): JSX.Element => {
  const ctx = useContext(store);
  const {
    state: {
      invalid,
      qualifications: qual = [],
      newQualifications: newQual = false,
    },
  } = ctx;

  const [qsOptions, setQsOptions] = useState<QsOptionsInterface[]>([]);
  const [qualifications, setQualifications] = useState<QualificationInterface[]>(newQual || qual);
  const [isInvalid, setInvalid] = useState(invalid);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    const sanitizeOption = (options) => (options || []).map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    try {
      setLoading(true);
      if (useV2Data) {
        const v2Options = await getv2RequirementOptions();

        const mappedOptions = getCategory().map((cat) => {
          // mapping the category to the correct option name to grab options
          const optionName = Object.keys(v2Options).find((k) => {
            const category = v2Options[k]?.results;
            if (!category || !category.length) {
              return false;
            }
            return category.some((c) => c.category === cat.value);
          }) || '';

          const options = v2Options[optionName]?.results || [];

          const mappedOption = options.map((option) => ({
            ...option,
            value: option.id,
            label: option.name,
          }));
          return {
            category: cat.label,
            options: mappedOption,
          };
        });
        setQsOptions(mappedOptions);
        return;
      }
      // qualifications v1
      const qsOptionsItems = getLocalStorage('qualificationOptions');
      if (qsOptionsItems) {
        setQsOptions(qsOptionsItems);
      } else {
        const certifications = await getCredentialsByType('certifications');
        const licenses = await getCredentialsByType('license');
        const equipments = await getQualificationsByType('equipment', 'yes');
        const insurances = await getQualificationsByType('insurance');
        const c19Vax = await getQualificationsByType('c19_vax');
        const backgroundChecks = await getQualificationsByType('backgroundCheck');
        const drugTests = await getQualificationsByType('drugTest');
        const options = [
          { category: 'Certifications', options: sanitizeOption(certifications) },
          { category: 'Licenses', options: sanitizeOption(licenses) },
          { category: 'Equipment', options: sanitizeOption(equipments) },
          { category: 'Insurance', options: sanitizeOption(insurances) },
          { category: 'Background Check', options: sanitizeOption(backgroundChecks) },
          { category: 'Drug Test', options: sanitizeOption(drugTests) },
          { category: 'COVID-19 Vaccination Proof', options: sanitizeOption(c19Vax) },
        ];
        // 1 (60000 ms) minute cache the results
        setLocalStorage('qualificationOptions', options, 60000);
        setQsOptions(options);
      }
    } catch (e) {
      setInvalid(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const triggerAmplitudeEvent = (id: number) => {
    const excluded = qualifications.find((q) => q.id === id);

    if (excluded?.qualificationIds.length) {
      const excludedLabels: any[] = [];
      if (qsOptions) {
        const category = qsOptions.find(
          (qs) => qs.category === excluded?.category?.label,
        );
        const excludedOptions = category?.options?.filter(
          (ol) => excluded.qualificationIds.indexOf(ol.value) !== -1,
        ) || [];
        excludedOptions.forEach((op) => excludedLabels.push(op.label));
      }

      trackAmplitudeEvent({
        category: 'Removed Qualification',
        ampProps: {
          Source: (window.location?.href.indexOf('templates') > -1)
            ? TEMPLATE_DETAILS : WORK_ORDER_DETAILS,
          Type: excluded?.category?.label || '',
          [excluded?.category?.label as string]: excludedLabels,
        },
      });
    }
  };

  const onChangeQualifications = (id: number, qs: QDropdownOptionsInterface[] = [], toRemove = false) => {
    if (toRemove) {
      const removed = qualifications.filter((q) => q.id !== id);

      triggerAmplitudeEvent(id);
      setQualifications([...removed]);
      onChangeData([...removed]);
    } else {
      const update = qualifications.map((m) => {
        if (m?.id === id) {
          return {
            ...m,
            qualificationIds: (qs || []).map(({ value }) => value),
          };
        }
        return m;
      });
      setQualifications([...update]);
      onChangeData(update);
    }
  };

  const getQualificationTypes = () => (
    getCategory().map((qt) => ({
      ...qt,
      disabled: (qualifications || []).filter(
        ({ category }) => category?.value === qt?.value,
      ).length > 0,
    }))
  );

  const menuFooter = (id: number, onChange: any, selectedCount: number, optionCount: number) => (
    <CustomFooter
      id={id}
      onChange={onChange}
      selectedCount={selectedCount}
      optionCount={optionCount}
    />
  );

  if (isInvalid) {
    return (
      <div className="text-danger u-margin-bottom--md">
        {isInvalid}
      </div>
    );
  }

  if (loading) {
    return <Loader isLoading fixed />;
  }

  return (
    <div>
      {(qualifications || [])
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter(({ id }) => id !== -99)
        .sort(({ category: c1 }, { category: c2 }) => {
          if (c1 && c1.displayOrder && c2 && c2.displayOrder) {
            return c1.displayOrder - c2.displayOrder;
          }
          return 0;
        })
        .map(({
          id, category, qualificationIds,
        }) => {
          const options = (qsOptions || []).find((f) => {
            const catId = getCategory().find((cat) => cat.label === f?.category)?.value;
            return category?.value === catId;
          })?.options;

          let isScreeningQualification = false;

          if (v2) isScreeningQualification = options?.find((a) => a?.label?.includes('Within') || a?.label?.includes('Any Age'))?.value;

          return (
            <div key={id} data-id={id} className="u-margin-top--md">
              <Box>
                <Grid>
                  <GridColumn xs="12" sm="12" lg="6" md="6">
                    <Group id="groupTest">
                      <IconAside iconPosition="left">
                        <IconAsideIcon verticalAlign="middle">
                          <div className="u-padding-top--md">
                            <IconButton
                              onClick={() => {
                                onChangeQualifications(id, [], true);
                              }}
                              name="removeHollow"
                            />
                          </div>
                        </IconAsideIcon>
                        <div className="u-margin-left--xl">
                          <Select
                            optional
                            hideOptionalLabel
                            label="Qualification type"
                            options={getQualificationTypes()}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                            value={category}
                            onChange={(type) => setQualifications([
                              ...qualifications.map((m) => {
                                if (m?.id === id) {
                                  return {
                                    ...m,
                                    category: type as any,
                                    qualificationIds: [],
                                  };
                                }
                                return m;
                              }),
                            ])}
                          />
                        </div>
                      </IconAside>
                    </Group>
                  </GridColumn>
                  <GridColumn xs="12" sm="12" lg="6" md="6">
                    {category?.label && typeof isScreeningQualification !== 'number' ? (
                      <div>
                        <Select
                          multi
                          optional
                          hideOptionalLabel
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                          validation={false}
                          removeSelected={false}
                          closeOnSelect={false}
                          label={category?.label}
                          placeholder="Select..."
                          options={options}
                          customFooter={
                          menuFooter(
                            id,
                            onChangeQualifications,
                            (qualificationIds || []).length,
                            (options || []).length,
                          )
                        }
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                          value={qualificationIds}
                          optionComponent={CustomOptions}
                          onChange={(qs: any) => onChangeQualifications(id, qs)}
                        />
                        <div className="u-textRight">
                          <Link onClick={() => onChangeQualifications(id, [])}>
                            <BodyText styleLevel="sm">Clear all</BodyText>
                          </Link>
                        </div>
                      </div>
                  ) : null}
                    {category?.label && typeof isScreeningQualification === 'number' ? (
                      <div>
                        <Select
                          optional
                          hideOptionalLabel
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                          validation={false}
                          removeSelected={false}
                          label={`${category?.label} Age`}
                          placeholder="Select..."
                          options={options}
                          value={String(qualificationIds[0]) || ''}
                          onChange={(qs) => {
                            const arr: any = [];
                            arr.push(qs);
                            onChangeQualifications(id, arr);
                          }}
                        />
                        <div className="u-textRight">
                          <Link onClick={() => onChangeQualifications(id, [])}>
                            <BodyText styleLevel="sm">Clear</BodyText>
                          </Link>
                        </div>
                      </div>
                  ) : null}
                  </GridColumn>
                </Grid>
              </Box>
            </div>
          );
        })}
      <div data-testid="add-qualification-btn" className="u-padding-top--md">
        <Button
          type="link"
          disabled={getQualificationTypes().every((qt) => qt.disabled)}
          onClick={() => setQualifications([
            ...qualifications,
            {
              id: getUniqId(),
              qualificationIds: [],
            },
          ])}
          icon="icon-plus"
          label=" Add Qualification"
        />
      </div>
    </div>
  );
};

Form.propTypes = {
  onChangeData: PropTypes.func.isRequired,
  useV2Data: PropTypes.bool.isRequired,
};

export default Form;
