import React from 'react';
import { Button } from '@fieldnation/platform-components';
import { IProps } from './types.d';
import css from './styles.scss';

const ActionBar: React.FC<IProps> = ({
  onSave,
  onClose,
  onSaveLabel = 'Save',
  onCloseLabel = 'Close',
  isOnSaveDisabled = false,
  isOnCloseDisabled = false,
}: IProps): JSX.Element => (
  <div className={css['action--bar']}>
    <Button
      label={onSaveLabel}
      type="primary"
      size="lg"
      onClick={onSave}
      disabled={isOnSaveDisabled}
    />
    &nbsp;&nbsp;
    <Button
      label={onCloseLabel}
      type="text"
      size="lg"
      onClick={onClose}
      disabled={isOnCloseDisabled}
    />
  </div>
);

export default ActionBar;
