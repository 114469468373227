/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { scroller } from 'react-scroll';
import BackoutsAssessmentDate from './Cells/BackoutsAssessmentDate';
import BackoutsAssessmentID from './Cells/BackoutsAssessmentID';
import BackoutsAssessmentName from './Cells/BackoutsAssessmentName';
import TimelinessChckInDate from './Cells/TimelinessChckInDate';

import { BackoutsColumns, ColumnRender } from '../types.d';

export const daysWOsOptions = [
  {
    label: 'Timeframe',
    options: [
      {
        label: 'Past 90 days',
        value: '90days',
      },
      {
        label: 'Past 180 days',
        value: '180days',
      },
      {
        label: 'All time',
        value: '0',
      },
    ],
  },
  {
    label: 'Assignments',
    options: [
      {
        label: 'Past 50 work orders',
        value: '50workorders',
      },
      {
        label: 'Past 100 work orders',
        value: '100workorders',
      },
      {
        label: 'Past 200 work orders',
        value: '200workorders',
      },
    ],
  },
];

export const daysWOsOptionsRating = [
  {
    label: 'Timeframe',
    options: [
      {
        label: 'Past 90 days',
        value: '90days',
      },
      {
        label: 'Past 180 days',
        value: '180days',
      },
      {
        label: 'All time',
        value: 'all',
      },
    ],
  },
  {
    label: 'Assignments',
    options: [
      {
        label: 'Past 50 work orders',
        value: '50workorders',
      },
      {
        label: 'Past 100 work orders',
        value: '100workorders',
      },
      {
        label: 'Past 200 work orders',
        value: '200workorders',
      },
    ],
  },
];

export const getSecondaryFilterOptions = () => (
  [
    {
      label: 'Timeframe',
      options: [
        {
          label: 'All time',
          value: '0',
        },
        {
          label: 'Past 90 days',
          value: '90days',
        },
        {
          label: 'Past 180 days',
          value: '180days',
        },
      ],
    },
  ]
);

export const myCompanyOption = [
  {
    label: 'For all marketplace buyers',
    value: '0',
  },
  {
    label: 'For my company',
    value: '1',
  },
];

export const dayOptions = [
  {
    label: '90 days', value: '90days',
  },
  {
    label: '180 days', value: '180days',
  },
  {
    label: 'All time', value: 'all',
  },
];

export enum Group {
  Provider = 1,
  Admin = 2,
  Staff = 3,
  Call_Coordinator = 4,
  Supervisor = 5,
  Manager = 7,
  Dispatcher = 8,
  Accountant = 9,
  Customer = 10,
  Company_Payment_User = 11,
}

export const isStaff = () => (
  [Group?.Staff as number, Group.Accountant].includes(window?.context?.group?.id || 0)
);

export const isProvider = () => (
  Group.Provider as number === window?.context?.group?.id
);

export const getPrimaryFilterOptions = (showAllTime = false) => {
  if (isStaff()) {
    return daysWOsOptions;
  }
  if (showAllTime) {
    return [{ ...daysWOsOptions[0] }];
  }

  return [{
    ...daysWOsOptions[0],
    options: daysWOsOptions[0]?.options.filter(({ value }) => value !== 'all'),
  }];
};

export const backoutsTimelinessColumns = [
  {
    id: 'title',
    header: 'Title',
    width: 250,
    render: (row: any) => (row.title),
  },
  {
    id: 'workOrderId',
    header: 'ID',
    render: (row: any) => (row.workOrderId),
  },
  {
    id: 'assignedDatetime',
    header: 'Assign date',
    render: (row: any) => (row.assignedDatetime),
  },
  {
    id: 'checkinDateTime',
    header: 'Check-in date',
    render: (row: any) => (row.checkinDateTime),
  },
  {
    id: 'location',
    header: 'Location',
    render: (row: any) => (row.location),
  },
  {
    id: 'typeOfWork',
    header: 'Type of Work',
    render: (row: any) => (row.typeOfWork),
  },
];

export const modalColumnComponents = [
  { id: 'title', element: BackoutsAssessmentName },
  { id: 'workOrderId', element: BackoutsAssessmentID },
  { id: 'assignedDatetime', element: BackoutsAssessmentDate },
  { id: 'checkinDateTime', element: TimelinessChckInDate },
];

export const renderColumn = (
  col: BackoutsColumns,
  row: any,
  components: ColumnRender[],
) => {
  const el = components.find(({ id }) => id === col.id);
  if (el) {
    const { element: Element } = el;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Element {...row} />;
  }
  return col.render(row);
};

export const scrollToTile = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const target = urlParams.get('scrollTo');

  if (target) {
    const timmy = setInterval(() => {
      const elements = document.getElementsByName(target);
      const loader = document.getElementById('user-profile-loader');
      if (elements.length > 0 && !loader) {
        scroller.scrollTo(target, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
          containerId: 'js-page__body',
        });
        clearInterval(timmy);
      }
    }, 500);
  }
};

export const scrollToSection = (target: string) => {
  if (target) {
    const timmy = setInterval(() => { // timmy......🙃
      const elements = document.getElementsByName(target);
      if (elements.length) {
        elements[0].scrollIntoView({ behavior: 'smooth' });
        clearInterval(timmy);
      }
    }, 500);
  }
};
