import { BodyText } from '@fieldnation/platform-components';
import React from 'react';
import { IProps } from './types.d';
import css from './styles.scss';

const TextContent: React.FC<IProps> = ({
  content,
}: IProps): JSX.Element => (
  <BodyText styleLevel="md" tag="span">
    <span className={css['text--content']}>{content}</span>
  </BodyText>
);

export default TextContent;
