/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Icon } from '@fieldnation/platform-components';
import JobHistoryTile from './JobHistoryTile';
import jobHistoryQuery from '../queries/job-history.query';

const JobHistory = (): JSX.Element | null => {
  const jobHistory = useRecoilValue(jobHistoryQuery);
  if (!jobHistory) {
    return null;
  }

  let style = '22rem';

  const {
    total, results, filters = {},
  } = jobHistory;

  const { contextGroupId = 0 } = filters;

  if (contextGroupId === 1) {
    style = '38rem';
  }

  return (
    <>
      <JobHistoryTile key="count">
        <small className="text-muted">
          {`${total} rating${total !== 1 ? 's' : ''}`}
        </small>
      </JobHistoryTile>
      {(results?.length === 0 || !results)
        && (
        <div className="u-padding-top--3-xl" style={{ marginLeft: style }}>
          <div className="u-margin-left--3-xl u-color--gray-50">
            <Icon name="block" size={32} />
          </div>
          <h3 className="u-color--gray-100" style={{ fontWeight: 600 }}> No ratings to display </h3>
          <span className="u-color--gray-80" style={{ marginLeft: '-3.3rem' }}> Adjust filters to see more ratings. </span>
        </div>
        )}
      {results && results.length > 0 && results.map((result, i) => (
        <JobHistoryTile key={result.id || i} {...result} filters={filters} />
      ))}
    </>
  );
};

export default JobHistory;
