import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  IconButton, Heading, BodyText, Expander,
} from '@fieldnation/platform-components';

import css from './accordion.scss';

// eslint-disable-next-line react/prop-types
const DefaultBadge = ({ children }) => (
  <span className={css.Accordion__badge}>{children}</span>
);
interface IProps {
    children: React.ReactNode;
    label: string;
    value: string;
    expand?: boolean;
    showExpandButton?: boolean;
    expanderHideButtonStyling?: boolean;
    status?: string;
    showBadge?: boolean;
    badge?: number;
    border?: string;
    actAsHeader?: boolean;
    subHeading?: string;
    clip?: boolean;
    hideExpander?: boolean;
    expanderPosition?: string;
    middleContent?: string;
    showHoverEffect?: boolean;
    onCollapse: () => void,
    onExpand: () => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customBadgeRenderer?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customHeaderContent?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    headerLeftIcon?: any;
    headerRightIcon?: React.ReactNode;
    labelPreventGrow?: boolean;
    subNode?: React.ReactNode;
}

const Accordion = (props: IProps): JSX.Element => {
  const {
    children,
    status,
    border,
    label,
    value,
    badge,
    showBadge,
    actAsHeader,
    subHeading,
    clip,
    expand,
    hideExpander,
    expanderPosition,
    middleContent,
    customBadgeRenderer,
    customHeaderContent,
    showHoverEffect,
    expanderHideButtonStyling,
    showExpandButton,
    onCollapse,
    onExpand,
    headerLeftIcon,
    headerRightIcon,
    labelPreventGrow,
    subNode,
  } = props;
  const [expanded, setExpanded] = useState(expand);

  useEffect(() => {
    setExpanded(expand);
  }, [expand]);

  const toggle = () => {
    if (expanded) {
      onCollapse();
    } else {
      onExpand();
    }
    setExpanded(!expanded);
  };

  const getExpander = () => (
    <div
      className={
        expanderHideButtonStyling
          ? css.Accordion__expander : css.Accordion__action
      }
    >
      {showExpandButton && (
        <IconButton
          name={expanded ? 'collapse' : 'expand'}
          onClick={toggle}
        />
      )}
    </div>
  );

  const renderHeader = () => {
    if (actAsHeader) {
      return (
        <div className={css['Accordion__labelContent--header']}>
          <Heading styleLevel="h3">{label}</Heading>
        </div>
      );
    }

    if (customBadgeRenderer) {
      const BadgeComponent = customBadgeRenderer;
      return (
        <>
          <Heading tag="span" styleLevel="h4">
            {label}
          </Heading>
          {showBadge && (
          <BadgeComponent>
            {badge}
          </BadgeComponent>
          )}
        </>
      );
    }

    return (
      <div className={css['Accordion__labelContent--header']}>
        <Heading styleLevel="h4">
          {label}
          {showBadge && <DefaultBadge>{badge}</DefaultBadge>}
        </Heading>
      </div>
    );
  };

  const renderCustomContent = () => {
    if (customHeaderContent) {
      return (
        <span>
          {customHeaderContent}
        </span>
      );
    }

    return actAsHeader ? (
      <div className={`${css[`Accordion__value--${status}`]}`}>
        <Heading styleLevel="h2">
          {value}
        </Heading>
      </div>
    ) : (
      <div className={`${css.Accordion__value} ${css[`Accordion__value--${status}`]}`}>
        <Heading styleLevel="h5">
          {value}
        </Heading>
      </div>
    );
  };

  return (
    <div>
      <div className={
          classNames(css.Accordion__container, css['Accordion--default'], {
            [css['Accordion__container--borderTop']]: border === 'top' || border === 'both',
            [css['Accordion__container--borderBottom']]: border === 'bottom' || border === 'both',
            [css['Accordion--clip']]: clip,
            [css['Accordion--hover']]: !!showHoverEffect,
          })
          }
      >
        {!hideExpander && expanderPosition === 'left' && getExpander()}
        <div className={css.Accordion__content} data-testid="snapshotAccordion" role="presentation">
          <div className={css.Accordion__container}>
            <div className={css.Accordion__label} style={{ flexGrow: labelPreventGrow ? 0 : 1 }}>
              <div className={css.Accordion__labelContent}>
                {headerLeftIcon ? <div className={css.Accordion__labelIcon}>{headerLeftIcon}</div> : null}
                {renderHeader()}
                {subNode}
                <div className={css['Accordion__labelContent--subHeading']}>
                  <BodyText styleLevel="xs">
                    {subHeading}
                  </BodyText>
                </div>
                {headerRightIcon ? <div className={css.Accordion__labelIconRight}>{headerRightIcon}</div> : null}
                <div className={css['Accordion__labelMiddleContent--header']}>
                  {middleContent}
                </div>
              </div>
            </div>
            <div>
              {renderCustomContent()}
            </div>
          </div>
        </div>
        {!hideExpander && expanderPosition !== 'left' && getExpander()}
      </div>
      <TransitionGroup component="div">
        {expanded && (
        <Expander>
          <div className={css['Accordion--default']}>
            {children}
          </div>
        </Expander>
        )}
      </TransitionGroup>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  status: PropTypes.oneOf(['default', 'positive', 'negative', 'strikethrough', 'pending', 'info']),
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  expand: PropTypes.bool,
  showBadge: PropTypes.bool,
  badge: PropTypes.number,
  border: PropTypes.oneOf(['top', 'bottom', 'both', 'none']),
  actAsHeader: PropTypes.bool,
  subHeading: PropTypes.string,
  clip: PropTypes.bool,
  hideExpander: PropTypes.bool,
  showExpandButton: PropTypes.bool,
  expanderPosition: PropTypes.oneOf(['left', 'right']),
  expanderHideButtonStyling: PropTypes.bool,
  middleContent: PropTypes.string,
  showHoverEffect: PropTypes.bool,
  onCollapse: PropTypes.func,
  onExpand: PropTypes.func,
  customBadgeRenderer: PropTypes.node,
  customHeaderContent: PropTypes.node,
  headerLeftIcon: PropTypes.node,
  headerRightIcon: PropTypes.node,
};

Accordion.defaultProps = {
  status: 'default',
  expand: false,
  showBadge: true,
  badge: 0,
  border: 'none',
  actAsHeader: false,
  subHeading: '',
  clip: false,
  hideExpander: false,
  showExpandButton: true,
  expanderPosition: 'right',
  expanderHideButtonStyling: false,
  middleContent: '',
  showHoverEffect: false,
  onCollapse: () => null,
  onExpand: () => null,
  customBadgeRenderer: undefined,
  customHeaderContent: undefined,
  headerLeftIcon: undefined,
  headerRightIcon: undefined,
  labelPreventGrow: false,
  subNode: undefined,
};

export default Accordion;
