import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import { format, isBefore } from 'date-fns';
import {
  v2,
  Grid,
  GridColumn,
  Status,
  IconButton,
} from '@fieldnation/platform-components';
import AddLicensesAndCertifications from './AddLicensesAndCertifications';
import DeleteLicensesAndCertifications from './DeleteLicensesAndCertifications';
import Padded from './Padded';
import licenseCertificationQuery from '../queries/licenses-certifications.query';

import { UserQualifications, QualUpload } from '../types.d';

const { Tile, TileDivision } = v2;

type StatusType = 'default' | 'success' | 'info' | 'danger' | 'active';

const qsStatus = [
  { type: 'info', label: 'Pending' },
  { type: 'success', label: 'Verified' },
  { type: 'danger', label: 'Denied' },
  { type: 'danger', label: 'Expired' },
];

const openLicenseOrCertification = (item: UserQualifications, userId: number): void => {
  const fileUpload = item.uploads?.filter((u: QualUpload) => u?.type?.id === 1);
  if (fileUpload?.[0]?.type?.name === 'DOC') {
    // eslint-disable-next-line no-restricted-globals
    open('fileUpload[0].path', '_self');
  } else if (item?.qualification?.category?.id === 2) {
    // eslint-disable-next-line no-restricted-globals
    open(
      `${'/functions/display_license_popup.php?dynamic_term_id='}${
        item.qualification.dd_id
      }&user_id=${userId}`,
      'prevWin',
      'toolbar=no,width=800,height=600,directories=no,status=no,scrollbars=yes, resize=no, menubar=no',
    );
  } else if (item.qualification?.category?.id === 1) {
    // eslint-disable-next-line no-restricted-globals
    open(
      `${'/functions/display_cert_popup.php?dynamic_term_id='}${
        item.qualification.dd_id
      }&user_id=${userId}`,
      'prevWin',
      'toolbar=no,width=800,height=600,directories=no,status=no,scrollbars=yes, resize=no, menubar=no',
    );
  }
};

const LicensesAndCertifications = (): JSX.Element | null => {
  const data = useRecoilValue(licenseCertificationQuery);
  if (!data) {
    return null;
  }

  const [isAdd, setAdd] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<number>(0);
  const [isDelete, setDelete] = useState<number>(0);

  const {
    isManagedOrSelf,
    showStatus,
    id: userId,
    canEdit,
    data: results,
    isViewingAdminsProfile,
  } = data;

  if (isViewingAdminsProfile) {
    return null;
  }

  const displayHtml = (resultsLC: UserQualifications[]) => (
    <Grid>
      {resultsLC?.map?.((item) => {
        const statusOfQs = item?.status?.name || '';
        const statusOfQsIndex: number = [
          'PENDING',
          'APPROVED',
          'DENIED',
          'EXPIRED',
        ].indexOf(statusOfQs);
        const isVerifiedByFN: boolean = item?.qualification?.verification?.id === 3;

        return (
          <GridColumn xs="6" key={`${item.id}${item.category}`}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  openLicenseOrCertification(item, userId);
                }}
              >
                {item.name}
                {' '}
              </a>
              {' '}
              {isVerifiedByFN && statusOfQs === 'APPROVED' ? (
                <span>
                  <Status
                    inlineFlex
                    size="sm"
                    type={qsStatus[statusOfQsIndex].type as StatusType}
                  >
                    {qsStatus[statusOfQsIndex].label}
                  </Status>
                </span>
              ) : (
                <span>
                  {showStatus && isVerifiedByFN && (
                  <Status
                    inlineFlex
                    size="sm"
                    type={
                      item?.status?.id !== -1
                        && isBefore(
                          new Date((item?.expire_date?.utc || '').replace(/-/g, '/')),
                          new Date(),
                        )
                        ? qsStatus[3].type as StatusType // Expired
                        : qsStatus[item?.status?.id === -1 ? 2 : 0]
                          .type as StatusType // approved or pending
                      }
                  >
                    {
                      item?.status?.id !== -1
                      && isBefore(
                        new Date((item?.expire_date?.utc || '').replace(/-/g, '/')),
                        new Date(),
                      )
                        ? qsStatus[3].label // Expired
                        : qsStatus[item?.status?.id === -1 ? 2 : 0].label // approved or pending
                    }
                  </Status>
                  )}
                </span>
              )}
              {isManagedOrSelf && (
                <span>
                  {' '}
                  <IconButton
                    onClick={() => setEdit(item?.id || 0)}
                    name="edit"
                    size="sm"
                  />
                  <IconButton
                    onClick={() => setDelete(item?.id || 0)}
                    name="deleteSolid"
                    size="sm"
                  />
                </span>
              )}
            </div>
            {item.number && (
              <div>
                ID #:
                {item.number}
              </div>
            )}
            {item?.expire_date?.utc && (
              <div>
                Expires:
                {' '}
                {format(new Date((item?.expire_date?.utc).replace(/-/g, '/')), 'MM/dd/yyyy')}
              </div>
            )}
          </GridColumn>
        );
      })}
    </Grid>
  );

  const onClose = () => {
    setAdd(false);
    setEdit(0);
    setDelete(0);
  };

  return (
    <Padded>
      <Tile>
        <TileDivision
          heading="Licenses & certifications"
          count={results.length.toString()}
          actions={
            canEdit
              ? [
                {
                  icon: 'add',
                  onClick: () => setAdd(true),
                },
              ]
              : []
          }
        >
          {Boolean(isAdd) && <AddLicensesAndCertifications onClose={onClose} />}
          {Boolean(isEdit) && (
            <AddLicensesAndCertifications
              editId={isEdit}
              onClose={onClose}
              isEdit
            />
          )}
          {Boolean(isDelete) && (
            <DeleteLicensesAndCertifications
              deleteId={isDelete}
              onClose={onClose}
            />
          )}
          <Padded>
            {results && results.length ? (
              displayHtml(results)
            ) : (
              <div className="text-xs-center text-muted">
                <em>No licenses or certification added</em>
              </div>
            )}
          </Padded>
        </TileDivision>
      </Tile>
    </Padded>
  );
};

export default LicensesAndCertifications;
export {
  qsStatus as LicenseAndCertqcStatusType, openLicenseOrCertification,
};

export type { StatusType as LicenseAndCertStatusType };
