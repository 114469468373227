import { CredentialInputData, CustomFields, UserCredentialsDetails } from './types';
import { EVENTS } from '../Amplitude/useAmplitudeTracker';
import getUserCredentialStatus from './Helpers';
import { saveCredentials, updateCredentials } from './api';
import { error } from '../legacy/flash-messenger';

export const prepareUserCredentialUpdateData = (resp: UserCredentialsDetails) =>
  ({
      userCredentialId: Number(resp.id),
      credentialId: Number(resp?.credential?.id || 0),
      credentialIdNumber: String(resp?.credentialIdNumber || 0),
      issuingOrgId: resp?.state?.id || resp?.issuingOrg?.verificationId || 0,
      issuedAt: resp?.issuedAt ? new Date(
        (resp?.issuedAt || 0) * 1000,
      ).toLocaleDateString('en-US') : '',
      expiresAt: resp?.expiresAt ? new Date(
        (resp?.expiresAt || 0) * 1000,
      ).toLocaleDateString('en-US') : '',
      noExpireDate: !resp?.expiresAt,
      uploadImageUrl: undefined,
      file: undefined,
      isEdit: true,
      vendorId: `${resp?.state?.id || resp?.issuingOrg?.verificationId || 0}`,
      vendorName: resp?.state?.name || resp?.issuingOrg?.name?.split?.(':')?.[0],
      customInputs: resp?.customInputs,
  });

const prepareUserCredentialPayload = (payload: CredentialInputData) =>
  ({
    id: payload.userCredentialId || payload.id,
    issuingOrgId: payload.vendorId,
    credentialIdNumber: payload?.credentialIdNumber || '',
    uploadImageUrl: payload?.file,
    issuedAt: payload?.issuedAt,
    expiresAt: !payload?.noExpireDate ? payload?.expiresAt : undefined,
    customInputs: payload?.credential?.verifiedOn ? [
        {
            name: 'LicenseNumber',
            type: 'string',
            options: [],
            fieldName: 'LicenseNumber',
            fieldValue: payload?.credentialIdNumber,
        },
        ...payload?.fields || [],
    ] : payload?.fields,
    middleName: payload.middleName,
    email: payload.email,
    city: payload.city,
    state: payload.state,
    country: payload.country,
    zip: payload.zip,
    certOrgId: payload?.certOrgId,
    vendorName: payload?.vendorName,
    licensedForNames: payload?.typesOfWorks,
    credentialId: payload?.credentialId,
    companyId: window?.context?.company?.id || 0,
    companyName: window?.context?.company?.name || '',
    firstName: window?.context?.first_name || '',
    lastName: window?.context?.last_name || '',
  }) as CredentialInputData;

export const getIputedField = (fields: CustomFields[], key: string) =>
  (fields || [])?.find((field: CustomFields) => field.fieldName === key) as CustomFields;

export const getOtherFields = (fields: CustomFields[], key: string) =>
  ((fields || [])?.filter((field: CustomFields) => field.fieldName !== key) ||
    []) as CustomFields[];

export const upsertCredentials = async (
  lcInputs,
  userId,
  setLoading,
) => {
  const payload = prepareUserCredentialPayload(lcInputs);
  setLoading(true);
  const resp = lcInputs?.isEdit && lcInputs?.userCredentialId
  ? await updateCredentials(payload, userId, lcInputs.userCredentialId, {
    headers: {
        'x-user-id': userId,
    },
    onError: async (_res: Response, msg: any) => {
      error(msg?.message || 'An error occurred while saving credentials', 'Error');
      setLoading(false);
      return null;
    },
  }) :
  await saveCredentials(payload, userId, {
    headers: {
        'x-user-id': userId,
    },
    onError: async (_res: Response, msg: any) => {
      error(msg?.message || 'An error occurred while saving credentials', 'Error');
      setLoading(false);
      return null;
    },
  });
  setLoading(false);
  return resp;
}

export const trackAddCredentialsAmplitude = (
  trackOnAmplitude,
  selectedCredential,
  lcInputs,
  source,
) => trackOnAmplitude(EVENTS.ADDED_LICENSE_OR_CERTIFICATION, {
    Type: selectedCredential?.type || '',
    IssueDate: lcInputs?.issuedAt || '',
    ExpirationDate: lcInputs?.expiresAt || '',
    CredentialIdNumber: lcInputs?.credentialIdNumber || '',
    CustomFields: lcInputs?.fields || [],
    MassUpload: false,
    CredentialName: selectedCredential?.name || '',
    Action: 'Save',
    'Types of Work': lcInputs?.typesOfWorks,
    ...(source ? { Source: source } : {}),
});

export const trackEditCredentialsAmplitude = (
  trackOnAmplitude,
  selectedCredential,
  lcInputs,
  source,
) => trackOnAmplitude(EVENTS.EDITED_LICENSE_OR_CERTIFICATION, {
    Type: selectedCredential?.type || '',
    IssueDate: lcInputs?.issuedAt || '',
    ExpirationDate: lcInputs?.expiresAt || '',
    CredentialIdNumber: lcInputs?.credentialIdNumber || '',
    CustomFields: lcInputs?.fields || [],
    VerificationStatus: getUserCredentialStatus(lcInputs?.verified || 0),
    CredentialName: selectedCredential?.name || '',
    ...(source ? { Source: source } : {}),
});
