import React, { useEffect, useState } from 'react';
import {
  Loader,
} from '@fieldnation/platform-components';
import GroupList from '../GroupList/GroupList';
import { useRecoilValue } from 'recoil';
import { lcSelectDataQuery } from './Store/query';
import CredentialForm from '../LicenseCertificaton/CredentialForm';
import PropTypes from 'prop-types';
import ClearLogo from './Svg/ClearLogo';
import { AddEditCredentialsProps, CredentialSelect } from './types';
import css from './Styles.scss';
import { PAGE } from './Helpers';
import { EVENTS, useAmplitudeTracker } from '../Amplitude/useAmplitudeTracker';

const EnhancedCredentialsAdd = ({
  setHeaderText,
  triggerPrimary,
  triggerSecondary,
  setParentLoading,
  userId,
  onSubmitSuccessCallback,
  onClose,
  source,
  onPageChange,
}: AddEditCredentialsProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [pageShowing, setPageShowing] = useState<PAGE>(PAGE.SELECT_CREDENTIAL);
  const [actionButtonTriggered, setActionButtonTriggered] = useState<boolean>(triggerPrimary);
  const [listingError, setListingError] = useState<string>('');

  const [selectedLisCert, setSelectedLisCert] = useState<CredentialSelect | null>(null);
  const [indemandCredential, setIndemandCredential] = useState<GroupLists[]>([]);
  const [nonIndemandCredential, setNonIndemandCredential] = useState<GroupLists[]>([]);

  const lcSelectOptions = useRecoilValue(lcSelectDataQuery) as CredentialSelect[];

  const trackOnAmplitude = useAmplitudeTracker();

  useEffect(() => {
    if (typeof setParentLoading === 'function') {
      setParentLoading(loading);
    }
  }, [loading, setParentLoading])

  useEffect(() => {
    () => setSelectedLisCert(null);
  }, []);

  useEffect(() => {
    if (actionButtonTriggered !== triggerPrimary) {
      setActionButtonTriggered(!actionButtonTriggered);

      if (pageShowing === PAGE.SELECT_CREDENTIAL) {
        if (!selectedLisCert?.value) {
          setListingError('Please select a license or certification below');
          return;
        }

        if (listingError?.length) {
          setListingError('');
        }

        // Page changing
        setPageShowing(PAGE.ADD_EDIT_CREDENTIAL);

        trackOnAmplitude(EVENTS.STARTED_ADDING_LICENSE_OR_CERTIFICATION, {
          Type: selectedLisCert?.type || '',
          CredentialName: selectedLisCert?.label || '',
          Source: source,
          Action: 'Next',
        });
      }
    }
  }, [triggerPrimary, actionButtonTriggered]);

  useEffect(() => {
    setSelectedLisCert(null);
    // back to listing Page
    setPageShowing(PAGE.SELECT_CREDENTIAL);
  }, [triggerSecondary]);

  useEffect(() => {
    // inform parent about page change
    onPageChange?.(pageShowing);
  }, [pageShowing]);

  useEffect(() => {
    setIndemandCredential(lcSelectOptions
      ?.filter(({ inDemand }) => !!inDemand)
      ?.map((lcSelectOptions) => lcSelectOptions?.verifiedOn !== null
        ? {
          label: lcSelectOptions?.label,
          value: lcSelectOptions?.value,
          type: lcSelectOptions?.type,
          showSideComponent: true,
        }: {
          label: lcSelectOptions?.label,
          value: lcSelectOptions?.value,
          type: lcSelectOptions?.type,
          }
        ) as GroupLists[]
      );
    setNonIndemandCredential(lcSelectOptions
      ?.filter(({ inDemand }) => !inDemand)
      ?.map((lcSelectOptions) => ({
        label: lcSelectOptions?.label,
        value: lcSelectOptions?.value,
        type: lcSelectOptions?.type,
      })) as GroupLists[]
    );
  }, [lcSelectOptions])

  useEffect(() => {
    setHeaderText?.([
      pageShowing === PAGE.SELECT_CREDENTIAL && 'Select License or Certification',
      pageShowing === PAGE.ADD_EDIT_CREDENTIAL && selectedLisCert?.label && `Enter ${selectedLisCert?.label} details`,
      pageShowing === PAGE.ADD_EDIT_CREDENTIAL && 'Add License or Certification',
    ].find(i => i));
  }, [pageShowing, setHeaderText])

  return (
    <Loader isLoading={loading}>
      {(pageShowing === PAGE.SELECT_CREDENTIAL) && <GroupList
        items={[
          {
            name: 'IN DEMAND LICENSES',
            lists: indemandCredential,
          },
          {
            name: 'ALL LICENSES AND CERTIFICATES',
            lists: nonIndemandCredential,
          }
        ]}
        searchPlaceholder="Find license or certification..."
        errorMessage={listingError}
        onChange={(list) => setSelectedLisCert(list)}
        sideComponent={
          <div>
            <span className={css.sideComponent} >
              <span className={css.sideSubComponent}>{'| Verified by '}</span>
              <ClearLogo width={54} height={18} />
            </span>
          </div>
        }
      />}
      {pageShowing === PAGE.ADD_EDIT_CREDENTIAL &&
        <CredentialForm
          setParentLoading={setLoading}
          userId={userId}
          triggerSubmit={actionButtonTriggered}
          selectedCredentialId={Number(selectedLisCert?.value || 0)}
          onSubmitSuccessCallback={onSubmitSuccessCallback}
          onClose={onClose}
          source={source}
        />}
    </Loader>
  );
}

EnhancedCredentialsAdd.propTypes = {
  triggerPrimary: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  triggerSecondary: PropTypes.bool,
  source: PropTypes.string,
  setParentLoading: PropTypes.func,
  setHeaderText: PropTypes.func,
  onSubmitSuccessCallback: PropTypes.func,
  onClose: PropTypes.func,
  onPageChange: PropTypes.func,
};

EnhancedCredentialsAdd.defaultProps = {
  setParentLoading: () => {},
  onClose: () => {},
};
export default EnhancedCredentialsAdd;
