import * as React from 'react';
import { Icon, v2 } from '@fieldnation/platform-components';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { backoutsDetails } from '../queries/backouts-details.query';
import css from './backouts-details.scss';
import { backoutsTimelinessColumns, modalColumnComponents, renderColumn } from './utils';

const { Listing } = v2;

const BackoutsList = ({ tab }: { tab: string }): JSX.Element => {
  const { results } = useRecoilValue(backoutsDetails);
  const data = results.filter(({ causedBy }) => causedBy === tab);

  const renderSuccessMessage = () => (
    <div className={classNames(css['message-container'], 'text-xs-center')}>
      <div className={classNames(css['message-success'], css['message-icon'])}>
        <Icon
          size={48}
          name="completeSolid"
        />
      </div>
      <div>
        <div className="u-padding-top--md">
          {tab === 'provider' ? 'No cancellations.' : 'No removals.'}
        </div>
        <span className="text-muted" style={{ fontSize: '1.2rem' }}>
          This provider has completed all Work Orders assigned
        </span>
      </div>
    </div>
  );

  const columnLists = backoutsTimelinessColumns.filter(({ id }) => id !== 'checkinDateTime');
  const columnComponents = modalColumnComponents.filter(({ id }) => id !== 'checkinDateTime');

  if (data.length === 0) {
    return renderSuccessMessage();
  }

  return (
    <div className={css['bl-details-table']}>
      <Listing
        activeView="list"
        tableConfig={{
          data,
          columns: columnLists.map((column) => (
            {
              id: column.id,
              header: column.header,
              width: column.width || 150,
              render: (row) => renderColumn(column, row, columnComponents),
            }
          )),
        }}
        views={['list']}
      />
    </div>
  );
};

export default BackoutsList;
